import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { CellularProviderChoices, isValidCellularProvider, SimCard, SimCardFields } from "../../../../api/device";
import { useNewDeviceContext } from "../../../../contexts/newDeviceContext"

export const SimCardForm = () => {
  const {newDevice, updateSimCard} = useNewDeviceContext() as {newDevice: SimCard, updateSimCard: (item: Partial<SimCardFields>) => void}

  return (
    <>
      <Typography sx={{textDecoration: 'underline'}}>Sim Card Fields</Typography>
      <TextField
        label='Line'
        value={newDevice.line}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateSimCard({line: e.target.value})}
      />
      <TextField
        required
        label='Sim'
        value={newDevice.sim}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateSimCard({sim: e.target.value})}
      />
      <TextField
        label='Phone Number'
        value={newDevice.phone_number}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateSimCard({phone_number: e.target.value})}
      />
      <FormControl sx={{ minWidth: 400 }}>
        <InputLabel id="provider" required>Provider</InputLabel>
        <Select
          labelId="provider"
          value={newDevice.provider}
          label="Provider"
          onChange={(e: SelectChangeEvent<CellularProviderChoices>) => {
            if (isValidCellularProvider(e.target.value)) {
              updateSimCard({provider: e.target.value});
            }
          }}
        >
          <MenuItem key="t-mobile" value="t-mobile">
            T-Mobile
          </MenuItem>
          <MenuItem key="verizon" value="verizon">
            Verizon
          </MenuItem>
          <MenuItem key="att" value="att">
            ATT
          </MenuItem>
        </Select>
      </FormControl>
    </>
  )
}