import { Box } from "@mui/material";
import { Cancel, Elevator, LiveTv } from "@mui/icons-material";
import { RobotMaintenanceButton } from "./RobotMaintenanceButton";
import { TeleopActionButton } from "../../TeleopActionButton";
import { LoadingIndicator } from "../../../../../common/LoadingIndicator";
import { FloorTransitionModalType } from "./FloorTransitionModal";
import { useAlerts } from "../../../../../../hooks/useAlerts";
import { deActivateFloorTransitionScreen } from "../../../../../../api/robots";
import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";
import { useState } from "react";

interface IRobotMaintenanceFloorTransitionButtonsProps {
  onOpenFloorTransitionModal: (type: FloorTransitionModalType) => void;
}

export const RobotMaintenanceFloorTransitionButtons = ({
  onOpenFloorTransitionModal,
}: IRobotMaintenanceFloorTransitionButtonsProps) => {
  const {
    activeTeleop,
  } = useMissionManagementContext();

  const robot = activeTeleop?.robot;
  const robotSSHPort = robot?.device.ssh_port;

  const {addSuccessAlert, addErrorAlert} = useAlerts();

  const [deactivateFloorTransitionPending, setDeactivateFloorTransitionPending] = useState<boolean>(false);

  const onClickFloorTransition = () => {
    onOpenFloorTransitionModal(FloorTransitionModalType.sendMessage);
  };

  const onClickCheckRobotScreen = () => {
    onOpenFloorTransitionModal(FloorTransitionModalType.checkScreen);
  };

  const onClickDeactivateFloorTransitionScreen = async () => {
    if (robotSSHPort) {
      setDeactivateFloorTransitionPending(true);

      try {
        await deActivateFloorTransitionScreen(robotSSHPort);
        addSuccessAlert('Floor transition screen deactivated successfully');
      } catch (err) {
        console.error(err);
        addErrorAlert('Error deactivating floor transition screen');
      } finally {
        setDeactivateFloorTransitionPending(false);
      }
    }
  }

  return (
    <Box display="flex" gap="5px">
      <RobotMaintenanceButton
        onClick={onClickFloorTransition}
        endIcon={<Elevator/>}
        sx={{
          flex: 1
        }}
      >
        Floor Transition
      </RobotMaintenanceButton>
      <TeleopActionButton
        iconButton
        sx={{
          borderRadius: 'auto',
        }}
        onClick={onClickCheckRobotScreen}
      >
        <LiveTv/>
      </TeleopActionButton>
      <TeleopActionButton
        disabled={deactivateFloorTransitionPending}
        iconButton
        onClick={onClickDeactivateFloorTransitionScreen}
        sx={{
          borderRadius: 'auto',
        }}
      >
        {!deactivateFloorTransitionPending &&
          <Cancel/>
        }
        {deactivateFloorTransitionPending &&
          <LoadingIndicator size={16}/>
        }
      </TeleopActionButton>
    </Box>
  );
}