import { useMsal } from "@azure/msal-react";
import { useCallback, useRef } from "react";
import { loginRequest } from "../msAuthConfig";
import { formatTokenResponseData, msTokenGenerate } from "../api/auth";
import { useHandleDbTokenResponse } from "./useHandleDbTokenResponse";
import { useNavigation } from "./useNavigation";

export const useAcquireMsalToken = () => {
  const { navigateToLogin } = useNavigation();
  const { instance } = useMsal();
  const msalTokenAquisitionAttempted = useRef<boolean>(false);
  const handleTokenResponse = useHandleDbTokenResponse();

  const generateTokenFromMsToken = useCallback(async (token: string) => {
    try {
      const tokenResponse = await msTokenGenerate(token);
      handleTokenResponse(formatTokenResponseData(tokenResponse));
    } catch (e) {
      navigateToLogin("Unable to authenticate");
    }
  }, [handleTokenResponse, navigateToLogin]);

  return useCallback(async () => {
    if (instance) {
      await instance.initialize();
      const activeAccount = instance.getActiveAccount();

      if (activeAccount && !msalTokenAquisitionAttempted.current) {
        msalTokenAquisitionAttempted.current = true;

        instance.acquireTokenSilent(loginRequest).then((response) => {
          msalTokenAquisitionAttempted.current = true;
          if (response && response.accessToken) {
            generateTokenFromMsToken(response.accessToken);
          } else {
            msalTokenAquisitionAttempted.current = false;
          }
        }).catch((e) => {
          msalTokenAquisitionAttempted.current = false;
        });
      }
    } else {
      console.log("MSAL INSTANCE NOT FOUND")
      throw new Error("Msal instance not found");
    }
  }, [generateTokenFromMsToken, instance]);
}