import { Box, Button, Typography } from "@mui/material";
import { FullScreenModal, ModalTitle } from "../../../../common/FullScreenModal";

export enum RequiresConfirmation {
  none,
  stop_mission,
  skip_task,
  resume_mission,
}

interface IRoverActionConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  type: RequiresConfirmation;
  onReceiveResponse: (response: boolean) => void;
}

export const RoverActionConfirmationModal = ({
  open,
  onClose,
  type,
  onReceiveResponse,
}: IRoverActionConfirmationModalProps) => {
  let taskToExecute = '';

  if (type === RequiresConfirmation.skip_task) {
    taskToExecute = 'SKIP TASK';
  } else if (type === RequiresConfirmation.stop_mission) {
    taskToExecute = 'STOP MISSION';
  } else if (type === RequiresConfirmation.resume_mission) {
    taskToExecute = 'RESUME MISSION';
  }

  return (
    <FullScreenModal
      open={open}
      onClose={onClose}
    >
      <ModalTitle>
        Please Confirm
      </ModalTitle>
      <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: '18px' }}>
        Confirming will execute: <b>{taskToExecute}</b>
      </Typography>
      <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
        <Button
          onClick={() => onReceiveResponse(false)}
        >
          Close
        </Button>
        <Button
          variant='contained'
          onClick={() => onReceiveResponse(true)}
        >
          Confirm
        </Button>
      </Box>
    </FullScreenModal>
  )
}