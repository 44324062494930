import { useCallback, useEffect, useRef, useState } from "react";
import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";
import { useRoverPose } from "../../../../../../hooks/teleopHooks/useRoverPose";
import { MapViewer } from "../../Map/MapViewer";
import { SequenceLine } from "../../Map/SequenceLine";
import { FloorPoints } from "../../Map/FloorPoints";
import { Arrow } from "../../Map/Arrow";
import { PathPoints } from "../../Map/PathPoints";
import { RobotMarker } from "../../Map/RobotMarker";
import { LidarPoints } from "../../Map/LidarPoints";
import { useRoverTeleopActions } from "../../../../../../hooks/teleopHooks/useRoverTeleopActions";
import { ProgressRegions } from "../../Map/ProgressRegions";
import { MapImageOverlay } from "./MapImageOverlay";
import styled from 'styled-components';
import { MapPointContextMenu } from "../../Map/MapPointContextMenu";

export const MissionMapImage = () => {
  const {
    relocalizeRobot,
  } = useRoverTeleopActions();

  const {
    activeTeleop
  } = useMissionManagementContext();

  const {
    floors,
    selectedFloor,
    panning,
  } = activeTeleop || {};

  const [loadedImg, setLoadedImg] = useState<string>('');
  const [showArrow, setShowArrow] = useState<boolean>(false);
  const [arrowAngle, setArrowAngle] = useState<number>(0);
  const [arrowX, setArrowX] = useState<number>(0);
  const [arrowY, setArrowY] = useState<number>(0);
  const [scale, setScale] = useState<number>(0.2);
  const [mouseDown, setMouseDown] = useState<boolean>(false);

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const showMap = !!floors && !!selectedFloor;
  const mapImageLoaded = loadedImg === selectedFloor?.latest_floor_plan?.web_image_url;
  const showMapOverlay = showMap && mapImageLoaded && !showArrow && !mouseDown;

  const {
    mapX,
    setMapX,
    mapY,
    setMapY
  } = useRoverPose(mapContainerRef, scale);

  const onPressEscape = useCallback(() => {
    if (showArrow) {
      setShowArrow(false);
      setArrowX(0);
      setArrowY(0);
      setArrowAngle(0);
    }
  }, [showArrow]);

  const onKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onPressEscape();
    }
  }, [onPressEscape]);

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    }
  }, [onKeyDown]);

  const activateArrow = useCallback((x: number | string, y: number | string) => {
    setArrowX(parseInt(x.toString()));
    setArrowY(parseInt(y.toString()));
    setShowArrow(true);
  }, []);

  const getPointerCoordinates = (e: React.MouseEvent) => {
    return {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
    };
  }

  const onDoubleClickMap = (e: React.MouseEvent) => {
    const {x,y} = getPointerCoordinates(e);

    activateArrow(x, y);
  }

  const onMouseUp = (e: MouseEvent) => {
    if (showArrow) {
      const angleToSend = ((arrowAngle * (180 / Math.PI)) + 90) % 360;
      
      relocalizeRobot(arrowX, arrowY, angleToSend);
      setShowArrow(false);
    }

    setMouseDown(false);
  }

  const onMouseDown = () => {
    setMouseDown(true);
  }

  const onMouseMove = (e: React.MouseEvent) => {
    if (showArrow) {
      const {x,y} = getPointerCoordinates(e);
      
      const theta = Math.atan2(y - arrowY, x - arrowX);
      setArrowAngle(theta);
    }
  }

  return (
    <div ref={mapContainerRef} style={{height: '100%', position: 'relative'}}>
      <div style={{position: 'absolute'}}>
        {showMap &&
          <MapViewer
            image={selectedFloor?.latest_floor_plan?.web_image_url}
            setLoadedImg={setLoadedImg}
            imageLoaded={mapImageLoaded}
            x={mapX}
            updateX={(newX: number) => setMapX(newX)}
            y={mapY}
            updateY={(newY: number) => setMapY(newY)}
            disablePan={!panning}
            onDoubleClick={onDoubleClickMap}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
            scale={scale}
            setScale={setScale}
          >
            <SequenceLine/>
            <FloorPoints
              activateArrow={activateArrow}
              pointerEvents={showArrow ? "none" : undefined}
            />
            <ProgressRegions
              pointerEvents={showArrow ? "none" : undefined}
            />
            { showArrow && 
              <Arrow
                angleInRadians={arrowAngle}
                x={arrowX}
                y={arrowY}
              />
            }
            <PathPoints/>
            <RobotMarker/>
            <LidarPoints/>
            <MapPointContextMenu
              mapScale={scale}
            />
          </MapViewer>
        }
      </div>
      {showMapOverlay &&
        <MapOverlayContainer
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        >
          <MapImageOverlay/>
        </MapOverlayContainer>
      }
    </div>
  )
}

const MapOverlayContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`;