import { createContext, useContext, useEffect, useMemo, useState, useCallback, useRef } from "react";
import { IMissionManagementState } from "./missionManagementContext";
import { useRobotSocketWorker } from "../hooks/useRobotSocketWorker";
import { startCopilot, stopCopilot } from "../api/copilot";
import { green, yellow, red, orange } from "@mui/material/colors";

export type CopilotWarning = "EMERGENCY STOP" | "STOP" | "EXTREME CAUTION" | "CAUTION" | "GOOD";

type CopilotBoundary = [number, number][];

interface CopilotSegmentation {
  boundary: CopilotBoundary,
  score: number;
}

interface CopilotRequest {
  port: number;
  mission_id: string;
  terminate: boolean;
}

interface CopilotPrediction {
  segmentations: CopilotSegmentation[];
}

export enum CopilotClass {
  DRIVEABLE_CLASS = 0,
  NON_DRIVEABLE_CLASS = 1,
  CLIFF_EDGE = 2,
  OBSTRUCTION = 3,
}

export const defaultCopilotClassColors: Record<CopilotClass, string> = {
  [CopilotClass.DRIVEABLE_CLASS]: green[500],
  [CopilotClass.NON_DRIVEABLE_CLASS]: yellow[500],
  [CopilotClass.CLIFF_EDGE]: red[500],
  [CopilotClass.OBSTRUCTION]: orange[500],
}

export const defaultActiveCopilotClasses: CopilotClass[] = [CopilotClass.NON_DRIVEABLE_CLASS, CopilotClass.CLIFF_EDGE, CopilotClass.OBSTRUCTION];

export interface ICopilotState {
  copilotActive: boolean;
  fillCopilotPolygons: boolean;
  copilotPolygonOpacity: number;
  copilotSocket: Worker | undefined;
  copilotSocketOpen: boolean;
  copilotOutput: CopilotOutput | null;
  activateCopilot: () => Promise<void>;
  deactivateCopilot: () => Promise<void>;
  activeCopilotClasses: Set<CopilotClass>;
  copilotClassColors: Record<CopilotClass, string>;
}

export const initialCopilotState: ICopilotState = {
  copilotActive: false,
  fillCopilotPolygons: true,
  copilotPolygonOpacity: 0.4,
  copilotSocket: undefined,
  copilotSocketOpen: false,
  copilotOutput: null,
  activateCopilot: async () => {},
  deactivateCopilot: async () => {},
  activeCopilotClasses: new Set([...defaultActiveCopilotClasses]),
  copilotClassColors: {...defaultCopilotClassColors},
}

export interface CopilotOutput {
  warning: CopilotWarning;
  predictions: Partial<Record<CopilotClass, CopilotPrediction>>;
  request: CopilotRequest;
  frame_no: number;
}

interface CopilotMessageData {
  event_type: string;
  message: CopilotOutput;
}

export const copilotPredictionStreamWidth = 360;
export const copilotPredictionStreamHeight = 270;

interface ICopilotContext {
  copilotOutput: CopilotOutput | null;
}

const initialCopilotContext: ICopilotContext = {
  copilotOutput: null,
};

const CopilotContext = createContext<ICopilotContext>({...initialCopilotContext});

interface ICopilotProviderProps {
  parentState?: IMissionManagementState;
  updateMission?: (identifier: string, stateUpdate: Partial<IMissionManagementState>, activate?: boolean) => void;
  identifier?: string;
}

export const CopilotProvider = ({
  children,
  parentState,
  updateMission,
  identifier,
}: React.PropsWithChildren<ICopilotProviderProps>) => {
  const [copilotOutput, setCopilotOutput] = useState<CopilotOutput | null>(initialCopilotContext.copilotOutput);
  const cleanupPerformed = useRef<boolean>(false);

  const {
    socket: copilotSocket,
    socketOpen,
    connectToSocket,
    disconnectFromSocket,
  } = useRobotSocketWorker();

  const updateParentState = useCallback((stateUpdate: Partial<IMissionManagementState>, activate?: boolean) => {
    if (updateMission && identifier) {
      updateMission(identifier, stateUpdate, activate);
    }
  }, [updateMission, identifier]);

  const onReceiveCopilotMessage = useCallback((event: MessageEvent) => {
    const eventData: CopilotMessageData = event.data;

    if (eventData.event_type === 'warning') {
      setCopilotOutput(eventData.message);
    }
  }, []);

  const activateCopilot = useCallback(async () => {
    cleanupPerformed.current = false;
    setCopilotOutput(null);

    const robot = parentState?.robot;

    if (robot) {
      updateParentState({copilotActive: true});

      const robotPublicId = robot.robot_details.public_id;
      const robotVideoPort = robot.robot_details.video_port;

      await startCopilot(robotVideoPort, robotPublicId);
      connectToSocket(`wss://services.nexterarobotics.com/ws/copilot/${robotPublicId}/`);
      copilotSocket.addEventListener('message', onReceiveCopilotMessage);
    }
  }, [connectToSocket, copilotSocket, onReceiveCopilotMessage, parentState?.robot, updateParentState]);

  const deactivateCopilot = useCallback(async () => {
    cleanupPerformed.current = true;
    setCopilotOutput(null);

    const robot = parentState?.robot;

    if (robot) {
      updateParentState({...initialCopilotState});

      const robotPublicId = robot.robot_details.public_id;
      const robotVideoPort = robot.robot_details.video_port;

      await stopCopilot(robotVideoPort, robotPublicId);
      disconnectFromSocket();
      copilotSocket.removeEventListener('message', onReceiveCopilotMessage);
    }
  }, [copilotSocket, disconnectFromSocket, onReceiveCopilotMessage, parentState?.robot, updateParentState]);

  useEffect(() => {
    if (updateParentState) {
      updateParentState({
        copilotSocket: copilotSocket,
        copilotSocketOpen: socketOpen,
        copilotOutput: copilotOutput,
        activateCopilot: activateCopilot,
        deactivateCopilot: deactivateCopilot,
      });
    }
  }, [activateCopilot, copilotOutput, copilotSocket, deactivateCopilot, socketOpen, updateParentState]);

  useEffect(() => {
    return () => {
      if (socketOpen && !cleanupPerformed.current) {
        deactivateCopilot();
      }
    }
  }, [deactivateCopilot, socketOpen]);

  const value = useMemo(() => {
    return {
      copilotOutput,
    }
  }, [copilotOutput]);

  return (
    <CopilotContext.Provider value={value}>
      {children}
    </CopilotContext.Provider>
  );
}

export const useCopilotContext = () => {
  return useContext(CopilotContext);
};