import { useCallback, useState } from "react"
import { RoverSocketOnMessageCallback, useRoverSocketOnMessage } from "./useRoverSocketOnMessage";

export interface LidarPoints {
  x: number[];
  y: number[];
}

export const useRoverLidarPoints = () => {
  const [lidarPoints, setLidarPoints] = useState<LidarPoints | null>({x: [], y: []});

  const onReceiveLidarPointsMessage: RoverSocketOnMessageCallback = useCallback(({message_type, message_version, message}) => {
    if (message_type === 'lidar') {
      if (message_version === 1) {
        setLidarPoints(message);
      }
    }
  }, []);

  const resetLidarPoints = useCallback(() => {
    setLidarPoints({x: [], y: []});
  }, []);

  useRoverSocketOnMessage(onReceiveLidarPointsMessage, resetLidarPoints);

  return lidarPoints;
}