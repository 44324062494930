import { useState, useEffect, useMemo } from 'react';
import { Box, TextField, Button, Switch, FormControlLabel, Autocomplete, CircularProgress, Select, MenuItem, FormControl, InputLabel } from "@mui/material"
import { Schedule } from '../../../../../../api/schedules';
import { CreateScriptOptions, Mission, fetchAllMissions, simpleCreateScript } from '../../../../../../api/missions';
import { useMissionManagementContext } from '../../../../../../contexts/missionManagementContext';
import { useSchedulesQuery } from '../../../../../../hooks/queries';
import { useAlerts } from '../../../../../../hooks/useAlerts';

export type ScanTask = 'scan' | 'teleop';

interface IMissionScriptGenerationControls {
  onChangeRobotScript: (newScript: Record<string, any> | null) => void;
  selectedRecentMission: Mission | null;
  onChangeSelectedRecentMission: (newMission: Mission | null) => void;
}

export const MissionScriptGenerationControls = ({
  onChangeRobotScript,
  selectedRecentMission,
  onChangeSelectedRecentMission,
}: IMissionScriptGenerationControls) => {
  const {
    activeTeleop,
    updateActiveMission,
  } = useMissionManagementContext();

  const {addErrorAlert} = useAlerts();

  const {
    schedule,
    schedulePoints,
    robot,
    startPoint,
    finalGoal,
    lidarRecord,
    networkRecord,
    selectedScanTask,
    project,
  } = activeTeleop || {};

  const [scriptGenerationInProgress, setScriptGenerationInProgress] = useState<boolean>(false);
  const [recentRobotMissions, setRecentRobotMissions] = useState<Mission[]>([]);
  const [recentRobotMissionsLoaded, setRecentRobotMissionsLoaded] = useState<boolean>(false);

  const {data: schedules} = useSchedulesQuery();

  const scriptGenerationDisabled = !startPoint || !finalGoal || !robot || !schedule || !selectedScanTask;

  const scheduleOptions = useMemo(() => {
    if (schedules) {
      return schedules.filter(schedule => {
        return schedule.project_id === project?.public_id;
      });
    }

    return schedules ?? [];
  }, [project?.public_id, schedules]);

  useEffect(() => {
    const roverPublicId = robot?.robot_details.public_id;
    const twoWeeksAgo = new Date(new Date(new Date().setDate(new Date().getDate() - 14)));

    if (roverPublicId && !recentRobotMissionsLoaded) {
      fetchAllMissions({
        robotPublicId: roverPublicId,
        startDateGTE: twoWeeksAgo
      }).then(returnedMissions => {
        returnedMissions.sort((a, b) => b.id - a.id);

        setRecentRobotMissions(returnedMissions);
        setRecentRobotMissionsLoaded(true);
      });
    }
  }, [robot?.robot_details.public_id, recentRobotMissionsLoaded]);

  const onChangeLidarRecord = (checked: boolean) => {
    updateActiveMission({
      lidarRecord: checked,
    });
  };

  const onClickGenerateScript = async () => {
    if (!scriptGenerationDisabled) {
      setScriptGenerationInProgress(true);

      const createScriptOptions: CreateScriptOptions = {
        lidar_record: !!lidarRecord,
        starting_goal: startPoint.point_id,
        final_goal: finalGoal.point_id,
        image_upload: true,
        scan_task: selectedScanTask,
        network_record: !!networkRecord,
      }

      const scriptScheduleId = selectedRecentMission?.schedule_id ?? schedule.id;

      simpleCreateScript(scriptScheduleId, robot.robot_details.public_id, createScriptOptions)
        .then(returnedScript => {
          onChangeRobotScript(returnedScript);
        })
        .catch(err => {
          addErrorAlert('Error generating script');
        })
        .finally(() => {
          setScriptGenerationInProgress(false);
        });
    }
  }

  const onChangeSelectedScanTask = (newTask: ScanTask) => {
    updateActiveMission({selectedScanTask: newTask});

    if (newTask === 'teleop') {
      onChangeSelectedRecentMission(null);
    }
  }

  const onChangeSchedule = (e: React.SyntheticEvent, value: Schedule | null) => {
    updateActiveMission({
      schedule: value,
    });
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={lidarRecord}
              onChange={(e, checked) => onChangeLidarRecord(checked)}
            />
          }
          label="Lidar Record"
        />
        <FormControlLabel
          control={
            <Switch
              checked={networkRecord}
              onChange={(e, checked) => updateActiveMission({networkRecord: checked})}
            />
          }
          label="Network Record"
        />
      </Box>
      <Box
        display="flex"
        gap={1}
      >
        <Box flex={1}>
          <FormControl sx={{width: '100%'}}>
            <InputLabel>Scan Type</InputLabel>
            <Select
              value={selectedScanTask}
              label="Scan Type"
              onChange={e => onChangeSelectedScanTask(e.target.value as ScanTask)}
            >
              <MenuItem value='scan'>Mission</MenuItem>
              <MenuItem value='teleop'>Teleop</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {selectedScanTask === 'scan' && 
          <Box flex={1}>
            <Autocomplete
              loading={!recentRobotMissionsLoaded}
              value={selectedRecentMission}
              onChange={(e, value) => onChangeSelectedRecentMission(value)}
              options={recentRobotMissions ?? []}
              getOptionLabel={option => `${option.id.toString()} - ${option.schedule_name}`}
              renderInput={(params) => <TextField {...params} label="Resume Recent Mission" />}
            />
          </Box>
        }
      </Box>
      <Box
        display="flex"
        gap={1}
      >
        <Box flex={1}>
          <Autocomplete
            disabled={!!selectedRecentMission}
            options={scheduleOptions}
            value={schedule}
            getOptionLabel={option => option.name ?? ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={onChangeSchedule}
            renderInput={(params) => {
              return (
                <TextField {...params} label="Schedule" />
              )
            }}
          />
        </Box>
        <Box
          flex={1}
          display="flex"
          gap={1}
          alignItems="center"
        >
          <Box
            flex={1}
          >
            <Autocomplete
              value={startPoint}
              onChange={(e, value) => updateActiveMission({startPoint: value})}
              options={schedulePoints ?? []}
              getOptionLabel={option => option.point_id.toString()}
              renderInput={(params) => <TextField {...params} required label="Start Point" />}
            />
          </Box>
          <Box
            flex={1}
          >
            <Autocomplete
              value={finalGoal}
              onChange={(e, value) => updateActiveMission({finalGoal: value})}
              options={schedulePoints ?? []}
              getOptionLabel={option => option.point_id.toString()}
              renderInput={(params) => <TextField {...params} required label="Final Goal" />}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gap="10px"
      >
        <Button
          disabled={scriptGenerationDisabled}
          variant='contained'
          onClick={onClickGenerateScript}
        >
          Generate Script
        </Button>
        {scriptGenerationInProgress &&
          <CircularProgress size={25}/>
        }
      </Box>
    </Box>
  )
}
