import { useCallback, useState } from "react"
import { RoverSocketOnMessageCallback, useRoverSocketOnMessage } from "./useRoverSocketOnMessage";

export const useRoverFlipperAngle = (teleopIdentifier?: string) => {
  const [flipperAngle, setFlipperAngle] = useState<number | null>(null);

  const onReceiveFlipperAngleMessage: RoverSocketOnMessageCallback = useCallback(({message_type, message_version, message}) => {
    if (message_type === 'flipper_angle') {
      
      if (message_version === 1) {   
        const currAngle = parseInt(message.angle);
        setFlipperAngle(currAngle);
      }
    }
  }, []);

  const onResetFlipperAngle = useCallback(() => {
    setFlipperAngle(null);
  }, []);

  useRoverSocketOnMessage(onReceiveFlipperAngleMessage, onResetFlipperAngle, teleopIdentifier);

  return flipperAngle;
}