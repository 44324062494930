import axios from "axios";
import { API_BASE } from "./constants";

export interface Version {
  id: number;
  description: string;
  major_version: number;
  minor_version: number;
  patch_version: number;
  version: string;
}

export interface SoftwareVersion extends Version {};

export const fetchSoftwareVersions = async (): Promise<SoftwareVersion[]> => {
  const res = await axios.get(
    `${API_BASE}/software-versions`
  );

  const response = await res.data;
  return response.data;
}

export const createSoftwareVersion = async (versionToCreate: SoftwareVersion): Promise<SoftwareVersion> => {
  const res = await axios.post(
    `${API_BASE}/software-version`,
    versionToCreate
  );

  const response = await res.data;
  return response.data;
}

export const editSoftwareVersion = async (id: number, versionToUpdate: Partial<SoftwareVersion>) => {
  const res = await axios.patch(
    `${API_BASE}/software-version/${id}`,
    versionToUpdate,
  );

  const response = await res.data;
  return response.data;
}

export interface HardwareVersion extends Version {};

export const fetchHardwareVersions = async (): Promise<HardwareVersion[]> => {
  const res = await axios.get(
    `${API_BASE}/hardware-versions`
  );

  const response = await res.data;
  return response.data;
}

export const createHardwareVersion = async (versionToCreate: HardwareVersion): Promise<HardwareVersion> => {
  const res = await axios.post(
    `${API_BASE}/hardware-version`,
    versionToCreate
  );

  const response = await res.data;
  return response.data;
}

export const editHardwareVersion = async (id: number, versionToUpdate: Partial<HardwareVersion>) => {
  const res = await axios.patch(
    `${API_BASE}/hardware-version/${id}`,
    versionToUpdate,
  );

  const response = await res.data;
  return response.data;
}