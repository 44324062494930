import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";
import { CreateMissionTab } from "./CreateMissionTab";
import { MissionInProgressTab } from "./MissionInProgressTab";

export const MissionTab = () => {
  const {
    missions,
    activeTeleop,
  } = useMissionManagementContext();

  const mission = activeTeleop?.mission;
  
  if (Object.getOwnPropertyNames(missions).length === 0) {
    return (
      <>Add Robot To Start Mission</>
    )
  } else if (!mission) {
    return (
      <CreateMissionTab/>
    )
  } else {
    return (
      <MissionInProgressTab/>
    )
  }
}