import { Box, Checkbox, Chip, FormControlLabel, Grid, TableCell, Typography } from "@mui/material"
import React, { useMemo, useState } from "react";
import { Schedule } from "../../../api/schedules";
import { useSchedulesQuery } from "../../../hooks/queries";
import { Breadcrumb } from "../../common/Breadcrumb";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { projectDropdownAllKey, ProjectsAutocomplete } from "../../common/Dropdowns";
import { HeadCell, Table } from "../../common/Table";
import { Project } from "../../../api/projects";
import { TableRow } from "../../common/TableRow";
import { useGeneratedPaths } from "../../../hooks/useGeneratedPaths";

export const Schedules = () => {
  const {
    generateScheduleDetailsPath,
  } = useGeneratedPaths();

  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const { data: schedules, isLoading: schedulesLoading } = useSchedulesQuery();

  const dataLoaded = !schedulesLoading && !!schedules;

  const rows = useMemo(() => {
    if (!dataLoaded) {
      return [];
    }

    return schedules.filter((schedule: Schedule) => {
      const matchesSelectedProject = !selectedProject || selectedProject.public_id === projectDropdownAllKey || schedule.project_id === selectedProject.public_id;
      const matchesShowInactive = showInactive || !schedule.next_scan_expired;

      return matchesSelectedProject && matchesShowInactive;
    });
  }, [dataLoaded, schedules, selectedProject, showInactive]);

  const columns: readonly HeadCell<Schedule>[] = [
    { id: 'name', label: 'Name' },
    { id: 'project_name', label: 'Project' },
    { id: 'frequency', label: 'Frequency' },
    { id: 'start_date', label: 'Next Scan' },
    { id: 'is_active', label: 'Status', align: 'center' }
  ];

  const renderVisibleRows = (row: Schedule, index: number) => {
    const statusChipColor = row.next_scan_expired ? 'offline' : 'success';
    const statusChipLabel = row.next_scan_expired ? 'Expired' : 'Active';
    const redirectTo = generateScheduleDetailsPath(row.id);

    return (
      <TableRow
        hover
        redirectTo={redirectTo}
        role="checkbox"
        tabIndex={-1}
        key={`${row.id}-${row.name}`}
      >
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left">{row.project_name}</TableCell>
        <TableCell align="left">{row.frequency}</TableCell>
        <TableCell align="left">{row.next_scan_expired ? 'Expired' : row.next_scan_formatted}</TableCell>
        <TableCell align="right">
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Chip label={statusChipLabel} color={statusChipColor} sx={{ color: 'white' }}/>
          </Box>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Grid container spacing={4} direction='column'>
      <Grid item>
        <Grid container alignItems='center'>
          <Grid item>
            <Typography variant='h5'>
              Schedules
            </Typography>
            <Breadcrumb
              items={[{text: 'Schedules'}]}
            />
          </Grid>
          <Grid item flexGrow={1}/>
          <Grid item>
            <FormControlLabel
              control={(
                <Checkbox
                  value={showInactive}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setShowInactive(checked)}
                />
              )}
              label='Show Inactive'
            />
          </Grid>
          <Grid item>
            <ProjectsAutocomplete
              includeAllOption
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              width={400}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        { !dataLoaded &&
          <LoadingIndicator/>
        }

        { dataLoaded &&
          <Table
            columns={columns}
            rows={rows}
            initialOrderedColumnName='name'
            renderVisibleRows={renderVisibleRows}
          />
        }
      </Grid>
    </Grid>
  )
}