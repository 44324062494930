import { useReducer, createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { UPDATE_USER_INPUT_STATE, UserInputState, userInputReducer } from '../components/views/Missions/MissionTeleop/RoverInformation/SafetyTab/userInputReducer';
import { useRoverTeleopActions } from '../hooks/teleopHooks/useRoverTeleopActions';
import { SafetyDetectors, useTeleopSafetyStatus } from '../hooks/teleopHooks/useTeleopSafetyStatus';

const Safety_Detector_Cooldown = 3000;

export interface ISafetyTabContext {
  cooldownInEffect: boolean;
  userInputState: UserInputState;
  onChangeSafetyDetectors: (detectorUpdates: [string, boolean][]) => void;
  isSafetyDetectorDisabled: (detector: string) => boolean;
  getDetectorState: (detector: string) => { enabled: boolean, triggered: boolean, temp: boolean };
  teleopSafetyStatus: SafetyDetectors | null;
  timeElapsedSinceLastUpdate: number;
}

const initialSafetyTabContext: ISafetyTabContext = {
  cooldownInEffect: false,
  userInputState: {},
  onChangeSafetyDetectors: () => {},
  isSafetyDetectorDisabled: () => false,
  getDetectorState: () => ({ enabled: false, triggered: false, temp: false }),
  teleopSafetyStatus: null,
  timeElapsedSinceLastUpdate: Infinity,
}

const SafetyTabContext = createContext<ISafetyTabContext>({...initialSafetyTabContext});

export const SafetyTabProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [now, setNow] = useState<number>(Date.now());
  const [userInputState, dispatchUserInputState] = useReducer(userInputReducer, {});

  const {updateSafetyDetectors} = useRoverTeleopActions();
  const teleopSafetyStatus = useTeleopSafetyStatus();

  const cooldownInEffect = useMemo(() => {
    return Object.values(userInputState).some(detector => now - detector.executed_on < Safety_Detector_Cooldown);
  }, [now, userInputState]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 500);

    return () => {
      clearInterval(interval);
    }
  }, []);

  const onChangeSafetyDetectors = useCallback((detectorUpdates: [string, boolean][]) => {
    const userInputStateUpdate = detectorUpdates.reduce((acc: UserInputState, [detector, checked]) => {
      acc[detector] = {
        desired_state: checked,
        executed_on: Date.now(),
      };

      return acc;
    }, {});

    const safetyDetectorUpdate = detectorUpdates.reduce((acc: Record<string, {enabled: boolean}>, [detector, checked]) => {
      acc[detector] = {
        enabled: checked
      };

      return acc;
    }, {});

    dispatchUserInputState({
      type: UPDATE_USER_INPUT_STATE,
      payload: userInputStateUpdate
    });

    updateSafetyDetectors(safetyDetectorUpdate);
  }, [updateSafetyDetectors]);

  const isSafetyDetectorDisabled = useCallback((detector: string) => {
    return !!userInputState[detector] && now - userInputState[detector].executed_on < Safety_Detector_Cooldown;
  }, [now, userInputState]);

  const getDetectorState = useCallback((detector: string) => {
    const detectorUserInputState = userInputState[detector];
    const detectorTeleopSafetyStatus = teleopSafetyStatus?.detectors[detector];

    if (!detectorTeleopSafetyStatus) {
      return { enabled: false, triggered: false, temp: false };
    }

    const returnTempValue = !!detectorUserInputState && now - detectorUserInputState.executed_on < 1000 && detectorUserInputState.desired_state !== detectorTeleopSafetyStatus.enabled;

    if (!returnTempValue) {
      return {...detectorTeleopSafetyStatus, temp: false};
    } else {
      return { enabled: detectorUserInputState.desired_state, triggered: false, temp: true }
    }
  }, [now, teleopSafetyStatus?.detectors, userInputState]);

  const timeElapsedSinceLastUpdate = useMemo(() => {
    if (teleopSafetyStatus) {
      return now - teleopSafetyStatus.received_on;
    }

    return Infinity;
  }, [now, teleopSafetyStatus]);

  return (
    <SafetyTabContext.Provider
      value={{
        cooldownInEffect: cooldownInEffect,
        userInputState: userInputState,
        onChangeSafetyDetectors: onChangeSafetyDetectors,
        isSafetyDetectorDisabled: isSafetyDetectorDisabled,
        getDetectorState: getDetectorState,
        teleopSafetyStatus: teleopSafetyStatus,
        timeElapsedSinceLastUpdate: timeElapsedSinceLastUpdate,
      }}
    >
      {children}
    </SafetyTabContext.Provider>
  )
}

export const useSafetyTabContext = () => {
  return useContext(SafetyTabContext);
};
