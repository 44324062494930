import { useState, useEffect, createRef, useMemo } from "react";
import { FullScreenModal, ModalTitle } from "../../../../../common/FullScreenModal";
import { Box, Button } from "@mui/material"
import { RobotMarker } from "../../Map/RobotMarker";
import { MapViewer } from "../../Map/MapViewer";
import { getMapCoordinates } from "../../../../../../hooks/teleopHooks/useRoverPose";
import { Stairs, StairsOutlined } from "@mui/icons-material";
import { TeleopActionButton } from "../../TeleopActionButton";
import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";

interface IStairConfirmationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
}

export const StairConfirmationModal = ({
  open,
  setOpen,
  onConfirm,
}: IStairConfirmationModalProps) => {

  const {
    activeTeleop,
  } = useMissionManagementContext();

  const {
    floors,
    selectedFloor,
    drawerSelectedStairClimberScript,
  } = activeTeleop || {};

  const [mapX, setMapX] = useState<number>(0);
  const [mapY, setMapY] = useState<number>(0);
  const [scale, setScale] = useState<number>(0.7);
  const [loadedImg, setLoadedImg] = useState('');
  const [initialLocationSet, setInitialLocationSet] = useState<boolean>(false);
  const [flickerBool, setFlickerBool] = useState<boolean>(false);
  const mapContainerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    const flickerTimeout = setTimeout(() => {
      setFlickerBool(prevValue => !prevValue);
    }, 500);

    return () => {
      clearTimeout(flickerTimeout);
    }
  }, [flickerBool]);
  
  const onCloseModal = () => {
    setOpen(false);
  }

  const selectedStairClimberScriptFloor = useMemo(() => {
    if (!!selectedFloor && drawerSelectedStairClimberScript) {
      const selectedFloorCode = selectedFloor.floor_code;
    
      return drawerSelectedStairClimberScript.floors.find((floor) => floor.project_floor.floor_code === selectedFloorCode);
    }

    return undefined
  }, [drawerSelectedStairClimberScript, selectedFloor]);

  useEffect(() => {
    if (!initialLocationSet && selectedStairClimberScriptFloor && mapContainerRef && mapContainerRef.current) {
      const newScale = 0.7;

      const {
        x: convertedX,
        y: convertedY,
      } = getMapCoordinates(mapContainerRef.current, newScale, selectedStairClimberScriptFloor.approach_pose_x, selectedStairClimberScriptFloor.approach_pose_y);

      setMapX(convertedX);
      setMapY(convertedY);
      setInitialLocationSet(true);
      setScale(newScale);
    }
  }, [setScale, mapContainerRef, initialLocationSet, selectedStairClimberScriptFloor]);

  return (
    <FullScreenModal
      open={open}
      onClose={onCloseModal}
      sx={{
        top: '50%'
      }}
    >
      <ModalTitle>
        Confirm Staircase
      </ModalTitle>
      {(!!floors && !!selectedFloor) &&
        <div ref={mapContainerRef} style={{height: '500px', position: 'relative', overflow: 'hidden'}}>
          <div style={{position: 'absolute'}}>
            <MapViewer
              image={selectedFloor.latest_floor_plan.web_image_url}
              setLoadedImg={setLoadedImg}
              imageLoaded={loadedImg === selectedFloor.latest_floor_plan.web_image_url}
              x={mapX}
              updateX={(newX: number) => setMapX(newX)}
              y={mapY}
              updateY={(newY: number) => setMapY(newY)}
              scale={scale}
              setScale={setScale}
            >
              {selectedStairClimberScriptFloor &&
                <Box
                  style={{
                    position: 'absolute',
                    top: `${selectedStairClimberScriptFloor.approach_pose_y}px`,
                    left: `${selectedStairClimberScriptFloor.approach_pose_x}px`,
                    transform: 'translate(-50%, -50%)',
                    pointerEvents: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }} 
                >
                  <TeleopActionButton
                    iconButton
                    color="primary"
                    variant='contained'
                  >
                    {flickerBool ? <Stairs/> : <StairsOutlined/>}
                  </TeleopActionButton>
                </Box>
              }        
              <RobotMarker/>
            </MapViewer>
          </div>
        </div>
      }
      <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
        <Button
          onClick={onCloseModal}
        >
          Close
        </Button>
        <Button
          variant='contained'
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Box>
    </FullScreenModal>
  )
}