import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import { isValidPiModel, PiModelChoices, RaspberryPi, RaspberryPiFields } from "../../../../api/device";
import { useNewDeviceContext } from "../../../../contexts/newDeviceContext";

export const RaspberryPiForm = () => {
  const {
    newDevice, 
    updateRaspberryPi
  } = useNewDeviceContext() as {newDevice: RaspberryPi, updateRaspberryPi: (item: Partial<RaspberryPiFields>) => void};

  return (
    <>
      <Typography sx={{textDecoration: 'underline'}}>Raspberry Pi Fields</Typography>
      <FormControl sx={{ minWidth: 400 }}>
        <InputLabel id="model" required>Model</InputLabel>
        <Select
          labelId="model"
          value={newDevice.model}
          label="Provider"
          onChange={(e: SelectChangeEvent<PiModelChoices>) => {
            if (isValidPiModel(e.target.value)) {
              updateRaspberryPi({model: e.target.value});
            }
          }}
        >
          <MenuItem key="4B" value="4B">
            4B
          </MenuItem>
          <MenuItem key="Zero" value="Zero">
            Zero
          </MenuItem>
          <MenuItem key="ZeroW" value="ZeroW">
            ZeroW
          </MenuItem>
          <MenuItem key="Zero2W" value="Zero2W">
            Zero2W
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        label='Gateway IP'
        value={newDevice.gateway_ip}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateRaspberryPi({gateway_ip: e.target.value})}
      />
      <TextField
        required
        label='Username'
        value={newDevice.username}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateRaspberryPi({username: e.target.value})}
      />
    </>
  )
}