import { SafetyDetectorControl } from "./SafetyDetectorControl";
import { ISafetyTabContext, useSafetyTabContext } from "../../../../../../contexts/safetyTabContext";
import { SafetyDetectorStatus } from "../../../../../../hooks/teleopHooks/useTeleopSafetyStatus";

export const SafetyDetectorControls = () => {
  const {
    onChangeSafetyDetectors,
    isSafetyDetectorDisabled,
    getDetectorState,
    teleopSafetyStatus,
  } = useSafetyTabContext() as ISafetyTabContext & { teleopSafetyStatus: SafetyDetectorStatus};

  return (
    <>
      {Object.getOwnPropertyNames(teleopSafetyStatus.detectors).map(detector => {
        const {enabled, triggered, temp} = getDetectorState(detector);
        const switchDisabled = isSafetyDetectorDisabled(detector);

        return (
          <SafetyDetectorControl
            key={detector}
            detector={detector}
            enabled={enabled}
            triggered={triggered}
            temp={temp}
            onChangeSwitch={checked => onChangeSafetyDetectors([[detector, checked]])}
            switchDisabled={switchDisabled}
          />
        );
      })}
    </>
  );
}