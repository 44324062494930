import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { isValidRadioModel, Radio, RadioFields, RadioModel } from "../../../../api/device";
import { useNewDeviceContext } from "../../../../contexts/newDeviceContext"

export const RadioForm = () => {
  const {newDevice, updateRadio} = useNewDeviceContext() as {newDevice: Radio, updateRadio: (item: Partial<RadioFields>) => void};

  return (
    <>
      <Typography sx={{textDecoration: 'underline'}}>Radio Fields</Typography>
      <TextField
        required
        label='MAC Address'
        value={newDevice.mac_address}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateRadio({mac_address: e.target.value})}
      />
      <TextField
        required
        type='number'
        label='Channel Width'
        value={newDevice.channel_width}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateRadio({channel_width: parseInt(e.target.value)})}
      />
      <TextField
        required
        type='number'
        label='Channel Center'
        value={newDevice.channel_center}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateRadio({channel_center: parseInt(e.target.value)})}
      />
      <FormControl sx={{ minWidth: 400 }}>
        <InputLabel id="model">Model</InputLabel>
        <Select
          labelId="model"
          value={newDevice.model}
          label="Model"
          onChange={(e: SelectChangeEvent<RadioModel>) => {
            if (isValidRadioModel(e.target.value)) {
              updateRadio({model: e.target.value});
            }
          }}
        >
          <MenuItem key="XE" value="XE">
            XE
          </MenuItem>
          <MenuItem key="XM" value="XM">
            XM
          </MenuItem>
          <MenuItem key="XEC" value="XEC">
            XEC
          </MenuItem>
          <MenuItem key="XMC" value="XMC">
            XMC
          </MenuItem>
          <MenuItem key="PE" value="PE">
            PE
          </MenuItem>
          <MenuItem key="PEC" value="PEC">
            PEC
          </MenuItem>
        </Select>
      </FormControl>
    </>
  )
}