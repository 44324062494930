import { Box, FormControlLabel, Switch } from "@mui/material";
import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";
import { useCallback, useMemo } from "react";

export const MissionMapControls = () => {
  const {
    activeTeleop,
    updateActiveMission,
  } = useMissionManagementContext();

  const regionBasedToggleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updateActiveMission({
      regionBased: checked,
      selectedPoint: null,
      selectedProgressRegion: null,
    });
  }, [updateActiveMission]);

  const displayRegionBasedToggle = useMemo(() => {
    if (!activeTeleop?.schedule || activeTeleop?.schedulePoints.length === 0 || !activeTeleop?.mission) {
      return false;
    }

    return activeTeleop.schedulePoints.some(point => point.progress_regions.length > 0);
  }, [activeTeleop?.mission, activeTeleop?.schedule, activeTeleop?.schedulePoints]);

  return (
    <Box
      display="flex"
    >
      <FormControlLabel
        control={(
          <Switch
            checked={activeTeleop?.tracking}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => updateActiveMission({ tracking: checked })}
          />
        )}
        label='Tracking'
      />
      <FormControlLabel
        control={(
          <Switch
            checked={activeTeleop?.panning}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => updateActiveMission({ panning: checked })}
          />
        )}
        label='Panning'
      />
      <FormControlLabel
        control={(
          <Switch
            checked={activeTeleop?.sequence}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => updateActiveMission({ sequence: checked })}
          />
        )}
        label='Sequence'
      />
      {displayRegionBasedToggle &&
        <FormControlLabel
          control={(
            <Switch
              checked={activeTeleop?.regionBased}
              onChange={regionBasedToggleOnChange}
            />
          )}
          label='Region Based'
        />
      }
    </Box>
  )
}