import { createContext, useContext, useReducer } from "react";
import { useMatch } from "react-router-dom";
import { DeviceDetails, initialDevice } from "../api/device";
import { useDeviceQuery } from "../hooks/queries";
import { PATH_STRINGS } from "../hooks/useGeneratedPaths";

interface DeviceDetailsState {
  device: DeviceDetails | undefined;
  deviceId: string;
  deviceLoaded: boolean;
}

const initialDeviceDetails: DeviceDetails = {
  device: initialDevice,
}

const initialDeviceState: DeviceDetailsState = {
  device: undefined,
  deviceId: '',
  deviceLoaded: false,
}

const DeviceDetailsContext = createContext(initialDeviceState);

const deviceDetailsReducer = (state: DeviceDetails, payload: Partial<DeviceDetails>) => {
  return {
    ...state,
    ...payload
  };
}

export const DeviceDetailsProvider = ({children}: React.PropsWithChildren<{}>) => {
  const deviceMatch = useMatch(PATH_STRINGS.deviceDetails);
  const deviceId: string = deviceMatch?.params.deviceId || '';
  const [device, dispatchDevice] = useReducer(deviceDetailsReducer, initialDeviceDetails);
  const {isLoading: deviceLoading} = useDeviceQuery(deviceId, dispatchDevice);
  const deviceLoaded = !!device && !deviceLoading;

  return (
    <DeviceDetailsContext.Provider
      value={{
        device,
        deviceId,
        deviceLoaded,
      }}>
      {children}
    </DeviceDetailsContext.Provider>
  );
};

export const useDeviceDetailsContext = () => {
  const deviceDetails = useContext(DeviceDetailsContext);

  return deviceDetails;
};
