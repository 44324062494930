import { useState } from "react"
import { Autocomplete, Box, TextField } from "@mui/material";
import { MissionMapControls } from "./MissionMapControls";
import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";
import { ProjectFloor } from "../../../../../../api/projectFloors";

export const MapImageOverlay = () => {
  const {
    activeTeleop,
    updateActiveMission,
  } = useMissionManagementContext();

  const floors = activeTeleop?.floors;
  const selectedFloor = activeTeleop?.selectedFloor;
  const showFloorSelect = !!activeTeleop?.mission;

  const [hovering, setHovering] = useState<boolean>(false);

  const onChangeProjectFloor = (e: React.SyntheticEvent, value: ProjectFloor | null) => {
    updateActiveMission({
      selectedFloor: value,
      selectedPoint: null,
    });
  }

  const onMouseEnter = () => {
    setHovering(true);
  }

  const onMouseLeave = () => {
    setHovering(false);
  }

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        opacity: hovering ? 0.8 : 0,
        padding: '10px',
        width: '100%',
        backgroundColor: 'black',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <MissionMapControls/>
      {showFloorSelect &&
        <Box>
          <Autocomplete
            options={floors ?? []}
            value={selectedFloor}
            getOptionLabel={option => option.floor_code}
            onChange={onChangeProjectFloor}
            sx={{
              width: '150px',
            }}
            renderInput={(params) => {
              return (
                <TextField {...params} label="Floor" />
              )
            }}
          />
        </Box>
      }
    </div>
  )
}