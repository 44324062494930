import { useCallback } from "react";
import { useTimeoutTakeover } from "./useTimeoutTakeover";
import { useMissionManagementContext } from "../../contexts/missionManagementContext";
import { pauseMissionMessage, resumeMissionMessage, skipCheckpointMessage } from "../../contexts/teleopContext";
import { ProjectSchedulePoint } from "../../api/schedules";
import { RoverNode } from "./useRoverSensorConfiguration";

export const useRoverTeleopActions = () => {
  const {
    activeTeleop,
    updateActiveMission,
  } = useMissionManagementContext();

  const timeoutTakeover = useTimeoutTakeover();

  const {
    socket,
    socketOpen,
    selectedPoint,
    project,
    mission,
    robot,
    selectedProgressRegion,
    selectedFloor,
    regionBased,
    networkRecord: networkRecordToggledOn,
  } = activeTeleop || {};

  const socketReadyForMessage = !!socket && socketOpen;

  const postMessage = useCallback((message: any) => {
    if (socketReadyForMessage) {
      socket.postMessage(message);
    }
  }, [socket, socketReadyForMessage]);

  const pauseMission = useCallback(() => {
    postMessage(pauseMissionMessage);
  }, [postMessage]);

  const resumeMission = useCallback(() => {
    postMessage(resumeMissionMessage);
  }, [postMessage]);

  const performNetworkRecord = useCallback((args: Record<string, any>) => {
    postMessage({
      message_type: "perform_operation",
      message_version: 1,
      message: {
          action: "network_record",
          args: args
      }
    });
  }, [postMessage]);

  const takePicture = useCallback((robot: any, args: any) => {
    let action = "camera_360"
    if(robot.robot_details.software){
      if(robot.robot_details.software.major_version >= 1 && robot.robot_details.software.minor_version >= 7){
        action = "image_record"
      }
    }

    postMessage({
      message_type: 'perform_operation',
      message_version: 1,
      message: {
        action: action,
        args: args
      }
    });
  }, [postMessage]);

  const teleopTakePicture = useCallback(() => {
    takePicture(robot, {});
  }, [robot, takePicture]);

  const missionTakePicture = useCallback(() => {
    if (!regionBased && !selectedPoint) {
      alert("Error! Please select a point to take a picture");
      return
    } else if (regionBased && !selectedProgressRegion) {
      alert("Error! Please select a progress region to take a picture");
      return;
    } else if (!mission || !project || !selectedFloor) {
      alert("Error! Mission not currently in progress");
      return
    }

    const pictureMetaData = {
      point_id: regionBased ? selectedProgressRegion?.viewpoint_sub_id : selectedPoint?.point_id,
      region_id: regionBased ? selectedProgressRegion?.id : null,
      project_floor_id: selectedFloor.id,
      mission_id: mission.id,
      project_id: project.id,
      angle: 0,
      file_path: "",
    }

    takePicture(robot, pictureMetaData);

    //if taking a picture at a point, also do a network record
    if (!regionBased && networkRecordToggledOn) {
      performNetworkRecord({
        point_id: pictureMetaData.point_id,
        project_id: pictureMetaData.project_id,
        project_floor_id: pictureMetaData.project_floor_id,
        mission_id: pictureMetaData.mission_id,
      });
    }

    updateActiveMission({
      selectedPoint: null,
      selectedProgressRegion: null,
    });
  }, [
    regionBased,
    selectedPoint,
    selectedProgressRegion,
    mission,
    project,
    selectedFloor,
    takePicture,
    robot,
    updateActiveMission,
    networkRecordToggledOn,
    performNetworkRecord,
  ]);

  const setTarget = useCallback((targetPoint: ProjectSchedulePoint) => {
    postMessage({
      message_type: 'mission_action',
      message_version: 1,
      message: {
        action: "general_action",
        args: {
          name: "go_to_point",
          point_id: targetPoint.point_id,
          x: parseFloat(targetPoint.x.toString()),
          y: parseFloat(targetPoint.y.toString()),
        }
      }
    });
  }, [postMessage]);

  const skipToPoint = useCallback((targetPoint: ProjectSchedulePoint) => {
    const regionId = (!!targetPoint.progress_regions && !!targetPoint.progress_regions[0]) ? targetPoint.progress_regions[0].id : null;

    postMessage({
      message_type: "mission_action",
      message_version: 1,
      message: {
        action: "general_action",
        args: {
            name: "skip_to_point",
            point_id: targetPoint.point_id,
            region_id: regionId
        }
      }
    });
  }, [postMessage]);

  const stopMission = useCallback(() => {
    postMessage({
      message_type: 'mission_action',
      message_version: 1,
      message: {
        action: 'stop_mission',
        args: {}
      }
    });
  }, [postMessage]);

  const skipTask = useCallback(() => {
    postMessage({
      message_type: 'mission_action',
      message_version: 1,
      message: {
        action: 'skip_task',
        args: {}
      }
    });
  }, [postMessage]);

  const moveFlipper = useCallback((angle: number) => {
    postMessage({
      message_type: 'move_flipper',
      message_version: 1,
      message: {'angle': angle}
    });
  }, [postMessage]);

  const setDefaultFlipperAngle = useCallback(() => {
    postMessage({
      message_type: "default_flipper",
      message_version: 1,
      message: {
        message: "set_default",
        angle: null,
      }
    });
  }, [postMessage]);

  const relocalizeRobot = useCallback((x: number, y: number, angle: number) => {
    postMessage({
      message_type: 'set_pose',
      message_version: 1,
      message: {
        x: x,
        y: y,
        angle: parseFloat(angle.toFixed(2))
      }
    });
  }, [postMessage]);

  const moveAction = useCallback((x: number, y: number) => {
    const _x = parseFloat((x / 100).toFixed(2));
    const _y = parseFloat((y / 100).toFixed(2));

    postMessage({
      message_type: 'move_linear',
      message_version: 2,
      message: {
        x: _x,
        y: _y
      }
    });

    timeoutTakeover();
  }, [postMessage, timeoutTakeover]);

  const skipCheckpoint = useCallback(() => {
    updateActiveMission({
      requestSkipPoint: false
    });

    postMessage(skipCheckpointMessage);
  }, [updateActiveMission, postMessage]);

  const startMission = useCallback((script: string) => {
    try {
      const jsonScript = JSON.parse(script);

      postMessage({
        message_type: 'mission_action',
        message_version: 1,
        message: {
          action: 'start_mission',
          args: jsonScript
        }
      });
    } catch (error: any) {
      // renderMessage("Script Parse Error, Invalid JSON Script" + error.toString())
    }
  }, [postMessage]);

  const executeUploadImageCommand = useCallback(() => {
    postMessage({
      message_type: 'perform_operation',
      message_version: 1,
      message: {
        action: 'image_upload',
        args: {}
      }
    });
  }, [postMessage]);

  const executeUploadBagCommand = useCallback(() => {
    postMessage({
      message_type: 'perform_operation',
      message_version: 1,
      message: {
        action: 'bag_upload',
        args: {}
      }
    });
  }, [postMessage]);

  const toggleRobotMode = useCallback(() => {
    postMessage({
      message_type: 'mode',
      message_version: 1,
      message: {
        action: 'toggle',
        args: {}
      }
    });
  }, [postMessage]);

  const updateSafetyDetectors = useCallback((message: Record<string, {enabled: boolean}>) => {
    postMessage({
      message_type: 'ui_safety_features',
      message_version: 1,
      message: message
    });
  }, [postMessage]);

  const restartNode = useCallback((nodeName: RoverNode) => {
    postMessage({
      message_type: 'sensor_configuration',
      message_version: 1,
      message: {
        action: "RESTART_NODE",
        args: [nodeName]
      }
    });
  }, [postMessage]);

  const removeNodes = useCallback((nodesToRemove: RoverNode[]) => {
    postMessage({
      message_type: 'sensor_configuration',
      message_version: 1,
      message: {
        action: "REMOVE_NODES",
        args: nodesToRemove
      }
    });
  }, [postMessage]);

  return {
    pauseMission,
    resumeMission,
    teleopTakePicture,
    missionTakePicture,
    setTarget,
    skipToPoint,
    stopMission,
    skipTask,
    moveFlipper,
    setDefaultFlipperAngle,
    relocalizeRobot,
    moveAction,
    skipCheckpoint,
    startMission,
    executeUploadImageCommand,
    executeUploadBagCommand,
    toggleRobotMode,
    updateSafetyDetectors,
    restartNode,
    removeNodes,
  }
}
