import arrow from '../../../../../assets/images/arrow.svg'

interface IArrowProps {
  angleInRadians: number;
  x: number;
  y: number;
}

const height = 60;

export const Arrow = ({
  angleInRadians,
  x,
  y,
}: IArrowProps) => {
  return (
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        height: `${height}px`,
        width: `${height * 3}px`,
        transformOrigin: `0px ${height / 2}px`,
        transform: `rotate(${angleInRadians}rad)`,
        top: `${y - height / 2}px`,
        left: `${x}px`,
      }}
    >
      <img
        src={arrow}
        alt="localization arrow"
      />
    </div>
  )
}