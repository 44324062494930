import { Box, Button } from "@mui/material";
import { useCallback, useMemo } from "react";
import { ISafetyTabContext, useSafetyTabContext } from "../../../../../../contexts/safetyTabContext";
import { SafetyDetectorStatus } from "../../../../../../hooks/teleopHooks/useTeleopSafetyStatus";

export const SafetyDetectorControlButtons = () => {
  const {
    cooldownInEffect,
    onChangeSafetyDetectors,
    teleopSafetyStatus,
  } = useSafetyTabContext() as ISafetyTabContext & { teleopSafetyStatus: SafetyDetectorStatus};

  const activateAllDisabled = useMemo(() => {
    return cooldownInEffect || Object.values(teleopSafetyStatus.detectors).every(detector => detector.enabled);
  }, [cooldownInEffect, teleopSafetyStatus.detectors]);

  const deactivateAllDisabled = useMemo(() => {
    return cooldownInEffect || Object.values(teleopSafetyStatus.detectors).every(detector => !detector.enabled);
  }, [cooldownInEffect, teleopSafetyStatus.detectors]);

  const bulkUpdateSafetyDetectors = useCallback((desiredState: boolean) => {
    const detectorUpdates = (
      Object.entries(teleopSafetyStatus.detectors)
        .filter(([_, status]) => status.enabled !== desiredState)
        .map(([detector, _]) => [detector, desiredState] as [string, boolean])
    );
    onChangeSafetyDetectors(detectorUpdates);
  }, [onChangeSafetyDetectors, teleopSafetyStatus.detectors]);

  const onClickActivateAll = useCallback(() => {
    bulkUpdateSafetyDetectors(true);
  }, [bulkUpdateSafetyDetectors]);

  const onClickDeactivateAll = useCallback(() => {
    bulkUpdateSafetyDetectors(false);
  }, [bulkUpdateSafetyDetectors]);

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="5px"
    >
      <Button
        disabled={activateAllDisabled}
        onClick={onClickActivateAll}
      >
        Activate All
      </Button>
      <Button
        disabled={deactivateAllDisabled}
        onClick={onClickDeactivateAll}
      >
        Deactivate All
      </Button>
    </Box>
  );
}