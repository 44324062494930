import { useState, useCallback, useMemo } from "react"
import { Box, Chip, ChipProps, TableCell, Typography } from '@mui/material';
import { Breadcrumb } from '../../common/Breadcrumb';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { HeadCell, Table } from '../../common/Table';
import { TableRow } from "../../common/TableRow";
import { Project, ProjectStatus } from "../../../api/projects";
import { useProjectsQuery } from "../../../hooks/queries";
import { useGeneratedPaths } from "../../../hooks/useGeneratedPaths";

const projectStatuses: ProjectStatus[] = ['active', 'hold', 'done'];
const projectStatusColors: Record<ProjectStatus, ChipProps["color"]> = {
  active: "success",
  done: "error",
  hold: "warning"
}

export const Projects = () => {
  const {
    generateProjectDetailsPath,
  } = useGeneratedPaths();

  const [selectedStatusFilters, setSelectedStatusFilters] = useState<ProjectStatus[]>(['active']);
  const {data: projects, isLoading: projectsLoading} = useProjectsQuery();

  const rows = useMemo(() => {
    if (projects) {
      if (selectedStatusFilters.length === 0) {
        return projects;
      } else {
        return projects.filter(project => selectedStatusFilters.includes(project.status))
      }
    }

    return [];
  }, [projects, selectedStatusFilters]);

  const columns: readonly HeadCell<Project>[] = useMemo(() => [
    { id: 'name', label: 'Name' },
    { id: 'location', label: 'Location'},
    { id: 'status', label: 'Status'}
  ], []);

  const renderVisibleRows = useCallback((row: Project, index: number) => {
    const redirectTo = generateProjectDetailsPath(row.public_id);

    return (
      <TableRow
        hover
        redirectTo={redirectTo}
        role="checkbox"
        tabIndex={-1}
        key={row.public_id}
      >
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left">{`${row.location.city}, ${row.location.state_name}`}</TableCell>
        <TableCell align="left">{row.status}</TableCell>
      </TableRow>
    )
  }, [generateProjectDetailsPath]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Box>
        <Typography variant='h5'>
          Projects
        </Typography>
        <Breadcrumb
          items={[{text: 'Projects'}]}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gap="5px"
      >
        {projectStatuses.map(status => {
          const selected = selectedStatusFilters.includes(status);

          return (
            <Chip
              key={status}
              variant={selected ? 'filled' : 'outlined'}
              label={status}
              color={projectStatusColors[status]}
              sx={{color: 'white'}}
              onClick={() => {
                if (selected) {
                  setSelectedStatusFilters(prevFilters => prevFilters.filter(filter => filter !== status));
                } else {
                  setSelectedStatusFilters(prevFilters => [...prevFilters, status]);
                }
              }}
            />
          )
        })}
      </Box>
      <Box>
        { projectsLoading &&
          <LoadingIndicator/>
        }

        { !projectsLoading &&
          <Table
            columns={columns}
            rows={rows}
            initialOrderedColumnName='name'
            renderVisibleRows={renderVisibleRows}
          />
        }
      </Box>
    </Box>
  );
}