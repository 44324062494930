import { useReducer, createContext, useContext } from 'react';

export const defaultCameraGain = 0;
export const defaultCameraGamma = 225;

export type CameraSize = '640x480' | '480x360' | '360x270';
export const isValidCameraSize = (keyInput: string): keyInput is CameraSize => {
  return ['640x480', '480x360', '360x270'].includes(keyInput);
}

export type CameraTransport = 'raw' | 'compressed';
export const isValidCameraTransport = (keyInput: string): keyInput is CameraTransport => {
  return ['raw', 'compressed'].includes(keyInput);
}

export enum CameraTopic {
  ONE = '/camera/usb_cam1/image_raw',
  TWO = '/camera/usb_cam2/image_raw'
}

interface VideoState {
  cameraGain: number;
  cameraGamma: number;
  cameraQuality: number;
  invert: boolean;
  selectedCameraSize: CameraSize;
  selectedCameraTransport: CameraTransport;
  selectedCameraTopic: CameraTopic;
  feedKey: Date;
  drawerOpen: boolean;
}

const initialState: VideoState = {
  cameraQuality: 30,
  invert: true,
  selectedCameraSize: '360x270',
  selectedCameraTransport: 'compressed',
  selectedCameraTopic: CameraTopic.ONE,
  cameraGain: defaultCameraGain,
  cameraGamma: defaultCameraGamma,
  feedKey: new Date(),
  drawerOpen: false,
};

interface IVideoContext {
  state: VideoState,
  dispatch: React.Dispatch<Partial<VideoState>>;
}

const initialVideoContext: IVideoContext = {
  state: {...initialState},
  dispatch: () => {},
}

const VideoContext = createContext<IVideoContext>({...initialVideoContext});

const videoReducer = (state: VideoState, action: Partial<VideoState>) => {
  return {
    ...state,
    ...action,
  }
}

export const VideoProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(videoReducer, {...initialState});

  return (
    <VideoContext.Provider value={{state, dispatch}}>
      {children}
    </VideoContext.Provider>
  )
}

export const useVideoContext = () => {
  return useContext(VideoContext);
};
