import React, {createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState} from 'react';
import { useMatch } from 'react-router-dom';
import { Device, DeviceCategory, DeviceDetails, DeviceType, fetchDevice, GatewayFields, initialDevice, ModemFields, RadioFields, RaspberryPiFields, Robot, RobotDetailsFields, RobotNetworkFields, SimCardFields } from '../api/device';
import { useDeviceTypesQuery } from '../hooks/queries';
import { PATH_STRINGS } from '../hooks/useGeneratedPaths';

const UPDATE_DEVICE = 'UPDATE_DEVICE';
const UPDATE_DEVICE_TYPE = 'UPDATE_DEVICE_TYPE';
const UPDATE_GENERAL_FIELDS = 'UPDATE_GENERAL_FIELDS';
const UPDATE_SIM_CARD = 'UPDATE_SIM_CARD';
const UPDATE_RADIO = 'UPDATE_RADIO';
const UPDATE_RASPBERRY_PI = 'UPDATE_RASPBERRY_PI';
const UPDATE_MODEM = 'UPDATE_MODEM';
const UPDATE_GATEWAY = 'UPDATE_GATEWAY';
const UPDATE_ROBOT_DEVICE = 'UPDATE_ROBOT_DEVICE';
const UPDATE_ROBOT_DETAILS = 'UPDATE_ROBOT_DETAILS';

type UpdateDevice = {type: typeof UPDATE_DEVICE, payload: Partial<DeviceDetails>}
type UpdateDeviceTye = {type: typeof UPDATE_DEVICE_TYPE, payload: DeviceCategory}
type UpdateGeneralFields = {type: typeof UPDATE_GENERAL_FIELDS, payload: Partial<Device>};
type UpdateSimCard = {type: typeof UPDATE_SIM_CARD, payload: Partial<SimCardFields>};
type UpdateRadio = {type: typeof UPDATE_RADIO, payload: Partial<RadioFields>};
type UpdateRaspberryPi = {type: typeof UPDATE_RASPBERRY_PI, payload: Partial<RaspberryPiFields>}
type UpdateModem = {type: typeof UPDATE_MODEM, payload: Partial<ModemFields>};
type UpdateGateway = {type: typeof UPDATE_GATEWAY, payload: Partial<GatewayFields>};
type UpdateRobotDevice = {type: typeof UPDATE_ROBOT_DEVICE, payload: Partial<RobotNetworkFields>};
type UpdateRobotDetails = {type: typeof UPDATE_ROBOT_DETAILS, payload: Partial<RobotDetailsFields>};

type NewDeviceAction = UpdateDevice | UpdateDeviceTye | UpdateGeneralFields | UpdateSimCard | UpdateRadio | UpdateRaspberryPi | UpdateModem | UpdateGateway | UpdateRobotDevice | UpdateRobotDetails;

const initialNewDevice = {device: {...initialDevice}};

interface NewDeviceState {
  newDevice: DeviceDetails,
  dispatch: React.Dispatch<NewDeviceAction>;
  deviceTypes: DeviceType[] | undefined;
  deviceTypesLoading: boolean;
  isNewDevice: boolean,
  deviceLoaded: boolean;
  isValidForSubmit: boolean;
}

const initialState: NewDeviceState = {
  newDevice: initialNewDevice,
  dispatch: (action: NewDeviceAction) => {},
  deviceTypes: [],
  deviceTypesLoading: false,
  isNewDevice: true,
  deviceLoaded: false,
  isValidForSubmit: false,
}

const NewDeviceContext = createContext(initialState);

const newDeviceReducer = <T extends DeviceDetails>(state: T, action: NewDeviceAction) => {
  switch (action.type) {
    case UPDATE_GENERAL_FIELDS:
      return {
        ...state,
        device: {
          ...state.device,
          ...action.payload
        }
      }
    case UPDATE_DEVICE_TYPE:
      const resetDevice: any = {
        device: {
          ...state.device,
          type: action.payload,
        }
      };

      if (action.payload === 'robot') {
        resetDevice.robot_details = {}
      }

      return resetDevice;
    case UPDATE_ROBOT_DETAILS:
      return {
        ...state,
        robot_details: {
          ...(state as Robot).robot_details,
          ...action.payload,
        }
      }
    default: {
      return {
        ...state,
        ...action.payload
      }
    }
  }
}

export const NewDeviceProvider = ({children}: React.PropsWithChildren<{}>) => {
  const deviceMatch = useMatch(PATH_STRINGS.manageDevice);
  const deviceId: string = deviceMatch?.params.deviceId || '';
  const {data: deviceTypes, isLoading: deviceTypesLoading} = useDeviceTypesQuery();
  const [newDevice, dispatch] = useReducer(newDeviceReducer, initialNewDevice);
  const [deviceLoaded, setDeviceLoaded] = useState<boolean>(false);

  const simCardValidForSubmit = !!newDevice.sim && !!newDevice.provider;
  const robotValidForSubmit = !!newDevice.robot_details && !!newDevice.robot_details.category;
  const radioValidForSubmit = !!newDevice.mac_address && !!newDevice.channel_width && !!newDevice.channel_center;
  const modemValidForSubmit = !!newDevice.model && !!newDevice.mac_address && !!newDevice.imei && !!newDevice.type;
  const piValidForSubmit = !!newDevice.model && !!newDevice.username;

  const locationFieldValid = !!newDevice.device.project_public_id === !!newDevice.device.project_floor || !!newDevice.device.auxiliary_location;
  
  const unacknowledgedGatewayModemConflict = !!newDevice.modem && !!newDevice.modem.conflict && !newDevice.modem.reassign;
  const unacknowledgedGatewayRadioConflict = !!newDevice.radio && !!newDevice.radio.conflict && !newDevice.radio.reassign;
  const unacknowledgedGatewayPiConflict = !!newDevice.raspberrypi && !!newDevice.raspberrypi.conflict && !newDevice.raspberrypi.reassign;

  const gatewayValidForSubmit = (!newDevice.modem || !unacknowledgedGatewayModemConflict) &&
                                (!newDevice.radio || !unacknowledgedGatewayRadioConflict) &&
                                (!newDevice.raspberrypi || !unacknowledgedGatewayPiConflict);

  const isValidForSubmit = useMemo(() => {
    const deviceType = newDevice.device.type;

    return (
      !!newDevice.device.name &&
      !!newDevice.device.serial_number &&
      !!deviceType && 
      (deviceType === 'sim-card' || !!newDevice.device.ssh_port) &&
      locationFieldValid &&
      (simCardValidForSubmit || deviceType !== 'sim-card') &&
      (robotValidForSubmit || deviceType !== 'robot') &&
      (radioValidForSubmit || deviceType !== 'radio') && 
      (modemValidForSubmit || deviceType !== 'modem') &&
      (piValidForSubmit || deviceType !== 'raspberrypi') && 
      (gatewayValidForSubmit || deviceType !== 'gateway')
    )
  }, [
    newDevice,
    locationFieldValid,
    simCardValidForSubmit,
    robotValidForSubmit,
    radioValidForSubmit,
    modemValidForSubmit,
    piValidForSubmit,
    gatewayValidForSubmit,
  ]);

  useEffect(() => {
    setDeviceLoaded(false);
    
    if (deviceId !== 'new') {
      fetchDevice(deviceId).then((fetchedDevice) => {
        dispatch({
          type: UPDATE_DEVICE,
          payload: fetchedDevice,
        });
        setDeviceLoaded(true);
      });
    } else {
      dispatch({
        type: UPDATE_DEVICE,
        payload: {...initialNewDevice},
      });
      setDeviceLoaded(true);
    }
  }, [deviceId]);

  return (
    <NewDeviceContext.Provider
      value={{
        isNewDevice: !newDevice.device.DeviceID,
        newDevice,
        deviceTypes,
        deviceTypesLoading,
        dispatch,
        deviceLoaded,
        isValidForSubmit,
      }}>
      {children}
    </NewDeviceContext.Provider>
  );
};

export const useNewDeviceContext = () => {
  const {
    newDevice,
    dispatch,
    deviceTypes,
    deviceTypesLoading,
    isNewDevice,
    deviceLoaded,
    isValidForSubmit,
  } = useContext(NewDeviceContext);

  const updateDevice = useCallback((item: Partial<DeviceDetails>) => {
    dispatch({
      type: UPDATE_DEVICE,
      payload: item,
    })
  }, [dispatch]);

  const updateDeviceType = useCallback((item: DeviceCategory) => {
    dispatch({
      type: UPDATE_DEVICE_TYPE,
      payload: item,
    })
  }, [dispatch]);

  const updateGeneralFields = useCallback((item: Partial<Device>) => {
    dispatch({
      type: UPDATE_GENERAL_FIELDS,
      payload: item,
    });
  }, [dispatch]);

  const updateSimCard = useCallback((item: Partial<SimCardFields>) => {
    dispatch({
      type: UPDATE_SIM_CARD,
      payload: item,
    })
  }, [dispatch]);

  const updateRadio = useCallback((item: Partial<RadioFields>) => {
    dispatch({
      type: UPDATE_RADIO,
      payload: item,
    })
  }, [dispatch]);

  const updateRaspberryPi = useCallback((item: Partial<RaspberryPiFields>) => {
    dispatch({
      type: UPDATE_RASPBERRY_PI,
      payload: item,
    })
  }, [dispatch]);

  const updateModem = useCallback((item: Partial<ModemFields>) => {
    dispatch({
      type: UPDATE_MODEM,
      payload: item,
    })
  }, [dispatch]);

  const updateGateway = useCallback((item: Partial<GatewayFields>) => {
    dispatch({
      type: UPDATE_GATEWAY,
      payload: item,
    })
  }, [dispatch]);

  const updateRobotDevice = useCallback((item: Partial<RobotNetworkFields>) => {
    dispatch({
      type: UPDATE_ROBOT_DEVICE,
      payload: item,
    })
  }, [dispatch]);

  const updateRobotDetails = useCallback((item: Partial<RobotDetailsFields>) => {
    dispatch({
      type: UPDATE_ROBOT_DETAILS,
      payload: item,
    })
  }, [dispatch]);

  return {
    newDevice,
    deviceTypes,
    deviceTypesLoading,
    updateDevice,
    updateDeviceType,
    updateGeneralFields,
    updateSimCard,
    updateRadio,
    updateRaspberryPi,
    updateModem,
    updateGateway,
    updateRobotDevice,
    updateRobotDetails,
    isNewDevice,
    deviceLoaded,
    isValidForSubmit,
  }
};
