import { useRef, useCallback, useEffect, useMemo } from "react";
import { RoverTeleopAction } from "../../types/teleopContextTypes";
import { useMissionManagementContext } from "../../contexts/missionManagementContext";
import { useTeleopContext } from "../../contexts/teleopContext";

export type RoverSocketOnMessageCallback = (action: RoverTeleopAction) => void

export const useRoverSocketOnMessage = (
  callback: RoverSocketOnMessageCallback,
  onReset: () => void,
  identifier?: string
) => {
  const initialLoadComplete = useRef<boolean>(false);

  const {
    missions,
    activeTeleop
  } = useMissionManagementContext();

  const {
    socket: teleopSocket,
  } = useTeleopContext();

  const socket = useMemo(() => {
    let socketToUse: Worker | undefined;

    if (identifier) {
      socketToUse = missions[identifier].socket;
    } else if (activeTeleop?.socket) {
      socketToUse = activeTeleop.socket;
    } else {
      socketToUse = teleopSocket;
    }

    initialLoadComplete.current = true;
    return socketToUse
  }, [activeTeleop, identifier, missions, teleopSocket]);

  useEffect(() => {
    if (initialLoadComplete.current) {
      onReset();
    }
  }, [socket]);

  const roverSocketOnMessage = useCallback((event: MessageEvent) => {
    const action = event.data as RoverTeleopAction;

    if (action) {
      callback(action);
    }
  }, [callback]);

  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', roverSocketOnMessage);
    }

    return () => {
      if (socket) {
        socket.removeEventListener('message', roverSocketOnMessage);
      }
    }
  }, [socket, roverSocketOnMessage]);
}