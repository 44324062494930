import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Gateway } from "../../../../api/device";
import { useDeviceDetailsContext } from "../../../../contexts/deviceDetailsContext"
import { useGeneratedPaths } from "../../../../hooks/useGeneratedPaths";

export const GatewayDetails = () => {
  const {device, deviceLoaded} = useDeviceDetailsContext() as {device: Gateway, deviceLoaded: boolean}
  const dataLoaded = !!device && deviceLoaded;

  const {
    generateDeviceDetailsPath,
  } = useGeneratedPaths();

  if (!dataLoaded) {
    return <></>
  }

  return (
    <>
      <Typography variant='body1' color='text.secondary'>
        Modem:&nbsp;
        <Link
          to={generateDeviceDetailsPath(device.modem?.device.DeviceID ?? '')}
          style={{ color: 'white'}}
        >
         {device.modem?.device.name}
        </Link>
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        Radio:&nbsp;
        <Link
          to={generateDeviceDetailsPath(device.radio?.device.DeviceID ?? '')}
          style={{ color: 'white'}}
        >
         {device.radio?.device.name}
        </Link>
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        Raspberry PI:&nbsp;
        <Link
          to={generateDeviceDetailsPath(device.raspberrypi?.device.DeviceID ?? '')}
          style={{ color: 'white'}}
        >
         {device.raspberrypi?.device.name}
        </Link>
      </Typography>
    </>
  )
}