import { useState } from 'react';
import { Project } from "../../../api/projects";
import { SpeedTestFloorView } from "../Missions/SpeedTestFloorView/SpeedTestFloorView";
import { Mission } from '../../../api/missions';

interface INetworkHeatmapTabProps {
  project: Project;
}

export const NetworkHeatmapTab = ({
  project
}: INetworkHeatmapTabProps) => {
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null);

  return (
    <SpeedTestFloorView
      showMissionDropdown
      projectId={project.public_id}
      selectedMission={selectedMission}
      onChangeSelectedMission={setSelectedMission}
    />
  );
}