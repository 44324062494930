import { Box } from "@mui/material";
import { useMemo } from "react";
import styled from "styled-components";
import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";
import { SafetyDetectorControls } from "./SafetyDetectorControls";
import { SafetyDetectorControlButtons } from "./SafetyDetectorControlButtons";
import { useSafetyTabContext } from "../../../../../../contexts/safetyTabContext";

export const SafetyTab = () => {
  const {
    activeTeleop,
  } = useMissionManagementContext();

  const {
    robot,
  } = activeTeleop || {};

  const {teleopSafetyStatus} = useSafetyTabContext();

  const lastUpdatedFormated = useMemo(() => {
    if (!teleopSafetyStatus) {
      return '';
    }

    const receivedOn = new Date(teleopSafetyStatus.received_on);

    return receivedOn.toLocaleTimeString().replace(/:\d+ /, ' ');
  }, [teleopSafetyStatus]);

  if (!robot) {
    return <></>
  }  

  return (
    <SafetyDetectorsScrollContainer>
      <SafetyDetectorsContainer>
        <SafetyDetectorsFlexContainer>
          {!!teleopSafetyStatus &&
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div>Last Updated: {lastUpdatedFormated}</div>
                <SafetyDetectorControlButtons/>
              </Box>
              <SafetyDetectorControls/>
            </>
          }

          {!teleopSafetyStatus &&
            <>Teleop safety status not available</>
          }
        </SafetyDetectorsFlexContainer>
      </SafetyDetectorsContainer>
    </SafetyDetectorsScrollContainer>
  );
}

const SafetyDetectorsScrollContainer = styled(Box)`
  height: 100%;
  position: relative;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const SafetyDetectorsContainer = styled.div`
  position: absolute;
  width: 100%;
`;

const SafetyDetectorsFlexContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;