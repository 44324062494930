import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { useMissionManagementContext } from "../../../../../contexts/missionManagementContext";
import { useMemo } from "react";
import { useRoverTeleopActions } from "../../../../../hooks/teleopHooks/useRoverTeleopActions";
import { ArrowForward, CameraAlt, ModeStandby } from "@mui/icons-material";

interface IMapPointContextMenuProps {
  mapScale: number;
}

export const MapPointContextMenu = ({
  mapScale,
}: IMapPointContextMenuProps) => {
  const {
    activeTeleop,
    updateActiveMission
  } = useMissionManagementContext();

  const {
    selectedPoint,
    mission,
    mapPointContextMenuOpen,
    mapPointContextMenuPointX,
    mapPointContextMenuPointY,
  } = activeTeleop || {};

  const {
    teleopTakePicture,
    missionTakePicture,
    setTarget,
    skipToPoint,
  } = useRoverTeleopActions();

  const handleClose = () => {
    updateActiveMission({
      mapPointContextMenuOpen: false,
      mapPointContextMenuPointX: null,
      mapPointContextMenuPointY: null,
      mapPointContextMenuClickX: null,
      mapPointContextMenuClickY: null,
      selectedPoint: null,
    })
  }

  const onClickTakePicture = () => {
    if (!!mission) {
      missionTakePicture();
    } else {
      teleopTakePicture();
    }

    handleClose();
  }

  const onClickSetAsTarget = () => {
    if (selectedPoint) {
      setTarget(selectedPoint);
    }
    
    handleClose();
  }

  const onClickSkipToPoint = () => {
    if (selectedPoint) {
      skipToPoint(selectedPoint);
    }
    
    handleClose();
  }

  const anchorPosition = useMemo(() => {
    if (mapPointContextMenuOpen && mapPointContextMenuPointX !== null && mapPointContextMenuPointY !== null && mapPointContextMenuPointX !== undefined && mapPointContextMenuPointY !== undefined) {
      let offsetTop = !!mission ? -43 : -26;
      let offsetLeft = 25;

      return {
        top: mapPointContextMenuPointY + offsetTop,
        left: mapPointContextMenuPointX + offsetLeft
      };
    }

    return undefined;
  }, [mapPointContextMenuOpen, mapPointContextMenuPointX, mapPointContextMenuPointY, mission]);

  return (
    <Popper
      open={!!mapPointContextMenuOpen}
      transition
      disablePortal
      style={{
        position: 'absolute',
        left: anchorPosition?.left,
        top: anchorPosition?.top,
        transform: `scale(${1 / mapScale})`,
        transformOrigin: 'left center',
        zIndex: 10,
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList>
                <MenuItem
                  onClick={onClickTakePicture}
                >
                  <CameraAlt
                    sx={{
                      marginRight: '8px'
                    }}
                  />
                  Take Picture
                </MenuItem>
                {!!mission &&
                  <MenuItem
                    onClick={onClickSetAsTarget}
                  >
                    <ModeStandby
                      sx={{
                        marginRight: '8px'
                      }}
                    />
                    Set As Target
                  </MenuItem>
                }
                {!!mission &&
                  <MenuItem
                    onClick={onClickSkipToPoint}
                  >
                    <ArrowForward
                      sx={{
                        marginRight: '8px'
                      }}
                    />
                    Skip To Point
                  </MenuItem>
                }
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}