import { Drawer, Toolbar, Box } from "@mui/material"
import { StartMission } from "./StartMission/StartMission";
import { RobotMaintenance } from "./RobotMaintenance/RobotMaintenance";
import { useMissionManagementContext } from "../../../../../contexts/missionManagementContext";

export enum DrawerContentType {
  none,
  logs,
  customScript,
  startMission,
  videoSettings,
  robotMaintenance,
}

export const TeleopDrawer = () => {
  const {
    activeTeleop,
    drawerOpen,
    closeTeleopDrawer,
  } = useMissionManagementContext();

  const drawerContentType = activeTeleop?.drawerContentType;

  return (
    <Drawer
      anchor='right'
      open={drawerOpen}
      onClose={closeTeleopDrawer}
    >
      <Toolbar/>
      <Box
        padding='0 20px'
        height="100%"
      >
        { drawerContentType === DrawerContentType.startMission &&
          <StartMission/>
        }
        { drawerContentType === DrawerContentType.robotMaintenance &&
          <RobotMaintenance/>
        }
      </Box>
    </Drawer>
  )
}
