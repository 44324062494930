import { useCallback, useState } from "react"
import { RoverSocketOnMessageCallback, useRoverSocketOnMessage } from "./useRoverSocketOnMessage";
import { CapturedImage, IMissionManagementState, useMissionManagementContext } from "../../contexts/missionManagementContext";

interface Camera360Operation {
  name:  'camera_360';
  progress: string;
}

interface ImageRecordOperation {
  name:  'image_record';
  progress: string;
}

interface LidarRecordStartOperation {
  name: 'lidar_record_start';
  progress: string;
}

interface ChangeRegionOperation {
  name: 'change_region';
  progress: string;
}

export interface ImageUploadOperation {
  name: 'image_upload';
  progress: string;
}

export interface BagUploadOperation {
  name: 'bag_upload';
  progress: string;
}

export interface MapDownloadOperation {
  name: 'map_download';
  progress: string;
}

export type NexcoreStatusOperation = Camera360Operation | ImageRecordOperation | LidarRecordStartOperation | ChangeRegionOperation | ImageUploadOperation | BagUploadOperation | MapDownloadOperation;
export type NexcoreStatusOperationCategory = 'active' | 'completed' | 'failed';

export interface NexcoreStatus {
  core_state: string;
  error_nodes: string[];
  logs: string;
  mission_id: string;
  operations: Record<NexcoreStatusOperationCategory, NexcoreStatusOperation[]>;
  task_id: string;
  task_name: string;
  task_state: string;
}

export const useNexcoreStatus = () => {
  const {
    updateActiveMission,
  } = useMissionManagementContext();

  const [nexcoreStatus, setNexcoreStatus] = useState<NexcoreStatus | null>(null);

  const handleCapturedImages = (message: NexcoreStatus) => {
    const updatedCapturedImages = new Map<number, CapturedImage>();
    
    message.operations.completed.forEach(operation => {
      if (operation.name === 'camera_360' || operation.name === 'image_record') {
        const operationProgress: CapturedImage = JSON.parse(operation.progress);
        updatedCapturedImages.set(parseInt(operationProgress.point_id.toString()), operationProgress);
      }
    });

    return updatedCapturedImages;
  }

  const onReceiveNexcoreStatus: RoverSocketOnMessageCallback = useCallback(({message_type, message_version, message}) => {
    if (message_type === 'nexcore_status') {
      if (message_version === 2) {
        setNexcoreStatus(message);
        const stateToUpdate: Partial<IMissionManagementState> = {};

        const updatedCapturedImages = handleCapturedImages(message);

        stateToUpdate.capturedImages = updatedCapturedImages;
  
        updateActiveMission(stateToUpdate);
      }
    }
  }, [updateActiveMission]);

  const onResetNexcoreStatus = useCallback(() => {
    setNexcoreStatus(null);
  }, []);

  useRoverSocketOnMessage(onReceiveNexcoreStatus, onResetNexcoreStatus);

  return nexcoreStatus;
}