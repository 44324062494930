import React, {useCallback, useEffect, useRef} from "react";
import { Button, Typography } from "@mui/material";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";

interface ITeleopActionButtonProps {
  iconButton?: boolean;
  color?: any;
  onClick?: () => void;
  variant?: "text" | "outlined" | "contained";
  hotkey?: string;
  pressedKey?: string | null;
  sx?: React.CSSProperties;
  disabled?: boolean;
}

const iconButtonSx = {
  borderRadius: '0px',
  minWidth: '50px',
  minHeight: '50px',
  maxWidth: '50px',
  maxHeight: '50px',
  position: 'relative'
};

export const TeleopActionButton = ({
  iconButton,
  color,
  onClick,
  variant,
  children,
  hotkey,
  pressedKey,
  sx,
  disabled,
}: React.PropsWithChildren<ITeleopActionButtonProps>) => {
  const rippleRef = useRef<any>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const triggerRipple = useCallback(() => {
    const container = buttonRef.current;
    const rect = container?.getBoundingClientRect();

    if (rect) {
      rippleRef.current.start(
        {
          clientX: rect.left + rect.width / 2,
          clientY: rect.top + rect.height / 2,
        },
        {
          center: false,
        }
      );
  
      setTimeout(() => {
        rippleRef.current.stop({});
      }, 320);
    }
  }, []);

  useEffect(() => {
    if (pressedKey && pressedKey === hotkey) {
      triggerRipple();
    }    
  }, [pressedKey, hotkey, triggerRipple]);

  return (
    <Button
      ref={buttonRef}
      disabled={disabled}
      onClick={onClick}
      variant={variant ?? 'outlined'}
      sx={{...iconButton ? iconButtonSx : {}, ...sx}}
      color={color}
    >
      {children}
      {hotkey &&
        <HotKey>{hotkey}</HotKey>
      }
      <TouchRipple ref={rippleRef} center />
    </Button>
  )
}

const HotKey = ({
  children,
}: React.PropsWithChildren<{}>) => {
  return (
    <Typography
      sx={{
        position: 'absolute',
        right: '1px',
        bottom: '-3px',
      }}
    >
      {children}
    </Typography>
  )
}