import { useCallback, useState } from "react";
import { RoverNode, useRoverSensorConfiguration } from "../../../../../../hooks/teleopHooks/useRoverSensorConfiguration";
import { useRoverTeleopActions } from "../../../../../../hooks/teleopHooks/useRoverTeleopActions";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { multiSelectRenderOption } from "../../../../../common/Dropdowns";
import { useAlerts } from "../../../../../../hooks/useAlerts";

export const RobotMaintenanceSensorControls = () => {
  const sensorConfiguration = useRoverSensorConfiguration();

  const {
    nodes
  } = sensorConfiguration || {};

  const {
    removeNodes,
  } = useRoverTeleopActions();

  const {
    addSuccessAlert,
    addErrorAlert,
  } = useAlerts();

  const [selectedSensors, setSelectedSensors] = useState<RoverNode[]>([]);

  const onChangeSelectedSensors = useCallback((_: React.SyntheticEvent<Element, Event>, newSelectedSensors: RoverNode[]) => {
    setSelectedSensors(newSelectedSensors);
  }, []);

  const onClickRemoveNodes = useCallback(() => {
    try {
      const nonCriticalNodesSet = new Set(nodes);
      const nodesToRemove = selectedSensors.filter((sensor) => nonCriticalNodesSet.has(sensor));

      removeNodes(nodesToRemove);
      addSuccessAlert("Remove nodes message sent successfully");
    } catch {
      addErrorAlert("Failed to remove nodes");
    }
  }, [nodes, addErrorAlert, addSuccessAlert, removeNodes, selectedSensors]);

  return (
    <Box
      display="flex"
      gap="5px"
    >
      <Autocomplete
        multiple
        disabled={!nodes || nodes.length === 0}
        disableCloseOnSelect
        options={nodes ?? []}
        value={selectedSensors}
        onChange={onChangeSelectedSensors}
        limitTags={1}
        renderOption={multiSelectRenderOption}
        renderInput={params => <TextField {...params} label="Sensors"/>}
        sx={{
          flex: 1,
        }}
      />
      <Button
        disabled={selectedSensors.length === 0}
        onClick={onClickRemoveNodes}
      >
        Deactivate
      </Button>
    </Box>
  );
}