import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../contexts/userContext';

interface PermissionWrapperProps {
  permission: string;
  project_id?: string;
}
export const PermissionWrapper = ({ permission, project_id, children }: React.PropsWithChildren<PermissionWrapperProps>) => {
  const { state: userState } = useUserContext();
  const params = useParams();
  const navigate = useNavigate();
  const [userCanAccess, setUserCanAccess] = useState<boolean>(false);

  const _checkUserPermissions = useCallback(() => {
    //@ts-ignore
    return checkUserPermissions(userState.permissions, permission, (project_id || params.projectId));
    //@ts-ignore
  }, [params.projectId, permission, project_id, userState.permissions]);

  useEffect(() => {
    if (!_checkUserPermissions()) {
      navigate('/');
    } else {
      setUserCanAccess(true);
    }
  }, [_checkUserPermissions, navigate]);

  if (!userCanAccess) {
    return <></>;
  }

  return <>{children}</>;
};

interface PermissionWrappedComponentProps {
  permission: string | string[];
  useOrInPermissionCheck?: boolean;
  project_id?: string;
}

export const PermissionWrappedComponent = ({ permission, useOrInPermissionCheck, project_id, children }: React.PropsWithChildren<PermissionWrappedComponentProps>) => {
  const { state: userState } = useUserContext();
  const _checkUserPermissions = () => {
    if (Array.isArray(permission)) {
      if (useOrInPermissionCheck) {
        return permission.some((perm) => checkUserPermissions(userState.permissions, perm, project_id));
      } else {
        return permission.every((perm) => checkUserPermissions(userState.permissions, perm, project_id));
      }
    } else {
      return checkUserPermissions(userState.permissions, permission, project_id);
    }    
  };
  if (!_checkUserPermissions()) {
    return <></>;
  }
  return <>{children}</>;
};

export const checkUserPermissions = (
  userPermissions: any,
  permission: string,
  project_id?: string
) => {
  let userLevelMatch = !!userPermissions.user.find(
    (userPermission: string) => userPermission === permission
  );
  // let companyLevelMatch = !!userState.permissions.company.find(
  //   (userPermission: string) => userPermission === permission
  // );
  let projectLevelMatch = false;
  if (project_id) {
    if (userPermissions.project[project_id]) {
      
      projectLevelMatch = !!userPermissions.project[project_id].find(
        (userPermission: string) => userPermission === permission
      );
    }
  }
  return userLevelMatch || projectLevelMatch;
};
