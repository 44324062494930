import { useState, useEffect, useMemo } from 'react';
import { Button, Grid, Typography, Card, CardContent, CardActions, Chip } from "@mui/material"
import { Link, useLocation, useMatch } from "react-router-dom";
import { PATH_STRINGS, useGeneratedPaths } from "../../../hooks/useGeneratedPaths";
import { Breadcrumb } from "../../common/Breadcrumb";
import { missionStatusColors } from "../Dashboard/MissionsTable";
import { PageLoadingIndicator } from '../../common/LoadingIndicator';
import { Mission, fetchMission } from '../../../api/missions';
import { SpeedTestFloorView } from './SpeedTestFloorView/SpeedTestFloorView';
import { FullScreenModal } from '../../common/FullScreenModal';

export const CompletedMissionInformation = () => {
  const {
    generateRoverDetailsPath,
    generateScheduleDetailsPath,
  } = useGeneratedPaths();

  const missionDetailsMatch = useMatch(PATH_STRINGS.missionDetails);
  const missionId: string = missionDetailsMatch?.params.missionId || '';
  const locationState = useLocation().state as { mission: Mission | undefined } | undefined;
  const initialMission = locationState?.mission;

  const [mission, setMission] = useState<Mission | undefined>(initialMission);
  const [missionLoaded, setMissionLoaded] = useState<boolean>(!!initialMission);
  const [speedTestModalOpen, setSpeedTestModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!initialMission && !!missionId) {
      fetchMission(missionId).then(returnedMission => {
        setMission(returnedMission);
        setMissionLoaded(true);
      });
    }
  }, [initialMission, missionId]);

  const manualDriveDuration = useMemo(() => {
    let printout = '';

    if (mission && mission.manualDriveDuration) {
      const hours = Math.floor(mission.manualDriveDuration / 3600);
      const minutes = Math.floor((mission.manualDriveDuration - hours * 3600) / 60);
      const seconds = mission.manualDriveDuration - (hours * 3600) - (minutes * 60);

      if (hours > 0) {
        printout += `${hours} hour${hours !== 1 ? 's' : ''} `;
      }

      if (minutes > 0) {
        printout += `${minutes} minute${minutes !== 1 ? 's' : ''} `;
      }

      if (seconds > 0) {
        printout += `${seconds} second${seconds !== 1 ? 's' : ''}`;
      }
    }
    
    return printout;
  }, [mission]);

  const manualDriveDurationPercent = useMemo(() => {
    if (mission && mission.manualDriveDuration) {
      const startTime = new Date(mission.start_time).getTime() / 1000;
      const endTime = new Date(mission.end_time as string).getTime() / 1000;

      return Math.round(mission.manualDriveDuration / (endTime - startTime) * 100);
    }

    return 0;
  }, [mission]);

  if (!mission || !missionLoaded) {
    return <PageLoadingIndicator/>;
  }

  const startTimeAsDate = new Date(mission.start_time);

  return (
    <Grid container spacing={4} direction='column'>
      <Grid item>
        <Grid container alignItems='center'>
          <Grid item>
            <Typography variant='h5'>
              Mission Details
            </Typography>
            <Breadcrumb
              items={[{text: 'Mission Details'}]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card
              sx={{
                height: '100%'
              }}
            >
              <CardContent>
                <Grid container justifyContent="space-between" mb="30px">
                  <Grid item>
                    <Typography variant='h5'>
                      {`${mission.project_name} - ${startTimeAsDate.toLocaleDateString()}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      label={mission.status}
                      color={missionStatusColors[mission.status]}
                      sx={{ color: 'white' }}
                    />
                  </Grid>
                </Grid>
                <Typography variant='body1' color='text.secondary'>
                  Rover:&nbsp;
                  <Link
                    to={generateRoverDetailsPath(mission.robot.device_id)}
                    style={{ color: 'white'}}
                  >
                    {mission.robot.name}
                  </Link>
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  Schedule:&nbsp;
                  <Link
                    to={generateScheduleDetailsPath(mission.schedule_id)}
                    style={{ color: 'white'}}
                  >
                    {mission.schedule_name}
                  </Link>
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  Start Time: {mission.start_time_formatted}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  End Time: {mission.end_time_formatted}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  Supervisor: {`${mission.supervisor.first_name} ${mission.supervisor.last_name}`}
                </Typography>
                {manualDriveDuration &&
                  <Typography variant='body1' color='text.secondary'>
                    Manual Drive Duration: {`${manualDriveDuration} (${manualDriveDurationPercent}%)`} 
                  </Typography>
                }
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => setSpeedTestModalOpen(true)}>View Network Throughput Information</Button>
              </CardActions>
              <FullScreenModal
                open={speedTestModalOpen}
                onClose={() => setSpeedTestModalOpen(false)}
                sx={{height: '750px', width: '1000px'}}
              >
                <SpeedTestFloorView
                  selectedMission={mission}
                  projectId={mission.project_id}
                />
              </FullScreenModal>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}