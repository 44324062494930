import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
// import { styled } from '@mui/material/styles';
import styled from 'styled-components';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerwidth: number;
}

export const StyledAppBar = styled(MuiAppBar)<AppBarProps>`
  z-index: ${props => props.theme.zIndex.drawer + 1};
  transition: ${props => `${props.theme.transitions.create(['width', 'margin'], {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen,
  })}`};

  ${props => props.open && `
    margin-left: ${props.drawerwidth};
    width: calc(100% - ${props.drawerwidth}px);
    transition: ${props.theme.transitions.create(['width', 'margin'], {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.enteringScreen,
    })};
  `}
`