import { useCallback, useState } from "react"
import { RoverSocketOnMessageCallback, useRoverSocketOnMessage } from "./useRoverSocketOnMessage";

export const useRoverBatteryLevel = (teleopIdentifier?: string) => {
  const [batteryLevel, setBatteryLevel] = useState<number | null>(null);
  const [isCharging, setIsCharging] = useState<boolean | null>(null);
  const [secondBatteryLevel, setSecondBatteryLevel] = useState<number | null>(null);
  const [secondBatteryIsCharging, setSecondBatteryIsCharging] = useState<boolean | null>(null);

  const onReceiveBatteryMessage: RoverSocketOnMessageCallback = useCallback(({message_type, message_version, message}) => {
    if (message_type === 'battery') {
      if (message_version === 1) {
        setBatteryLevel(message.battery_percentage);
        setIsCharging(message.is_charging);
      }
    } else if (message_type === 'second_battery') {
      if (message_version === 1) {
        setSecondBatteryLevel(message.battery_percentage);
        setSecondBatteryIsCharging(message.is_charging);
      }
    }
  }, []);

  const onResetBattery = useCallback(() => {
    setBatteryLevel(null);
    setIsCharging(null);
    setSecondBatteryLevel(null);
    setSecondBatteryIsCharging(null);
  }, []);

  useRoverSocketOnMessage(onReceiveBatteryMessage, onResetBattery, teleopIdentifier);

  return {
    batteryLevel,
    isCharging,
    secondBatteryLevel,
    secondBatteryIsCharging,
  }
}