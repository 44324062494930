import { TableCell } from "@mui/material";
import { Schedule } from "../../../api/schedules"
import { HeadCell, Table } from "../../common/Table"
import { useGeneratedPaths } from "../../../hooks/useGeneratedPaths";
import { TableRow } from "../../common/TableRow";

interface ITodaysSchedulesTableProps {
  schedules: Schedule[];
}

export const TodaysSchedulesTable = ({
  schedules,
}: ITodaysSchedulesTableProps) => {
  const {
    generateScheduleDetailsPath,    
  } = useGeneratedPaths();

  const columns: readonly HeadCell<Schedule>[] = [
    { id: 'name', label: 'Name' },
    { id: 'project_name', label: 'Project' },
    { id: 'time', label: 'Time' },
    { id: 'frequency', label: 'Frequency' }
  ];

  const renderVisibleRows = (row: Schedule, index: number) => {
    const redirectTo = generateScheduleDetailsPath(row.id);

    let hours = parseInt(row.time.substring(0, 2));
    const amPm = hours < 12 ? 'am' : 'pm';

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    const minutes = row.time.substring(3, 5);

    const twelveHourTime = `${hours}:${minutes} ${amPm}`;

    return (
      <TableRow
        hover
        redirectTo={redirectTo}
        role="checkbox"
        tabIndex={-1}
        key={`${row.id}-${row.name}`}
      >
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left">{row.project_name}</TableCell>
        <TableCell align="left">{twelveHourTime}</TableCell>
        <TableCell align="left">{row.frequency}</TableCell>
      </TableRow>
    )
  }

  return (
    <Table
      initialRowsPerPage={5}
      columns={columns}
      rows={schedules}
      initialOrderedColumnName='time'
      renderVisibleRows={renderVisibleRows}
    />
  )
}