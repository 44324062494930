import { Box } from "@mui/material";
import { Counter } from "../../../../common/Counter";
import { Joystick } from "../Joystick";
import { useMissionManagementContext } from "../../../../../contexts/missionManagementContext";
import { RoverFlipperAngleSlider } from "./RoverFlipperAngleSlider";

export const RoverDrivingControls = () => {
  const {
    activeTeleop,
    updateActiveMission,
  } = useMissionManagementContext();

  if (!activeTeleop || !activeTeleop.robot) {
    return <></>;
  }

  const {
    linearSpeed,
    angularSpeed,
  } = activeTeleop;

  const hasFlipper = activeTeleop.robot.robot_details.category === 'flipper';

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Joystick
          linearSpeed={linearSpeed}
          angularSpeed={angularSpeed}
        />
      </Box>
      {hasFlipper &&
        <Box>
          <RoverFlipperAngleSlider/>
        </Box>
      }
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Counter
          label='Linear Speed'
          value={linearSpeed}
          setValue={(newValue) => updateActiveMission({linearSpeed: newValue})}
          step={5}
          min={0}
          max={100}
        />
        <Counter
          label='Angular Speed'
          value={angularSpeed}
          setValue={(newValue) => updateActiveMission({angularSpeed: newValue})}
          step={5}
          min={0}
          max={100}
        />
      </Box>
    </Box>
  )
}