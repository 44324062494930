import { useState, useMemo, useEffect } from 'react';
import { Autocomplete, Box, TextField } from "@mui/material";
import { Mission, MissionSpeedTest } from "../../../../api/missions";
import { useAllMissionsQuery, useFetchAllSpeedTestsQuery, useProjectFloorViewpointsQuery, useProjectFloorsQuery, useScheduleQuery } from "../../../../hooks/queries";
import { MapViewer } from "../MissionTeleop/Map/MapViewer";
import styled from 'styled-components';
import { LoadingIndicator } from '../../../common/LoadingIndicator';
import { SpeedMetric, SpeedMetricRadioButtons } from './SpeedMetricRadioButtons';
import { SpeedTestMapPoint } from './SpeedTestMapPoint';
import { ProjectFloor } from '../../../../api/projectFloors';

interface SpeedTestFloorViewProps {
  projectId: string;
  selectedMission?: Mission | null;
  onChangeSelectedMission?: (selectedMission: Mission | null) => void;
  showMissionDropdown?: boolean;
}

export const SpeedTestFloorView = ({
  projectId,
  selectedMission,
  onChangeSelectedMission,
  showMissionDropdown,
}: SpeedTestFloorViewProps) => {
  const [mapX, setMapX] = useState<number>(0);
  const [mapY, setMapY] = useState<number>(0);
  const [scale, setScale] = useState<number>(0.2);
  const [selectedProjectFloor, setSelectedProjectFloor] = useState<ProjectFloor | null>(null);
  const [selectedSpeedMetric, setSelectedSpeedMetric] = useState<SpeedMetric>('upload');
  const [loadedImage, setLoadedImage] = useState<string>('');

  const {data: projectSpeedTests, isLoading: speedTestsLoading} = useFetchAllSpeedTestsQuery(projectId);
  const {data: floors, isLoading: floorsLoading} = useProjectFloorsQuery(projectId);
  const {data: points, isLoading: pointsLoading} = useProjectFloorViewpointsQuery(projectId, selectedProjectFloor?.floor_code ?? '');
  const {data: missions, isLoading: missionsLoading} = useAllMissionsQuery({project: projectId}, showMissionDropdown);
  const {data: schedule, isLoading: scheduleLoading} = useScheduleQuery(selectedMission?.schedule_id ?? '');

  const dataLoaded = !floorsLoading && !!floors && !pointsLoading && !!points && !speedTestsLoading && !scheduleLoading

  const missionFloors = useMemo(() => {
    if (floors && !floorsLoading && !scheduleLoading) {
      if (schedule) {
        return floors.filter(floor => schedule.schedule_floors.includes(floor.floor_code));
      } else {
        return floors;
      }
    }

    return [];
  }, [floors, floorsLoading, schedule, scheduleLoading]);

  useEffect(() => {
    if (missionFloors && missionFloors.length > 0) {
      setSelectedProjectFloor(missionFloors.find(floor => !!floor.latest_floor_plan) ?? null);
    } else {
      setSelectedProjectFloor(null);
    }
  }, [missionFloors]);

  const speedTests = useMemo(() => {
    if (projectSpeedTests) {
      const sortedSpeedTests = [...projectSpeedTests].sort((a,b) => new Date(b.taken_on).getTime() - new Date(a.taken_on).getTime());

      if (selectedMission) {
        return sortedSpeedTests.filter(test => test.mission_id === selectedMission.id);
      } else {
        const mostRecentAtPoint = new Map<number, MissionSpeedTest>();

        sortedSpeedTests.forEach(test => {
          if (!mostRecentAtPoint.has(test.sub_viewpoint_id)) {
            mostRecentAtPoint.set(test.sub_viewpoint_id, test);
          }
        });

        return Array.from(mostRecentAtPoint.values());
      }
    }

    return [];
  }, [projectSpeedTests, selectedMission]);

  const projectSpeedTestMissionIds = useMemo(() => {
    const missionIdSet = new Set<number>();

    projectSpeedTests?.forEach(test => missionIdSet.add(test.mission_id));

    return missionIdSet;
  }, [projectSpeedTests]);

  const missionOptions = useMemo(() => {
    if (missions) {
      const missionsWithSpeedTests = missions.filter(mission => projectSpeedTestMissionIds.has(mission.id));

      return missionsWithSpeedTests.sort((a,b) => new Date(b.start_time).getTime() - new Date(a.start_time).getTime());
    }

    return [];
  }, [missions, projectSpeedTestMissionIds]);

  const pointSpeedTestMap = useMemo(() => {
    const pointSpeedTests = new Map<number, MissionSpeedTest[]>();

    if (speedTests) {
      speedTests.forEach(test => {
        const associatedTests = pointSpeedTests.get(test.sub_viewpoint_id) ?? [];
        associatedTests.push(test);
        pointSpeedTests.set(test.sub_viewpoint_id, associatedTests);
      });
    }

    return pointSpeedTests
  }, [speedTests]);

  const floorPoints = useMemo(() => {
    if (points) {
      return points.map(point => {
        const associatedSpeedTests = pointSpeedTestMap.get(point.point_id) ?? [];

        return (
          <SpeedTestMapPoint
            key={point.id}
            point={point}
            associatedSpeedTests={associatedSpeedTests}
            selectedSpeedMetric={selectedSpeedMetric}
          />
        )
      })
    }

    return <></>
  }, [pointSpeedTestMap, points, selectedSpeedMetric]);

  return (
    <ContentContainer>
      <ControlsContainer>
        <Box
          width='250px'
        >
          {showMissionDropdown &&
            <Autocomplete
              loading={missionsLoading}
              value={selectedMission}
              onChange={(e, value) => {
                if (onChangeSelectedMission) {
                  onChangeSelectedMission(value)
                }
              }}
              options={missionOptions}
              getOptionLabel={option => `${new Date(option.start_time).toLocaleDateString()} - ${option.schedule_name}`}
              renderInput={(params) => <TextField {...params} label="Mission" />}
            />
          }
        </Box>
        <SpeedMetricRadioButtons
          selectedSpeedMetric={selectedSpeedMetric}
          onChangeSelectedSpeedMetric={setSelectedSpeedMetric}
        />
        <Box
          width="250px"
        >
          <Autocomplete
            loading={floorsLoading}
            value={selectedProjectFloor}
            onChange={(e, value) => setSelectedProjectFloor(value)}
            options={missionFloors}
            getOptionLabel={option => option.name}
            renderInput={(params) => <TextField {...params} label="Floor" />}
          />
        </Box>
      </ControlsContainer>
      <div style={{flex: 1, width: '100%', height: '100%', position: 'relative', overflow: 'hidden'}}>        
        {!dataLoaded &&
          <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <LoadingIndicator/>
          </div>
        }
        {(dataLoaded && !!selectedProjectFloor?.latest_floor_plan) &&
          <div style={{position: 'absolute'}}>
            <MapViewer
              image={selectedProjectFloor.latest_floor_plan.web_image_url}
              setLoadedImg={setLoadedImage}
              imageLoaded={loadedImage === selectedProjectFloor.latest_floor_plan.web_image_url}
              x={mapX}
              updateX={(newX: number) => setMapX(newX)}
              y={mapY}
              updateY={(newY: number) => setMapY(newY)}
              scale={scale}
              setScale={setScale}
            >
              {loadedImage === selectedProjectFloor.latest_floor_plan.web_image_url &&
                <>{floorPoints}</>
              }
            </MapViewer>
          </div>
        }
      </div>
    </ContentContainer>
  )
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
