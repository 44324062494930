import React from 'react';

const ZoomStep = 0.2;

type PanZoomProps = React.PropsWithChildren<{
  x: number;
  updateX: (newX: number) => void;
  y: number;
  updateY: (newY: number) => void;
  minScale?: number;
  maxScale?: number;
  interactive?: boolean;
  preventDoubleTouches?: boolean;
  disablePan?: boolean;
  onMouseDown?: (e: React.MouseEvent) => void;
  onMouseUp?: (e: MouseEvent) => void;
  onMouseMove?: (e: React.MouseEvent) => void;
  onDoubleClick?: (e: React.MouseEvent) => void;
  scale: number;
  setScale: (newScale: number) => void;
}>;

class PanZoom extends React.Component<PanZoomProps> {
  private mapContainerRef = React.createRef<HTMLDivElement>();

  private isDraggingDown = false;
  private isScaling = false;
  private dragStartX = 0;
  private dragStartY = 0;
  private dragStartScale = 0;
  private interactive = false;
  private lastTouch = 0;

  constructor(props: Readonly<PanZoomProps> | PanZoomProps) {
    super(props);
    
    this.interactive = this.props.interactive ?? true;

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchDown = this.onTouchDown.bind(this);
    this.onTouchUp = this.onTouchUp.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onWheel = this.onWheel.bind(this);

    this.onDragStart = this.onDragStart.bind(this);

    this.onGestureStart = this.onGestureStart.bind(this);
    this.onGestureEnd = this.onGestureEnd.bind(this);
    this.onGestureChange = this.onGestureChange.bind(this);
  }

  public getZoom(): number {
    return this.props.scale;
  }

  public setZoom(newZoom: number) {
    const mainDiv = this.mapContainerRef.current!;
    const rect = mainDiv.getBoundingClientRect();

    this.setZoomRelativeTo(newZoom, rect.width / 2, rect.height / 2);
  }

  public setZoomRelativeTo(newZoom: number, x: number, y: number) {
    if (this.props.maxScale != null && newZoom > this.props.maxScale) newZoom = this.props.maxScale;
    if (this.props.minScale != null && newZoom < this.props.minScale) newZoom = this.props.minScale;

    const scaleMult = newZoom / this.props.scale;

    this.props.updateX(x - (x - this.props.x) * scaleMult);
    this.props.updateY(y - (y - this.props.y) * scaleMult);
    this.props.setScale(this.props.scale * scaleMult);
  }

  public componentDidMount() {
    const mainDiv = this.mapContainerRef.current!;

    if (this.interactive) {
      document.addEventListener('mouseup', this.onMouseUp, true);
      document.addEventListener('touchend', this.onTouchUp, true);
      document.addEventListener('touchmove', this.onTouchMove, true);
      mainDiv.addEventListener('wheel', this.onWheel, false);
      mainDiv.addEventListener('dragstart', this.onDragStart, false);
      mainDiv.addEventListener('gesturestart', this.onGestureStart, false);
      mainDiv.addEventListener('gestureend', this.onGestureEnd, false);
      mainDiv.addEventListener('gesturechange', this.onGestureChange, false);
    }
  }

  public componentWillUnmount() {
    const mainDiv = this.mapContainerRef.current!;

    if (this.interactive) {
      document.removeEventListener('mouseup', this.onMouseUp, false);
      document.removeEventListener('touchend', this.onTouchUp, false);
      document.removeEventListener('touchmove', this.onTouchMove, false);
      mainDiv.removeEventListener('wheel', this.onWheel, false);
      mainDiv.removeEventListener('dragstart', this.onDragStart, false);
      mainDiv.removeEventListener('gesturestart', this.onGestureStart, false);
      mainDiv.removeEventListener('gestureend', this.onGestureEnd, false);
      mainDiv.removeEventListener('gesturechange', this.onGestureChange, false);
    }
  }

  private onMouseDown(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.disablePan) {
      return;
    }

    this.isDraggingDown = true;
    this.dragStartX = event.clientX;
    this.dragStartY = event.clientY;
    
    if (this.props.onMouseDown) {
      this.props.onMouseDown(event);
    }
  }

  private onTouchDown(event: React.TouchEvent) {
    if (this.props.disablePan) {
      return;
    }
    
    const currentTouch = new Date().getTime();
    const isDoubleTouch: boolean = currentTouch - this.lastTouch <= 200;
    const eligibleToProceed = !isDoubleTouch || !this.props.preventDoubleTouches;

    if (eligibleToProceed && event.touches.length === 1) {
      this.isDraggingDown = true;
      this.dragStartX = event.touches[0].clientX;
      this.dragStartY = event.touches[0].clientY;
    }

    this.lastTouch = currentTouch;
  }

  private onMouseUp(event: MouseEvent) {
    this.isDraggingDown = false;
    
    if (this.props.onMouseUp) {
      this.props.onMouseUp(event);
    }
  }

  private onTouchUp() {
    this.isDraggingDown = false;
  }

  private onMouseMove(event: React.MouseEvent) {
    event.preventDefault();
    if (this.isDraggingDown && !this.props.disablePan) {
      this.props.updateX(this.props.x + (event.clientX - this.dragStartX));
      this.props.updateY(this.props.y + (event.clientY - this.dragStartY));
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
    }

    if (this.props.onMouseMove) {
      this.props.onMouseMove(event);
    }
  }

  private onTouchMove(event: TouchEvent) {
    event.preventDefault();
    if (this.isDraggingDown) {
      // eslint-disable-next-line
      if (event.touches.length == 1) {
        this.props.updateX(this.props.x + (event.touches[0].clientX - this.dragStartX));
        this.props.updateY(this.props.y + (event.touches[0].clientY - this.dragStartY));
        this.dragStartX = event.touches[0].clientX;
        this.dragStartY = event.touches[0].clientY;
      }
    }
  }

  private onWheel(e: WheelEvent) {
    e.preventDefault();
    const mainDiv = this.mapContainerRef.current!;

    const dir = e.deltaY > 0 ? 1 : -1;

    if (e.target) {
      const rect = mainDiv.getBoundingClientRect();

      const delta = (e as any).wheelDeltaY;
      const zoomStep = 1 + ZoomStep * Math.max(0, Math.min(1.5, Math.abs(delta) / 120));

      const mouseX = e.clientX - rect.x;
      const mouseY = e.clientY - rect.y;
      const scaleMult = dir === 1 ? 1 / zoomStep : zoomStep;
      
      this.setZoomRelativeTo(this.props.scale * scaleMult, mouseX, mouseY);
    }
  }

  private onDragStart(e: Event) {
    e.preventDefault();
  }

  private onGestureStart(e: any) {
    e.preventDefault();
    this.dragStartScale = e.scale;
    this.isScaling = true;
  }

  private onGestureEnd(e: any) {
    e.preventDefault();
    this.isScaling = false;
  }

  private onGestureChange(e: any) {
    e.preventDefault();
    if (this.isScaling) {
      const diff = e.scale / this.dragStartScale;
      this.dragStartScale = e.scale;

      const mainDiv = this.mapContainerRef.current!;

      if (e.target) {
        const rect = mainDiv.getBoundingClientRect();

        const mouseX = e.clientX - rect.x;
        const mouseY = e.clientY - rect.y;

        this.setZoomRelativeTo(this.props.scale * diff, mouseX, mouseY);
      }
    }
  }

  render() {
    return (
      <div 
        ref={this.mapContainerRef} 
        style={{ width: '100%', height: '100%', overflow: 'hidden' }}
        onDoubleClick={this.props.onDoubleClick}
        onMouseDown={this.onMouseDown}
        onTouchStart={this.onTouchDown}
        onMouseMove={this.onMouseMove}
      >
        <div style={{ position: 'relative', display: 'inline-block', transform: `translate(${this.props.x}px, ${this.props.y}px) translate(-50%,-50%) scale(${this.props.scale}) translate(50%,50%)` }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

interface IMapViewerProps extends React.PropsWithChildren<{}>{
  image: any;
  setLoadedImg: (value: string) => void;
  imageLoaded: boolean;
  onDoubleClick?: (e: React.MouseEvent) => void;
  onMouseDown?: (e: React.MouseEvent) => void;
  onMouseUp?: (e: MouseEvent) => void;
  onMouseMove?: (e: React.MouseEvent) => void;
  preventDoubleTouches?: boolean;
  x: number;
  updateX: (newX: number) => void;
  y: number;
  updateY: (newY: number) => void;
  disablePan?: boolean;
  scale: number;
  setScale: (newScale: number) => void;
}

export class MapViewer extends React.Component<IMapViewerProps> {
  private mapRef = React.createRef<PanZoom>();

  public zoomIn() {
    const map = this.mapRef.current;
    if (map) map.setZoom(map.getZoom() * (1 + ZoomStep));
  }

  public zoomOut() {
    const map = this.mapRef.current;
    if (map) map.setZoom(map.getZoom() / (1 + ZoomStep));
  }

  // shouldComponentUpdate(
  //   nextProps: Readonly<{
  //     image: any;
  //     selectedDateRange: any;
  //     points: Point[];
  //     isDateSelected: boolean;
  //     imageLoaded: boolean;
  //     setLoadedImg: (value: string) => void;
  //     highlightedPointId?: string | null;
  //   }>,
  //   nextState: Readonly<{}>
  // ) {
  //   return nextProps.image !== this.props.image;
  // }

  render() {
    return (
      <PanZoom 
        ref={this.mapRef}
        minScale={0.1}
        maxScale={2}
        preventDoubleTouches={this.props.preventDoubleTouches}
        x={this.props.x}
        updateX={this.props.updateX}
        y={this.props.y}
        updateY={this.props.updateY}
        disablePan={this.props.disablePan}
        onMouseDown={this.props.onMouseDown}
        onMouseUp={this.props.onMouseUp}
        onMouseMove={this.props.onMouseMove}
        onDoubleClick={this.props.onDoubleClick}
        scale={this.props.scale}
        setScale={this.props.setScale}
      >
        <img
          src={this.props.image}
          alt=""
          onLoad={() => {
            this.props.setLoadedImg(this.props.image);
          }}
          className={`smooth-image image-${this.props.imageLoaded ? 'visible' : 'hidden'}`}
          key={this.props.image}
        />
        {this.props.children}
      </PanZoom>
    );
  }
}