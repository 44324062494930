import { Box, Modal, Typography } from '@mui/material';
import React from 'react';

interface IFullScreenModalProps extends React.PropsWithChildren {
  open: boolean;
  onClose: () => void;
  sx?: React.CSSProperties;
}

export const FullScreenModal = ({
  open,
  onClose,
  sx,
  children,
}: IFullScreenModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          ...sx
        }}
      >
        {children}
      </Box>
    </Modal>
  )
}

export const ModalTitle = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <Typography id="modal-modal-title" variant="h5" component="h2">
      {children}
    </Typography>
  )
}