import { TextField, Typography, Grid, Autocomplete } from "@mui/material";
import { useRef, useMemo } from "react";
import { Device, isValidDeviceType } from "../../../../api/device";
import { useNewDeviceContext } from "../../../../contexts/newDeviceContext";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DevicePrimaryLocation, DevicePrimaryLocationAutocomplete, DeviceSecondaryLocationAutocomplete, SelectedLocation } from "./DeviceFormLocationDropdowns";

export const DeviceForm = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const floorGridItemStyle = useMemo(() => {
    return smallScreen ? {paddingTop: '0px', paddingLeft: "0px"} : {paddingTop: '0px'};
  }, [smallScreen]);

  const {
    deviceTypes,
    deviceTypesLoading,
    newDevice,
    updateGeneralFields,
    updateDeviceType,
    isNewDevice,
  } = useNewDeviceContext();

  const sshPortLastSeenValue = useRef<number | null>(newDevice.device.ssh_port);

  const deviceTypeOptions = useMemo(() => {
    if (deviceTypes) {
      return deviceTypes.map(type => type.name);
    }

    return [];
  }, [deviceTypes]);

  const selectedPrimaryLocation: SelectedLocation | null = useMemo(() => {
    if (newDevice.device.project_public_id) {
      return {
        id: newDevice.device.project_public_id,
        type: 'Active Projects',
      };
    } else if (newDevice.device.auxiliary_location) {
      return {
        id: newDevice.device.auxiliary_location,
        type: 'Auxiliary Locations',
      }
    }

    return null;
  }, [newDevice.device.project_public_id, newDevice.device.auxiliary_location]);

  const onChangeDevicePrimaryLocation = (newLocation: DevicePrimaryLocation | null) => {
    const stateToUpdate: Partial<Device> = {
      project_public_id: null,
      project_floor: null,
      auxiliary_location: null,
    }
  
    if (newLocation) {
      if (newLocation.type === 'Active Projects') {
        stateToUpdate.project_public_id = newLocation.id;
      } else if (newLocation.type === 'Auxiliary Locations') {
        stateToUpdate.auxiliary_location = newLocation.id;
      }
    }
  
    updateGeneralFields(stateToUpdate);
  }

  const onChangeSSHPort = (newValue: number) => {
    updateGeneralFields({ssh_port: newValue});

    sshPortLastSeenValue.current = newValue;
  }

  return (
    <>
      <Typography sx={{textDecoration: 'underline'}}>Device Fields</Typography>
      <TextField
        required
        label='Name'
        value={newDevice.device.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateGeneralFields({name: e.target.value})}
      />
      <TextField
        label='IP'
        value={newDevice.device.ip}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateGeneralFields({ip: e.target.value})}
      />
      <TextField
        required
        label='Serial Number'
        value={newDevice.device.serial_number}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateGeneralFields({serial_number: e.target.value})}
      />
      <TextField
        required={!newDevice.device.type || newDevice.device.type !== 'sim-card'}
        label='SSH Port'
        type="number"
        inputProps={{
          min: 0,
        }}
        value={newDevice.device.ssh_port !== null ? newDevice.device.ssh_port : ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeSSHPort(parseInt(e.target.value))}
      />
      <TextField
        label='Iperf Port'
        type="number"
        inputProps={{
          min: 0,
        }}
        value={newDevice.device.iperf_port}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateGeneralFields({iperf_port: parseInt(e.target.value)})}
      />
      <Autocomplete
        disabled={deviceTypesLoading || !deviceTypes || !isNewDevice}
        value={newDevice.device.type}
        onChange={(e, value) => {
          if (value && isValidDeviceType(value)) {
            const newDeviceType = value;

            updateDeviceType(newDeviceType);

            if (newDeviceType === 'sim-card') {
              updateGeneralFields({ssh_port: null});
            } else if (!newDevice.device.ssh_port) {
              updateGeneralFields({ssh_port: sshPortLastSeenValue.current});
            }
          }
        }}
        options={deviceTypeOptions}
        sx={{ minWidth: 250 }}
        renderInput={(params) => {
          return (
            <TextField {...params} required label="Type" />
          )
        }}
      />
      <Grid
        container
        spacing={2}
        marginBottom={-2}
      >
        <Grid
          item
          style={{paddingLeft: '0px', paddingTop: '0px'}}
          marginBottom={2}
        >
          <DevicePrimaryLocationAutocomplete
            selectedLocation={selectedPrimaryLocation}
            onChangeLocation={onChangeDevicePrimaryLocation}
            minWidth={!newDevice.device.project_public_id ? 516 : 250}
          />
        </Grid>
        {newDevice.device.project_public_id &&
          <Grid
            item
            style={floorGridItemStyle}
            marginBottom={2}
          >
            <DeviceSecondaryLocationAutocomplete/>
          </Grid>
        }
      </Grid>
    </>
  )
}