import { useMemo } from "react";
import { useRoverLidarPoints } from "../../../../../hooks/teleopHooks/useRoverLidarPoints";

export const LidarPoints = () => {
  const lidarPoints = useRoverLidarPoints();

  const renderedLidarPoints = useMemo(() => {
    if (!lidarPoints) {
      return <></>;
    }

    const points: JSX.Element[] = [];

    for (let i=0; i<lidarPoints.x.length; i++) {
      points.push(
        <div
          key={i}
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            left: `${lidarPoints.x[i]}px`,
            top: `${lidarPoints.y[i]}px`,
            border: 'solid rgba(0, 210, 91, 1.0) 3px',
            height: '2px',
            width: '2px',
            borderRadius: '2px',
            backgroundColor: 'rgba(0, 210, 91, 1.0)'
          }}
        />
      )
    }

    return points;
  }, [lidarPoints]);

  return (
    <>{renderedLidarPoints}</>
  )
}