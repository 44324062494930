import { useCallback, useState } from "react"
import { RoverSocketOnMessageCallback, useRoverSocketOnMessage } from "./useRoverSocketOnMessage";

export interface PathPoint {
  x: number; y: number;
}

export const useRoverPathPoints = () => {
  const [pathPoints, setPathPoints] = useState<PathPoint[]>([]);

  const onReceivePathPointsMessage: RoverSocketOnMessageCallback = useCallback(({message_type, message_version, message}) => {
    if (message_type === 'path') {
      if (message_version === 1) {
        setPathPoints(message.path.poses);
      }
    }
  }, []);

  const resetPathPoints = useCallback(() => {
    setPathPoints([]);
  }, []);

  useRoverSocketOnMessage(onReceivePathPointsMessage, resetPathPoints);

  return pathPoints;
}