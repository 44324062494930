import axios from "axios";
import { API_BASE } from "./constants";

const servicesApiBase = 'https://services.nexterarobotics.com';

type RobotCategory = 'flipper' | 'wheeled';

export interface Robot {
  category: RobotCategory;
  current_project: string;
  device_id: number;
  is_charging: boolean;
  is_online: boolean;
  is_verified: boolean;
  name: string;
  public_id: string;
  status: "idle" | "scanning";
  video_port: number;
  vpn_ip_address: string;
  network_status: string;
  ssh_port: number | null;
}

export const fetchRobots = async (is_verified: boolean): Promise<Robot[]> => {
  const res = await axios.get(
    `${API_BASE}/robots?is_verified=${is_verified}`
  );
  const response = await res.data;
  return response.data;
}

export const fetchRobot = async (public_id: string): Promise<Robot> => {
  const res = await axios.get(
    `${API_BASE}/robot/${public_id}`
  );
  const response = await res.data;
  return response.data;
}

export interface RobotTakeoverPayload {
  robot: string;
  action: string;
  project: string;
  user: string;
  mission: string | null;
  start_frame: number;
  end_frame: number;
}

export const logRobotTakeover = async (data: RobotTakeoverPayload) => {
  const res = await axios.post(
    `${API_BASE}/robot/${data.robot}/takeover`,
    data,
  );
  const response = await res.data;
  return response.data;
}

export const activateFloorTransitionScreen = async (robotSSHPort: number, destinationFloor: number | string, theme?: string) => {
  const res = await axios.post(
    `${servicesApiBase}/transitions/floor/`,
    {
      robot_port: robotSSHPort,
      transition_floor: destinationFloor,
      theme: theme,
    }
  );

  return res.data;
}

export const deActivateFloorTransitionScreen = async (robotSSHPort: number) => {
  const res = await axios.post(
    `${servicesApiBase}/transitions/end/`,
    {
      robot_port: robotSSHPort,
    }
  );

  return res.data;
}

export const viewRobotScreen = async (robotSSHPort: number) => {
  const res = await axios.post(
    `${servicesApiBase}/transitions/check/`,
    {
      robot_port: robotSSHPort,
    },
    {
      responseType: 'blob',
    }
  );

  return res.data;
}

export const rebootStreamers = async (robotSSHPort: number) => {
  const res = await axios.post(
    `${servicesApiBase}/commands/streamers/`,
    {
      robot_port: robotSSHPort,
    }
  );

  return res.data;
}

export const rebootStartupService = async (robotSSHPort: number) => {
  const res = await axios.post(
    `${servicesApiBase}/commands/startup/`,
    {
      robot_port: robotSSHPort,
    }
  );

  return res.data;
}

export interface SpeedTest {
  download: number;
  upload: number;
  ping: number;
  server: {
    url: string;
    lat: string;
    lon: string;
    name: string;
    country: string;
    cc: string;
    sponsor: string;
    id: string;
    host: string;
    d: number;
    latency: number;
  },
  timestamp: string;
  bytes_sent: number;
  bytes_received: number;
  share: null;
  client: {
    ip: string;
    lat: string;
    lon: string;
    isp: string;
    isprating: string;
    rating: string;
    ispdlavg: string;
    ispulavg: string;
    loggedin: string;
    country: string;
  }
}

export const runSpeedTest = async (robotSSHPort: number, abortController?: AbortController): Promise<SpeedTest | null> => {
  const res = await axios.post(
    `${servicesApiBase}/commands/speed_test/`,
    {
      robot_port: robotSSHPort,
    },
    {
      signal: abortController?.signal,
    }
  );

  const response = await res.data;

  return response.data;
}

export const adjustCameraBrightness = async (robotSSHPort: number, isForward: boolean, gamma: number, gain: number) => {
  const res = await axios.post(
    `${servicesApiBase}/commands/camera_brightness/`,
    {
      robot_port: robotSSHPort,
      is_forward: isForward,
      gamma: gamma,
      gain: gain,
    }
  );

  return res.data;
}