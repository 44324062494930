import { Box } from "@mui/material";
import { RobotMaintenanceButton } from "./RobotMaintenanceButton";
import { Image, Work } from "@mui/icons-material";
import { useRoverTeleopActions } from "../../../../../../hooks/teleopHooks/useRoverTeleopActions";

enum UploadType {
  images = "Images",
  bag = "Bag"
}

export const RobotMaintenanceUploadButtons = () => {
  const {
    executeUploadImageCommand,
    executeUploadBagCommand,
  } = useRoverTeleopActions();

  const uploadAssets = (uploadType: UploadType) => {
    if (uploadType === UploadType.images) {
      executeUploadImageCommand();
    } else if (uploadType === UploadType.bag) {
      executeUploadBagCommand();
    }
  }

  return (
    <Box
      display="flex"
      gap="10px"
    >
      <RobotMaintenanceButton
        onClick={() => uploadAssets(UploadType.images)}
        endIcon={<Image/>}
        style={{
          flexBasis: '50%'
        }}
      >
        Upload Images
      </RobotMaintenanceButton>
      <RobotMaintenanceButton
        onClick={() => uploadAssets(UploadType.bag)}
        endIcon={<Work/>}
        style={{
          flexBasis: '50%'
        }}
      >
        Upload Bag
      </RobotMaintenanceButton>
    </Box>
  );
}