import { useCallback, useState } from "react"
import { RoverSocketOnMessageCallback, useRoverSocketOnMessage } from "./useRoverSocketOnMessage";

export const useRoverSequencePoints = () => {
  const [sequencePoints, setSequencePoints] = useState<number[]>([]);

  const onReceiveSequenceMessage: RoverSocketOnMessageCallback = useCallback(({message_type, message_version, message}) => {
    if (message_type === 'sequence') {
      if (message_version === 1) {      
        const incomingSequencePoints = message.sequence.data;
        
        setSequencePoints(incomingSequencePoints);
      }
    }
  }, []);

  const resetSequencePoints = useCallback(() => {
    setSequencePoints([]);
  }, []);

  useRoverSocketOnMessage(onReceiveSequenceMessage, resetSequencePoints);

  return sequencePoints;
}