import { useMemo } from "react";
import { useAllMissionsQuery } from "./queries";
import { Mission } from "../api/missions";

export const useActiveRobotMissions = () => {
  const { data: activeMissions, isLoading } = useAllMissionsQuery({status: 'In Progress'});

  const roverPublicIdMissionMap = useMemo(() => {
    if (activeMissions) {
      const missionsMap = new Map<string, Mission>();

      activeMissions.forEach(mission => {
        missionsMap.set(mission.robot.public_id, mission);
      });

      return missionsMap;
    }

    return undefined;
  }, [activeMissions]);

  return {
    isLoading,
    roverPublicIdMissionMap,
    activeMissions,
  }
}