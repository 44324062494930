export const UPDATE_USER_INPUT_STATE = 'UPDATE_USER_INPUT_STATE';

interface UserInputStateEntry {
  desired_state: boolean;
  executed_on: number;
}

export type UserInputState = Record<string, UserInputStateEntry>;

export interface UpdateUserStateAction {
  type: typeof UPDATE_USER_INPUT_STATE;
  payload: UserInputState;
}

export const userInputReducer = (state: UserInputState, action: UpdateUserStateAction) => {
  switch (action.type) {
    case UPDATE_USER_INPUT_STATE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return {...state};
  }
}