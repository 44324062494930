import { Box, Checkbox, FormControlLabel, FormGroup, /*ChipProps,*/ Tab, Tabs, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Project } from "../../../api/projects";
import { Breadcrumb } from "../../common/Breadcrumb"
import { ProjectsAutocomplete } from "../../common/Dropdowns";
import { useLocation } from "react-router-dom";
import { RoversTab } from "./RoversTab/RoversTab";
import { useNavigation } from "../../../hooks/useNavigation";
import { SoftwareVersionsTab } from "./Versions/SoftwareVersionsTab";
import { HardwareVersionsTab } from "./Versions/HardwareVersionsTab";

// type RoverStatus = 'Charging' | 'Driving' | 'Error' | 'Offline';
// type RoverStatusColors = Record<RoverStatus, ChipProps['color']>;

// const roverStatusColors: RoverStatusColors = {
//   Charging: 'success',
//   Driving: 'violet',
//   Error: 'error',
//   Offline: 'offline'
// }

type TabType = 'rovers' | 'software versions' | 'hardware versions' ;
const tabs: TabType[] = ['rovers', 'software versions', 'hardware versions'];

export const Rovers = () => {
  const {
    navigateToRovers,
  } = useNavigation();

  const params = new URLSearchParams(useLocation().search);
  const selectedTabParam = parseInt(params.get("selectedTab") ?? '');

  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [selectedTab, setSelectedTab] = useState<number>(selectedTabParam ? selectedTabParam : 0);
  const [showUnassigned, setShowUnassigned] = useState<boolean>(false);

  const showProjectsDropdown = tabs[selectedTab] === 'rovers';
  const showShowUnassignedCheckbox = tabs[selectedTab] === 'rovers' && (!selectedProject || selectedProject?.name === 'All');

  useEffect(() => {
    if (isNaN(selectedTabParam) || selectedTabParam !== selectedTab) {
      navigateToRovers({selectedTab: selectedTab.toString()});
    }
  }, [navigateToRovers, selectedTab, selectedTabParam]);

  const onChangeSelectedProject = (project: Project | null) => {
    setSelectedProject(project);

    if (project && project.name === 'Unassigned') {
      setShowUnassigned(true);
    } else {
      setShowUnassigned(false);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant='h5'>
            Rovers
          </Typography>
          <Breadcrumb
            items={[{text: 'Rovers'}]}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap={2}
        >
          {showShowUnassignedCheckbox &&
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showUnassigned}
                    onChange={(e, checked) => setShowUnassigned(checked)}
                  />
                }
                label="Show Unassigned"
              />
            </FormGroup>
          }
          {showProjectsDropdown &&
            <ProjectsAutocomplete
              includeAllOption
              includeUnassignedOption
              selectedProject={selectedProject}
              setSelectedProject={onChangeSelectedProject}
              width={400}
            />
          }
        </Box>
      </Box>

      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}
      >
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tabs
            value={selectedTab}
            onChange={(e: React.ChangeEvent<{}>, newValue: number) => setSelectedTab(newValue)}
          >
            {tabs.map(tab => {
              return (
                <Tab
                  key={tab}
                  label={tab}
                  style={{width: `${Math.round(1 / tabs.length * 100)}%`}}
                />
              )
            })}
          </Tabs>
        </Box>
        <Box flex={1}>
          {tabs[selectedTabParam] === 'rovers' &&
            <RoversTab
              selectedProject={selectedProject}
              showUnassigned={showUnassigned}
            />
          }
          {tabs[selectedTabParam] === 'software versions' &&
            <SoftwareVersionsTab/>
          }
          {tabs[selectedTabParam] === 'hardware versions' &&
            <HardwareVersionsTab/>
          }
        </Box>
      </Box>
    </Box>
  );
}