import { useState, useEffect, useCallback, useRef } from 'react';
import { FullScreenModal, ModalTitle } from "../../common/FullScreenModal";
import { SpeedTest, runSpeedTest } from '../../../api/robots';
import { Alert, Box, Button, Typography } from '@mui/material';
import { LoadingIndicator } from '../../common/LoadingIndicator';

let controller = new AbortController();

interface ISpeedTestModalProps {
  robotSSHPort?: number | null;
  robotName?: string;
  open: boolean;
  onClose: () => void;
}

export const SpeedTestModal = ({
  robotSSHPort,
  robotName,
  open,
  onClose,
}: ISpeedTestModalProps) => {
  const modalActive = !!robotSSHPort && !!robotName && open;

  const [speedTest, setSpeedTest] = useState<SpeedTest | null>(null);
  const [displaySpeedTestError, setDisplaySpeedTestError] = useState<boolean>(false);
  const [speedTestInProgress, setSpeedTestInProgress] = useState<boolean>(true);
  const [secondsRemaining, setSecondsRemaining] = useState<number>(59);
  const countdownInterval = useRef<NodeJS.Timer>();

  const clearCountdownInterval = () => {
    clearInterval(countdownInterval.current);
    countdownInterval.current = undefined;
    setSecondsRemaining(59);
  }

  const resetModalState = () => {
    setDisplaySpeedTestError(false);
    setSpeedTest(null);
    setSpeedTestInProgress(true);
  }

  const resetModal = useCallback(() => {
    controller.abort();
    controller = new AbortController();
    clearCountdownInterval();
    resetModalState();
  }, []);

  const executeRunSpeedTest = useCallback(() => {
    if (modalActive) {
      resetModalState();

      runSpeedTest(robotSSHPort, controller)
        .then(speedTestResult => {
          setSpeedTest(speedTestResult);
          setSpeedTestInProgress(false);

          if (speedTestResult === null) {
            throw new Error();
          }
        })
        .catch((err) => {
          const requestWasCanceled = !!err && (err.code === 'ERR_CANCELED' || err.name === 'CanceledError');
          
          if (!requestWasCanceled) {
            setDisplaySpeedTestError(true);
            setSpeedTestInProgress(false);
          }
        })
    }
  }, [modalActive, robotSSHPort]);

  useEffect(() => {
    executeRunSpeedTest();
  }, [executeRunSpeedTest]);

  useEffect(() => {
    if (speedTestInProgress && modalActive) {
      countdownInterval.current = setInterval(() => {
        setSecondsRemaining(prevValue => prevValue - 1);
      }, 1000);
    } else {
      clearCountdownInterval();
    }
  }, [speedTestInProgress, modalActive]);

  useEffect(() => {
    if (!modalActive) {
      resetModal();
    }
  }, [modalActive, resetModal]);

  const formatSpeed = (speed: number) => {
    return `${(speed / 1000000).toFixed(2)} mbps`;
  }

  return (
    <FullScreenModal
      open={open}
      onClose={onClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
      >
        <ModalTitle>
          {robotName} Speed Test
        </ModalTitle>
        <Box>
          {speedTestInProgress &&
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <LoadingIndicator/>
              <Typography color="primary">
                {secondsRemaining >= 1 &&
                  <>{`0:${secondsRemaining >= 10 ? secondsRemaining : '0' + secondsRemaining}`}</>
                }
                {secondsRemaining < 1 &&
                  <>Loading</>
                }
              </Typography>
            </Box>
          }
          {!speedTestInProgress &&
            <>
              {displaySpeedTestError &&
                <Alert
                  severity="error"
                  variant="filled"
                >
                  Error running speed test
                </Alert>
              }
              {(!!speedTest && !displaySpeedTestError) &&
                <Box>
                  <Typography>
                    Download: {formatSpeed(speedTest.download)}
                  </Typography>
                  <Typography>
                    Upload: {formatSpeed(speedTest.upload)}
                  </Typography>
                  <Typography>
                    Ping: {`${speedTest.ping} ms`}
                  </Typography>
                </Box>
              }
            </>
          }
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <Button
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            disabled={speedTestInProgress}
            variant="contained"
            onClick={executeRunSpeedTest}
          >
            Re-Run
          </Button>
        </Box>
      </Box>
    </FullScreenModal>
  )
}