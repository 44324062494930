import { Button, ButtonProps } from "@mui/material"

export const RobotMaintenanceButton = ({
  children,
  ...rest
}: ButtonProps) => {
  return (
    <Button
      variant="outlined"
      sx={{
        height: '50px',
      }}
      {...rest}
    >
      {children}
    </Button>
  )
}