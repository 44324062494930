import { Autocomplete, AutocompleteProps, TextField, Box, Button } from "@mui/material";
import { styled, lighten, darken } from '@mui/system';
import { useMemo } from "react";
import { Device } from "../../../../api/device";
import { useNewDeviceContext } from "../../../../contexts/newDeviceContext";
import { useFetchDeviceAuxiliaryLocationsQuery, useProjectFloorsQuery, useProjectsQuery } from "../../../../hooks/queries";
import { multiSelectRenderOption } from "../../../common/Dropdowns";

interface BaseLocation {
  id: number | string;
  name: string;
  type: 'Active Projects' | 'Auxiliary Locations' | 'Floors';
}

interface ProjectPrimaryLocation extends BaseLocation {
  id: string;
  type: 'Active Projects';
}

interface AuxiliaryPrimaryLocation extends BaseLocation {
  id: number;
  type: 'Auxiliary Locations';
}

interface ProjectFloorSecondaryLocation extends BaseLocation {
  id: number;
  type: 'Floors';
}

export type DevicePrimaryLocation = ProjectPrimaryLocation | AuxiliaryPrimaryLocation;
export type DeviceSecondaryLocation = ProjectFloorSecondaryLocation | AuxiliaryPrimaryLocation;
export type SelectedLocation = Omit<DevicePrimaryLocation | DeviceSecondaryLocation, 'name'>;

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

interface IBaseDeviceLocationAutoCompleteProps<T extends BaseLocation> {
  disabled?: boolean;
  value: T | T[] | null;
  options: T[];
  onChange: ((val: T | null) => void) | ((val: T[]) => void);
  label: string;
  minWidth?: number;
  multiple?: boolean;
  renderOption?: AutocompleteProps<T, boolean | undefined, undefined, undefined, "div">["renderOption"];
  onClickGroupSelectAll?: (selectedGroup: string) => void;
}

const BaseDeviceLocationAutoComplete = <T extends BaseLocation>({
  disabled,
  value,
  options,
  onChange,
  minWidth,
  label,
  multiple,
  renderOption,
  onClickGroupSelectAll,
}: IBaseDeviceLocationAutoCompleteProps<T>) => {
  
  return (
    <Autocomplete
      disabled={disabled}
      value={value}
      onChange={(e: any, val: any) => onChange(val)}
      options={options}
      groupBy={option => option.type}
      getOptionLabel={option => option.name}
      sx={{
        minWidth: minWidth ?? 250,
        maxWidth: multiple ? minWidth ?? 250 : undefined,
      }}
      limitTags={2}
      disableCloseOnSelect={multiple}
      renderInput={params => <TextField {...params} label={label} />}
      renderGroup={params => (
        <li key={params.group}>
          <GroupHeader
            style={{zIndex: 2}}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {params.group}
              {multiple &&
                <Button
                  onClick={() => {
                    if (onClickGroupSelectAll) {
                      onClickGroupSelectAll(params.group);
                    }
                  }}
                >
                  Select All
                </Button>
              }
            </Box>
            
          </GroupHeader>
          <GroupItems>
            {params.children}
          </GroupItems>
        </li>
      )}
      renderOption={renderOption}
      multiple={!!multiple}
    />
  );
}

interface IDeviceLocationAutocompleteProps {
  selectedLocation: SelectedLocation | SelectedLocation[] | null;
  onChangeLocation: ((newLocation: DevicePrimaryLocation | null) => void) | ((newLocation: DevicePrimaryLocation[]) => void);
  minWidth?: number;
  label?: string;
  multiple?: boolean;
  onClickGroupSelectAll?: (selectedGroup: string) => void;
}

interface IDeviceLocationAutocompletePropsSingular extends IDeviceLocationAutocompleteProps{
  multiple?: false;
  selectedLocation: SelectedLocation | null;
  onChangeLocation: (newLocation: DevicePrimaryLocation | null) => void;
  onClickGroupSelectAll?: undefined;
}

interface IDeviceLocationAutocompletePropsMultiple extends IDeviceLocationAutocompleteProps{
  multiple: true;
  selectedLocation: SelectedLocation[];
  onChangeLocation: (newLocation: DevicePrimaryLocation[]) => void;
}

export const DevicePrimaryLocationAutocomplete = ({
  selectedLocation,
  onChangeLocation,
  minWidth,
  label,
  multiple,
}: IDeviceLocationAutocompletePropsSingular | IDeviceLocationAutocompletePropsMultiple) => {
  const {
    data: projects,
    isLoading: projectsLoading,
  } = useProjectsQuery('active');

  const {
    data: deviceAuxiliaryLocations,
    isLoading: deviceAuxiliaryLocationsLoading,
  } = useFetchDeviceAuxiliaryLocationsQuery();

  const projectsLoaded = !!projects && !projectsLoading;
  const auxiliaryLocationsLoaded = !!deviceAuxiliaryLocations && !deviceAuxiliaryLocationsLoading;

  const projectOptions: DevicePrimaryLocation[] = useMemo(() => {
    if (!projectsLoaded) {
      return [];
    }

    return projects.map(project => {
      return {
        id: project.public_id,
        name: project.name,
        type: 'Active Projects',
      };
    });
  }, [projects, projectsLoaded]);

  const auxiliaryOptions: DevicePrimaryLocation[] = useMemo(() => {
    if (!auxiliaryLocationsLoaded) {
      return [];
    }

    return deviceAuxiliaryLocations.map(aux => {
      return {
        id: aux.id,
        name: aux.name,
        type: 'Auxiliary Locations'
      }
    });
  }, [deviceAuxiliaryLocations, auxiliaryLocationsLoaded]);

  const options = useMemo(() => {
    if (!projectsLoaded || !auxiliaryLocationsLoaded) {
      return [];
    }

    return [...projectOptions, ...auxiliaryOptions];
  }, [
    projectsLoaded,
    auxiliaryLocationsLoaded,
    projectOptions,
    auxiliaryOptions,
  ]);

  const value = useMemo(() => {
    if (!multiple) {
      if (selectedLocation) {
        if (selectedLocation.type === 'Active Projects' && projectOptions.length > 0) {
          return projectOptions.filter(option => option.id === selectedLocation.id)[0];
        } else if (selectedLocation.type === 'Auxiliary Locations' && auxiliaryOptions.length > 0) {
          return auxiliaryOptions.filter(option => option.id === selectedLocation.id)[0];
        }
      }
      
      return null;
    } else {
      return [...projectOptions, ...auxiliaryOptions].filter(option => {
        return selectedLocation.some(selected => {
          return selected.id === option.id && selected.type === option.type;
        });
      });
    }
  }, [selectedLocation, projectOptions, auxiliaryOptions, multiple]);

  const onClickGroupSelectAll = (group: string) => {
    if (multiple) {
      const optionsInGroup = options.filter(option => option.type === group);

      onChangeLocation(optionsInGroup as DevicePrimaryLocation[]);
    }
  }

  return (
    <BaseDeviceLocationAutoComplete
      disabled={!projectsLoaded || !auxiliaryLocationsLoaded}
      value={value}
      onChange={(val: any) => onChangeLocation(val)}
      options={options}
      minWidth={minWidth}
      label={label ?? "Location"}
      renderOption={multiple ? (props, option, state) => multiSelectRenderOption(props, option.name, state) : undefined}
      multiple={multiple}
      onClickGroupSelectAll={onClickGroupSelectAll}
    />
  );
}

export const DeviceSecondaryLocationAutocomplete = () => {
  const {
    newDevice,
    updateGeneralFields,
  } = useNewDeviceContext();

  const {
    data: projectFloors,
    isLoading: projectFloorsLoading
  } = useProjectFloorsQuery(newDevice.device.project_public_id ?? '');

  const {
    data: deviceAuxiliaryLocations,
    isLoading: deviceAuxiliaryLocationsLoading,
  } = useFetchDeviceAuxiliaryLocationsQuery(newDevice.device.project_public_id);

  const projectFloorsLoaded = !!projectFloors && !projectFloorsLoading;
  const auxiliaryLocationsLoaded = !!deviceAuxiliaryLocations && !deviceAuxiliaryLocationsLoading;

  const onChangeDevicePrimaryLocation = (newLocation: DeviceSecondaryLocation | null) => {
    const stateToUpdate: Partial<Device> = {
      project_floor: null,
      auxiliary_location: null,
    }

    if (newLocation) {
      if (newLocation.type === 'Floors') {
        stateToUpdate.project_floor = newLocation.id;
      } else if (newLocation.type === 'Auxiliary Locations') {
        stateToUpdate.auxiliary_location = newLocation.id;
      }
    }

    updateGeneralFields(stateToUpdate);
  }

  const projectFloorOptions: DeviceSecondaryLocation[] = useMemo(() => {
    if (!projectFloorsLoaded) {
      return [];
    }

    return projectFloors.map(floor => {
      return {
        id: floor.id,
        name: floor.name,
        type: 'Floors',
      };
    });
  }, [projectFloors, projectFloorsLoaded]);

  const auxiliaryOptions: DeviceSecondaryLocation[] = useMemo(() => {
    if (!auxiliaryLocationsLoaded) {
      return [];
    }

    return deviceAuxiliaryLocations.map(aux => {
      return {
        id: aux.id,
        name: aux.name,
        type: 'Auxiliary Locations'
      }
    });
  }, [deviceAuxiliaryLocations, auxiliaryLocationsLoaded]);

  const options = useMemo(() => {
    if (!projectFloorsLoaded || !auxiliaryLocationsLoaded) {
      return [];
    }

    return [...projectFloorOptions, ...auxiliaryOptions];
  }, [
    projectFloorsLoaded,
    auxiliaryLocationsLoaded,
    projectFloorOptions,
    auxiliaryOptions,
  ]);

  const value = useMemo(() => {
    if (newDevice.device.project_floor && projectFloorOptions.length > 0) {
      return projectFloorOptions.filter(option => option.id === newDevice.device.project_floor)[0];
    } else if (newDevice.device.auxiliary_location && auxiliaryOptions.length > 0) {
      return auxiliaryOptions.filter(option => option.id === newDevice.device.auxiliary_location)[0];
    }

    return null;
  }, [projectFloorOptions, auxiliaryOptions, newDevice.device]);

  return (
    <BaseDeviceLocationAutoComplete
      disabled={!projectFloorsLoaded || !auxiliaryLocationsLoaded}
      value={value}
      onChange={(val: DeviceSecondaryLocation | null) => onChangeDevicePrimaryLocation(val)}
      options={options}
      label="Sub-Location"
    />
  );
}
