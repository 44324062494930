import { useMemo } from 'react';
import styled from 'styled-components';
import { useMissionManagementContext } from '../../../../../contexts/missionManagementContext';
import { CopilotClass } from '../../../../../contexts/copilotContext';

interface CopilotOverlayProps {
  scaleX: number;
  scaleY: number;
  identifier: string;
}

export const CopilotOverlay = ({
  scaleX,
  scaleY,
  identifier,
}: CopilotOverlayProps) => {
  const {
    missions
  } = useMissionManagementContext();

  const {
    fillCopilotPolygons,
    copilotOutput,
    copilotPolygonOpacity,
    activeCopilotClasses,
    copilotClassColors,
  } = missions[identifier] ?? {}

  const predictedPolygons = useMemo(() => {
    if (copilotOutput) {
      const predictions = copilotOutput.predictions;
      const predictedClasses = Object.getOwnPropertyNames(copilotOutput.predictions).map(propName => parseInt(propName) as CopilotClass);
      const classesToDisplay = predictedClasses.filter(predictedClass => activeCopilotClasses.has(predictedClass));

      return classesToDisplay.map(identifiedClass => {
        const predictedClass = predictions[identifiedClass];

        return predictedClass?.segmentations.map(segmentation => {
          const boundaryPoints = segmentation.boundary;
          const scaledBoundaryPoints = boundaryPoints.map(coordinates => [coordinates[0] * scaleX, coordinates[1] * scaleY])
          const joinedPoints = scaledBoundaryPoints.map(coordinates => coordinates.join(','));
          const stringifiedPoints = joinedPoints.join(' ');

          const classColor = copilotClassColors[identifiedClass];
          const fill = fillCopilotPolygons ? classColor : 'transparent';
          const opacity = fillCopilotPolygons ? copilotPolygonOpacity : 1;

          return (
            <polygon
              key={stringifiedPoints}
              points={stringifiedPoints}
              stroke={classColor}
              strokeWidth="3"
              fill={fill}
              opacity={opacity}
            />
          )
        });
      });
    }

    return <></>;
  }, [activeCopilotClasses, copilotClassColors, copilotOutput, copilotPolygonOpacity, fillCopilotPolygons, scaleX, scaleY]);

  return (
    <CopilotSVG
      width='100%'
      height='100%'
    >
      {predictedPolygons}
    </CopilotSVG>
  );
}

const CopilotSVG = styled.svg`
  position: absolute;
  left: 0;
  right: 0;
  background-color:transparent;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::selection 
  {
      background-color:transparent;
  } 
  
  &::-moz-selection
  {
      background-color:transparent;
  }
`;