import { useTheme } from '@mui/material/styles';
import { Divider, IconButton, List, Toolbar, Box, Avatar, Menu, MenuItem, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  ChevronLeft,
  ChevronRight,
  DashboardOutlined,
  CalendarTodayOutlined,
  ElectricCarOutlined,
  SportsEsportsOutlined,
  NetworkCheckOutlined,
  CorporateFare,
} from '@mui/icons-material';
import { StyledAppBar } from './StyledAppBar';
import { StyledDrawer, StyledDrawerHeader } from './StyledDrawer';
import { ILeftNavItem, LeftNavIcon } from './LeftNavIcon';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { useUserContext } from '../../../contexts/userContext';
import { PATH_STRINGS } from '../../../hooks/useGeneratedPaths';
import { PermissionWrappedComponent } from '../../common/PermissionWrapper';
import { useMatch } from 'react-router-dom';

const leftNavItems: ILeftNavItem[] = [
  { text: 'Dashboard', icon: <DashboardOutlined/>, to: '/' },
  { text: 'Mission Control', icon: <SportsEsportsOutlined/>, to: PATH_STRINGS.missionControl},
  { text: 'Rovers', icon: <ElectricCarOutlined/>, to: PATH_STRINGS.rovers },
  { text: 'Schedules', icon: <CalendarTodayOutlined/>, to: PATH_STRINGS.schedules, permission: 'scheduler_tables.list_scheduler_schedule' },
  { text: 'Network', icon: <NetworkCheckOutlined/>, to: PATH_STRINGS.network },
  { text: 'Site View', icon: <CorporateFare/>, to: PATH_STRINGS.projects},
];

const drawerWidth = 240;

export const Navigation = () => {
  const {
    resetUser,
    state: userState,
  } = useUserContext();

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const medium = useMediaQuery(theme.breakpoints.down('md'));

  const missionControlMatch = useMatch(PATH_STRINGS.missionControl);
  const inTeleopInterface = !!missionControlMatch;

  const defaultPaddingX = small ? '10px' : medium ? '15px' : '75px';
  const teleopPaddingX = small ? '10px' : medium ? '10px' : '10px';

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    resetUser();
    localStorage.clear();
  }

  const userInitials = `${userState.first_name[0]}${userState.last_name[0]}`;

  return (
    <Box
      display="flex"
    >
      <StyledAppBar
        position="fixed"
        open={open}
        drawerwidth={drawerWidth}
        enableColorOnDark
        color='appBar'
        theme={theme}
        style={{
          zIndex: open ? 1 : theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon/>
          </IconButton>
          <Box flexGrow={1}/>
          <Box display='flex'>
            <Avatar
              onClick={handleProfileMenuOpen}
              sx={{
                backgroundColor: '#E2E8F0',
                cursor: 'pointer'
              }}
            >
              {userInitials}
            </Avatar>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleProfileMenuClose}
              sx={{ top: '45px' }}
            >
              <MenuItem onClick={logout}>
                Log out
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </StyledAppBar>
      <StyledDrawer
        variant="permanent"
        open={open}
        drawerwidth={drawerWidth}
      >
        <StyledDrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </StyledDrawerHeader>
        <Divider />
        <List>
          { leftNavItems.map((item: ILeftNavItem) => {
            const icon = (
              <LeftNavIcon
                key={item.text}
                item={item}
                open={open}
                setOpen={setOpen}
              />
            );

            if (item.permission) {
              return (
                <PermissionWrappedComponent
                  key={item.text}
                  permission={item.permission}
                >
                  {icon}
                </PermissionWrappedComponent>
              )
            } else {
              return icon
            }
          })}
        </List>
      </StyledDrawer>
      <Box
        flexGrow={1}
        pt={inTeleopInterface ? '75px' : '104px'}
        pb={inTeleopInterface ? '10px' : '40px'}
        px={inTeleopInterface ? teleopPaddingX : defaultPaddingX}
        component="main"
        height="100vh"
      >
        <Outlet/>
      </Box>
    </Box>
  );
}
