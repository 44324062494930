import { useEffect } from "react";
import { PageLoadingIndicator } from "../../common/LoadingIndicator";
import { useAcquireMsalToken } from "../../../hooks/useAcquireMsalToken";
import { useNavigation } from "../../../hooks/useNavigation";
import { useUserContext } from "../../../contexts/userContext";

export const LoginRedirect = () => {
  const { navigateToLogin } = useNavigation();
  const { resetUser } = useUserContext();
  const acquireMsalToken = useAcquireMsalToken();

  useEffect(() => {
    try {
      acquireMsalToken();
    } catch (e) {
      localStorage.clear();
      resetUser();
      navigateToLogin();
    }
    
  }, [acquireMsalToken, navigateToLogin, resetUser]);

  return (
    <PageLoadingIndicator/>
  );
}