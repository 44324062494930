import axios from "axios";
import { SERVICES_API_BASE } from "./constants";

export const startCopilot = async (port: number, robotId: string | number) => {
  const res = await axios.post(
    `${SERVICES_API_BASE}/copilot/start/`, {
      port: port,
      robot_id: robotId,
    }
  )
  const response = await res.data;
  return response.data;
}

export const stopCopilot = async (port: number, robotId: string | number) => {
  const res = await axios.post(
    `${SERVICES_API_BASE}/copilot/stop/`, {
      port: port,
      robot_id: robotId,
    }
  )
  const response = await res.data;
  return response.data;
}