import { useCallback, useState } from "react";
import { RoverSocketOnMessageCallback, useRoverSocketOnMessage } from "./useRoverSocketOnMessage";

interface IncomingSafetyDetectorStatus {
  enabled: 'true' | 'false';
  triggered: 'true' | 'false';
}

export interface SafetyDetectorStatus {
  enabled: boolean;
  triggered: boolean;
}

export interface SafetyDetectors {
  detectors: Record<string, SafetyDetectorStatus>;
  received_on: number;
}

export const useTeleopSafetyStatus = () => {
  const [teleopSafetyStatus, setTeleopSafetyStatus] = useState<SafetyDetectors | null>(null);

  const onReceiveTeleopSafetyMessage: RoverSocketOnMessageCallback = useCallback(({message_type, message_version, message}) => {
    if (message_type === 'safety_detectors') {
      if (message_version === 1) {
        const parsedMessageData = JSON.parse(message.data) as Record<string, IncomingSafetyDetectorStatus>;
        let formattedMessageData: Record<string, SafetyDetectorStatus> = {};

        for (const detectorName in parsedMessageData) {
          const detectorStatus = parsedMessageData[detectorName];
          formattedMessageData[detectorName] = {
            enabled: detectorStatus.enabled === 'true',
            triggered: detectorStatus.triggered === 'true',
          };
        }

        setTeleopSafetyStatus({
          detectors: formattedMessageData,
          received_on: Date.now(),
        });
      }
    }
  }, []);

  const resetTeleopSafetyStatus = useCallback(() => {
    setTeleopSafetyStatus(null);
  }, []);

  useRoverSocketOnMessage(onReceiveTeleopSafetyMessage, resetTeleopSafetyStatus);

  return teleopSafetyStatus;
}