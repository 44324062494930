import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface ILeftNavItem {
  text: string;
  icon: JSX.Element;
  to: string;
  permission?: string;
}

interface ILeftNavIconProps {
  item: ILeftNavItem
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LeftNavIcon = ({
  item,
  open,
  setOpen,
}: ILeftNavIconProps) => {
  const navigate = useNavigate();

  return (
    <ListItem
      key={item.text}
      disablePadding
      sx={{ display: 'block' }}
      onClick={() => {
        setOpen(false);
        navigate(item.to);
      }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  )
}