import { Button, Grid, Stack, Box } from "@mui/material"
import { createNewDevice, editDevice } from "../../../../api/device"
import { useNewDeviceContext } from "../../../../contexts/newDeviceContext"
import { PATH_STRINGS, useGeneratedPaths } from "../../../../hooks/useGeneratedPaths"
import { useNavigation } from "../../../../hooks/useNavigation"
import { Breadcrumb } from "../../../common/Breadcrumb"
import { LoadingIndicator } from "../../../common/LoadingIndicator"
import { DeviceForm } from "./DeviceForm"
import { GatewayForm } from "./GatewayForm"
import { ModemForm } from "./ModemForm"
import { RadioForm } from "./RadioForm"
import { RaspberryPiForm } from "./RaspberryPiForm"
import { RobotForm } from "./RobotForm"
import { SimCardForm } from "./SimCardForm"
import { useState } from "react"
import { useAlerts } from "../../../../hooks/useAlerts"

export const NewDevice = () => {
  const {
    newDevice,
    updateDevice,
    isNewDevice,
    deviceLoaded,
    isValidForSubmit,
  } = useNewDeviceContext();

  const {addSuccessAlert, addErrorAlert} = useAlerts();

  const {
    generateDeviceDetailsPath,
  } = useGeneratedPaths();

  const {
    navigateToCreateNewDevice,
    navigateToEditDevice,
  } = useNavigation();

  const onClickCreateNewDevice = () => {
    navigateToCreateNewDevice();
  }

  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  const onCreateNewDevice = async () => {
    try {
      const createdDevice = await createNewDevice(newDevice);

      navigateToEditDevice(createdDevice.device.DeviceID);
      addSuccessAlert('Device created successfully');
    } catch (err) {
      addErrorAlert('Error creating device');
    }
  }

  const onUpdateDevice = async () => {
    try {
      const updatedDevice = await editDevice(newDevice);

      updateDevice(updatedDevice);
      addSuccessAlert('Device updated successfully');
    } catch (err) {
      addErrorAlert('Error updating device');
    }
  }

  const onClickSubmit = async () => {
    if (isValidForSubmit) {
      setSaveInProgress(true);

      if (isNewDevice) {
        await onCreateNewDevice();
      } else {
        await onUpdateDevice();
      }

      setSaveInProgress(false);
    }
  }

  if (!deviceLoaded) {
    return <LoadingIndicator />
  }

  const breadcrumbItems = isNewDevice ?
    [{text: 'Devices', to: PATH_STRINGS.network}, {text: 'New Device'}] :
    [{text: 'Device Details', to: generateDeviceDetailsPath(newDevice.device.DeviceID)}, {text: 'Edit Device'}]

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Breadcrumb
              items={breadcrumbItems}
            />
          </Grid>
          {!isNewDevice &&
            <Grid item>
              <Button
                variant="contained"
                onClick={onClickCreateNewDevice}
              >
                Create New Device
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>

      <Grid item flexBasis="100%">
        <Grid container justifyContent="space-between">
          <Grid item mb={2}>
            <Stack spacing={2}>
              <DeviceForm />
            </Stack>
          </Grid>
          <Grid item mb={2}>
            <Stack spacing={2}>
              {newDevice.device.type === 'radio' &&
                <RadioForm />
              }
              {newDevice.device.type === 'raspberrypi' &&
                <RaspberryPiForm />
              }
              {newDevice.device.type === 'sim-card' &&
                <SimCardForm />
              }
              {newDevice.device.type === 'modem' &&
                <ModemForm />
              }
              {newDevice.device.type === 'gateway' &&
                <GatewayForm />
              }
              {newDevice.device.type === 'robot' &&
                <RobotForm />
              }
            </Stack>
          </Grid>
          <Grid item/>
        </Grid>
      </Grid>

      <Grid item>
        <Box
          display="flex"
          alignItems="center"
          mb={2}
        >
          <Button
            disabled={!isValidForSubmit}
            variant="contained"
            onClick={onClickSubmit}
            sx={{
              width: '100px'
            }}
          >
            {isNewDevice ? 'Create' : 'Update'}
          </Button>
          {saveInProgress &&
            <LoadingIndicator
              containerStyle={{
                width: '50px'
              }}
            />
          }
        </Box>
      </Grid>
    </Grid>
  )
}