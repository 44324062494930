import { Alert, Box, Button, Card, CardContent, Stack, TextField, Typography } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react";
import axios from 'axios';
import { formatTokenResponseData, login, msTokenGenerate } from "../../../api/auth";
import { loginRequest, silentRequest } from '../../../msAuthConfig';
import { useMsal } from "@azure/msal-react";
import { Microsoft } from "@mui/icons-material";
import styled from "styled-components";
import { PageLoadingIndicator } from "../../common/LoadingIndicator";
import { useHandleDbTokenResponse } from "../../../hooks/useHandleDbTokenResponse";
import { useAcquireMsalToken } from "../../../hooks/useAcquireMsalToken";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../../../contexts/userContext";

const axiosLoginInstance = axios.create();
delete axiosLoginInstance.defaults.headers.common['Authorization'];

export const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [localLoad, setLocalLoad] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const redirectErrorMessage = location.state?.errorMessage;

  const { instance } = useMsal();
  const acquireMsalToken = useAcquireMsalToken();
  const handleTokenResponse = useHandleDbTokenResponse();

  const { resetUser } = useUserContext();

  const logOutUser = useCallback(() => {
    localStorage.clear();
    resetUser();
  }, [resetUser]);

  useEffect(() => {
    if (redirectErrorMessage) {
      setError(redirectErrorMessage);
    }
  }, [redirectErrorMessage]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  const generateTokenFromMsToken = useCallback(async (token: string) => {
    msTokenGenerate(token)
    .then(res => formatTokenResponseData(res))
    .then(tokenResponse => handleTokenResponse(tokenResponse))
    .catch(e => {
      setError("Unable to authenticate");
      logOutUser();
      setLocalLoad(false);
    });
  }, [handleTokenResponse, logOutUser]);

  const handleMsalLogin = async () => {
    const dbUsr = localStorage.getItem('db_usr');
    const parsedDbUser = dbUsr ? JSON.parse(dbUsr) : undefined;
    const userEmail = parsedDbUser ? parsedDbUser.email : undefined;

    const silentLoginRequest = {
      ...silentRequest,
      loginHint: userEmail
    }

    setLocalLoad(true);

    try {
      const ssoSilentResponse = await instance.ssoSilent(silentLoginRequest);
      await generateTokenFromMsToken(ssoSilentResponse.accessToken);
    } catch (err) {
      await instance.loginRedirect(loginRequest);
    } finally {
      setLocalLoad(false);
    }
  }

  const _login = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    delete axios.defaults.headers.common['Authorization'];

    if (email.trim() && password.trim()) {
      login(email, password)
        .then(user => handleTokenResponse(user))
        .catch(e => {
          setError(e.message);
          setLocalLoad(false);
        });
    }
  }

  useEffect(() => {
    acquireMsalToken();
  }, [acquireMsalToken]);

  if (localLoad) return <PageLoadingIndicator/>;

  return (
    <form onSubmit={() => _login}>
      <Box
        position='relative'
        height='100vh'
      >
        { error &&
          <Alert
            severity="error"
          >
            {error}
          </Alert>
        }
        <Card
          sx={{
            width: '350px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack spacing={2}>
              <Typography variant='h4'>
                Log In
              </Typography>
              <TextField
                label='Email'
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
              <TextField
                type="password"
                label='Password'
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                variant='contained'
                onClick={_login}
              >
                LOG IN
              </Button>
              <ThirdPartySeparator>
                or
              </ThirdPartySeparator>
              <Button
                type="submit"
                variant='contained'
                startIcon={<Microsoft/>}
                onClick={handleMsalLogin}
              >
                <span
                  style={{
                    marginTop: '1.5px'
                  }}
                >
                  Log in with Microsoft
                </span>
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </form>
  )
}

const ThirdPartySeparator = styled.div`
  text-align: center;
  color: gray;
`;
