import { LoadingIndicator } from "../../../../../common/LoadingIndicator";

interface IEndIconProps {
  icon: JSX.Element;
  loading?: boolean;
}

export const RobotMaintenanceButtonEndIcon = ({
  loading,
  icon,
}: IEndIconProps) => {
  return (
    <>
      {loading &&
        <LoadingIndicator
          size={14}
          containerStyle={{
            marginLeft: '6px'
          }}
        />
      }
      {!loading &&
        <>{icon}</>
      }
    </>
  )
}