import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, PaletteColorOptions } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useMatch } from 'react-router-dom';
import { Dashboard } from './components/views/Dashboard/Dashboard';
import { Login } from './components/views/Login/Login';
import { Navigation } from './components/views/Navigation/Navigation';
import { Rovers } from './components/views/Rovers/Rovers';
import { Schedules } from './components/views/Schedules/Schedules';
import { UserProvider, useUserContext } from './contexts/userContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PageLoadingIndicator } from './components/common/LoadingIndicator';
import { ScheduleDetails } from './components/views/Schedules/ScheduleDetails';
import { RoverDetails } from './components/views/Rovers/RoverDetails';
import { PATH_STRINGS } from './hooks/useGeneratedPaths';
import { AllMissions, MyMissions } from './components/views/Missions/Missions';
import { PermissionRoute } from './components/common/PermissionRoute';
import { NewDeviceProvider } from './contexts/newDeviceContext';
import { DeviceDetailsProvider } from './contexts/deviceDetailsContext';
import { Network } from './components/views/Network/Network';
import { NewDevice } from './components/views/Network/NewDevice/NewDevice';
import { DeviceDetails } from './components/views/Network/DeviceDetails/DeviceDetails';
import { CompletedMissionInformation } from './components/views/Missions/CompletedMissionInformation';
import { MissionControl } from './components/views/Missions/MissionControl';
import { TeleopProvider } from './contexts/teleopContext';
import { Projects } from './components/views/Projects/Projects';
import { ProjectDetails } from './components/views/Projects/ProjectDetails';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { LoginRedirect } from './components/views/Login/LoginRedirect';
import { SnackbarProvider } from "notistack";

export const violet = '#A78BFA';
export const lightBlue = '#67E8F9';
export const goalGreen = '#00d25b';
export const defaultPointColor = '#FB7185';

declare module '@mui/material/styles' {
  interface Palette {
    appBar: PaletteColorOptions;
    violet: PaletteColorOptions;
    offline: PaletteColorOptions;
    lightBlue: PaletteColorOptions;
  }
  interface PaletteOptions {
    appBar: PaletteColorOptions;
    violet: PaletteColorOptions;
    offline: PaletteColorOptions;
    lightBlue: PaletteColorOptions;
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    violet: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    violet: true;
    lightBlue: true;
    offline: true;
  }
}

declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides {
    violet: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    violet: true;
    offline: true;
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    appBar: true;
  }
}

const { palette } = createTheme();

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90CAF9',
    },
    error: {
      main: '#FF5252',
    },
    appBar: palette.augmentColor({
      color: {
        main: '#7588A3'
      }
    }),
    violet: palette.augmentColor({
      color: {
        main: violet,
      }
    }),
    lightBlue: palette.augmentColor({
      color: {
        main: lightBlue,
      }
    }),
    offline: palette.augmentColor({
      color: {
        main: '#CBD5E1'
      }
    }),
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 5000,
      refetchOnWindowFocus: false,
    },
  },
});

interface AppProps {
  msalInstance: PublicClientApplication;
}

function App({
  msalInstance,
}: AppProps) {
  useEffect(() => {
    const dbUsr = localStorage.getItem('db_usr');
    const parsedDbUsr = dbUsr ? JSON.parse(dbUsr) : null;
    const authToken = parsedDbUsr?.token;

    if (authToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    }
  }, []);

  return (
    <MsalProvider
      instance={msalInstance}
    >
      <BrowserRouter>
        <UserProvider>
        <ThemeProvider theme={darkTheme}>
        <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={Infinity}>
          <CssBaseline/>
          <Routes>
            <Route path={PATH_STRINGS.redirect} element={<LoginRedirect/>}/>
            <Route path={PATH_STRINGS.login} element={<Login/>}/>
            <Route element={<PrivateRoute/>}>
              <Route element={<Navigation/>}>
                <Route path='/' element={<Dashboard/>}/>
                <Route path={PATH_STRINGS.roverDetails} element={<WrappedRoverDetails/>}/>
                <Route path={PATH_STRINGS.rovers} element={<Rovers/>}/>
                <Route path={PATH_STRINGS.scheduleDetails} element={<ScheduleDetails/>}/>
                <Route path={PATH_STRINGS.schedules} element={
                    <PermissionRoute
                      permission='scheduler_tables.list_scheduler_schedule'
                    >
                      <Schedules/>
                    </PermissionRoute>
                  }
                />
                <Route path={PATH_STRINGS.manageDevice} element={
                  <PermissionRoute permission='device_tables.create_device'>
                    <WrappedNewDevice/>
                  </PermissionRoute>
                }/>
                <Route path={PATH_STRINGS.deviceDetails} element={<WrappedDeviceDetails/>}/>
                <Route path={PATH_STRINGS.network} element={<Network/>}/>
                <Route path={PATH_STRINGS.myMissions} element={<MyMissions/>}/>
                <Route path={PATH_STRINGS.allMissions} element={
                    <PermissionRoute
                      permission='mission_tables.list_missions'
                    >
                      <AllMissions/>
                    </PermissionRoute>
                  }
                />
                <Route path={PATH_STRINGS.missionControl} element={<MissionControl/>}/>
                <Route path={PATH_STRINGS.missionDetails} element={<CompletedMissionInformation/>}/>
                <Route path={PATH_STRINGS.projects} element={<Projects/>}/>
                <Route path={PATH_STRINGS.projectDetails} element={<ProjectDetails/>}></Route>
              </Route>
            </Route>
          </Routes>
        </SnackbarProvider>
        </QueryClientProvider>
        </ThemeProvider>
        </UserProvider>
      </BrowserRouter>
    </MsalProvider>
  );
}

const PrivateRoute = ({ hideSpinner = false, ...rest }: any) => {
  const { state: authState } = useUserContext();
  const location = useLocation();
  
  if (authState.loading) {
    return (
      <>
        { !hideSpinner &&
          <PageLoadingIndicator/>
        }
      </>
    )
  } else if (!authState.isAuthenticated) {
    return (
      <Navigate
        to={PATH_STRINGS.login}
        state={{
          isAuthenticated: false,
          from: location
        }}
      />
    );
  }
  
  return (
    <Outlet {...rest}/>
  );
}

const WrappedRoverDetails = () => {
  const roverMatch = useMatch(PATH_STRINGS.roverDetails);
  const deviceId: string = roverMatch?.params.deviceId || '';

  return (
    <TeleopProvider robotDeviceId={deviceId}>
      <RoverDetails/>
    </TeleopProvider>
  );
}

const WrappedNewDevice = () => {
  return (
    <NewDeviceProvider>
      <NewDevice/>
    </NewDeviceProvider>
  )
}

const WrappedDeviceDetails = () => {
  return (
    <DeviceDetailsProvider>
      <DeviceDetails/>
    </DeviceDetailsProvider>
  )
}

export default App;
