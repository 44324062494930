import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { isValidRobotCategory, Radio, RaspberryPi, Robot, RobotDetailsFields, RobotNetworkFields } from "../../../../api/device";
import { useNewDeviceContext } from "../../../../contexts/newDeviceContext"
import { HardwareVersionDropdown, RadiosDropdown, RaspberryPisDropdown, SoftwareVersionDropdown } from "../../../common/Dropdowns";
import { HardwareVersion, SoftwareVersion } from "../../../../api/versions";

export const RobotForm = () => {
  const {
    newDevice,
    updateRobotDevice,
    updateRobotDetails,
  } = useNewDeviceContext() as {
    newDevice: Robot;
    updateRobotDevice: (item: Partial<RobotNetworkFields>) => void;
    updateRobotDetails: (item: Partial<RobotDetailsFields>) => void;
  };

  return (
    <>
      <Typography sx={{textDecoration: 'underline'}}>Robot Fields</Typography>
      <FormControl sx={{ minWidth: 400 }}>
        <InputLabel id="category-label" required>Category</InputLabel>
        <Select
          required
          labelId="category-label"
          value={newDevice.robot_details.category}
          label="Category"
          onChange={(e: SelectChangeEvent<string>) => {
            if (isValidRobotCategory(e.target.value)) {
              updateRobotDetails({category: e.target.value})
            }
          }}
        >
          <MenuItem key="flipper" value="flipper">
            Flipper
          </MenuItem>
          <MenuItem key="wheeled" value="wheeled">
            Wheeled
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        label='VPN IP Address'
        value={newDevice.robot_details.vpn_ip_address}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateRobotDetails({vpn_ip_address: e.target.value})}
      />
      <TextField
        type="number"
        label='Video Port'
        value={newDevice.robot_details.video_port}
        inputProps={{
          min: 0
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateRobotDetails({video_port: parseInt(e.target.value)})}
      />
      <RadiosDropdown
        selectedRadio={newDevice.radio}
        setSelectedRadio={(newValue?: Radio) => updateRobotDevice({radio: newValue})}
      />
      <RaspberryPisDropdown
        selectedRaspberryPi={newDevice.monitor}
        setSelectedRaspberryPi={(newValue?: RaspberryPi) => updateRobotDevice({monitor: newValue})}
      />
      <SoftwareVersionDropdown
        selectedSoftwareVersion={newDevice.robot_details.software}
        onSelectSoftwareVersion={(newValue: SoftwareVersion | null) => updateRobotDetails({software: newValue})}
      />
      <HardwareVersionDropdown
        selectedHardwareVersion={newDevice.robot_details.hardware}
        onSelectHardwareVersion={(newValue: HardwareVersion | null) => updateRobotDetails({hardware: newValue})}
      />
    </>
  )
}