import { useState, useEffect } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths";
import { useProjectQuery } from "../../../hooks/queries";
import { NetworkHeatmapTab } from "./NetworkHeatmapTab";
import { Breadcrumb } from "../../common/Breadcrumb";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { DevicesTable } from "../Network/DevicesTable";
import { useNavigation } from "../../../hooks/useNavigation";

type TabType = 'devices' | 'network heatmap';
const tabs: TabType[] = ['devices', 'network heatmap'];

export const ProjectDetails = () => {
  const {
    navigateToHome,
    navigateToProjectDetails,
  } = useNavigation();

  const projectMatch = useMatch(PATH_STRINGS.projectDetails);
  const projectId: string = projectMatch?.params.projectId || '';

  const params = new URLSearchParams(useLocation().search);
  const selectedTabParam = params.get("selectedTab");

  const projectQueryOnError = (err: unknown) => {
    navigateToHome();
  }

  const {data: project, isLoading: projectLoading} = useProjectQuery(projectId, () => {}, projectQueryOnError);

  const [selectedTab, setSelectedTab] = useState<number>(selectedTabParam ? parseInt(selectedTabParam) : 0);

  useEffect(() => {
    if (!selectedTabParam || parseInt(selectedTabParam) !== selectedTab) {
      navigateToProjectDetails(projectId, {selectedTab: selectedTab.toString()});
    }
  }, [navigateToProjectDetails, projectId, selectedTab, selectedTabParam]);

  if (!project || projectLoading) {
    return <LoadingIndicator/>
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      height="100%"
    >
      <Box>
        <Typography variant='h5'>
          {project.name}
        </Typography>
        <Breadcrumb
          items={[{text: 'Projects', to: PATH_STRINGS.projects}, {text: 'Project Details'}]}
        />
      </Box>
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tabs
            value={selectedTab}
            onChange={(e: React.ChangeEvent<{}>, newValue: number) => setSelectedTab(newValue)}
            aria-label="basic tabs example"
          >
            {tabs.map(tab => {
              return (
                <Tab
                  key={tab}
                  label={tab}
                  style={{width: `${Math.round(1 / tabs.length * 100)}%`}}
                />
              )
            })}
          </Tabs>
        </Box>
        <Box flex={1}>
          {selectedTabParam === '0' &&
            <DevicesTable
              projectId={projectId}
              sessionStoragePrefix={`${projectId}-devices`}
            />
          }
          {selectedTabParam === '1' &&
            <NetworkHeatmapTab
              project={project}
            />
          }
        </Box>
      </Box>
    </Box>
  );
}