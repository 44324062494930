import axios from "axios";
import { API_BASE } from "./constants";
import { HardwareVersion, SoftwareVersion } from "./versions";

export type DeviceStatus = 'Online' | 'Offline' | 'Charging';
export type DeviceCategory = 'robot' | 'gateway' | 'raspberrypi' | 'radio' | 'modem' | 'sim-card' | '';
export type CellularProviderChoices = "t-mobile" | "verizon" | "att";
export type PiModelChoices = "4B" | "Zero" | "ZeroW" | "Zero2W";
export type ModemManufacturerChoices = "netgear" | "mofi";
export type RobotCategory = 'flipper' | 'wheeled';
export type RadioModel = 'XE' | 'XM' | 'XMC' | 'XEC' | 'PE' | 'PEC';

export const isValidDeviceType = (keyInput: string): keyInput is DeviceCategory => {
  return ['robot', 'gateway', 'raspberrypi', 'radio', 'modem', 'sim-card'].includes(keyInput);
}

export const isValidCellularProvider = (keyInput: string): keyInput is CellularProviderChoices => {
  return ['t-mobile', 'verizon', 'att'].includes(keyInput);
}

export const isValidPiModel = (keyInput: string): keyInput is PiModelChoices => {
  return ["4B", "Zero", "ZeroW", "Zero2W"].includes(keyInput);
}

export const isValidModemManufacturer = (keyInput: string): keyInput is ModemManufacturerChoices => {
  return ["netgear", "mofi"].includes(keyInput);
}

export const isValidRobotCategory = (keyInput: string): keyInput is RobotCategory => {
  return ["flipper", "wheeled"].includes(keyInput);
}

export const isValidRadioModel = (keyInput: string): keyInput is RadioModel => {
  return ['XE', 'XM', 'XMC', 'XEC', 'PE', 'PEC'].includes(keyInput);
}

export interface Device {
  DeviceID: string;
  name: string;
  type: DeviceCategory;
  ip: string;
  ssh_port: number | null;
  iperf_port: number | null;
  serial_number: string;
  status: DeviceStatus;
  location?: string;
  robot_public_id?: string;
  project_floor: number | null;
  project_public_id: string | null;
  auxiliary_location: number | null;
}

export const initialDevice: Device = {
  DeviceID: '',
  type: '',
  name: '',
  ip: '',
  serial_number: '',
  ssh_port: 22,
  iperf_port: null,
  status: 'Online',
  project_floor: null,
  project_public_id: null,
  auxiliary_location: null
}

export interface DeviceType {
  id: number;
  name: DeviceCategory;
}

export interface Archive {
  id: number;
  DeviceID: number;
  speed: number;
  registered_on: Date;
}

export interface DeviceFields {
  device: Device;
}

export interface RadioFields extends GatewayBackReference {
  mac_address: string;
  channel_width: number;
  channel_center: number;
  speed: number;
  connectedDevices: [number, number][];
  model: RadioModel;
  robot_device_id: number | null;
  robot_name: string | null;
}

export type Radio = DeviceFields & RadioFields;

export interface RaspberryPiFields extends GatewayBackReference{
  model: PiModelChoices;
  gateway_ip: string;
  username: string;
}

export type RaspberryPi = DeviceFields & RaspberryPiFields;

export interface SimCardFields {
  line: string;
  sim: string;
  phone_number: string;
  provider: CellularProviderChoices;
}

export type SimCard = DeviceFields & SimCardFields;

export interface ModemFields extends GatewayBackReference{
  model: string;
  mac_address: string;
  imei: string;
  public_ip: string;
  type: ModemManufacturerChoices;
  sim_card: SimCard;
}

export type Modem = DeviceFields & ModemFields;

export interface GatewayFields {
  modem?: Modem;
  radio?: Radio;
  raspberrypi?: RaspberryPi;
  speed: number;
}

export interface GatewayBackReference {
  gateway_device_id: number | null;
  gateway_name: string | null;
  reassign?: boolean;
  conflict?: boolean;
}

export type Gateway = DeviceFields & GatewayFields;

export interface RobotNetworkFields {
  radio: Radio;
  monitor: RaspberryPi;
  signal_speed: number;
  signal_strength: number;
  last_modified_on: string;
  hardware_version: string | null;
}

export interface RobotDetailsFields {
  public_id: string;
  category: RobotCategory;
  is_online: boolean;
  is_charging: boolean;
  vpn_ip_address: string;
  video_port: number;
  project_id: string | null;
  software: SoftwareVersion | null;
  hardware: HardwareVersion | null;
}

export type Robot = DeviceFields & RobotNetworkFields & {robot_details: RobotDetailsFields};

export type DeviceDetails = DeviceFields | Radio | RaspberryPi | SimCard | Modem | Gateway | Robot;

export const fetchDevices = async (projectId?: string, include_robots: number=0): Promise<Device[]> => {
  const res = await axios.get(
    `${API_BASE}/devices`,
    {
      params: {
        include_robots: include_robots,
        project: projectId,
      }
    }
  );
  const response = await res.data;
  return response.data;
}

export const fetchDevice = async (deviceId: string | number): Promise<DeviceDetails> => {
  const res = await axios.get(
    `${API_BASE}/device/${deviceId}`
  );
  const response = await res.data;
  return response.data;
}

export const fetchDeviceTypes = async (): Promise<DeviceType[]> => {
  const res = await axios.get(
    `${API_BASE}/device/types`
  );
  const response = await res.data;
  return response.data;
}

export const fetchGatewayArchive = async (deviceId: string): Promise<Archive[]> => {
  const res = await axios.get(
    `${API_BASE}/device/gateway/${deviceId}/archive`
  );
  const response = await res.data;
  return response.data;
}

export const fetchRobotArchive = async (deviceId: string): Promise<Archive[]> => {
  const res = await axios.get(
    `${API_BASE}/device/robot/${deviceId}/archive`
  );
  const response = await res.data;
  return response.data;
}

export const fetchSimCards = async (): Promise<SimCard[]> => {
  const res = await axios.get(
    `${API_BASE}/device/sims`
  );
  const response = await res.data;
  return response.data;
}

export const fetchModems = async (): Promise<Modem[]> => {
  const res = await axios.get(
    `${API_BASE}/device/modems`
  );
  const response = await res.data;
  return response.data;
}

export const fetchRadios = async (): Promise<Radio[]> => {
  const res = await axios.get(
    `${API_BASE}/device/radios`
  );
  const response = await res.data;
  return response.data;
}

export const fetchRaspberryPis = async (): Promise<RaspberryPi[]> => {
  const res = await axios.get(
    `${API_BASE}/device/raspberries`
  );
  const response = await res.data;
  return response.data;
}

export const createNewDevice = async (device: DeviceDetails): Promise<DeviceDetails> => {
  const res = await axios.post(
    `${API_BASE}/device`,
    device
  );
  const response = await res.data;
  return response.data;
}

export const editDevice = async (device: DeviceDetails): Promise<DeviceDetails> => {
  const res = await axios.put(
    `${API_BASE}/device/${device.device.DeviceID}`,
    device
  );
  const response = await res.data;
  return response.data;
}

export const fetchRobotsOnline = async (): Promise<number> => {
  const res = await axios.get(
    `${API_BASE}/robots/online`
  );
  const response = await res.data;
  return response.data;
}

export const fetchNodesOnline = async (): Promise<number> => {
  const res = await axios.get(
    `${API_BASE}/devices/online?include_robots=0`
  );
  const response = await res.data;
  return response.data;
}

export interface DeviceNote {
  id: number;
  device_id: number;
  user: {
    public_id: string;
    first_name: string;
    last_name: string;
  }
  note: string;
  registered_on: string;
}

export const fetchDeviceNotes = async (device_id: string | number): Promise<DeviceNote[]> => {
  const res = await axios.get(
    `${API_BASE}/device/${device_id}/notes`
  );
  const response = await res.data;
  return response.data;
}

interface NewDeviceNote {
  note: string;
  user_public_id: string;
}

export const createDeviceNote = async (deviceId: string, newDeviceNote: NewDeviceNote): Promise<DeviceNote> => {
  const res = await axios.post(
    `${API_BASE}/device/${deviceId}/note`,
    newDeviceNote
  );
  const response = await res.data;
  return response.data;
}

interface UpdateDeviceNoteFields {
  note?: string;
  deleted?: boolean;
}

export const updateDeviceNote = async (deviceId: string | number, noteId: number, updateFields: UpdateDeviceNoteFields): Promise<DeviceNote> => {
  const res = await axios.patch(
    `${API_BASE}/device/${deviceId}/note/${noteId}`,
    updateFields
  );
  const response = await res.data;
  return response.data;
}

interface DeviceAuxiliaryLocation {
  id: number;
  name: string;
  project_public_id: string | null;
}

export const fetchDeviceAuxiliaryLocations = async (projectPublicId?: string | null): Promise<DeviceAuxiliaryLocation[]> => {
  const res = await axios.get(
    `${API_BASE}/device/auxiliary_locations`,
    {
      params: {
        project_public_id: projectPublicId,
      }
    }
  );
  const response = await res.data;
  return response.data;
}
