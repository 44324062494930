import { useCallback, useEffect, useMemo } from 'react';
import { useMissionManagementContext } from "../../../../../contexts/missionManagementContext";
import styled from 'styled-components';
import { ProgressRegion } from '../../../../../api/schedules';
import { defaultPointColor, goalGreen, violet } from '../../../../../App';
import { useAlerts } from '../../../../../hooks/useAlerts';

interface ProgressRegionsProps {
  pointerEvents?: React.CSSProperties["pointerEvents"];
}

export const ProgressRegions = ({
  pointerEvents,
}: ProgressRegionsProps) => {
  const {
    activeTeleop,
    updateActiveMission,
  } = useMissionManagementContext();

  const {addInfoAlert} = useAlerts();

  useEffect(() => {
    if (activeTeleop?.goingToRegion) {
      addInfoAlert("Going to Region");
    }
  }, [activeTeleop?.goingToRegion, addInfoAlert]);

  const progressRegionOnClick = useCallback((region: ProgressRegion) => {
    if (activeTeleop?.selectedProgressRegion?.id !== region.id) {
      updateActiveMission({selectedProgressRegion: region});
    } else {
      updateActiveMission({selectedProgressRegion: null});
    }
  }, [activeTeleop?.selectedProgressRegion?.id, updateActiveMission]);

  const regionPolygons = useMemo(() => {
    if (!activeTeleop?.schedulePoints) {
      return <></>
    }

    return activeTeleop.schedulePoints.map(point => {
      return point.progress_regions.map(region => {
        const coordinateStrings = region.position.map(regionPoint => `${regionPoint.x},${regionPoint.y}`);
        const polygonPoints = coordinateStrings.join(' ');
        const regionIsSelected = region.id === activeTeleop?.selectedProgressRegion?.id;
        const regionIsGoalRegion = region.id === activeTeleop?.goalProgressRegionId;
        const capturedImage = activeTeleop?.capturedImages.get(point.point_id);
        const regionPictureTaken = !!capturedImage && capturedImage.region_id === region.id && capturedImage.point_type === "region";
        const fill = regionIsSelected ? defaultPointColor : regionIsGoalRegion ? goalGreen : regionPictureTaken ? violet : 'transparent';
        const stroke = regionIsSelected ? defaultPointColor : regionIsGoalRegion ? goalGreen : regionPictureTaken ? violet : defaultPointColor;

        return (
          <polygon
            key={region.id}
            onClick={() => progressRegionOnClick(region)}
            points={polygonPoints}
            fill={fill}
            fillOpacity="0.5"
            stroke={stroke}
            strokeWidth="10"
            cursor="pointer"
          />
        )
      });
    })
  }, [activeTeleop?.capturedImages, activeTeleop?.goalProgressRegionId, activeTeleop?.schedulePoints, activeTeleop?.selectedProgressRegion?.id, progressRegionOnClick]);

  if (!!activeTeleop?.regionBased) {
    return (
      <ProgressRegionsSVG
        width='100%'
        height='100%'
        pointerEvents={pointerEvents}
      >
        {regionPolygons}
      </ProgressRegionsSVG>
    );
  } else {
    return <></>;
  }
}

const ProgressRegionsSVG = styled.svg`
  position: absolute;
  left: 0;
  right: 0;
  background-color:transparent;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::selection 
  {
      background-color:transparent;
  } 
  
  &::-moz-selection
  {
      background-color:transparent;
  }
`;