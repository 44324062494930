import { Grid, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { Mission } from "../../../api/missions";
import { useAllMissionsQuery, useMyMissionsQuery } from "../../../hooks/queries";
import { Breadcrumb } from "../../common/Breadcrumb";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { MissionsTable } from "../Dashboard/MissionsTable";
import { Project } from "../../../api/projects";
import { projectDropdownAllKey, ProjectsAutocomplete } from "../../common/Dropdowns";

interface IMissionsProps {
  title: string;
  missions: Mission[] | undefined;
  missionsLoading: boolean;
}

const Missions = ({
  title,
  missions,
  missionsLoading,
}: IMissionsProps) => {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  const dataLoaded = !missionsLoading && !!missions;

  const filteredMissions = useMemo(() => {
    if (dataLoaded) {
      if (selectedProject && selectedProject.public_id !== projectDropdownAllKey) {
        return missions.filter(mission => mission.project_id === selectedProject.public_id);
      }

      return missions;
    }

    return [];
  }, [dataLoaded, missions, selectedProject]);

  return (
    <Grid container spacing={4} direction='column'>
      <Grid item>
        <Grid container alignItems='center'>
          <Grid item>
            <Typography variant='h5'>
              {title}
            </Typography>
            <Breadcrumb
              items={[{text: 'Missions'}]}
            />
          </Grid>
          <Grid item flexGrow={1}/>
          <Grid item>
            <ProjectsAutocomplete
              includeAllOption
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              width={400}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        { !dataLoaded &&
          <LoadingIndicator/>
        }
        { dataLoaded && 
          <MissionsTable
            rows={filteredMissions}
          />
        }
      </Grid>
    </Grid>
  )
}

export const MyMissions = () => {
  const { data: missions, isLoading: missionsLoading } = useMyMissionsQuery();

  return (
    <Missions
      title='My Missions'
      missions={missions}
      missionsLoading={missionsLoading}
    />
  )
}

export const AllMissions = () => {
  const { data: missions, isLoading: missionsLoading } = useAllMissionsQuery();

  return (
    <Missions
      title='All Missions'
      missions={missions ?? []}
      missionsLoading={missionsLoading}
    />
  )
}