import { useCallback, useState } from "react"
import { RoverSocketOnMessageCallback, useRoverSocketOnMessage } from "./useRoverSocketOnMessage";

export const useRoverGoalPoint = () => {
  const [goalPoint, setGoalPoint] = useState<number | null>(null);

  const onReceiveGoalPointMessage: RoverSocketOnMessageCallback = useCallback(({message_type, message_version, message}) => {
    if (message_type === 'goal') {
      if (message_version === 1) {
        setGoalPoint(message.current_point_id);
      }
    }
  }, []);

  const onResetGoalPoint = useCallback(() => {
    setGoalPoint(null);
  }, []);

  useRoverSocketOnMessage(onReceiveGoalPointMessage, onResetGoalPoint);

  return goalPoint;
}