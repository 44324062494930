import { useState } from 'react';
import { Box, Select, Slider, MenuItem, SelectChangeEvent, Switch, FormControlLabel, InputLabel, FormControl, Grid, Typography, SliderProps, Button, Drawer, Toolbar } from "@mui/material"
import { Counter } from "../../../../common/Counter";
import { adjustCameraBrightness } from "../../../../../api/robots";
import { CameraTopic, defaultCameraGain, defaultCameraGamma, isValidCameraSize, isValidCameraTransport, useVideoContext } from '../../../../../contexts/videoContext';
import { useTeleopContext } from '../../../../../contexts/teleopContext';
import { useAlerts } from '../../../../../hooks/useAlerts';

export const VideoDrawer = () => {
  const {
    state: videoState,
    dispatch: dispatchVideo,
  } = useVideoContext();

  const {
    robot,
  } = useTeleopContext();

  const onCloseVideoDrawer = () => {
    dispatchVideo({
      drawerOpen: false,
    })
  }

  return (
    <Drawer
      anchor='right'
      open={videoState.drawerOpen}
      onClose={onCloseVideoDrawer}
    >
      <Toolbar/>
      <Box
        padding='10px 20px'
        height="100%"
      >
        {!!robot &&
          <Typography variant='h4' textAlign="center">
            {robot.device.name}
          </Typography>
        }
        <VideoSettings/>
      </Box>
    </Drawer>
  );
}

export const VideoSettings = () => {
  const {
    state: videoState,
    dispatch: dispatchVideo,
  } = useVideoContext();

  const {
    robot,
  } = useTeleopContext();

  const {addErrorAlert} = useAlerts();

  const {
    invert,
    cameraGain,
    cameraGamma,
    cameraQuality,
    selectedCameraSize,
    selectedCameraTopic,
    selectedCameraTransport,
  } = videoState;

  const [cameraBrightnessAdjustmentInProgress, setCameraBrightnessAdjustmentInProgress] = useState<boolean>(false);

  const sshPort = robot?.device.ssh_port

  const showCameraBrightnessSliders = !!sshPort;

  const onCameraSliderChangeCommitted = (gamma: number, gain: number) => {
    if (sshPort) {
      setCameraBrightnessAdjustmentInProgress(true);

      adjustCameraBrightness(sshPort, selectedCameraTopic === CameraTopic.ONE, gamma, gain)
        .catch(() => {
          addErrorAlert("Error adjusting camera brightness");
        })
        .finally(() => {
          setCameraBrightnessAdjustmentInProgress(false);
        });
    }
  }

  const onClickResetCameraBrightnessValues = () => {
    dispatchVideo({
      cameraGain: defaultCameraGain,
      cameraGamma: defaultCameraGamma,
    });

    onCameraSliderChangeCommitted(defaultCameraGamma, defaultCameraGain);
  }

  return (
    <Grid container direction="column" spacing={2} marginTop={2}>
      <Grid item>
        <FormControl sx={{ minWidth: 400 }}>
          <InputLabel id="project-label">Camera Size</InputLabel>
          <Select
            value={selectedCameraSize}
            onChange={(e: SelectChangeEvent<string>) => {
              if (isValidCameraSize(e.target.value)) {
                dispatchVideo({ selectedCameraSize: e.target.value })}
              }
            }
          >
            <MenuItem value='640x480'>640x480</MenuItem>
            <MenuItem value='480x360'>480x360</MenuItem>
            <MenuItem value='360x270'>360x270</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl sx={{ minWidth: 400 }}>
          <InputLabel id="project-label">Camera Transport</InputLabel>
          <Select
            value={selectedCameraTransport}
            onChange={(e: SelectChangeEvent<string>) => {
              if (isValidCameraTransport(e.target.value)) {
                dispatchVideo({ selectedCameraTransport: e.target.value })}
              }
            }
          >
            <MenuItem value='compressed'>compressed</MenuItem>
            <MenuItem value='raw'>raw</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <Counter
          label='Camera Quality'
          value={cameraQuality}
          setValue={(newValue) => dispatchVideo({cameraQuality: newValue})}
          step={5}
          min={0}
          max={95}
        />
      </Grid>
      {showCameraBrightnessSliders &&
        <>
          <Grid item>
            <CameraSlider
              disabled={cameraBrightnessAdjustmentInProgress}
              label="Gain"
              value={cameraGain}
              onChange={value => dispatchVideo({cameraGain: value})}
              onChangeCommitted={value => onCameraSliderChangeCommitted(cameraGamma, value)}
              step={1}
              min={0}
              max={100}
              marks={[
                {value: defaultCameraGain, label: defaultCameraGain}
              ]}
            />
          </Grid>
          <Grid item>
            <CameraSlider
              disabled={cameraBrightnessAdjustmentInProgress}
              label="Gamma"
              value={cameraGamma}
              onChange={value => dispatchVideo({cameraGamma: value})}
              onChangeCommitted={value => onCameraSliderChangeCommitted(value, cameraGain)}
              step={1}
              min={0}
              max={500}
              marks={[
                {value: defaultCameraGamma, label: defaultCameraGamma}
              ]}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={cameraBrightnessAdjustmentInProgress}
              variant="contained"
              onClick={onClickResetCameraBrightnessValues}
            >
              Reset Camera Brightness Values
            </Button>
          </Grid>
        </>
      }
      <Grid item>
        <FormControlLabel
          control={(
            <Switch
              checked={invert}
              onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => dispatchVideo({ invert: checked })}
            />
          )}
          label='Invert'
        />
      </Grid>
    </Grid>
  );
}

interface ICameraSliderProps {
  disabled?: boolean;
  label: string;
  value: number;
  onChange: (newValue: number) => void;
  onChangeCommitted: (newValue: number) => void;
  step: number;
  min: number;
  max: number;
  marks?: SliderProps["marks"];
}

const CameraSlider = ({
  disabled,
  label,
  value,
  onChange,
  onChangeCommitted,
  step,
  min,
  max,
  marks,
}: ICameraSliderProps) => {
  return (    
    <Box
      sx={{
        width: 380
      }}
    >
      <Typography>{label}</Typography>
      <Slider
        disabled={disabled}
        color='violet'
        valueLabelDisplay="auto"
        value={value}
        onChange={(e, value) => onChange(value as number)}
        onChangeCommitted={(e, value) => onChangeCommitted(value as number)}
        step={step}
        min={min}
        max={max}
        marks={marks}
        sx={{
          marginLeft: '10px'
        }}
      />
    </Box>
  )
}