import { Typography } from "@mui/material";
import { SimCard } from "../../../../api/device";
import { useDeviceDetailsContext } from "../../../../contexts/deviceDetailsContext"

export const SimCardDetails = () => {
  const {device, deviceLoaded} = useDeviceDetailsContext() as {device: SimCard, deviceLoaded: boolean}
  const dataLoaded = !!device && deviceLoaded;

  if (!dataLoaded) {
    return <></>
  }

  return (
    <>
      <Typography variant='body1' color='text.secondary'>
        Line: {device.line}
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        SIM: {device.sim}
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        Phone Number: {device.phone_number}
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        Provider: {device.provider}
      </Typography>
    </>
  )
}