import { createContext, useContext, useEffect, useMemo, useState, useCallback } from "react";
import { IMissionManagementState } from "./missionManagementContext";
import { RoverTeleopAction } from "../types/teleopContextTypes";
import { useDeviceQuery, useProjectFloorsQuery, useProjectQuery, useSchedulePointsQuery, useScheduleQuery } from "../hooks/queries";
import { DeviceDetails, Robot } from "../api/device";
import { Project } from "../api/projects";
import { ProjectFloor } from "../api/projectFloors";
import { ProjectSchedulePoint, Schedule } from "../api/schedules";
import { useLocation, useNavigate } from "react-router-dom";
import { useRobotSocketWorker } from "../hooks/useRobotSocketWorker";
import { useMatch } from "react-router-dom";
import { PATH_STRINGS } from "../hooks/useGeneratedPaths";
import { useAlerts } from "../hooks/useAlerts";

interface ITeleopContext {
  robot: Robot | undefined;
  robotLoading: boolean;
  socket: Worker | undefined;
  socketOpen: boolean;
  messages: string[];
}

const initialTeleopContext: ITeleopContext = {
  robot: undefined,
  robotLoading: true,
  socket: undefined,
  socketOpen: false,
  messages: [],
};

const TeleopContext = createContext<ITeleopContext>({...initialTeleopContext});

export const pauseMissionMessage = {
  'message_type': 'mission_action',
  'message_version': 1,
  'message': {
    'action': 'pause_mission',
    'args': {}
  }
};

export const resumeMissionMessage = {
  'message_type': 'mission_action',
  'message_version': 1,
  'message': {
    'action': 'resume_mission',
    'args': {}
  }
}

export const skipCheckpointMessage = {
  'message_type': 'mission_action',
  'message_version': 1,
  'message': {
    'action': 'skip_checkpoint',
    'args': {}
  }
}

export const taskPausedEvent = 'TASK_PAUSED';
export const taskResumedEvent = 'TASK_RESUMED';
export const taskStoppedEvent = 'TASK_STOPPED';
export const requestingSkipPointEvent = 'REQUESTING_SKIP_POINT';
export const nodeStoppedEvent = 'NODE_STOPPED';
export const goingToViewpointEvent = "GOING_TO_VIEWPOINT";
export const goingToRegionEvent = "GOING_TO_REGION";
export const goalAlreadyVisitedEvent = "GOAL_ALREADY_VISITED";
export const defaultFipperAngleSuccessfulEvent = 'DEFAULT_FLIPPER_ANGLE_SUCCESSFUL';
export const defaultFipperAngleUnSuccessfulEvent = 'DEFAULT_FLIPPER_ANGLE_UNSUCCESSFUL';
export const cameraOperation = ['camera_360', 'image_record'];

interface ITeleopProviderProps {
  robotDeviceId: string | number;
  parentState?: IMissionManagementState;
  updateMission?: (identifier: string, stateUpdate: Partial<IMissionManagementState>, activate?: boolean) => void;
  identifier?: string;
}

export const TeleopProvider = ({
  children,
  robotDeviceId,
  parentState,
  updateMission,
  identifier,
}: React.PropsWithChildren<ITeleopProviderProps>) => {
  const locationState = useLocation().state as { schedule_id: string | number } | undefined;
  const inMissionControl = !!useMatch(PATH_STRINGS.missionControl);

  const [messages, setMessages] = useState<string[]>([]);
  const navigate = useNavigate();

  const {addSuccessAlert, addErrorAlert} = useAlerts();

  const {
    socket: performActionSocket,
    socketOpen,
    connectToSocket,
    disconnectFromSocket,
  } = useRobotSocketWorker();

  const updateParentState = useCallback((stateUpdate: Partial<IMissionManagementState>, activate?: boolean) => {
    if (updateMission && identifier) {
      updateMission(identifier, stateUpdate, activate);
    }
  }, [updateMission, identifier]);

  const robotOnSuccess = (data: DeviceDetails) => {
    if (updateParentState) {
      updateParentState({
        robot: (data as Robot)
      });
    }
  }

  const robotOnError = (err: any) => {
    navigate('/');
  }

  const {data: robot, isLoading: robotLoading} = useDeviceQuery(robotDeviceId, robotOnSuccess, robotOnError) as {data: Robot | undefined, isLoading: boolean};

  const projectOnSuccess = (data: Project) => {
    if (updateParentState) {
      updateParentState({
        project: data,
      });
    }
  }

  const {data: project} = useProjectQuery(robot?.device.project_public_id ?? robot?.robot_details.project_id ?? '', projectOnSuccess);

  const projectFloorsOnSuccess = (data: ProjectFloor[]) => {
    if (updateParentState) {
      updateParentState({
        floors: data,
        selectedFloor: data[0]
      });
    }
  }

  const {data: floors} = useProjectFloorsQuery(project?.public_id ?? '', projectFloorsOnSuccess);

  const scheduleOnSuccess = (data: Schedule) => {
    if (updateParentState) {
      updateParentState({
        schedule: data,
      });
    }
  }

  const scheduleId = parentState?.schedule?.id ?? locationState?.schedule_id ?? '';
  const { data: schedule } = useScheduleQuery(scheduleId, scheduleOnSuccess);

  const schedulePointsOnSuccess = (data: ProjectSchedulePoint[]) => {
    if (updateParentState) {
      updateParentState({
        schedulePoints: data,
      });
    }
  }

  useSchedulePointsQuery(project?.public_id ?? '', schedule?.sub_id, schedulePointsOnSuccess);

  useEffect(() => {
    if (schedule && floors && updateParentState) {
      const firstScheduleFloorCode = schedule.schedule_floors[0];

      floors.forEach(floor => {
        if (floor.floor_code === firstScheduleFloorCode) {
          updateParentState({
            selectedFloor: floor,
          });
        }
      })
    }
  }, [floors, schedule, updateParentState]);

  //on change schedule, reset selected point values
  useEffect(() => {
    updateParentState({
      schedulePoints: [],
      finalGoal: null,
      startPoint: null,
      selectedPoint: null,
    });
  }, [updateParentState, schedule]);

  useEffect(() => {
    if (robot) {
      const robotPublicId = robot.robot_details.public_id;
      const action_url = `wss://rww.nexnet.global/ws/robot/${robotPublicId}/monitor`;
  
      connectToSocket(action_url);
    }
  }, [connectToSocket, robot]);

  useEffect(() => {
    if (updateParentState) {
      updateParentState({
        socket: performActionSocket,
        socketOpen,
      });
    }
  }, [performActionSocket, socketOpen, updateParentState]);

  useEffect(() => {
    return () => {
      if (socketOpen) {
        disconnectFromSocket();
      }
    }
  }, [disconnectFromSocket, socketOpen]);

  const handleEventStateChange = useCallback((event: string, updatingEvents: string[], positiveEvents: string[]) => {
    return {
      updated: updatingEvents.includes(event),
      newValue: positiveEvents.includes(event),
    }
  }, []);

  const handleStateUpdateOnMessage = useCallback((event: any, data: any, details: any, message: string) => {
    if (parentState && updateParentState) {
      const stateToUpdate: Partial<IMissionManagementState> = {};

      const pauseStateUpdate = handleEventStateChange(event, [taskPausedEvent, taskResumedEvent, taskStoppedEvent], [taskPausedEvent]);
      const requestSkipPointUpdate = handleEventStateChange(event, [taskResumedEvent, taskStoppedEvent, requestingSkipPointEvent], [requestingSkipPointEvent]);
      const goingToRegionUpdate = handleEventStateChange(event, [goingToViewpointEvent, goingToRegionEvent, requestingSkipPointEvent, goalAlreadyVisitedEvent], [goingToRegionEvent]);

      if (pauseStateUpdate.updated) {
        stateToUpdate.isPaused = pauseStateUpdate.newValue;
      }

      if (requestSkipPointUpdate.updated) {
        stateToUpdate.requestSkipPoint = requestSkipPointUpdate.newValue;

        if (requestSkipPointUpdate.newValue) {
          performActionSocket.postMessage(pauseMissionMessage);
        }
      }

      if (goingToRegionUpdate.updated) {
        stateToUpdate.goingToRegion = goingToRegionUpdate.newValue;
        stateToUpdate.regionBased = goingToRegionUpdate.newValue;

        if (goingToRegionUpdate.newValue) {
          stateToUpdate.goalProgressRegionId = details ? details.region_id : null;
        } else {
          stateToUpdate.goalProgressRegionId = null;
        }
      }

      if (cameraOperation.includes(data.operation)) {
        const updatedCapturedImages = new Map(parentState.capturedImages);
        updatedCapturedImages.set(parseInt(details.point_id), details);

        stateToUpdate.capturedImages = updatedCapturedImages;
      }

      stateToUpdate.messages = [message, ...parentState.messages];

      updateParentState(stateToUpdate);
    }
  }, [handleEventStateChange, parentState, performActionSocket, updateParentState]);

  const handleNotificationOnMessage = useCallback((event: string) => {
    if (inMissionControl) {
      if (event === defaultFipperAngleSuccessfulEvent) {
        addSuccessAlert('Default flipper angle set successfully');
      } else if (event === defaultFipperAngleUnSuccessfulEvent) {
        addErrorAlert('Error setting default flipper angle');
      }
    }
  }, [addErrorAlert, addSuccessAlert, inMissionControl]);

  const renderMessage = useCallback((data: any) => {
    const message = JSON.stringify(data);
    const event = data.event;
    const details = data.details;
    console.log("MESSAGE: ", data);

    setMessages(prevMessages => [message, ...prevMessages]);
    handleNotificationOnMessage(event);
    handleStateUpdateOnMessage(event, data, details, message);
  }, [handleNotificationOnMessage, handleStateUpdateOnMessage]);

  const actionResponse = useMemo(() => ({
    manager_event: {
      1: (data: any) => {
        renderMessage(data);
      }
    },
    task_event: {
      1: (data: any) => {
        renderMessage(data);
      }
    },
    operation: {
      1: (data: any) => {
        renderMessage(data);
      }
    },
    execute: (action: RoverTeleopAction) => {
      if (action) {
        var func = null;
        try {
          func = (actionResponse as any)[action['message_type']][action['message_version']];
        }
        catch (error) {
          return null;
        }
        if (func) {
          func(action['message']);
        }
      }
    }
  }), [renderMessage]);

  useEffect(() => {
    const socket = performActionSocket;

    const executeActionOnMessage = (event: MessageEvent) => {
      actionResponse.execute(event.data);
    }

    socket.addEventListener('message', executeActionOnMessage);

    return () => {
      return socket.removeEventListener('message', executeActionOnMessage);
    }
  }, [actionResponse, performActionSocket]);

  const value = useMemo(() => {
    return {
      socket: performActionSocket,
      socketOpen,
      robot: robot,
      robotLoading,
      messages,
    }
  }, [performActionSocket, socketOpen, robot, robotLoading, messages]);

  return (
    <TeleopContext.Provider value={value}>
      {children}
    </TeleopContext.Provider>
  );
}

export const useTeleopContext = () => {
  return useContext(TeleopContext);
};