import { useState, useMemo } from "react";
import { Robot } from "../../../../api/robots";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { RoversTable } from "./RoversTable";
import { useRobotsQuery } from "../../../../hooks/queries";
import { useNavigation } from "../../../../hooks/useNavigation";
import { useActiveRobotMissions } from "../../../../hooks/useActiveRobotMissions";
import { SpeedTestModal } from "../SpeedTestModal";
import { Project } from "../../../../api/projects";

interface RoversTabProps {
  selectedProject: Project | null;
  showUnassigned: boolean;
}

export const RoversTab = ({
  selectedProject,
  showUnassigned,
}: RoversTabProps) => {
  const {
    navigateToMissionControl,
  } = useNavigation();

  const { data: robots, isLoading: robotsLoading } = useRobotsQuery();
  const {roverPublicIdMissionMap, isLoading: activeMissionsLoading} = useActiveRobotMissions();

  const [speedTestRobot, setSpeedTestRobot] = useState<Robot>();

  const dataLoaded = !robotsLoading && !!robots && !activeMissionsLoading && !!roverPublicIdMissionMap;

  const filteredRobots = useMemo(() => {
    if (dataLoaded) {
      let robotsToDisplay = [...robots];

      if (selectedProject && selectedProject.name !== 'All') {
        robotsToDisplay = robotsToDisplay.filter(robot => robot.current_project === selectedProject.name);
      }

      robotsToDisplay = robotsToDisplay.filter(robot => showUnassigned ? true : robot.current_project !== 'Unassigned');

      return robotsToDisplay;
    }

    return [];
  }, [dataLoaded, robots, selectedProject, showUnassigned]);

  const onClickMissionButton = (e: React.MouseEvent<any>, row: Robot) => {
    e.preventDefault();
    e.stopPropagation();

    const activeRobotMission = roverPublicIdMissionMap?.get(row.public_id);

    if (activeRobotMission) {
      navigateToMissionControl([activeRobotMission.id], []);
    } else {
      navigateToMissionControl([], [row.device_id]);
    }
  }

  const onClickSpeedTestButton = (e: React.MouseEvent<any>, robot: Robot) => {
    e.preventDefault();
    e.stopPropagation();

    setSpeedTestRobot(robot);
  }

  return (
    <>
      { !dataLoaded &&
        <LoadingIndicator/>
      }
      
      { dataLoaded &&
        <RoversTable
          robots={filteredRobots}
          onClickSpeedTestButton={onClickSpeedTestButton}
          onClickMissionButton={onClickMissionButton}
        />
      }
      <SpeedTestModal
        robotName={speedTestRobot?.name}
        robotSSHPort={speedTestRobot?.ssh_port}
        open={!!speedTestRobot}
        onClose={() => setSpeedTestRobot(undefined)}
      />
    </>
  );
}