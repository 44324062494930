import { Box } from "@mui/material";
import { useMemo } from "react";
import { useMissionManagementContext } from "../../../../contexts/missionManagementContext";
import { TeleopProvider } from "../../../../contexts/teleopContext";
import { VideoProvider } from "../../../../contexts/videoContext";
import { RoverVideoCard } from "./Video/RoverVideoCard";
import { RoverInformationDisplayCard } from "./RoverInformation/RoverInformationDisplayCard";
import { RoverDrivingControlsCard } from "./RoverDrivingControls.tsx/RoverDrivingControlsCard";
import { TeleopDrawer } from "./TeleopDrawer/TeleopDrawer";
import { AddNewRobotCard } from "./AddNewRobotCard";
import { CopilotProvider } from "../../../../contexts/copilotContext";
import { SafetyTabProvider } from "../../../../contexts/safetyTabContext";

export const MissionTeleop = () => {
  const {
    missions,
    updateMission,
  } = useMissionManagementContext();

  const activeMissions = useMemo(() => {
    return Object.getOwnPropertyNames(missions);
  }, [missions]);

  return (
    <Box
      display="flex"
      height="100%"
      gap={2}
    >
      <Box
        flex={3}
        display="flex"
        flexWrap="wrap"
        gap="5px"
      >
        {activeMissions.length === 0 &&
          <Box
            height="100%"
            width="100%"
          >
            <AddNewRobotCard/>
          </Box>
        }
        {activeMissions.map(identifier => {
          const teleop = missions[identifier];
          const robotDeviceId = teleop.robotDeviceId;

          return (
            <Box
              key={identifier}
              height={activeMissions.length > 1 ? "50%" : '100%'}
              width={activeMissions.length > 2 ? "calc(50% - 2.5px)" : "100%"}
            >
              {!robotDeviceId &&
                <AddNewRobotCard
                  identifier={identifier}
                />
              }
              {!!robotDeviceId &&
                <TeleopProvider
                  parentState={teleop}
                  updateMission={updateMission}
                  identifier={identifier}
                  robotDeviceId={robotDeviceId}
                >
                  <CopilotProvider
                    parentState={teleop}
                    updateMission={updateMission}
                    identifier={identifier}
                  >
                    <VideoProvider>
                      <RoverVideoCard
                        identifier={identifier}
                      />
                    </VideoProvider>
                  </CopilotProvider>
                </TeleopProvider>
              }
            </Box>
          )
        })}
      </Box>
      <Box
        flex={2}
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Box
          flex={1}
        >
          <SafetyTabProvider>
            <RoverInformationDisplayCard/>
          </SafetyTabProvider>
        </Box>
        <Box>
          <RoverDrivingControlsCard/>
        </Box>
      </Box>
      <TeleopDrawer/>
    </Box>
  )
}