import { useCallback, useEffect, useMemo, useState } from "react";

export const useRobotSocketWorker = () => {
  const [socketOpen, setSocketOpen] = useState<boolean>(false);

  const socket = useMemo(() => {
    return new Worker('/js/robot_socket_worker.js');
  }, []);

  const connectToSocket = useCallback((url: string) => {
    const connectMessage = getConnectMessage(url);

    socket.postMessage(connectMessage);
  }, [socket]);

  const disconnectFromSocket = useCallback(() => {
    socket.postMessage(closeSocketMessage);
  }, [socket]);

  const executeActionOnMessage = useCallback((event: MessageEvent) => {
    const eventData = event.data;

    if (eventData.message_type === 'opened') {
      setSocketOpen(true);
    } else if (eventData.message_type === 'closed') {
      setSocketOpen(false);
    }
  }, []);

  useEffect(() => {
    socket.addEventListener('message', executeActionOnMessage);

    return () => {
      return socket.removeEventListener('message', executeActionOnMessage);
    }
  }, [executeActionOnMessage, socket]);

  return {
    socket,
    socketOpen,
    connectToSocket,
    disconnectFromSocket,
  }
}

const getConnectMessage = (action_url: string) => {
  return {
    action: 'connect',
    data: {url: action_url}
  }
}

const closeSocketMessage = {
  action: 'close',
  data: {}
}