import { useEffect } from "react";

export const useDocumentTitle = (documentTitle: string | null | undefined) => {
  useEffect(() => {
    if (documentTitle) {
      document.title = documentTitle;
    }

    return () => {
      document.title = "Fleet Management"
    }
  }, [documentTitle]);
}