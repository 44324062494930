import { useCallback, useMemo, useState } from "react";
import { MissionSpeedTest } from "../../../../api/missions";
import { ViewpointsPoint } from "../../../../api/projectFloors";
import { MapPoint } from "../MissionTeleop/Map/MapPoint";
import { SpeedMetric } from "./SpeedMetricRadioButtons";
import { Box } from "@mui/material";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";

interface SpeedTestMapPointProps {
  point: ViewpointsPoint;
  associatedSpeedTests: MissionSpeedTest[];
  selectedSpeedMetric: SpeedMetric;
}

export const SpeedTestMapPoint = ({
  point,
  associatedSpeedTests,
  selectedSpeedMetric,
}: SpeedTestMapPointProps) => {
  const [selectedSpeedTestIndex, setSelectedSpeedTestIndex] = useState<number>(0);

  const selectedSpeedTest = useMemo(() => {
    return associatedSpeedTests[selectedSpeedTestIndex];
  }, [selectedSpeedTestIndex, associatedSpeedTests])

  const generatePointColor = useCallback((speedTest: MissionSpeedTest) => {
    const {
      upload_speed,
      download_speed,
      ping,
    } = speedTest;

    if (selectedSpeedMetric === 'upload' && upload_speed !== null) {
      return upload_speed < 1.5 ? "red" : upload_speed < 5 ? "orange" : "green"; 
    } else if (selectedSpeedMetric === 'download' && download_speed !== null) {
      return download_speed < 1.5 ? "red" : download_speed < 5 ? "orange" : "green"; 
    } else if (selectedSpeedMetric === 'ping' && ping !== null) {
      return ping < 80 ? "green" : ping < 250 ? "orange" : "red";
    }

    return undefined;
  }, [selectedSpeedMetric]);

  return (
    <MapPoint
      key={point.point_id}
      id={point.point_id}
      x={point.x}
      y={point.y}
      empty={!selectedSpeedTest}
      color={selectedSpeedTest ? generatePointColor(selectedSpeedTest) : undefined}
      tooltipTitle={
        <SpeedTestMapPointTooltipTitle
          point={point}
          selectedSpeedMetric={selectedSpeedMetric}
          speedTests={associatedSpeedTests}
          selectedSpeedTestIndex={selectedSpeedTestIndex}
          setSelectedSpeedTestIndex={setSelectedSpeedTestIndex}
        />
      }
    />
  )
}

interface SpeedTestMapPointTooltipTitleProps {
  point: ViewpointsPoint;
  selectedSpeedMetric: SpeedMetric;
  speedTests: MissionSpeedTest[];
  selectedSpeedTestIndex: number;
  setSelectedSpeedTestIndex: (i: number) => void;
}

const SpeedTestMapPointTooltipTitle = ({
  point,
  speedTests,
  selectedSpeedTestIndex,
  setSelectedSpeedTestIndex,
  selectedSpeedMetric,
}: SpeedTestMapPointTooltipTitleProps) => {
  const selectedSpeedTest = useMemo(() => {
    return speedTests[selectedSpeedTestIndex];
  }, [selectedSpeedTestIndex, speedTests]);

  const formatTakenOn = (takenOn: string) => {
    const takenOnDate = new Date(takenOn);

    return takenOnDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  }

  const formatSpeedTestValue = (value: number | null, units: string) => {
    if (value === null) {
      return 'N/A';
    }

    return `${value} ${units}`;
  }

  const onChangeSelectedSpeedTestIndex = (delta: number) => {
    const newIndex = selectedSpeedTestIndex + delta;

    if (newIndex >= 0 && newIndex < speedTests.length) {
      setSelectedSpeedTestIndex(newIndex);
    }
  }

  return (
    <Box
      fontSize="14px"
      display="flex"
      flexDirection="column"
      gap="10px"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="20px"
      >
        <Box>
          <Box>Point ID: {point.point_id}</Box>
          {!!selectedSpeedTest &&
            <Box>
              Taken On: {formatTakenOn(selectedSpeedTest.taken_on)}
            </Box>
          }
        </Box>
        {speedTests.length > 1 &&
          <Box>
            <ArrowCircleLeft
              style={{
                cursor: 'pointer',
                visibility: selectedSpeedTestIndex > 0 ? 'visible' : 'hidden'
              }}
              onClick={() => onChangeSelectedSpeedTestIndex(-1)}
            />
            <ArrowCircleRight
              style={{
                cursor: 'pointer',
                visibility: selectedSpeedTestIndex < speedTests.length - 1 ? 'visible' : 'hidden',
              }}
              onClick={() => onChangeSelectedSpeedTestIndex(1)}
            />
          </Box>
        }
      </Box>
      {selectedSpeedTest && 
        <Box>
          <Box
            fontWeight={selectedSpeedMetric === 'upload' ? 'bold' : 'normal'}
          >
            Upload: {formatSpeedTestValue(selectedSpeedTest.upload_speed, "mbps")}
          </Box>
          <Box
            fontWeight={selectedSpeedMetric === 'download' ? 'bold' : 'normal'}
          >
            Download: {formatSpeedTestValue(selectedSpeedTest.download_speed, "mbps")}
          </Box>
          <Box
            fontWeight={selectedSpeedMetric === 'ping' ? 'bold' : 'normal'}
          >
            Ping: {formatSpeedTestValue(selectedSpeedTest.ping, "ms")}
          </Box>
        </Box>
      }
    </Box>
  )
}