import { useQuery } from "react-query";
import { fetchAllMissions, FetchAllMissionsParams, fetchAllSpeedTests, fetchMission, fetchMyMissions, fetchNumCompletedMissions, fetchNumScheduledMissions, fetchRecentMissions, fetchScheduleMissions } from "../api/missions";
import { fetchProjectFloors, fetchProjectFloorViewpoints, ProjectFloor, ViewpointsPoint } from "../api/projectFloors";
import { fetchFloorTransitionUsers, fetchProject, fetchProjects, Project } from "../api/projects";
import { fetchRobot, fetchRobots } from "../api/robots";
import { fetchSchedule, fetchSchedulePoints, fetchSchedules, fetchSchedulesWithScansToday, ProjectSchedulePoint, Schedule } from "../api/schedules";
import {Archive, DeviceDetails, fetchDevice, fetchDevices, fetchDeviceAuxiliaryLocations, fetchDeviceTypes, fetchGatewayArchive, fetchModems, fetchNodesOnline, fetchRadios, fetchRaspberryPis, fetchRobotArchive, fetchRobotsOnline, fetchSimCards, Modem, Radio, RaspberryPi, SimCard} from "../api/device";
import { fetchStairClimberScripts } from "../api/stairs";
import { fetchHardwareVersions, fetchSoftwareVersions, SoftwareVersion } from "../api/versions";

export const useSchedulesQuery = () => {
  return useQuery (
    ['schedules'],
    async () => {
      return await fetchSchedules();
    }
  );
}

export const useScheduleQuery = (scheduleId: string | number, onSuccess?: (data: Schedule) => void) => {
  return useQuery(
    ['schedule', scheduleId.toString()],
    async () => {
      return await fetchSchedule(scheduleId);
    },
    { enabled: !!scheduleId, onSuccess: data => {
      if (onSuccess) {
        onSuccess(data);
      }
    }}
  );
}

export const useSchedulePointsQuery = (projectId: string | undefined, subScheduleId: number | undefined, onSuccess: (data: ProjectSchedulePoint[]) => void) => {
  return useQuery(
    ['schedule-points', projectId, subScheduleId],
    async () => {
      if (projectId && subScheduleId) {
        return fetchSchedulePoints(projectId, subScheduleId);
      }

      return [];
    },
    { onSuccess: data => onSuccess(data) }
  );
}

export const useSchedulesWithScansTodayQuery = () => {
  return useQuery (
    ['todays-schedules'],
    async () => {
      return await fetchSchedulesWithScansToday();
    }
  );
}

export const useProjectsQuery = (status?: string) => {
  return useQuery (
    ['projects'],
    async () => {
      return await fetchProjects(status);
    }
  );
}

export const useProjectQuery = (projectId: string, onSuccess?: (data: Project) => void, onError?: (err: unknown) => void) => {
  return useQuery(
    ['project', projectId],
    async () => {
      return await fetchProject(projectId);
    },
    { enabled: !!projectId,
      onSuccess: onSuccess,
      onError: onError
    },
  );
}

export const useProjectFloorsQuery = (projectId: string, onSuccess?: (data: ProjectFloor[]) => void) => {
  return useQuery(
    ['project', 'floors', projectId],
    async () => {
      return await fetchProjectFloors(projectId);
    },
    { enabled: !!projectId, onSuccess: data => {
      if (onSuccess) {
        onSuccess(data);
      }
    }}
  );
}

export const useProjectFloorViewpointsQuery = (projectId: string | null, floorCode: string | null, onSuccess?: (data: ViewpointsPoint[] | undefined) => void) => {
  const enabled = !!projectId && !!floorCode;

  return useQuery(
    ['project', 'floor', 'viewpoints', projectId, floorCode],
    async () => {
      if (enabled) {
        return await fetchProjectFloorViewpoints(projectId, floorCode);
      }
    },
    { enabled: enabled,
      onSuccess: data => {
        if (onSuccess) {
          onSuccess(data);
        }
      }
    }
  );
}

export const useRobotsQuery = () => {
  return useQuery (
    ['robots'],
    async () => {
      return await fetchRobots(true);
    }
  );
}

export const useRobotQuery = (robotPublicId: string) => {
  return useQuery(
    ['robot', robotPublicId],
    async () => {
      return await fetchRobot(robotPublicId);
    }
  );
}

export const useGetFleetStatusQuery = () => {
  return useQuery(
    ['fleet-status'],
    async () => {
      const roversOnline = await fetchRobotsOnline();
      const totalRovers = (await fetchRobots(true)).length;
      const nodesOnline = await fetchNodesOnline();
      const totalNodes = (await fetchDevices()).length;

      return {
        roversOnline,
        totalRovers,
        nodesOnline,
        totalNodes,
      }
    }
  );
}

export const useDevicesQuery = (projectId?: string) => {
  return useQuery (
    ['devices', projectId],
    async () => {
        return await fetchDevices(projectId);
    }
  );
}

export const useDeviceQuery = (deviceId: string | number, onSuccess?: (data: DeviceDetails) => void, onError?: (err: unknown) => void) => {
  return useQuery(
    ['deviceId', deviceId.toString()],
    async () => {
      return await fetchDevice(deviceId);
    },
    { enabled: !!deviceId,
      onSuccess: data => {
        if (onSuccess) {
          onSuccess(data);
        }
      },
      onError: onError,
    }
  );
}

export const useDeviceTypesQuery = () => {
  return useQuery(
    ['device-types'],
    async () => {
      return await fetchDeviceTypes();
    }
  )
}

const mapArchiveEntry = (history: Archive) => {
  return {
    registered_on: new Date(history.registered_on),
    speed_in_mbps: history.speed / 1024,
  };
}

export const useGatewayHistoryQuery = (deviceId: string) => {
  return useQuery(
    ['gateway-history'],
    async () => {
      const archive = await fetchGatewayArchive(deviceId);

      const mappedArchive = archive.map(mapArchiveEntry);

      return mappedArchive;
    },
    { enabled: !!deviceId}
  )
}

export const useRobotHistoryQuery = (deviceId: string) => {
  return useQuery(
    ['robot-history'],
    async () => {
      const archive = await fetchRobotArchive(deviceId);

      const mappedArchive = archive.map(mapArchiveEntry);

      return mappedArchive;
    },
    { enabled: !!deviceId}
  )
}

export const useSimCardsQuery = (onSuccess?: (data: SimCard[]) => void) => {
  return useQuery(
    ['sims'],
    async () => {
      return await fetchSimCards();
    },
    { onSuccess: data => {
      if (onSuccess) {
        onSuccess(data)
      }
    }}
  )
}

export const useModemsQuery = (onSuccess?: (data: Modem[]) => void) => {
  return useQuery(
    ['modems'],
    async () => {
      return await fetchModems();
    },
    { onSuccess: data => {
      if (onSuccess) {
        onSuccess(data)
      }
    }}
  );
}

export const useRadiosQuery = (onSuccess?: (data: Radio[]) => void) => {
  return useQuery(
    ['radios'],
    async () => {
      return await fetchRadios();
    },
    { onSuccess: data => {
      if (onSuccess) {
        onSuccess(data)
      }
    }}
  )
}

export const useRaspberryPisQuery = (onSuccess?: (data: RaspberryPi[]) => void) => {
  return useQuery(
    ['raspberry-pis'],
    async () => {
      return await fetchRaspberryPis();
    },
    { onSuccess: data => {
      if (onSuccess) {
        onSuccess(data)
      }
    }}
  )
}

export const useRecentMissionsQuery = () => {
  return useQuery (
    ['recent-missions'],
    async () => {
      return await fetchRecentMissions();
    }
  );
}

export const useMyMissionsQuery = () => {
  return useQuery (
    ['my-missions'],
    async () => {
      return await fetchMyMissions();
    }
  );
}

export const useAllMissionsQuery = (params?: FetchAllMissionsParams, enabled: boolean = true) => {
  return useQuery (
    ['all-missions', params],
    async () => {
      return await fetchAllMissions(params);
    },
    {enabled: enabled}
  )
}

export const useFetchMissionQuery = (missionId: number | string) => {
  return useQuery(
    ['mission', missionId.toString()],
    async () => {
      if (missionId !== 'new') {
        return await fetchMission(missionId);
      }
    },
    { enabled: !!missionId }
  );
}

export const useMissionCountsQuery = () => {
  return useQuery (
    ['mission-counts'],
    async () => {
      const numScheduledMissions = await fetchNumScheduledMissions();
      const numCompletedMissions = await fetchNumCompletedMissions();

      return {
        numScheduledMissions,
        numCompletedMissions,
      }
    }
  );
}

export const useScheduleMissionsQuery = (scheduleId: number | string) => {
  return useQuery(
    ['schedule-missions'],
    async () => {
      return await fetchScheduleMissions(scheduleId);
    }
  );
}

export const useFetchDeviceAuxiliaryLocationsQuery = (projectId?: string | null) => {
  return useQuery(
    ['device-auxiliary-locations', projectId],
    async () => {
      return await fetchDeviceAuxiliaryLocations(projectId);
    }
  );
}

export const useFetchStairClimberScripts = (projectId?: string) => {
  return useQuery(
    ['stair-climber-scripts', projectId],
    async () => {
      return await fetchStairClimberScripts(projectId);
    }
  );
}

export const useFetchFloorTransitionUsersQuery = (projectId?: string) => {
  return useQuery(
    ['floor-transition-users', projectId],
    async () => {
      if (projectId) {
        return await fetchFloorTransitionUsers(projectId);
      }
    },
    { enabled: !!projectId }
  );
}

export const useFetchAllSpeedTestsQuery = (projectPublicId?: string) => {
  return useQuery(
    ['speed-tests', projectPublicId],
    async () => {
      return await fetchAllSpeedTests(projectPublicId);
    }
  );
}

export const useFetchSoftwareVersionsQuery = (onSuccess?: (data: SoftwareVersion[]) => void) => {
  return useQuery(
    ['software-versions'],
    async () => {
      return await fetchSoftwareVersions();
    },
    {
      onSuccess: onSuccess
    }
  );
}

export const useFetchHardwareVersionsQuery = (onSuccess?: (data: SoftwareVersion[]) => void) => {
  return useQuery(
    ['hardware-versions'],
    async () => {
      return await fetchHardwareVersions();
    },
    {
      onSuccess: onSuccess
    }
  );
}
