import axios from "axios";
import { projectBase } from "./projects";

interface ProjectFloorPlan {
  sub_id: number;
  web_image_url: string | null;
  scale: number;
}

export interface ProjectFloor {
  floor_code: string;
  name: string;
  latest_floor_plan: ProjectFloorPlan;
  id: number;
  no_go_zones: any[];
  equipment: any[];
}

export interface ProjectFloorSection {
  id: number;
  name: string;
  sub_id: number;
}

export interface ViewpointsPoint {
  has_image: boolean;
  id: number;
  is_active: boolean;
  point_id: number;
  project_floor_section: number;
  x: string;
  y: string;
}

export const fetchProjectFloors = async (projectId: string): Promise<ProjectFloor[]> => {
  const res = await axios.get(
    `${projectBase}/${projectId}/floors/manage_floors`
  );
  const response = await res.data;
  return response.data;
}

export const fetchVerifiedProjectFloors = async (projectId: string): Promise<ProjectFloor[]> => {
  const res = await axios.get(
    `${projectBase}/${projectId}/floors`
  );
  const response = await res.data;
  return response.data;
}

export const fetchProjectFloor = async (projectId: string, floorCode: string): Promise<ProjectFloor> => {
  const res = await axios.get(
    `${projectBase}/${projectId}/floor/${floorCode}/manage_floor`
  );
  const response = await res.data;
  return response.data;
}

export const fetchProjectFloorViewpoints = async (projectId: string, floorCode: string): Promise<ViewpointsPoint[]> => {
  const res = await axios.get(
    `${projectBase}/${projectId}/floor/${floorCode}/viewpoints/points`
  );
  const response = await res.data;
  return response.data;
}