import { Box, Card } from "@mui/material"
import { RoverActionButtons } from "./RoverActionButtons";
import { RoverDrivingControls } from "./RoverDrivingControls";

export const RoverDrivingControlsCard = () => {
  return (
    <Card
      sx={{
        height: '100%',
        padding: 2,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <Box>
          <RoverActionButtons/>
        </Box>
        <Box
          flex={1}
        >
          <RoverDrivingControls/>
        </Box>
      </Box>
    </Card>
  )
}