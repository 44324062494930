import { useState, useEffect } from 'react';
import { Checkbox, Typography } from "@mui/material";
import { DeviceFields, Gateway, GatewayBackReference, GatewayFields, Modem, Radio, RaspberryPi } from "../../../../api/device";
import { useNewDeviceContext } from "../../../../contexts/newDeviceContext"
import { ModemsDropdown, RadiosDropdown, RaspberryPisDropdown } from "../../../common/Dropdowns";
import { useGeneratedPaths } from '../../../../hooks/useGeneratedPaths';
import { Box } from '@mui/system';

export const GatewayForm = () => {
  const [initialModemId, setInitialModemId] = useState<string | null>(null);
  const [initialRadioId, setInitialRadioId] = useState<string | null>(null);
  const [initialPiId, setInitialPiId] = useState<string | null>(null);
  const [gatewayForeignKeysLoaded, setGatewayForeignKeysLoaded] = useState<boolean>(false);

  const {
    newDevice,
    updateGateway,
    deviceLoaded
  } = useNewDeviceContext() as {
    newDevice: Gateway;
    updateGateway: (item: Partial<GatewayFields>) => void;
    deviceLoaded: boolean;
  };

  const gatewayModem = newDevice.modem;
  const gatewayRadio = newDevice.radio;
  const gatewayPi = newDevice.raspberrypi;

  useEffect(() => {
    if (deviceLoaded && !gatewayForeignKeysLoaded) {
      if (gatewayModem) {
        setInitialModemId(gatewayModem.device.DeviceID);
      }

      if (gatewayRadio) {
        setInitialRadioId(gatewayRadio.device.DeviceID);
      }

      if (gatewayPi) {
        setInitialPiId(gatewayPi.device.DeviceID);
      }

      setGatewayForeignKeysLoaded(true);
    }
  }, [
    deviceLoaded,
    gatewayModem,
    gatewayRadio,
    gatewayPi,
    gatewayForeignKeysLoaded
  ]);

  const onAcknowledgeConflict = <T extends GatewayBackReference>(propName: keyof Gateway, relatedDevice: T | undefined, checked: boolean) => {
    if (relatedDevice) {
      const updatedRelatedDevice = {...relatedDevice};

      updatedRelatedDevice.reassign = checked;
  
      updateGateway({[propName]: updatedRelatedDevice});
    }
  }

  const onChangeRelatedDevice = <T extends GatewayBackReference & DeviceFields>(propName: keyof Gateway, relatedDevice: T | undefined, initialRelatedDeviceId: string | null) => {
    if (relatedDevice) {
      const updatedRelatedDevice = {...relatedDevice};
      updatedRelatedDevice.conflict = gatewayForeignKeysLoaded && relatedDevice && !!relatedDevice.gateway_device_id && relatedDevice.device.DeviceID !== initialRelatedDeviceId;

      updateGateway({[propName]: updatedRelatedDevice});
    } else {
      updateGateway({[propName]: relatedDevice})
    }
  }

  return (
    <>
      <Typography sx={{textDecoration: 'underline'}}>Gateway Fields</Typography>
      <ModemsDropdown
        error={gatewayModem && gatewayModem.conflict && !gatewayModem.reassign}
        helperText={
          <ConflictHelperText
            showHelperText={gatewayModem?.conflict}
            relatedDevice={gatewayModem}
            onChangeReassign={(checked) => onAcknowledgeConflict('modem', gatewayModem, checked)}
          />
        }
        selectedModem={gatewayModem}
        setSelectedModem={(newValue?: Modem) => onChangeRelatedDevice('modem', newValue, initialModemId)}
      />
      <RadiosDropdown
        error={gatewayRadio && gatewayRadio.conflict && !gatewayRadio.reassign}
        helperText={
          <ConflictHelperText
            showHelperText={gatewayRadio?.conflict}
            relatedDevice={gatewayRadio}
            onChangeReassign={(checked) => onAcknowledgeConflict('radio', gatewayRadio, checked)}
          />
        }
        selectedRadio={gatewayRadio}
        setSelectedRadio={(newValue?: Radio) => onChangeRelatedDevice('radio', newValue, initialRadioId)}
      />
      <RaspberryPisDropdown
        error={gatewayPi && gatewayPi.conflict && !gatewayPi.reassign}
        helperText={
          <ConflictHelperText
            showHelperText={gatewayPi?.conflict}
            relatedDevice={gatewayPi}
            onChangeReassign={(checked) => onAcknowledgeConflict('raspberrypi', gatewayPi, checked)}
          />
        }
        selectedRaspberryPi={gatewayPi}
        setSelectedRaspberryPi={(newValue?: RaspberryPi) => onChangeRelatedDevice('raspberrypi', newValue, initialPiId)}
      />
    </>
  )
}

interface IConflictHelperTextProps<T> {
  showHelperText?: boolean;
  relatedDevice: T | undefined;
  onChangeReassign: (checked: boolean) => void;
}

const ConflictHelperText = <T extends GatewayBackReference>({
  relatedDevice,
  showHelperText,
  onChangeReassign,
}: IConflictHelperTextProps<T>) => {
  const {
    generateDeviceDetailsPath,
  } = useGeneratedPaths();

  if (!!showHelperText && relatedDevice?.gateway_device_id) {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginTop="-5px"
      >
        <Box>
          Used in:<>&nbsp;</>
          <a
            target="_blank"
            rel="noreferrer"
            href={generateDeviceDetailsPath(relatedDevice.gateway_device_id)}
            style={{color: 'inherit'}}
          >
            {relatedDevice.gateway_name}
          </a>
        </Box>
        <Box>
          <span>Reassign?</span>
          <Checkbox
            checked={!!relatedDevice.reassign}
            onChange={(e, checked) => onChangeReassign(checked)}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 14
              }
            }}
          />
        </Box>
      </Box>
    );
  }

  return null;
}