import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Card, Tab, Tabs } from "@mui/material";
import { MissionTab } from "./MissionTab/MissionTab";
import { EventsTab } from "./EventsTab/EventsTab";
import { LogsTab } from "./LogsTab/LogsTab";
import { CopilotTab } from "./CopilotTab/CopilotTab";
import { useNexcoreStatus } from "../../../../../hooks/teleopHooks/useNexcoreStatus";
import { useRoverStairClimberInformation } from "../../../../../hooks/teleopHooks/useRoverStairClimberInformation";
import { SafetyTab } from "./SafetyTab/SafetyTab";
import { useSafetyTabContext } from "../../../../../contexts/safetyTabContext";

type TabType = 'mission' | 'events' | 'logs' | 'copilot' | 'safety';
const tabs: TabType[] = ['mission', 'events', 'logs', 'copilot', 'safety'];
const safetyTabIndex = tabs.indexOf('safety');

export const RoverInformationDisplayCard = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const tabRefs = useRef<(HTMLDivElement | null)[]>([]);
  const rippleRefs = useRef<any[]>([]);
  const rippleTimeoutRefs = useRef<(NodeJS.Timeout | null)[]>(tabs.map(() => null));

  const nexcoreStatus = useNexcoreStatus();
  const stairClimberStatus = useRoverStairClimberInformation();
  const {
    teleopSafetyStatus,
    timeElapsedSinceLastUpdate: timeElapsedSinceLastSafetyUpdate,
  } = useSafetyTabContext();

  const teleopSafetyStatusTriggered = useMemo(() => {
    if (!!teleopSafetyStatus?.detectors) {
      return Object.values(teleopSafetyStatus.detectors).some(detector => detector.triggered);
    }

    return false;
  }, [teleopSafetyStatus]);

  const triggerRipple = useCallback((index: number) => {
    const container = tabRefs.current[index];
    const rippleRef = rippleRefs.current[index];
    const rect = container?.getBoundingClientRect();

    if (rect && rippleRef) {
      rippleRef.start(
        {
          clientX: rect.left + rect.width / 2,
          clientY: rect.top + rect.height / 2,
        },
        {
          center: false,
        }
      );
  
      setTimeout(() => {
        rippleRef.stop({});
      }, 320);
    }
  }, []);

  useEffect(() => {
    if (teleopSafetyStatusTriggered && selectedTab !== safetyTabIndex && timeElapsedSinceLastSafetyUpdate < 30 * 1000) {
      if (!rippleTimeoutRefs.current[safetyTabIndex]) {
        rippleTimeoutRefs.current[safetyTabIndex] = setInterval(() => {
          triggerRipple(safetyTabIndex);
        }, 1000);
      }
    } else if (!!rippleTimeoutRefs.current[safetyTabIndex]) {
      clearInterval(rippleTimeoutRefs.current[safetyTabIndex] as NodeJS.Timeout);
      rippleTimeoutRefs.current[safetyTabIndex] = null;
    }
  }, [selectedTab, teleopSafetyStatusTriggered, timeElapsedSinceLastSafetyUpdate, triggerRipple]);

  return (
    <Card
      sx={{
        height: '100%',
        padding: 1
      }}
    >
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tabs
            value={selectedTab}
            onChange={(e: React.ChangeEvent<{}>, newValue: number) => setSelectedTab(newValue)}
            aria-label="basic tabs example"
          >
            {tabs.map((tab, i) => {
              return (
                <Tab
                  ref={el => tabRefs.current[i] = el}
                  key={tab}
                  label={tab}
                  style={{width: `${Math.round(1 / tabs.length * 100)}%`}}
                  touchRippleRef={el => rippleRefs.current[i] = el}
                />
              )
            })}
          </Tabs>
        </Box>
        <Box flex={1}>
          {selectedTab === 0 &&
            <MissionTab/>
          }
          {selectedTab === 1 &&
            <EventsTab
              nexcoreStatus={nexcoreStatus}
              stairClimberStatus={stairClimberStatus}
            />
          }
          {selectedTab === 2 &&
            <LogsTab/>
          }
          {selectedTab === 3 &&
            <CopilotTab/>
          }
          {selectedTab === 4 &&
            <SafetyTab/>
          }
        </Box>
      </Box>
    </Card>
  )
}