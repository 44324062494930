import { Radio as MUIRadio } from "@mui/icons-material"
import { Box, Button, Card, CardActions, CardContent, Chip, Grid, Typography } from "@mui/material"
import { Radio } from "../../../../api/device"
import { useDeviceDetailsContext } from "../../../../contexts/deviceDetailsContext"
import { PATH_STRINGS } from "../../../../hooks/useGeneratedPaths"
import { useNavigation } from "../../../../hooks/useNavigation"
import { Breadcrumb } from "../../../common/Breadcrumb"
import { LoadingIndicator } from "../../../common/LoadingIndicator"
import { PermissionWrappedComponent } from "../../../common/PermissionWrapper"
import { GridItem } from "../../../common/StandardGrid"
import { deviceStatusColors } from "../Network"
import { ConnectivityCard } from "./ConnectivityCard"
import { GatewayDetails } from "./GatewayDetails"
import { ModemDetails } from "./ModemDetails"
import { RadioDetails } from "./RadioDetails"
import { RaspberryPiDetails } from "./RaspberryPiDetails"
import { SimCardDetails } from "./SimCardDetails"
import { GatewaySpeedHistory } from "./Archive/GatewaySpeedHistory"
import { DeviceNotesDrawer } from "./DeviceNotesDrawer"
import { useState } from "react"
import { useDocumentTitle } from "../../../../hooks/useDocumentTitle"

export const DeviceDetails = () => {
  const {device, deviceLoaded, deviceId} = useDeviceDetailsContext();
  const dataLoaded = !!device && deviceLoaded;
  const [deviceNotesOpen, setDeviceNotesOpen] = useState<boolean>(false);
  useDocumentTitle(device?.device.name);

  const {
    navigateToEditDevice
  } = useNavigation();

  let showConnectivityCard = false;
  let connectivityCardGridItems: GridItem[] = [];

  if (dataLoaded) {
    showConnectivityCard = device.device.type === 'radio';

    if (device.device.type === 'radio') {
      const radio = (device as Radio);
      const speedMeasurementExists = radio.speed !== null && radio.speed !== undefined;
      const speedInMbps = radio.speed / 1024;

      connectivityCardGridItems = [
        { icon: <MUIRadio />, label: 'Speed', info: `${speedMeasurementExists ? speedInMbps.toFixed(2) : 'unknown'} mbps`}
      ];
    }
  }

  return (
    <Grid container spacing={4} direction='column'>
      <Grid item>
        <Grid container alignItems='center'>
          <Grid item>
            <Typography variant='h5'>
              Devices
            </Typography>
            <Breadcrumb
              items={[{text: 'Devices', to: PATH_STRINGS.network}, {text: 'Device Details'}]}
            />
          </Grid>
          <Grid item flexGrow={1}/>
          { dataLoaded &&
            <Grid item>
              <Button
                onClick={() => setDeviceNotesOpen(true)}
                sx={{marginRight: 1}}
              >
                Notes
              </Button>
              <PermissionWrappedComponent permission="device_tables.edit_device">
                  <Button
                    variant='contained'
                    onClick={() => navigateToEditDevice(deviceId)}
                  >
                    {`Edit ${device.device.type}`}
                  </Button>
              </PermissionWrappedComponent>
            </Grid>
          }
        </Grid>
      </Grid>

      { !dataLoaded &&
        <LoadingIndicator/>
      }
      { dataLoaded &&
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Card
                sx={{
                  height: '100%'
                }}
              >
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Box
                        marginBottom='30px'
                      >
                        <Box
                          display='flex'
                          justifyContent='space-between'
                        >
                          <Typography variant='h5'>
                            {device.device.name}
                          </Typography>
                        </Box>
                          <Box
                            display='flex'
                            justifyContent='space-between'
                          >
                            <Typography variant='body1' color='text.secondary'>
                              {device.device.type}
                            </Typography>
                          </Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Chip
                        label={device.device.status}
                        color={deviceStatusColors[device.device.status]}
                        sx={{ color: 'white' }}
                      />
                    </Grid>
                  </Grid>
                  <Typography variant='body1' color='text.secondary'>
                    Location: {device.device.location}
                  </Typography>
                  <Typography variant='body1' color='text.secondary'>
                    IP: {device.device.ip}
                  </Typography>
                  <Typography variant='body1' color='text.secondary'>
                    iPerf Port: {device.device.iperf_port}
                  </Typography>
                  {device.device.type === 'sim-card' &&
                    <SimCardDetails />
                  }
                  {device.device.type === 'radio' &&
                    <RadioDetails />
                  }
                  {device.device.type === 'modem' &&
                    <ModemDetails/>
                  }
                  {device.device.type === 'raspberrypi' &&
                    <RaspberryPiDetails/>
                  }
                  {device.device.type === 'gateway' &&
                    <GatewayDetails/>
                  }
                </CardContent>
                {/* {device.device.type === 'gateway' &&
                  <CardActions>
                    <Button size="small" disabled>SSH CONNECT</Button>
                  </CardActions>
                } */}
              </Card>
            </Grid>
            {showConnectivityCard &&
              <Grid item xs={12} lg={4}>
                <ConnectivityCard gridItems={connectivityCardGridItems} />
              </Grid>
            }
            {device.device.type === 'gateway' &&
              <Grid item xs={12}>
                <Card
                  sx={{mb: 2}}
                >
                  <CardContent>
                    <Typography variant='h5'>
                      Speed History <span style={{fontSize: "0.5em"}}>last 24hrs</span>
                    </Typography>
                    <GatewaySpeedHistory />
                  </CardContent>
                </Card>
              </Grid>
            }
          </Grid>
        </Grid>
      }
      {dataLoaded &&
        <DeviceNotesDrawer
          open={deviceNotesOpen}
          onClose={() => setDeviceNotesOpen(false)}
          deviceId={device.device.DeviceID}
        />
      }
    </Grid>
  )
}
