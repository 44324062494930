import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import { useCallback } from "react"

export const useAlerts = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const addAlert = useCallback((message: SnackbarMessage, options?: OptionsObject<"default" | "error" | "success" | "warning" | "info"> | undefined) => {
    return enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      ...options
    });
  }, [enqueueSnackbar]);

  const addSuccessAlert = useCallback((message: SnackbarMessage, options?: OptionsObject<"success"> | undefined) => {
    return addAlert(message, {
      variant: 'success',
      ...options
    });
  }, [addAlert]);

  const addErrorAlert = useCallback((message: SnackbarMessage, options?: OptionsObject<"error"> | undefined) => {
    return addAlert(message, {
      variant: 'error',
      ...options
    });
  }, [addAlert]);

  const addInfoAlert = useCallback((message: SnackbarMessage, options?: OptionsObject<"info"> | undefined) => {
    return addAlert(message, {
      variant: 'info',
      ...options
    });
  }, [addAlert]);

  const closeAlert = useCallback((key: SnackbarKey) => {
    return closeSnackbar(key);
  }, [closeSnackbar]);

  const closeAlerts = useCallback(() => {
    return closeSnackbar();
  }, [closeSnackbar]);

  return {
    addAlert,
    addSuccessAlert,
    addErrorAlert,
    addInfoAlert,
    closeAlert,
    closeAlerts,
  }
}