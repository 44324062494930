import { useDeviceDetailsContext } from "../../../../../contexts/deviceDetailsContext";
import { useGatewayHistoryQuery } from "../../../../../hooks/queries";
import { SpeedHistory } from "./SpeedHistory";

export const GatewaySpeedHistory = () => {
  const {deviceId} = useDeviceDetailsContext();
  const {data: gatewayHistory, isLoading: gatewayLoading} = useGatewayHistoryQuery(deviceId);

  return (
    <SpeedHistory
      data={gatewayHistory}
      dataLoading={gatewayLoading}
    />
  )
}