import { Box } from "@mui/material";
import { Speed, SignalCellular0Bar, BatteryChargingFull, BatteryFull, BatteryCharging20, Battery20, BatteryCharging90, Battery90, BatteryCharging80, Battery80, BatteryCharging60, Battery60, BatteryCharging50, Battery50, BatteryCharging30, Battery30, Pause, WarningAmber } from "@mui/icons-material";
import { useRoverLinearVelocity } from "../../../../../hooks/teleopHooks/useRoverLinearVelocity";
import { useRoverBatteryLevel } from "../../../../../hooks/teleopHooks/useRoverBatteryLevel";
import { useMemo } from "react";
import { useRoverFlipperAngle } from "../../../../../hooks/teleopHooks/useRoverFlipperAngle";
import { DrivingIndicator } from "./RoverVideoCard";
import { lightBlue } from "../../../../../App";

interface IRoverStatusDisplayProps {
  drivingIndicator: DrivingIndicator | undefined;
  identifier?: string;
}

export const RoverStatusDisplay = ({
  drivingIndicator,
  identifier,
}: IRoverStatusDisplayProps) => {
  const {
    speedX,
    speedY,
  } = useRoverLinearVelocity(identifier);

  const speedValuePresent = speedX !== null && speedY !== null;

  const flipperAngle = useRoverFlipperAngle(identifier);

  const {
    batteryLevel,
    isCharging,
    secondBatteryLevel,
    secondBatteryIsCharging,
  } = useRoverBatteryLevel(identifier);

  return (
    <Box
      display="flex"
      gap="20px"
      alignItems="center"
      minHeight="48px"
    >
      <Box
        display="flex"
        gap="5px"
        marginRight={speedValuePresent ? "-10px" : '0px'}
      >
        <Speed/>
        <span
          style={{
            width: speedValuePresent ? '80px' : 'auto'
          }}
        >
          {speedValuePresent ? <>{speedX}, {speedY}</> : 'N/A'}
        </span>
      </Box>
      <Box
        display="flex"
        gap="5px"
      >
        <SignalCellular0Bar/>
        <span>{flipperAngle !== null ? flipperAngle : 'N/A'}</span>
      </Box>
      {(batteryLevel !== null && isCharging !== null) &&
        <RoverBatteryDisplay
          label="Base"
          batteryLevel={batteryLevel}
          isCharging={isCharging}
        />
      }
      {(secondBatteryLevel !== null && secondBatteryIsCharging !== null) &&
        <RoverBatteryDisplay
          label="Payload"
          batteryLevel={secondBatteryLevel}
          isCharging={secondBatteryIsCharging}
        />
      }
      {drivingIndicator === 'paused' &&
        <Pause
          style={{
            color: lightBlue
          }}
        />
      }
      {drivingIndicator === 'danger' &&
        <WarningAmber
          color="warning"
        />
      }
    </Box>
  )
}

const BatteryIcons = {
  'BatteryChargingFull': BatteryChargingFull,
  'BatteryFull': BatteryFull,
  'BatteryCharging90': BatteryCharging90,
  'Battery90': Battery90,
  'BatteryCharging80': BatteryCharging80,
  'Battery80': Battery80,
  'BatteryCharging60': BatteryCharging60,
  'Battery60': Battery60,
  'BatteryCharging50': BatteryCharging50,
  'Battery50': Battery50,
  'BatteryCharging30': BatteryCharging30,
  'Battery30': Battery30,
  'BatteryCharging20': BatteryCharging20,
  'Battery20': Battery20,
}

interface RoverBatteryDisplayProps {
  label?: string;
  batteryLevel: number;
  isCharging: boolean;
}

const RoverBatteryDisplay = ({
  label,
  batteryLevel,
  isCharging,
}: RoverBatteryDisplayProps) => {
  const getBatteryIconLevelName = (batteryLevel: number) => {
    if (batteryLevel >= 100) {
      return "Full"
    } else if (batteryLevel >= 90) {
      return 90;
    } else if (batteryLevel >= 80) {
      return 80;
    } else if (batteryLevel >= 60) {
      return 60;
    } else if (batteryLevel >= 50) {
      return 50;
    } else if (batteryLevel >= 30) {
      return 30;
    } else {
      return 20;
    }
  }

  const BatteryIcon = useMemo(() => {
    return BatteryIcons[`Battery${isCharging ? 'Charging' : ''}${getBatteryIconLevelName(batteryLevel)}`]
  }, [batteryLevel, isCharging]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {!!label &&
        <Box>
          {label}
        </Box>
      }
      <Box
        display="flex"
      >
        <BatteryIcon color={isCharging ? 'success' : undefined}/>
        <span>{batteryLevel}%</span>
      </Box>
    </Box>
  )
}