import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {v4 as uuidv4} from 'uuid';
import { Project } from "../api/projects";
import { ProjectFloor } from "../api/projectFloors";
import { ProgressRegion, ProjectSchedulePoint, Schedule } from "../api/schedules";
import { Mission } from "../api/missions";
import { Robot } from "../api/device";
import { IAlert } from "../types/types";
import { AlertColor } from "@mui/material";
import { ScanTask } from "../components/views/Missions/MissionTeleop/TeleopDrawer/StartMission/MissionScriptGenerationControls";
import { StairClimberScript } from "../api/stairs";
import { DrawerContentType } from "../components/views/Missions/MissionTeleop/TeleopDrawer/TeleopDrawer";
import { useGeneratedPaths } from "../hooks/useGeneratedPaths";
import { ICopilotState, initialCopilotState } from "./copilotContext";

export interface CapturedImage {
  mission_id: number;
  point_type: "viewpoint" | "region";
  project_id: number;
  project_floor_id: number;
  point_id: number;
  region_id: number | null;
  file_path: string;
  taken_on: string;
  x: number;
  y: number;
  angle: number;
}

interface IMapPointContextMenuState {
  mapPointContextMenuOpen: boolean;
  mapPointContextMenuPointX: number | null;
  mapPointContextMenuPointY: number | null;
  mapPointContextMenuClickX: number | null;
  mapPointContextMenuClickY: number | null;
}

const initialMapPointContextMenuState: IMapPointContextMenuState = {
  mapPointContextMenuOpen: false,
  mapPointContextMenuPointX: null,
  mapPointContextMenuPointY: null,
  mapPointContextMenuClickX: null,
  mapPointContextMenuClickY: null,
}

export interface IMissionManagementState extends ICopilotState, IMapPointContextMenuState {
  robotDeviceId: string | number | undefined;
  robot: Robot | undefined;
  socket: Worker | undefined;
  socketOpen: boolean;
  tracking: boolean;
  panning: boolean;
  sequence: boolean;
  regionBased: boolean;
  project: Project | undefined;
  floors: ProjectFloor[];
  selectedFloor: ProjectFloor | null;
  schedule: Schedule | null;
  schedulePoints: ProjectSchedulePoint[];
  selectedPoint: ProjectSchedulePoint | null;
  selectedProgressRegion: ProgressRegion | null;
  goalProgressRegionId: number | null;
  linearSpeed: number;
  angularSpeed: number;
  sliderFlipperAngle: number;
  capturedImages: Map<number, CapturedImage>;
  isPaused: boolean;
  requestSkipPoint: boolean;
  goingToRegion: boolean;
  mission: Mission | undefined;
  robotScript: string;
  startPoint: ProjectSchedulePoint | null;
  finalGoal: ProjectSchedulePoint | null;
  lidarRecord: boolean;
  networkRecord: boolean;
  selectedScanTask: ScanTask;
  drawerContentType: DrawerContentType;
  drawerSelectedTab: number;
  drawerSelectedStairClimberScript: StairClimberScript | null;
  messages: string[];
}

const getInitialMissionState = (deviceId?: string | number | undefined, initialState?: Partial<IMissionManagementState>): IMissionManagementState => {
  return {
    robotDeviceId: deviceId,
    robot: undefined,
    tracking: true,
    panning: true,
    sequence: true,
    regionBased: false,
    project: undefined,
    socket: undefined,
    socketOpen: false,
    floors: [],
    selectedFloor: null,
    schedule: null,
    schedulePoints: [],
    selectedProgressRegion: null,
    goalProgressRegionId: null,
    selectedPoint: null,
    linearSpeed: 30,
    angularSpeed: 60,
    sliderFlipperAngle: 0,
    capturedImages: new Map(),
    isPaused: false,
    requestSkipPoint: false,
    goingToRegion: false,
    mission: undefined,
    robotScript: '',
    startPoint: null,
    finalGoal: null,
    lidarRecord: true,
    networkRecord: true,
    selectedScanTask: 'scan',
    drawerContentType: DrawerContentType.none,
    drawerSelectedTab: 0,
    drawerSelectedStairClimberScript: null,
    messages: [],
    ...initialCopilotState,
    ...initialMapPointContextMenuState,
    ...initialState,
  }
}

type Missions = Record<string, IMissionManagementState>;

interface IMissionManagementContext {
  missions: Missions;
  addNewMission: (deviceId?: string | number, initialState?: Partial<IMissionManagementState>) => string;
  updateMission: (identifier: string, stateUpdate: Partial<IMissionManagementState>, activate?: boolean) => void;
  updateActiveMission: (stateToUpdate: Partial<IMissionManagementState>) => void;
  removeMission: (identifier: string) => void;
  activeTeleopIdentifier: string;
  setActiveTeleopIdentifier: React.Dispatch<React.SetStateAction<string>>;
  activeTeleop: IMissionManagementState | undefined;
  canAddMission: boolean;
  drawerOpen: boolean;
  openTeleopDrawer: (contentType: DrawerContentType) => void;
  closeTeleopDrawer: () => void;
}

const initialMissionManagementContext: IMissionManagementContext = {
  missions: {},
  addNewMission: () => '',
  updateMission: () => {},
  updateActiveMission: () => {},
  removeMission: () => {},
  activeTeleopIdentifier: '',
  setActiveTeleopIdentifier: () => {},
  canAddMission: true,
  activeTeleop: undefined,
  drawerOpen: false,
  openTeleopDrawer: () => {},
  closeTeleopDrawer: () => {},
}

const MissionManagementContext = createContext<IMissionManagementContext>({...initialMissionManagementContext});

interface MissionManagementProviderProps {
  initialDeviceIds?: (string | number)[];
  initialMissions?: Mission[];
}

export const MissionManagementProvider = ({
  initialDeviceIds,
  initialMissions,
  children,
}: React.PropsWithChildren<MissionManagementProviderProps>) => {
  const {
    generateMissionControlPath,
  } = useGeneratedPaths();

  const initialSetupComplete = useRef<boolean>(false);

  const [activeTeleopIdentifier, setActiveTeleopIdentifier] = useState<string>(initialMissionManagementContext.activeTeleopIdentifier);
  const [missions, setMissions] = useState<Missions>({});
  const [alert, setAlert] = useState<IAlert | undefined>();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(initialMissionManagementContext.drawerOpen);

  useEffect(() => {
    let activeDeviceIds: string[] = [];
    let activeMissionIds: string[] = [];
    let urlDeviceIds: string[] = [];
    let urlMissionIds: string[] = [];

    Object.getOwnPropertyNames(missions).forEach(identifier => {
      const missionId = missions[identifier]?.mission?.id;
      const deviceId = missions[identifier]?.robotDeviceId;

      if (!!missionId) {
        activeMissionIds.push(missionId.toString());
      } else if (!!deviceId) {
        activeDeviceIds.push(deviceId.toString());
      }
    });

    const urlParams = new URLSearchParams(window.location.search);
    const robotParam = urlParams.get('robots');
    const missionParam = urlParams.get('missions');
    const testParam = urlParams.get('test');
    const isTestMission = testParam?.toLowerCase() === 'true' || testParam === '1'; 

    if (robotParam) {
      urlDeviceIds = robotParam.split(',');
    }

    if (missionParam) {
      urlMissionIds = missionParam.split(',');
    }

    const equalDeviceIds = unorderedArrayCompare(activeDeviceIds, urlDeviceIds);
    const equalMissionIds = unorderedArrayCompare(activeMissionIds, urlMissionIds);

    if (!equalDeviceIds || !equalMissionIds) {
      const newPath = generateMissionControlPath(activeMissionIds, activeDeviceIds, isTestMission);
      window.history.replaceState(null, '', newPath);
    }
  }, [generateMissionControlPath, missions]);

  const addNewMission = useCallback((deviceId?: string | number, initialState?: Partial<IMissionManagementState>) => {
    const newIdentifier = uuidv4();

    setMissions(prevMissions => {
      return {
        ...prevMissions,
        [newIdentifier]: getInitialMissionState(deviceId, initialState)
      }
    });

    if (!!deviceId) {
      setActiveTeleopIdentifier(newIdentifier);
    }

    return newIdentifier;
  }, []);

  const updateMission = useCallback((identifier: string, stateUpdate: Partial<IMissionManagementState>, activate?: boolean) => {
    setMissions(prevMissions => {
      return {
        ...prevMissions,
        [identifier]: {
          ...prevMissions[identifier],
          ...stateUpdate,
        }
      }
    });

    if (activate) {
      setActiveTeleopIdentifier(identifier);
    }
  }, []);

  const updateActiveMission = useCallback((stateToUpdate: Partial<IMissionManagementState>) => {
    updateMission(activeTeleopIdentifier, stateToUpdate);
  }, [activeTeleopIdentifier, updateMission]);

  const removeMission = useCallback((identifier: string) => {
    setMissions(prevMissions => {
      const updatedMissions = {...prevMissions};
      delete updatedMissions[identifier];

      if (identifier === activeTeleopIdentifier) {
        const initialIdentifier = Object.getOwnPropertyNames(updatedMissions)[0];

        setActiveTeleopIdentifier(initialIdentifier);
      }

      return updatedMissions;
    });
  }, [activeTeleopIdentifier]);

  useEffect(() => {
    if (!initialSetupComplete.current) {
      if (initialMissions) {
        initialMissions.forEach(mission => {
          addNewMission(mission.robot.device_id, {
            mission: mission,
            schedule: { id: mission.schedule_id } as Schedule
          });
        });
      }

      if (initialDeviceIds) {
        initialDeviceIds.forEach(deviceId => {
          addNewMission(deviceId);
        });
      }
    }

    initialSetupComplete.current = true;
  }, [addNewMission, initialDeviceIds, initialMissions]);

  const activeTeleop = useMemo(() => {
    return missions[activeTeleopIdentifier];
  }, [missions, activeTeleopIdentifier]);

  const canAddMission = useMemo(() => {
    const missionIdentifiers = Object.getOwnPropertyNames(missions)

    const sizeLimitReached = missionIdentifiers.length >= 4;
    const missionCreationInProgress = missionIdentifiers.some(identifier => {
      return !missions[identifier].robotDeviceId;
    });

    return !sizeLimitReached && !missionCreationInProgress;
  }, [missions]);

  const addAlert = useCallback((severity: AlertColor, message: string, timeout: number = 3000) => {
    setAlert({
      message: message,
      severity: severity
    });

    setTimeout(() => {
      setAlert(undefined);
    }, timeout);
  }, []);

  const openTeleopDrawer = useCallback((contentType: DrawerContentType) => {
    setDrawerOpen(true);
    
    updateActiveMission({
      drawerContentType: contentType,
    });
  }, [updateActiveMission]);

  const closeTeleopDrawer = useCallback(() => {
    setDrawerOpen(false);

    updateActiveMission({
      drawerContentType: DrawerContentType.none,
    });
  }, [updateActiveMission]);

  const value: IMissionManagementContext = useMemo(() => {
    return {
      missions,
      addNewMission,
      updateMission,
      updateActiveMission,
      removeMission,
      activeTeleopIdentifier,
      setActiveTeleopIdentifier,
      canAddMission,
      activeTeleop,
      alert,
      addAlert,
      drawerOpen,
      openTeleopDrawer,
      closeTeleopDrawer,
    }
  }, [
    missions,
    addNewMission,
    updateMission,
    updateActiveMission,
    removeMission,
    activeTeleopIdentifier,
    canAddMission,
    activeTeleop,
    alert,
    addAlert,
    drawerOpen,
    openTeleopDrawer,
    closeTeleopDrawer,
  ]);

  return (
    <MissionManagementContext.Provider value={value}>
      {children}
    </MissionManagementContext.Provider>
  );
}

export const useMissionManagementContext = () => {
  return useContext(MissionManagementContext);
};

const unorderedArrayCompare = <T extends any>(a: T[], b: T[], compartor?: (a: T, b: T) => number) => {
  if (a.length !== b.length) {
    return false;
  }
  
  const aSorted = [...a].sort(compartor);
  const bSorted = [...b].sort(compartor);

  for (let i=0; i<aSorted.length; i++) {
    if (aSorted[i] !== bSorted[i]) {
      return false;
    }
  }

  return true;
}