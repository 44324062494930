import React, { useState, useCallback } from 'react';
import { Box, TextField, Button, Tabs, Tab, CircularProgress } from "@mui/material"
import { useRoverTeleopActions } from '../../../../../../hooks/teleopHooks/useRoverTeleopActions';
import { MissionScriptGenerationControls } from './MissionScriptGenerationControls';
import { StairClimbingScriptControls } from './StairClimbingScriptControls';
import { StairConfirmationModal } from './StairConfirmationModal';
import { Mission, createMission, updateMission } from '../../../../../../api/missions';
import { useUserContext } from '../../../../../../contexts/userContext';
import { Robot } from '../../../../../../api/device';
import { useSearchParams } from 'react-router-dom';
import { useMissionManagementContext } from '../../../../../../contexts/missionManagementContext';
import { Schedule } from '../../../../../../api/schedules';
import { Project } from '../../../../../../api/projects';

const prettyPrint = (obj: any) => {
  return JSON.stringify(obj, null, 2);
}

type TabType = 'mission' | 'stair climbing';
const tabs: TabType[] = ['mission', 'stair climbing'];

export const StartMission = () => {
  const {
    state: userState,
  } = useUserContext();

  const {
    startMission,
  } = useRoverTeleopActions();

  const {
    activeTeleop,
    updateActiveMission,
    closeTeleopDrawer,
  } = useMissionManagementContext();

  const {
    mission,
    robot,
    project,
    schedule,
    drawerSelectedStairClimberScript,
  } = activeTeleop || {};

  const robotScript = activeTeleop?.robotScript ?? '';
  const drawerSelectedTab = activeTeleop?.drawerSelectedTab ?? 0;

  const missionTabSelected = tabs[drawerSelectedTab] === 'mission';
  const stairClimbingTabSelected = tabs[drawerSelectedTab] === 'stair climbing';

  const [stairConfirmationModalOpen, setStairConfirmationModalOpen] = useState<boolean>(false);
  const [startMissionInProgress, setStartMissionInProgress] = useState<boolean>(false);
  const [selectedRecentMission, setSelectedRecentMission] = useState<Mission | null>(null);

  const [searchParams] = useSearchParams();
  const testParam = searchParams.get('test');

  const missionInProgressIsTest = !!mission && mission.is_test;
  const missionToBeCreatedIsTest = !mission && !!testParam && (testParam.toLowerCase() === 'true' || testParam === '1');
  const isTestMission = missionInProgressIsTest || missionToBeCreatedIsTest;

  const scriptIsValidJson = () => {
    try {
      JSON.parse(robotScript);

      return true;
    } catch (err) {
      return false;
    }
  }

  const startMissionDisabled = !robotScript || !robotScript.trim() || !scriptIsValidJson() || startMissionInProgress || !schedule;

  const onClickStartMission = () => {
    if (stairClimbingTabSelected) {
      if (drawerSelectedStairClimberScript) {
        setStairConfirmationModalOpen(true);
      }      
    } else {
      onStartMission();
    }
  }

  const robotScriptOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && e.ctrlKey && !startMissionDisabled) {
      onClickStartMission();
    }
  }

  const onClickPrettyPrint = () => {
    if (scriptIsValidJson()) {
      const parsedScript = JSON.parse(robotScript);
      
      updateActiveMission({robotScript: prettyPrint(parsedScript)});
    }
  }

  const createMissionIfDoesntExist = async (): Promise<Mission> => {
    let missionToStart: Mission;

    if (selectedRecentMission) {
      const updateData: Partial<Mission> = {status: 'In Progress', end_time: null};

      missionToStart = await updateMission(selectedRecentMission.id, updateData);
    } else if (!mission) {
      const createMissionInfo = {
        supervisor_public_id: userState.public_id,
        robot_public_id: (robot as Robot).robot_details.public_id,
        schedule_id: (schedule as Schedule).id,
        project_id: (project as Project).public_id,
        is_test: isTestMission,
      }

      missionToStart = await createMission(createMissionInfo);
    } else {
      missionToStart = mission;
    }

    return missionToStart;
  }

  const onChangeRobotScript = (script: Record<string, any> | null) => {
    let newRobotScript = '';

    if (!!script) {
      const scriptWithMissionId = {
        mission_id: selectedRecentMission?.id ?? mission?.id,
        ...script,
      };

      newRobotScript = prettyPrint(scriptWithMissionId);
    }

    updateActiveMission({
      robotScript: newRobotScript,
    });
  }

  const onStartMission = async () => {
    setStartMissionInProgress(true);

    let scriptForStartMission = robotScript;
    const missionForScript = await createMissionIfDoesntExist();
    const parsedRobotScript = JSON.parse(scriptForStartMission);
    delete parsedRobotScript.mission_id;

    scriptForStartMission = prettyPrint({
      mission_id: missionForScript.id,
      ...parsedRobotScript,      
    });

    updateActiveMission({
      mission: missionForScript,
      robotScript: scriptForStartMission,
      schedule: {id: missionForScript.schedule_id} as Schedule,
    });

    startMission(scriptForStartMission);

    setTimeout(() => {
      closeTeleopDrawer();
      setStartMissionInProgress(false);
    }, 1000);
  }

  const onChangeSelectedTabIndex = useCallback((e: React.ChangeEvent<{}>, newValue: number) => {
    updateActiveMission({
      robotScript: '',
      drawerSelectedTab: newValue,
      drawerSelectedStairClimberScript: null,
    });
  }, [updateActiveMission]);

  const onChangeSelectedRecentMission = useCallback((newlySelectedMission: Mission | null) => {
    setSelectedRecentMission(newlySelectedMission);

    if (newlySelectedMission?.schedule_id && newlySelectedMission.schedule_id.toString() !== schedule?.id?.toString()) {
      updateActiveMission({
        schedule: newlySelectedMission ? {id: parseInt(newlySelectedMission.schedule_id.toString())} as Schedule : null,
      });
    }
  }, [schedule?.id, updateActiveMission]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      height="100%"
      padding="20px 0px 10px 0px"
    >
      <Box>
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={drawerSelectedTab} onChange={onChangeSelectedTabIndex} aria-label="basic tabs example">
                <Tab label={tabs[0]} style={{width: '50%'}}/>
                <Tab label={tabs[1]} style={{width: '50%'}}/>
              </Tabs>
            </Box>
          </Box>
        </Box>
      <Box>
        {missionTabSelected &&
          <MissionScriptGenerationControls
            onChangeRobotScript={onChangeRobotScript}
            selectedRecentMission={selectedRecentMission}
            onChangeSelectedRecentMission={onChangeSelectedRecentMission}
          />
        }
        {(stairClimbingTabSelected) &&
          <StairClimbingScriptControls
            onChangeRobotScript={onChangeRobotScript}
            selectedStairClimberScript={drawerSelectedStairClimberScript}
            setSelectedStairClimberScript={newSelectedScript => updateActiveMission({drawerSelectedStairClimberScript: newSelectedScript})}
          />
        }
      </Box>
      <Box flex={1}>
        <TextField
            label="Code Editor"
            multiline
            rows={1}
            value={robotScript}
            onKeyDown={robotScriptOnKeyDown}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateActiveMission({robotScript: e.target.value})}
            sx={{
              width: '550px',
              height: '100%',
              '& .MuiInputBase-root': {
                height: '100%',
                '& ::-webkit-scrollbar':{
                  width: 0,
                  height: 0
                }
              },
            }}
            inputProps={{
              spellCheck: false,
              style: {
                height: '100%',
              }
            }}
          />
      </Box>
      <Box>
        <Box display="flex" gap={2} alignItems="center">
          <Button
            disabled={startMissionDisabled}
            onClick={onClickPrettyPrint}
          >
            Pretty Print
          </Button>
          <Button
            disabled={startMissionDisabled}
            variant='contained'
            onClick={onClickStartMission}
          >
            Start Mission
          </Button>
          {startMissionInProgress &&
            <CircularProgress size={25}/>
          }
        </Box>
      </Box>
      {(drawerSelectedStairClimberScript && stairConfirmationModalOpen) &&
        <StairConfirmationModal
          open={stairConfirmationModalOpen}
          setOpen={setStairConfirmationModalOpen}
          onConfirm={onStartMission}
        />
      }
    </Box>
  );
}