import { useState, useEffect } from 'react';
import { HardwareVersion, createHardwareVersion, editHardwareVersion, fetchHardwareVersions } from "../../../../api/versions";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { VersionsTable } from "./VersionsTable";

export const HardwareVersionsTab = () => {
  const [hardwareVersions, setHardwareVersions] = useState<HardwareVersion[]>([]);
  const [hardwareVersionsLoaded, setHardwareVersionsLoaded] = useState<boolean>(false);

  const dataLoaded = !!hardwareVersions && !!hardwareVersionsLoaded;

  useEffect(() => {
    setHardwareVersionsLoaded(false);

    fetchHardwareVersions().then(returnedVersions => {
      setHardwareVersions(returnedVersions);
      setHardwareVersionsLoaded(true);
    })
  }, []);

  return (
    <>
      { !dataLoaded &&
        <LoadingIndicator/>
      }
      
      { dataLoaded &&
        <VersionsTable
          data={hardwareVersions}
          onCreateItem={createHardwareVersion}
          onEditItem={editHardwareVersion}
        />
      }
    </>
  );
}