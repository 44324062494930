import React from 'react';
import { Location, Navigate, useLocation } from 'react-router-dom';
import { useUserContext } from '../../contexts/userContext';
import { LoadingIndicator } from './LoadingIndicator';
import { PermissionWrapper } from './PermissionWrapper';

interface IPermissionRouteProps {
  permission: string;
}

export interface PermissionRouteState {
  isAuthenticated?: boolean;
  location?: Location;
}

export function PermissionRoute({ permission, children }: React.PropsWithChildren<IPermissionRouteProps>) {
  const { state: authState } = useUserContext();
  const location = useLocation();

  if (authState.loading) return <LoadingIndicator />;

  return (
    <>
      { authState.isAuthenticated &&
        <PermissionWrapper permission={permission}>
          {children}
        </PermissionWrapper>
      }
      { !authState.isAuthenticated &&
        <Navigate
          to={{
            pathname: '/login',
          }}
          state={{
            isAuthenticated: false,
            from: location,
          }}
        />
      }
    </>
  );
}
