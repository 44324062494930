import { useCallback } from "react";
import { logRobotTakeover, RobotTakeoverPayload } from "../../api/robots";
import { useUserContext } from "../../contexts/userContext";
import { useMissionManagementContext } from "../../contexts/missionManagementContext";

let roverTakeoverTimer: NodeJS.Timeout | null = null;
let roverTakeoverStart: Date | null = null;

const gracePeriod = 5000;

export const useTimeoutTakeover = () => {
  const {
    state: userState
  } = useUserContext();

  const {
    activeTeleop,
  } = useMissionManagementContext();

  const mission = activeTeleop?.mission;

  const missionInProgress = mission && mission.status === 'In Progress';

  const postTakeover = useCallback(() => {
    if (missionInProgress && roverTakeoverStart) {
      const recordingStart = new Date(mission.start_time);

      const payload: RobotTakeoverPayload = {
        robot: mission.robot.public_id,
        action: "drive",
        project: mission.project_id,
        user: userState.public_id,
        mission: mission.public_id,
        start_frame: (roverTakeoverStart.valueOf() - recordingStart.valueOf()) / 1000,
        end_frame: ((new Date().valueOf() - recordingStart.valueOf()) - gracePeriod) / 1000  // subtract the timeout waiting period
      }
      
      logRobotTakeover(payload);

      if (roverTakeoverTimer) {
        clearTimeout(roverTakeoverTimer);
        roverTakeoverTimer = null;
      }

      roverTakeoverStart = null;
    }
  }, [mission, missionInProgress, userState.public_id]);

  const timeoutTakeover = useCallback(() => {
    if (missionInProgress) {
      if (roverTakeoverTimer) {
        clearTimeout(roverTakeoverTimer);
      }
    
      if (!roverTakeoverStart) {
        roverTakeoverStart = new Date();
      }

      roverTakeoverTimer = setTimeout(postTakeover, gracePeriod);
    }
  }, [missionInProgress, postTakeover]);

  return timeoutTakeover;
}