import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import nipplejs from 'nipplejs'
import { useRoverTeleopActions } from "../../../../hooks/teleopHooks/useRoverTeleopActions";
import { useMissionManagementContext } from "../../../../contexts/missionManagementContext";

const arrowKeys = {
  left: 'ArrowLeft',
  up: 'ArrowUp',
  right: 'ArrowRight',
  down: 'ArrowDown'
};

const isArrowKey = (e: KeyboardEvent) => {
  return e.key === arrowKeys.up || e.key === arrowKeys.down || e.key === arrowKeys.left || e.key === arrowKeys.right;
}

interface IJoystickProps {
  linearSpeed: number;
  angularSpeed: number;
}

let teleopX = 0;
let teleopY = 0;

const initialLinearSpeed = 10;
const initialAngularSpeed = 25;
const speedDelta = 5;

export const Joystick = ({
  linearSpeed,
  angularSpeed,
}: IJoystickProps) => {
  const {
    moveAction,
  } = useRoverTeleopActions();

  const {
    drawerOpen
  } = useMissionManagementContext();

  const joystickContainerRef = useRef<HTMLDivElement>(null);
  const [publishImmediately, setPublishImmediately] = useState<boolean>(false);
  const [joystickSize, setJoystickSize] = useState<number>(0);
  const moveInterval = useRef<NodeJS.Timer>();

  const moveEnabled = !drawerOpen;

  useEffect(() => {
    if (publishImmediately && !moveInterval.current && moveEnabled) {
      moveInterval.current = setInterval(() => {
        moveAction(teleopX, teleopY);
      }, 100);
    } else {
      clearInterval(moveInterval.current);
      moveInterval.current = undefined;
    }
  }, [moveAction, publishImmediately, moveEnabled]);

  const manager = useMemo(() => {
    const joystick = joystickContainerRef.current;
    let manager: nipplejs.JoystickManager;

    if (joystick !== null) {
      const height = joystick.clientHeight;
      const width = joystick.clientWidth;

      const size = Math.min(175, Math.min(height, width) / 1.5);
      setJoystickSize(size);

      const options: any = {
        zone: joystick,
        position: {
          top: '50%',
          left: '50%'
        },
        mode: 'static',
        size: size,        
        color: '#A78BFA',
        restJoystick: true,
        dynamicPage: true
      };

      manager = nipplejs.create(options);

      return manager;
    }
  }, [joystickContainerRef.current]);

  const onMoveNipple = useCallback((e: nipplejs.EventData, nipple: nipplejs.JoystickOutputData) => {
    // nipplejs returns direction is screen coordiantes
    // we need to rotate it, that dragging towards screen top will move robot forward
    var direction = nipple.angle.degree - 90;
    if (direction > 180) {
        direction = -(450 - nipple.angle.degree);
    }
    // convert angles to radians and scale linear and angular speed
    // adjust if youwant robot to drvie faster or slower

    const xDistance = (Math.cos(direction / 57.29) * nipple.distance);
    const yDistance = (Math.sin(direction / 57.29) * nipple.distance);

    const radius = joystickSize / 2;

    teleopX = (xDistance * linearSpeed) / radius;
    teleopY = (yDistance * angularSpeed) / radius;
  }, [angularSpeed, joystickSize, linearSpeed]);

  const activatePublishImmediately = useCallback(() => {
    setPublishImmediately(true);
  }, []);

  const onStopMoveAction = useCallback(() => {
    setPublishImmediately(false);
    teleopX = 0;
    teleopY = 0;
    moveAction(teleopX, teleopY);
  }, [moveAction]);

  useEffect(() => {
    if (manager) {
      manager.on('move', onMoveNipple);
      manager.on('start', activatePublishImmediately);
      manager.on('end', onStopMoveAction);

      return () => {
        manager.off('move', onMoveNipple);
        manager.off('start', activatePublishImmediately);
        manager.off('end', onStopMoveAction);
      }
    }
  }, [manager, activatePublishImmediately, onStopMoveAction, onMoveNipple]);

  const onKeyDown = useCallback((e: KeyboardEvent) => {
    if (isArrowKey(e)) {
      // e.preventDefault();
    } else {
      return;
    }

    if (e.repeat) {
      let deltaX = 0;
      let deltaY = 0;

      if (e.key === arrowKeys.up && teleopX < linearSpeed) {
        deltaX = speedDelta;
      } else if (e.key === arrowKeys.down && teleopX > -linearSpeed) {
        deltaX = -speedDelta;
      } else if (e.key === arrowKeys.left && teleopY < angularSpeed) {
        deltaY = speedDelta;
      } else if (e.key === arrowKeys.right && teleopY > -angularSpeed) {
        deltaY = -speedDelta;
      }

      teleopX += deltaX;
      teleopY += deltaY;
    }
    else {
      if (e.key === arrowKeys.up) {
        teleopX = initialLinearSpeed;
      }
      else if (e.key === arrowKeys.down) {
        teleopX = -initialLinearSpeed;
      }
      else if (e.key === arrowKeys.left) {
        teleopY = initialAngularSpeed;
      }
      else if (e.key === arrowKeys.right) {
        teleopY = -initialAngularSpeed;
      }

      activatePublishImmediately();
    }
  }, [angularSpeed, linearSpeed, activatePublishImmediately]);

  const onKeyUp = useCallback((e: KeyboardEvent) => {
    if (isArrowKey(e)) {
      onStopMoveAction();
    }
  }, [onStopMoveAction]);

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown, false);
    window.addEventListener("keyup", onKeyUp, false);

    return () => {
      window.removeEventListener("keydown", onKeyDown, false);
      window.removeEventListener("keyup", onKeyUp, false);
    }
  }, [onKeyDown, onKeyUp]);

  return (
    <div
      ref={joystickContainerRef}
      style={{ 
        height: '175px',
        width: '175px',
        position: 'relative' 
      }}
    />
  )
}