import { Add, Remove } from "@mui/icons-material"
import { Fab, Grid, TextField, Typography } from "@mui/material"

interface CounterProps {
  label: string;
  value: number;
  setValue: (newValue: number) => void;
  step: number;
  min: number;
  max: number;
}

const buttonStyle = {
  maxHeight: "30px",
  minHeight: "30px",
  minWidth: "30px",
  maxWidth: "30px",
};

export const Counter = ({
  label,
  value,
  setValue,
  step,
  min,
  max,
}: CounterProps) => {
  return (
    <Grid container direction="column" width="max-content" spacing={1}>
      <Grid item>
        <Typography
          sx={{
            textAlign: 'center',
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" spacing={1.5}>
          <Grid item>
            <Fab
              disabled={value - step < min}
              color="violet"
              aria-label="add"
              size='small'
              onClick={() => setValue(value - step)}
              style={buttonStyle}
            >
              <Remove/>
            </Fab>
          </Grid>
          <Grid item>
            <TextField
              disabled
              sx={{
                width: '50px'
              }}
              inputProps={{
                style: {
                  height: '5px'
                }
              }}
              value={value}
            />
          </Grid>
          <Grid item>
            <Fab
              disabled={value + step > max}
              color="violet"
              aria-label="add"
              size='small'
              onClick={() => setValue(value + step)}
              style={buttonStyle}
            >
              <Add/>
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}