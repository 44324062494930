import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Radio } from "../../../../api/device";
import { useDeviceDetailsContext } from "../../../../contexts/deviceDetailsContext"
import { useGeneratedPaths } from "../../../../hooks/useGeneratedPaths";

export const RadioDetails = () => {
  const {
    generateRoverDetailsPath,
    generateDeviceDetailsPath,
  } = useGeneratedPaths();

  const {device, deviceLoaded} = useDeviceDetailsContext() as {device: Radio, deviceLoaded: boolean}
  const dataLoaded = !!device && deviceLoaded;

  if (!dataLoaded) {
    return <></>
  }

  return (
    <>
      <Typography variant='body1' color='text.secondary'>
        MAC Address: {device.mac_address}
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        Channel Width: {device.channel_width}
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        Channel Center: {device.channel_center}
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        Model: {device.model}
      </Typography>
      {!!device.gateway_device_id &&
        <Typography variant='body1' color='text.secondary'>
          Gateway:&nbsp;
            <Link
              to={generateDeviceDetailsPath(device.gateway_device_id.toString())}
              style={{ color: 'white'}}
            >
            {device.gateway_name}
            </Link>
        </Typography>
      }
      {!!device.robot_device_id &&
        <Typography variant='body1' color='text.secondary'>
          Robot:&nbsp;
            <Link
              to={generateRoverDetailsPath(device.robot_device_id.toString())}
              style={{ color: 'white'}}
            >
            {device.robot_name}
            </Link>
        </Typography>
      }
    </>
  )
}