import axios from "axios";
import { Company } from "./companies";
import { API_BASE } from "./constants";

interface Location {
  city: string;
  country_code: string;
  country_id: number;
  country_name: string;
  id: number;
  state_code: string;
  state_id: number;
  state_name: string;
}

export type ProjectStatus = 'active' | 'hold' | 'done';

export interface Project {
  id: number;
  public_id: string;
  name: string;
  company: Company;
  location: Location;
  is_verified: boolean;
  image_url: string | null;
  forge_model_urn: string;
  rendering_url: string | null;
  white_rendering_url: string | null;
  rendering_vector_url: string | null;
  client_logo_url: string | null;
  latest_data_received_on: string;
  has_floor_transitions: boolean;
  status: ProjectStatus;
}

export interface ProjectWorker {
  user: {
    email: string;
    first_name: string;
    last_name: string;
    public_id: string;
  };
  handles_floor_transitions: boolean;
}

export const projectBase = `${API_BASE}/project`;

export const fetchProjects = async (status?: string): Promise<Project[]> => {
  const res = await axios.get(
    `${API_BASE}/projects`,
    {
      params: {
        status: status,
      }
    }
  );
  const response = await res.data;
  return response.data;
}

export const fetchProject = async (projectId: string): Promise<Project> => {
  const res = await axios.get(
    `${projectBase}/${projectId}`
  )
  const response = await res.data;
  return response.data;
}

export const fetchFloorTransitionUsers = async (projectId: string): Promise<ProjectWorker[]> => {
  const res = await axios.get(
    `${projectBase}/${projectId}/workers?handles_floor_transitions=1`
  );
  const response = await res.data;
  return response.data;
}

interface RequestFloorTransitionData {
  send_to: string[];
  from_floor: number;
  to_floor: number;
}

export const sendFloorTransitionEmail = async (projectId: string, floorTransitionData: RequestFloorTransitionData): Promise<{response_code: number}> => {
  const res = await axios.post(
    `${projectBase}/${projectId}/workers/floor-transition`, floorTransitionData
  );
  const response = await res.data;
  return response.data;
}