import { defaultPointColor, goalGreen, violet } from '../../../../../App';
import { useRoverGoalPoint } from '../../../../../hooks/teleopHooks/useRoverGoalPoint';
import { ProjectSchedulePoint } from '../../../../../api/schedules';
import { MapPoint } from './MapPoint';
import { useMissionManagementContext } from '../../../../../contexts/missionManagementContext';
import { useCallback, useMemo, useRef } from 'react';

interface IManagedMapPointProps {
  point: ProjectSchedulePoint;
  activateArrow: (x: string | number, y: string | number) => void;
  pointerEvents?: React.CSSProperties["pointerEvents"];
}

export const ManagedMapPoint = ({
  point,
  activateArrow,
  pointerEvents,
}: IManagedMapPointProps) => {
  const {
    activeTeleop,
    updateActiveMission,
  } = useMissionManagementContext();

  const goalPointId = useRoverGoalPoint();

  const pictureTaken = activeTeleop && activeTeleop.capturedImages.has(point.point_id);
  const isGoalPoint = point.point_id === goalPointId;
  const pointIsSelected = activeTeleop?.selectedPoint?.point_id === point.point_id;
  const empty = !pictureTaken && !isGoalPoint && !pointIsSelected;

  const color = useMemo(() => {
    if (!pointIsSelected) {
      return pictureTaken ? violet : isGoalPoint ? goalGreen : defaultPointColor;
    }

    return defaultPointColor;
  }, [isGoalPoint, pictureTaken, pointIsSelected]);

  const onClick = useCallback((e: React.MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      activateArrow(point.x, point.y);
    } else {
      updateActiveMission({
        selectedPoint: pointIsSelected ? null : point,
      });
    }
  }, [activateArrow, point, pointIsSelected, updateActiveMission]);

  const onDoubleClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    activateArrow(point.x, point.y);
  }, [activateArrow, point]);

  const onContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();

    updateActiveMission({
      mapPointContextMenuOpen: true,
      mapPointContextMenuPointX: parseInt(point.x.toString()),
      mapPointContextMenuPointY: parseInt(point.y.toString()),
      mapPointContextMenuClickX: e.clientX,
      mapPointContextMenuClickY: e.clientY,
      selectedPoint: point,
    });
  }

  return (
    <MapPoint
      id={point.point_id}
      x={point.x}
      y={point.y}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      color={color}
      empty={empty}
      pointerEvents={pointerEvents}
      onContextMenu={onContextMenu}
    />
  )
}