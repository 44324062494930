import { Card, CardContent, Typography } from "@mui/material"
import { GridItem, StandardGrid } from "../../../common/StandardGrid"

interface IConnectivityCardProps {
  gridItems: GridItem[];
};

export const ConnectivityCard = ({
  gridItems,
}: IConnectivityCardProps) => {
  return (
    <Card
      sx={{
        height: '100%'
      }}
    >
      <CardContent>
        <Typography variant='h5'>
          Connectivity
        </Typography>
        <StandardGrid
          items={gridItems}
        />
      </CardContent>
    </Card>
  )
}