import axios from "axios";
import { useCallback } from "react";
import { useUserContext } from "../contexts/userContext";
import { permissions } from "../api/auth";

export const useHandleDbTokenResponse = () => {
  const { updateUser } = useUserContext();

  return useCallback((user: any) => {
    localStorage.setItem('db_usr', JSON.stringify(user));
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

    permissions(user.public_id).then(data => {
      updateUser({
        ...user,
        token: user.token,
        isAuthenticated: true,
        permissions: data.data,
        loading: false,
      });
    });
  }, [updateUser]);
}