import { useCallback, useState } from "react";
import { RoverSocketOnMessageCallback, useRoverSocketOnMessage } from "./useRoverSocketOnMessage";
import { nodeStoppedEvent } from "../../contexts/teleopContext";

export const UNSAFE_OMEGA_FLAG = 'UNSAFE_OMEGA';
export const UNSAFE_YAW_FLAG = 'UNSAFE_YAW';
export const  UNSAFE_PITCH_FLAG = 'UNSAFE_PITCH';

type PauseFlag = 'PAUSE_COMMANDED' | typeof UNSAFE_OMEGA_FLAG | typeof UNSAFE_YAW_FLAG | typeof UNSAFE_PITCH_FLAG | 'OBSTACLE_DETECTED' | 'PC_YAW' | 'TOF_RANGE' | 'FLIPPER' | 'MISSING_IMU' | 'MISSING_STATUS' | 'MISSING_FRONTPC' | 'MISSING_REARPC' | 'MISSING_FRONT25' | 'MISSING_FRONT39' | 'MISSING_REAR13';
 
export interface StairClimberStatus {
  header: any;
  mode: string;
  direction: string;
  state: string;
  substate: string;
  flag: string;
  pause_flag: PauseFlag;
  turn_direction: string;
  turn_type: string;
  remaining_landings: number | null;
  dist_to_far_edge: number | null;
  curr_flipper_angle: number | null;
  orientation: {
    x: number;
    y: number;
    z: number;
  } | null;
}

export const useRoverStairClimberInformation = () => {
  const [stairClimberInformation, setStairClimberInformation] = useState<StairClimberStatus | null>(null);

  const onReceiveStairClimberMessage: RoverSocketOnMessageCallback = useCallback(({message_type, message_version, message}) => {
    if (message_type === 'stair') {
      if (message_version === 1) {
        setStairClimberInformation(message);
      }
    } else if (message_type === 'manager_event' || message_type === 'task_event' || message_type === 'operation') {
      if (message_version === 1) {
        if(message.event === nodeStoppedEvent && message.details && message.details.node_name === 'go_to_floor') {
          setStairClimberInformation(null);
        }
      }
    }
  }, []);

  const resetStairClimberStatus = useCallback(() => {
    setStairClimberInformation(null);
  }, []);

  useRoverSocketOnMessage(onReceiveStairClimberMessage, resetStairClimberStatus);

  return stairClimberInformation;
}