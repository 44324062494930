import { Frequency } from "./schedules";

const getTimePieces = (time: string): number[] => {
  return time.split(':').map((piece: string) => parseInt(piece));
}

export const convertScheduleDate = (date: string) => {
  const datePieces = date.split('-');

  return new Date(parseInt(datePieces[0]), parseInt(datePieces[1])-1, parseInt(datePieces[2]));
}

export const isWithin24Hours = (earlierDate: Date, laterDate: Date) => {
  const fullDayInMS = 24 * 60 * 60 * 1000;
  const msDifference = laterDate.getTime() - earlierDate.getTime();

  return msDifference <= fullDayInMS
}

export const getNextScanFormatted = (date?: Date) => {
  if (!date) {
    return '';
  }

  const datePart = date.toISOString().slice(0, 10);
  const timePart = date.toLocaleTimeString();

  return `${datePart} at ${timePart}`
}

export const getFinalScan = (frequency: string, start_date: Date | string, till_date: Date | string, time: string) => {
  let convertedTillDate = typeof till_date === 'string' ? convertScheduleDate(till_date) : till_date;
  let convertedStartDate = typeof start_date === 'string' ? convertScheduleDate(start_date) : start_date;

  let finalScan = new Date(convertedTillDate);
  const tillDateDayOfWeek = convertedTillDate.getDay();
  const tillDateDayOfMonth = convertedTillDate.getDate();

  if (frequency === Frequency.weekly) {
    const scanDayOfWeek = convertedStartDate.getDay();

    if (tillDateDayOfWeek !== scanDayOfWeek) {
      const daysBack = scanDayOfWeek < tillDateDayOfWeek ? tillDateDayOfWeek - scanDayOfWeek : tillDateDayOfWeek - scanDayOfWeek + 7;
      finalScan.setDate(finalScan.getDate() - daysBack);
    }
  } else if (frequency === Frequency.monthly) {
    const scanDayOfMonth = convertedStartDate.getDate();

    if (tillDateDayOfMonth !== scanDayOfMonth) {
      finalScan.setDate(scanDayOfMonth);

      if (scanDayOfMonth > tillDateDayOfMonth) {
        finalScan.setMonth(finalScan.getMonth() - 1);
      }
    }
  }

  return finalScan;
}

export const getNextScan = (frequency: string, start_date: Date | string, till_date: Date | string, time: string) => {
  if (!time) {
    return { nextScan: undefined, nextScanFormatted: '', isExpired: false, lessThan24HoursAway: false, }
  }

  const today = new Date();
  today.setSeconds(0, 0);
  const timePieces = getTimePieces(time);

  let convertedTillDate = typeof till_date === 'string' ? convertScheduleDate(till_date) : till_date;
  convertedTillDate.setHours(timePieces[0], timePieces[1], 0);

  //if the till date is in the past, return the last scan and note the scan has expired
  if (convertedTillDate < today) {
    return { 
      nextScan: getFinalScan(frequency, start_date, till_date, time),
      nextScanFormatted: 'expired',
      isExpired: true,
      lessThan24HoursAway: true
    };
  }

  let convertedStartDate = typeof start_date === 'string' ? convertScheduleDate(start_date) : start_date;
  convertedStartDate.setHours(timePieces[0], timePieces[1], 0);
  
  const startDatePast: boolean = convertedStartDate.getTime() < today.getTime();
  
  //today's date with the schedule time
  let nextScan = new Date(today);
  nextScan.setHours(timePieces[0], timePieces[1], 0);

  if (!startDatePast) {
    nextScan = convertedStartDate;
  } else if (frequency === Frequency.weekly) {
    const scheduleDayOfWeek = convertedStartDate.getDay();
    const todaysDayOfWeek = today.getDay();

    const daysFromNow = todaysDayOfWeek <= scheduleDayOfWeek ? scheduleDayOfWeek - todaysDayOfWeek : scheduleDayOfWeek - todaysDayOfWeek + 7;
    nextScan.setDate(nextScan.getDate() + daysFromNow);
    
    if (nextScan.getTime() < today.getTime()) {
      nextScan.setDate(nextScan.getDate() + 7);
    }
  } else if (frequency === Frequency.monthly) {
    const scheduleDayOfMonth = convertedStartDate.getDate();
    const todaysDayOfMonth = today.getDate();

    if (todaysDayOfMonth < scheduleDayOfMonth) {
      nextScan.setDate(scheduleDayOfMonth);
    } else if (todaysDayOfMonth !== scheduleDayOfMonth || nextScan < today) {
      nextScan.setDate(scheduleDayOfMonth);
      nextScan.setMonth(nextScan.getMonth() + 1);
    }
  }

  const nextScanFormatted: string = getNextScanFormatted(nextScan);

  return { 
    nextScan: nextScan,
    nextScanFormatted: nextScanFormatted,
    isExpired: false,
    lessThan24HoursAway: isWithin24Hours(today, nextScan)
  };
}

export const formatLine = (fromX: number, fromY: number, toX: number, toY: number) => {
  const fT = fromY;
  const tT = toY;
  const fL = fromX;
  const tL = toX;

  const CA   = Math.abs(tT - fT);
  const CO   = Math.abs(tL - fL);
  const H    = Math.sqrt(CA*CA + CO*CO);
  let ANG  = 180 / Math.PI * Math.acos( CA/H );
  let top  = (fT-tT)/2 + tT;
  let left = (fL-tL)/2 + tL;

  if(tT > fT){
      top = (tT-fT)/2 + fT;
  } 

  if(tL > fL){
      left = (tL-fL)/2 + fL;
  }

  if(( fT < tT && fL < tL) || ( tT < fT && tL < fL) || (fT > tT && fL > tL) || (tT > fT && tL > fL)){
    ANG *= -1;
  }
  top-= H/2;

  return {
    top,
    left,
    height: H,
    rotation: ANG
  }
};