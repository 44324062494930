import { useCallback, useState } from "react"
import { RoverSocketOnMessageCallback, useRoverSocketOnMessage } from "./useRoverSocketOnMessage";

export type RoverNode = 'web_server' | "pico_top" | "pico_bottom" | "realsense_front";

export interface SensorConfiguration {
  description: string;
	nodes: RoverNode[];
}

export const useRoverSensorConfiguration = (teleopIdentifier?: string) => {
  const [sensorConfiguration, setSensorConfiguration] = useState<SensorConfiguration | null>(null);

  const onReceiveSensorConfigurationMessage: RoverSocketOnMessageCallback = useCallback(({message_type, message_version, message}) => {
    if (message_type === 'sensor_configuration') {
      if (message_version === 1) {
        setSensorConfiguration(message);
      }
    }
  }, []);

  const onResetSensorConfiguration = useCallback(() => {
    setSensorConfiguration(null);
  }, []);

  useRoverSocketOnMessage(onReceiveSensorConfigurationMessage, onResetSensorConfiguration, teleopIdentifier);

  return sensorConfiguration;
}