import { Wifi, SignalCellularAlt, MoreVert } from "@mui/icons-material"
import { Box, Button, Card, CardActions, CardContent, Chip, Grid, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { useRoverBatteryLevel } from "../../../hooks/teleopHooks/useRoverBatteryLevel"
import { PATH_STRINGS, useGeneratedPaths } from "../../../hooks/useGeneratedPaths"
import { useNavigation } from "../../../hooks/useNavigation"
import { Breadcrumb } from "../../common/Breadcrumb"
import { LinearProgressWithLabel } from "../../common/LinearProgressWithLabel"
import { LoadingIndicator } from "../../common/LoadingIndicator"
import { PermissionWrappedComponent } from "../../common/PermissionWrapper"
import { StandardGrid } from "../../common/StandardGrid"
import { HeadCell, Table } from "../../common/Table"
import { RobotSpeedHistory } from "../Network/DeviceDetails/Archive/RobotSpeedHistory"
import { DeviceNotesDrawer } from "../Network/DeviceDetails/DeviceNotesDrawer"
import { SpeedTestModal } from "./SpeedTestModal"
import { LogMessage } from "../Missions/MissionTeleop/RoverInformation/LogsTab/LogsTab"
import { useTeleopContext } from "../../../contexts/teleopContext"
import { useDocumentTitle } from "../../../hooks/useDocumentTitle"
import { useActiveRobotMissions } from "../../../hooks/useActiveRobotMissions"

export const RoverDetails = () => {
  const {
    generateDeviceDetailsPath,
  } = useGeneratedPaths();

  const {
    navigateToEditDevice,
    navigateToMissionControl,
  } = useNavigation();

  const {
    robot,
    robotLoading,
    messages,
  } = useTeleopContext();

  useDocumentTitle(robot?.device.name);

  const {batteryLevel, secondBatteryLevel} = useRoverBatteryLevel();

  const {roverPublicIdMissionMap, isLoading: activeMissionsLoading} = useActiveRobotMissions();

  const dataLoaded = !!robot && !robotLoading && !!roverPublicIdMissionMap && !activeMissionsLoading;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDevice, setSelectedDevice] = useState<null | number>(null);
  const [deviceNotesOpen, setDeviceNotesOpen] = useState<boolean>(false);
  const [speedTestModalOpen, setSpeedTestModalOpen] = useState<boolean>(false);

  const handleDeviceMenuOpen = (event: React.MouseEvent<HTMLElement>, deviceId: number) => {
    setAnchorEl(event.currentTarget);
    setSelectedDevice(deviceId);
  };

  const handleDeviceMenuClose = () => {
    setAnchorEl(null);
    setSelectedDevice(null);
  };

  const onClickMissionButton = (e: React.MouseEvent<any>) => {
    if (dataLoaded) {
      const activeRobotMission = roverPublicIdMissionMap.get(robot.robot_details.public_id);

      if (activeRobotMission) {
        navigateToMissionControl([activeRobotMission.id], []);
      } else {
        navigateToMissionControl([], [robot.device.DeviceID]);
      }
    }
  }

  const columns: readonly HeadCell<any>[] = [
    { id: 'device_name', label: 'Device Name' },
    { id: 'usb_port', label: 'USB Port' },
    { id: 'status', label: 'Status', align: 'center' },
    { id: '', label: '' }
  ];

  const rows: any[] = [];

  const renderVisibleRows = (row: any) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.public_id}
      >
        <TableCell align="left">XXX</TableCell>
        <TableCell align="left">XXX</TableCell>
        <TableCell align="left">
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Chip label='XXX' color='success' sx={{ color: 'white' }}/>
          </Box>
        </TableCell>
        <TableCell align="right">
          <IconButton
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleDeviceMenuOpen(e, row.id)}
          >
            <MoreVert/>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }

  if (!dataLoaded) {
    return <LoadingIndicator/>
  }

  const isCharging = robot.robot_details.is_charging;
  const roverChargingStatus = isCharging ? 'Charging' : 'Not Charging';
  
  const robotSignalSpeedExists = robot.signal_speed !== null && robot.signal_speed !== undefined; 
  const robotSignalSpeedInMbps = robot.signal_speed / 1024;
  const robotSignalSpeed = robotSignalSpeedExists ?  robotSignalSpeedInMbps.toFixed(2) : 'unknown';

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Breadcrumb
              items={[{text: 'Rovers', to: PATH_STRINGS.rovers}, {text: 'Rover Details'}]}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => setDeviceNotesOpen(true)}
              sx={{marginRight: 1}}
            >
              Notes
            </Button>
            <PermissionWrappedComponent permission="device_tables.edit_device">
              <Button
                variant="contained"
                onClick={() => navigateToEditDevice(robot?.device.DeviceID)}
              >
                Edit Robot
              </Button>
            </PermissionWrappedComponent>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Card
          sx={{
            height: '100%'
          }}
        >
          <CardContent>
            <Box
              marginBottom='30px'
            >
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems="center"
              >
                <Typography variant='h5'>
                  {robot.device.name}
                </Typography>
                <Chip
                  label={roverChargingStatus}
                  color={isCharging ? 'success' : 'offline'}
                  sx={{ color: 'white' }}
                />
              </Box>
              <Box
                display='flex'
                justifyContent='space-between'
              >
                <Typography variant='body1' color='text.secondary'>
                  {robot.robot_details.category === 'wheeled' ? 'Rover' : 'Crawler'}
                </Typography>
                <Box
                  flexBasis="33%"
                  marginTop="10px"
                >
                  { batteryLevel !== null &&
                    <LinearProgressWithLabel
                      value={batteryLevel}
                      label="Base"
                      color='success'
                    />
                  }
                  {secondBatteryLevel !== null &&
                    <LinearProgressWithLabel
                      value={secondBatteryLevel}
                      label="Payload"
                      color='success'
                    />
                  }
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
            >
              <Box
                flex={1}
              >
                <Typography variant='body1' color='text.secondary'>
                  Location: {robot.device.location}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  Public ID: {robot.robot_details.public_id}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  IP Address: {robot.device.ip}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  VPN IP Address: {robot.robot_details.vpn_ip_address}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  iPerf Port: {robot.device.iperf_port}
                </Typography>
              </Box>
              <Box
                flex={1}
              >
                <Typography variant='body1' color='text.secondary'>
                  Radio:&nbsp;
                  <Link
                    to={generateDeviceDetailsPath(robot.radio?.device.DeviceID ?? '')}
                    style={{ color: 'white'}}
                  >
                  {robot.radio?.device.name}
                  </Link>
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  Monitor:&nbsp;
                  <Link
                    to={generateDeviceDetailsPath(robot.monitor?.device.DeviceID ?? '')}
                    style={{ color: 'white'}}
                  >
                  {robot.monitor?.device.name}
                  </Link>
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  Software Version: {robot.robot_details.software?.version}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  Hardware Version: {robot.robot_details.hardware?.version}
                </Typography>
              </Box>
            </Box>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={onClickMissionButton}>Mission</Button>
            {!!robot.device.ssh_port &&
              <Button size="small" onClick={() => setSpeedTestModalOpen(true)}>Speed Test</Button>
            }
            {/* <Button size="small" disabled>SSH CONNECT</Button> */}
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card
          sx={{
            height: '100%'
          }}
        >
          <CardContent>
            <Typography variant='h5'>
              Connectivity
            </Typography>
            <StandardGrid
              items={[
                {icon: <Wifi/>, label: 'Speed', info: `${robotSignalSpeed} mbps`},
                {icon: <SignalCellularAlt/>, label: 'Signal Strength', info: `${robot.signal_strength ?? 'unknown'} dB`}
              ]}
            />
            <Typography style={{fontSize: "0.8em", marginTop: "1em"}}>
              Last Update: {new Date(robot.last_modified_on).toDateString()} {new Date(robot.last_modified_on).toTimeString()}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Card
          sx={{
            height: '100%'
          }}
        >
          <CardContent>
            <Typography variant='h5'>
              Devices
            </Typography>
            <Table
              columns={columns}
              rows={rows}
              initialOrderedColumnName='status'
              renderVisibleRows={renderVisibleRows}
            />
            <Menu
              id="rover-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleDeviceMenuClose}
              sx={{ left: '120px', top: '-25px' }}
            >
              <MenuItem
                onClick={() => alert(`POWER CYCLE DEVICE: ${selectedDevice}`)}
              >
                Power Cycle
              </MenuItem>
              <MenuItem
                onClick={() => alert(`VIEW LOGS DEVICE: ${selectedDevice}`)}
              >
                View Logs
              </MenuItem>
            </Menu>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card
          sx={{
            height: '100%'
          }}
        >
          <CardContent>
            <Typography variant='h5' sx={{ marginBottom: '10px' }}>
              Log Output
            </Typography>
            <LogOutput>
              { messages.map((message: string, i: number) => {
                const parsedMessage: LogMessage = JSON.parse(message);

                return (
                  <div
                    key={i}
                  >
                    &gt; Event: {parsedMessage.event}
                    <div>Source: {parsedMessage.source}</div>
                    { parsedMessage.details &&
                      Object.getOwnPropertyNames(parsedMessage.details).map((propName: string) => {
                        return <div>{`${propName}: ${parsedMessage.details[propName]}`}</div>
                      })
                    }
                    <br/>
                  </div>
                )
              })}
            </LogOutput>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card
          sx={{mb: 2}}
        >
          <CardContent>
            <Typography variant='h5'>
              Speed History <span style={{fontSize: "0.5em"}}>last 24hrs</span>
            </Typography>
            <RobotSpeedHistory
              deviceId={robot.device.DeviceID}
            />
          </CardContent>
        </Card>
      </Grid>

      <DeviceNotesDrawer
        open={deviceNotesOpen}
        onClose={() => setDeviceNotesOpen(false)}
        deviceId={robot.device.DeviceID}
      />
      {!!robot.device.ssh_port &&
        <SpeedTestModal
          robotName={robot.device.name}
          robotSSHPort={robot.device.ssh_port}
          open={speedTestModalOpen}
          onClose={() => setSpeedTestModalOpen(false)}
        />
      }
    </Grid>
  )
}

const LogOutput = styled.div`
  height: 100%;
  max-height: 500px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  background-color: black;
  color: green;
`;
