import { Close } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Chip, CircularProgress, Grid, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react"
import { useMatch } from "react-router-dom";
import { fetchRobotMissions, Mission, ScheduleMission, endMission } from "../../../api/missions";
import { Robot } from "../../../api/robots";
import { useScheduleMissionsQuery, useScheduleQuery } from "../../../hooks/queries";
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths";
import { useNavigation } from "../../../hooks/useNavigation";
import { Breadcrumb } from "../../common/Breadcrumb";
import { RobotsDropdown } from "../../common/Dropdowns";
import { FullScreenModal, ModalTitle } from "../../common/FullScreenModal";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { PermissionWrappedComponent } from "../../common/PermissionWrapper";
import { HeadCell, Table } from "../../common/Table";
import { missionStatusColors } from "../Dashboard/MissionsTable";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

enum ModalResponseTypes {
  CLOSE,
  USE_CURRENT_MISSION,
  END_CURRENT_MISSION,
}

let activeRobotMissionsPromise: Promise<Mission[]> | undefined;
let isTestMission = false;

export const ScheduleDetails = () => {
  const {
    navigateToMissionControl,
  } = useNavigation();

  const scheduleMatch = useMatch(PATH_STRINGS.scheduleDetails);
  const scheduleId: string = scheduleMatch?.params.scheduleId || '';

  const [selectedRobot, setSelectedRobot] = useState<Robot | null>(null);
  const [missionInProgressModalOpen, setMissionInProgressModalOpen] = useState<boolean>(false);
  const [robotMissions, setRobotMissions] = useState<Mission[]>([]);
  const [robotMissionsLoading, setRobotMissionsLoading] = useState<boolean>(false);
  const [missionUpdateInProgress, setMissionUpdateInProgress] = useState<boolean>(false);

  const { data: schedule, isLoading: scheduleLoading } = useScheduleQuery(scheduleId);
  const { data: missions, isLoading: missionsLoading } = useScheduleMissionsQuery(scheduleId);

  useDocumentTitle(schedule?.name);
  
  const dataLoaded = !scheduleLoading && !!schedule && !missionsLoading && !!missions;
  const startMissionDisabled = !selectedRobot?.public_id;

  useEffect(() => {
    if (selectedRobot && selectedRobot.public_id) {
      activeRobotMissionsPromise = fetchRobotMissions(selectedRobot.public_id, 'In Progress');
    }
  }, [selectedRobot]);

  const columns: readonly HeadCell<ScheduleMission>[] = [
    { id: 'start_time', label: 'Date' },
    { id: 'num_photos', label: '# Photos' },
    { id: 'status', label: 'Status', align: 'center' },
  ];

  const handleClick = (event: React.MouseEvent<unknown>, row: ScheduleMission) => {
    navigateToMissionControl([row.mission_id], []);
  };

  const renderVisibleRows = (row: ScheduleMission) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        onClick={e => handleClick(e, row)}
      >
        <TableCell align="left">{row.start_time}</TableCell>
        <TableCell align="left">{row.num_photos}</TableCell>
        <TableCell align="left">
          {row.status &&
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Chip label={row.status} color={missionStatusColors[row.status]} sx={{ color: 'white' }}/>
            </Box>
          }
        </TableCell>
      </TableRow>
    );
  }

  const startMission = (isTest: boolean) => {
    if (selectedRobot && schedule) {
      navigateToMissionControl([], [selectedRobot.device_id], schedule.id, isTest);
    } 
  }

  const onClickStartMission = (isTest: boolean) => {
    if (dataLoaded) {
      isTestMission = isTest;

      if (activeRobotMissionsPromise && !isTest) {
        setRobotMissionsLoading(true);

        activeRobotMissionsPromise
          .then(activeRobotMissions => {
            if (activeRobotMissions.length === 0) {
              startMission(isTestMission);
            } else {
              setRobotMissions(activeRobotMissions);
              setMissionInProgressModalOpen(true);
            }
          })
          .finally(() => setRobotMissionsLoading(false));
      } else {
        startMission(isTestMission);
      }
    }
  }

  const onReceiveModalResponse = async (response: ModalResponseTypes) => {
    const currentMissionId = robotMissions[0].id;

    if (response === ModalResponseTypes.USE_CURRENT_MISSION) {
      navigateToMissionControl([currentMissionId], []);
    } else if (response === ModalResponseTypes.END_CURRENT_MISSION) {
      setMissionUpdateInProgress(true);
      
      await endMission(currentMissionId);

      startMission(false);
    } else if (response === ModalResponseTypes.CLOSE) {
      setMissionInProgressModalOpen(false);
    }
  }

  const robotDropdownOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, dropdownIsOpen: boolean) => {
    if (e.key === 'Enter' && !dropdownIsOpen) {
      onClickStartMission(false);
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Breadcrumb
          items={[{text: 'Schedules', to: PATH_STRINGS.schedules}, {text: 'Schedule Details'}]}
        />
      </Grid>
      { !dataLoaded &&
        <Grid item>
          <LoadingIndicator/>
        </Grid>
      }
      { dataLoaded &&
        <>
          <Grid item xs={12} lg={8}>
            <Card
              sx={{
                height: '100%'
              }}
            >
              <CardContent>
                <Box
                  marginBottom='30px'
                >
                  <Box
                    display='flex'
                    justifyContent='space-between'
                  >
                    <Typography variant='h5'>
                      {schedule.name}
                    </Typography>
                    <Chip
                      label={schedule.next_scan_expired ? 'Expired' : 'Active'}
                      color={schedule.next_scan_expired ? 'offline' : 'success'}
                      sx={{ color: 'white' }}
                    />
                  </Box>
                  <Typography variant='body1' color='text.secondary'>
                    {`${schedule.frequency[0].toUpperCase()}${schedule.frequency.slice(1).toLowerCase()}`}
                  </Typography>
                </Box>
                <Typography variant='body1' color='text.secondary'>
                  Location: {schedule.project_name}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  ID: {schedule.id}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  Assigned Rover:
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  Points: {schedule.num_points}
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  Floors: {schedule.schedule_floors.join(', ')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card
              sx={{
                height: '100%',
              }}
            >
              <CardContent>
                <RobotsDropdown
                  selectedRobot={selectedRobot}
                  setSelectedRobot={setSelectedRobot}
                  onKeyDown={robotDropdownOnKeyDown}
                />
                <Box
                  marginTop='15px'
                  display='flex'
                  gap='10px'
                  alignItems='center'
                >
                  <Button
                    variant="contained"
                    onClick={() => onClickStartMission(false)}
                    disabled={startMissionDisabled}
                  >
                    Start Mission
                  </Button>
                  <Button
                    onClick={() => onClickStartMission(true)}
                    disabled={startMissionDisabled}
                  >
                    Test Mission
                  </Button>

                  {robotMissionsLoading &&
                    <CircularProgress size={25}/>
                  }
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card
              sx={{
                height: '100%'
              }}
            >
              <CardContent>
                <Typography variant='h5'>
                  Recent Missions
                </Typography>
                <Table
                  columns={columns}
                  rows={missions}
                  renderVisibleRows={renderVisibleRows}
                  initialOrderedColumnName='start_time'
                  initialOrder="desc"
                />
              </CardContent>
            </Card>
          </Grid>
      </>
    }
    {selectedRobot &&
      <MissionsInProgressModal
        open={missionInProgressModalOpen}
        setOpen={setMissionInProgressModalOpen}
        robotMissions={robotMissions}
        onReceiveResponse={onReceiveModalResponse}
        missionUpdateInProgress={missionUpdateInProgress}
        selectedRobot={selectedRobot}
      />
    }
    </Grid>
  )
}

interface IMissionsInProgressModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  robotMissions: Mission[];
  onReceiveResponse: (response: ModalResponseTypes) => void;
  missionUpdateInProgress: boolean;
  selectedRobot: Robot;
}

const MissionsInProgressModal = ({
  open,
  setOpen,
  robotMissions,
  onReceiveResponse,
  missionUpdateInProgress,
  selectedRobot,
}: IMissionsInProgressModalProps) => {
  const modalTitle = `Mission${robotMissions.length !== 1 ? 's' : ''} In Progress`;
  
  return (
    <FullScreenModal
      open={open}
      onClose={() => setOpen(false)}
      sx={{width: 600}}
    >
      <IconButton
        sx={{
          position: 'absolute',
          right: 0,
          top: 0
        }}
        onClick={() => onReceiveResponse(ModalResponseTypes.CLOSE)}
      >
        <Close/>
      </IconButton>
      <ModalTitle>
        {modalTitle}
      </ModalTitle>
      <Box id="modal-modal-description" sx={{ mt: 2, fontSize: '18px' }}>
        <Typography mb={2}>Robot: <b>{selectedRobot.name}</b> has the following missions in progress: </Typography>

        {robotMissions.map(mission => {
          return (
            <Box mb={3} key={mission.id}>
              <Typography><b>Id:</b> {mission.id}</Typography>
              <Typography><b>Supervisor:</b> {`${mission.supervisor.last_name}, ${mission.supervisor.first_name}`}</Typography>
              <Typography><b>Schedule:</b> {`${mission.project_name} ${mission.schedule_name}`}</Typography>
              <Typography><b>Start Time:</b> {mission.start_time_formatted}</Typography>
            </Box>
          )
        })}
      </Box>
      <Box sx={{mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px'}}>
        <Button
          disabled={missionUpdateInProgress}
          variant='contained'
          onClick={() => onReceiveResponse(ModalResponseTypes.USE_CURRENT_MISSION)}
        >
          Use Current Mission
        </Button>
        <PermissionWrappedComponent
          permission="mission_tables.edit_mission"
        >
          <Button
            disabled={missionUpdateInProgress}
            variant='contained'
            onClick={() => onReceiveResponse(ModalResponseTypes.END_CURRENT_MISSION)}
          >
            End Current Mission
          </Button>
        </PermissionWrappedComponent>
      </Box>
    </FullScreenModal>
  )
}