import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { introspect, validate, permissions } from '../api/auth';
import axios, { AxiosError } from 'axios';
import { useNavigation } from '../hooks/useNavigation';

let loginExpiredNotificationSent = false;

const initialState = {
  email: null,
  token: null,
  firstName: null,
  last_name: null,
  public_id: null,
  isAuthenticated: false,
  permissions: null,
  loading: true,
};

const UserContext = createContext<any>(null);

const UPDATE_USER = 'UPDATE_USER';
const RESET_USER = 'RESET_USER';
const LOGIN_USER = 'LOGIN_USER';

const userReducer = (state: any, action: any) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload.item,
      };
    case RESET_USER:
      let reset = { ...initialState, loading: false };
      return reset;
    default:
      return state;
  }
};

export const UserProvider = ({ children }: any) => {
  const {
    navigateToLogin
  } = useNavigation();

  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [state, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(response => {
      return response;
    }, (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        if (!loginExpiredNotificationSent) {
          loginExpiredNotificationSent = true;
        }

        dispatch({
          type: 'UPDATE_USER',
          payload: { item: { token: null, isAuthenticated: false, loading: false } },
        });
      }
      
      return Promise.reject(error);
    });

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    }
  }, []);

  const setUserToNotAuthenticated = () => {
    dispatch({
      type: 'UPDATE_USER',
      payload: { item: { token: null, isAuthenticated: false, loading: false } },
    });
  }

  const unAuthenticateAndRemoveToken = () => {
    localStorage.removeItem('db_usr');
    setUserToNotAuthenticated();
    navigateToLogin();
  }

  const checkSetToken = () => {
    let existingUser = localStorage.getItem('db_usr');
    const parsedUser = existingUser ? JSON.parse(existingUser) : null;
    const existingToken = parsedUser ? parsedUser.token : null;

    if (existingToken) {
      validate(existingToken).then(valid => {
        if (valid && existingToken) {
          introspect(existingToken).then(user => {
            permissions(user.public_id).then(data => {
              dispatch({
                type: 'LOGIN_USER',
                payload: {
                  ...user,
                  token: existingToken,
                  isAuthenticated: true,
                  permissions: data.data,
                  loading: false,
                },
              });
            });
          });
        } else {
          unAuthenticateAndRemoveToken();
        }
      }).catch(() => {
        unAuthenticateAndRemoveToken();
      });
    } else {
      setUserToNotAuthenticated();
    }
  };

  useEffect(() => {
    if (state.isAuthenticated) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      if (window.location.pathname.includes('login') || window.location.pathname.includes('redirect')) {
        //@ts-ignore
        let nextPath = locationState?.from?.pathname;
        if (nextPath) {
          //@ts-ignore
          navigate(locationState.from);
        } else {
          navigate('/');
        }
      }
    }
    //@ts-ignore
  }, [state]);

  useEffect(() => {
    checkSetToken();
  }, []);

  return <UserContext.Provider value={[state, dispatch]}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const [state, dispatch] = useContext(UserContext);

  const updateUser = (item: any) => {
    dispatch({
      type: UPDATE_USER,
      payload: {
        item,
      },
    });
  };

  const resetUser = () => {
    dispatch({
      type: RESET_USER,
    });
  };

  return {
    updateUser,
    resetUser,
    state,
  };
};
