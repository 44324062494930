import { useCallback, useMemo } from 'react';
import { Box, FormControlLabel, Slider, Switch, Typography } from '@mui/material';
import { useMissionManagementContext } from '../../../../../../contexts/missionManagementContext';
import { CopilotClass } from '../../../../../../contexts/copilotContext';

export const CopilotTab = () => {
  const {
    activeTeleop,
    updateActiveMission
  } = useMissionManagementContext();

  const {
    robot,
    activateCopilot,
    deactivateCopilot,
    copilotActive,
    fillCopilotPolygons,
    copilotPolygonOpacity,
    activeCopilotClasses,
    copilotClassColors,
  } = activeTeleop || {};

  const onChangeClassToggle = useCallback((key: number, checked: boolean) => {
    const updatedactiveCopilotClasses = new Set(activeCopilotClasses);

    if (checked) {
      updatedactiveCopilotClasses.add(key);
    } else {
      updatedactiveCopilotClasses.delete(key);
    }

    updateActiveMission({activeCopilotClasses: updatedactiveCopilotClasses});
  }, [activeCopilotClasses, updateActiveMission]);

  const formatClassLabel = useCallback((label: string) => {
    const labelComponents = label.split('_');
    const formattedComponents = labelComponents.map(component => {
      return component.charAt(0).toUpperCase() + component.slice(1).toLowerCase();
    });

    return formattedComponents.join(' ');
  }, []);

  const onChangeCopilotClassColor = useCallback((copilotClass: CopilotClass, newColor: string) => {
    updateActiveMission({
      copilotClassColors: {
        ...copilotClassColors as Record<CopilotClass, string>,
        [copilotClass]: newColor,
      }
    })
  }, [copilotClassColors, updateActiveMission]);

  const CopilotClassToggles = useMemo(() => {
    return Object.getOwnPropertyNames(CopilotClass)
      .filter(propName => !isNaN(parseInt(propName)))
      .map(propName => {
        const key: CopilotClass = parseInt(propName);
        let copilotClassLabel = CopilotClass[key];
        const toggleLabel = formatClassLabel(copilotClassLabel);

        const checked = activeCopilotClasses?.has(key);

        return (
          <Box
            display="flex"
            gap="10px"
            alignItems="center"
            key={key}
          >
            <FormControlLabel
              control={(
                <Switch
                  checked={checked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>  onChangeClassToggle(key, checked)}
                />
              )}
              label={toggleLabel}
            />
            {!!copilotClassColors &&
              <input
                type="color"
                value={copilotClassColors[key]}
                onChange={e => onChangeCopilotClassColor(key, e.target.value)}
              />
            }
          </Box>
        )
      }
    );
  }, [activeCopilotClasses, copilotClassColors, formatClassLabel, onChangeClassToggle, onChangeCopilotClassColor]);

  if (!robot) {
    return <></>
  }

  const copilotSwitchOnChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (activateCopilot && deactivateCopilot) {
      if (checked) {
        activateCopilot();
      } else {
        deactivateCopilot();
      }
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="10px"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="10px"
      >
        <FormControlLabel
          control={(
            <Switch
              checked={copilotActive}
              onChange={copilotSwitchOnChange}
            />
          )}
          label='Active'
        />
        {!!copilotActive &&
          <>
            <FormControlLabel
              control={(
                <Switch
                  checked={fillCopilotPolygons}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>  updateActiveMission({fillCopilotPolygons: checked})}
                />
              )}
              label='Solid'
            />
          </>
        }
        {(copilotActive && fillCopilotPolygons) &&
          <Box>
            <Typography>Opacity</Typography>
            <Slider
              value={copilotPolygonOpacity}
              onChange={(e, value) => updateActiveMission({copilotPolygonOpacity: value as number})}
              step={0.1}
              marks
              min={0}
              max={1}
              sx={{
                width: '33%',
                minWidth: '100px'
              }}
            />
          </Box>
        }
      </Box>
      {!!copilotActive &&
        <Box>
          <Typography>Displayed Classes:</Typography>
          <Box
            display="flex"
            flexDirection="column"
          >
            {CopilotClassToggles}
          </Box>
        </Box>
      }
    </Box>
  );
}