import { useCallback, useMemo, useState } from "react";
import { RoverSocketOnMessageCallback, useRoverSocketOnMessage } from "./useRoverSocketOnMessage";
import { useMissionManagementContext } from "../../contexts/missionManagementContext";

export const getMapCoordinates = (div: HTMLDivElement, scale: number, x: number, y: number) => {
  const offsetX = div.clientWidth/2;
  const offsetY = div.clientHeight/2;

  return {
    x: -x * scale + offsetX,
    y: -y * scale + offsetY,
  }
}

export const useRoverPose = (mapContainerRef?: React.MutableRefObject<HTMLDivElement | null>, scale?: number, identifier?: string) => {
  const [roverX, setRoverX] = useState<number>(0);
  const [roverY, setRoverY] = useState<number>(0);
  const [roverAngle, setRoverAngle] = useState<number>(0);
  const [mapX, setMapX] = useState<number>(0);
  const [mapY, setMapY] = useState<number>(0);

  const {
    activeTeleop,
    missions,
  } = useMissionManagementContext();

  const tracking = useMemo(() => {
    if (identifier) {
      return missions[identifier].tracking;
    } else {
      return !!activeTeleop?.tracking;
    }
  }, [activeTeleop?.tracking, identifier, missions]);

  const onReceivePoseMessage: RoverSocketOnMessageCallback = useCallback(({message_type, message_version, message}) => {
    if (message_type === 'pose') {
      if (message_version === 1) {
        const x = message.x;
        const y = message.y;

        setRoverX(x - 24);
        setRoverY(y - 24);
        setRoverAngle(message.angle % 360);

        if (mapContainerRef && mapContainerRef.current && tracking && scale !== undefined) {
          const panzoomParent = mapContainerRef.current;

          const {x: mapXFromRover, y: mapYFromRover} = getMapCoordinates(panzoomParent, scale, x, y);

          setMapX(mapXFromRover);
          setMapY(mapYFromRover);
        }
      }
    }
  }, [mapContainerRef, scale, tracking]);

  const resetPose = useCallback(() => {
    setRoverX(0)
    setRoverY(0)
    setRoverAngle(0)
    setMapX(0)
    setMapY(0)
  }, []);
  
  useRoverSocketOnMessage(onReceivePoseMessage, resetPose, identifier);

  return {
    roverX,
    roverY,
    roverAngle,
    mapX,
    setMapX,
    mapY,
    setMapY,
  };
}