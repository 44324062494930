import { useRobotHistoryQuery } from "../../../../../hooks/queries";
import { SpeedHistory } from "./SpeedHistory";

interface IRobotSpeedHistoryProps {
  deviceId: string;
}

export const RobotSpeedHistory = ({
  deviceId,
}: IRobotSpeedHistoryProps) => {
  const {data: robotHistory, isLoading: robotLoading} = useRobotHistoryQuery(deviceId);

  return (
    <SpeedHistory
      data={robotHistory}
      dataLoading={robotLoading}
    />
  )
}