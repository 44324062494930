import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { RaspberryPi } from "../../../../api/device";
import { useDeviceDetailsContext } from "../../../../contexts/deviceDetailsContext"
import { useGeneratedPaths } from "../../../../hooks/useGeneratedPaths";

export const RaspberryPiDetails = () => {
  const {
    generateDeviceDetailsPath,
  } = useGeneratedPaths();

  const {device, deviceLoaded} = useDeviceDetailsContext() as {device: RaspberryPi, deviceLoaded: boolean}
  const dataLoaded = !!device && deviceLoaded;

  if (!dataLoaded) {
    return <></>
  }

  return (
    <>
      <Typography variant='body1' color='text.secondary'>
        Model: {device.model}
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        Gateway:&nbsp;
        {!!device.gateway_device_id &&
          <Link
            to={generateDeviceDetailsPath(device.gateway_device_id.toString())}
            style={{ color: 'white'}}
          >
          {device.gateway_name}
          </Link>
        }
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        Gateway IP: {device.gateway_ip}
      </Typography>
    </>
  )
}