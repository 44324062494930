import { useCallback } from "react";
import { useNavigate } from "react-router-dom"
import { PATH_STRINGS, useGeneratedPaths } from "./useGeneratedPaths";

export const useNavigation = () => {
  const {
    generateScheduleDetailsPath,
    generateRoversPath,
    generateRoverDetailsPath,
    generateDeviceDetailsPath,
    generateManageDevicePath,
    generateMissionControlPath,
    generateProjectDetailsPath,
  } = useGeneratedPaths();

  const navigate = useNavigate();

  const navigateToHome = useCallback(
    () => navigate('/'),
    [navigate]
  )

  const navigateToLogin = useCallback(
    (errorMessage?: string) => navigate(PATH_STRINGS.login, {
      state: {
        errorMessage: errorMessage
      }
    }),
    [navigate]
  );

  const navigateToScheduleDetails = useCallback(
    (scheduleId: string | number) => navigate(generateScheduleDetailsPath(scheduleId)),
    [navigate, generateScheduleDetailsPath]
  );

  const navigateToRovers = useCallback(
    (params?: Record<string, string>) => navigate(generateRoversPath(params)),
    [generateRoversPath, navigate]
  )

  const navigateToRoverDetails = useCallback(
    (deviceId: string | number) => navigate(generateRoverDetailsPath(deviceId)),
    [navigate, generateRoverDetailsPath]
  );

  const navigateToMyMissions = useCallback(
    () => navigate(PATH_STRINGS.myMissions),
    [navigate]
  );

  const navigateToAllMissions = useCallback(
    () => navigate(PATH_STRINGS.allMissions),
    [navigate]
  );

  const navigateToCreateNewDevice = useCallback(
    () => navigate(generateManageDevicePath('new')),
    [navigate, generateManageDevicePath],
  );

  const navigateToEditDevice = useCallback(
    (deviceId: string) => navigate(generateManageDevicePath(deviceId)),
    [navigate, generateManageDevicePath],
  );

  const navigateToDeviceDetails = useCallback(
      (deviceId: string) => navigate(generateDeviceDetailsPath(deviceId)),
      [navigate, generateDeviceDetailsPath]
  );

  const navigateToMissionControl = useCallback(
    (
      initialMissionIds: (string | number)[],
      initialDeviceIds: (string | number)[],
      schedule_id?: string | number,
      isTest: boolean = false
    ) => {
      return navigate(
        generateMissionControlPath(initialMissionIds, initialDeviceIds, isTest),
        {
          state: {
            schedule_id: schedule_id,
          }
        }
      );
    },
    [navigate, generateMissionControlPath],
  );

  const navigateToProjectDetails = useCallback(
    (projectPublicId: string, params?: Record<string, string>) => navigate(generateProjectDetailsPath(projectPublicId, params)),
    [generateProjectDetailsPath, navigate]
  );

  return {
    navigateToHome,
    navigateToLogin,
    navigateToScheduleDetails,
    navigateToRovers,
    navigateToRoverDetails,
    navigateToMyMissions,
    navigateToAllMissions,
    navigateToCreateNewDevice,
    navigateToEditDevice,
    navigateToDeviceDetails,
    navigateToMissionControl,
    navigateToProjectDetails,
  };
}