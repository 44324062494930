import { Alert, Box, Button, Autocomplete, TextField, CircularProgress, FormGroup, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { FullScreenModal, ModalTitle } from "../../../../../common/FullScreenModal";
import { FloorTransitionUsersDropdown } from "../../../../../common/Dropdowns";
import { useCallback, useEffect, useState } from "react";
import { ProjectWorker, sendFloorTransitionEmail } from "../../../../../../api/projects";
import { ProjectFloor } from "../../../../../../api/projectFloors";
import { activateFloorTransitionScreen, viewRobotScreen } from "../../../../../../api/robots";
import { LoadingIndicator } from "../../../../../common/LoadingIndicator";
import { IAlert } from "../../../../../../types/types";
import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";

export enum FloorTransitionModalType {
  sendMessage,
  checkScreen,
}

interface IFloorTransitionModalProps {
  open: boolean;
  onClose: () => void;
  type: FloorTransitionModalType | null;
}

export const FloorTransitionModal = ({
  open,
  onClose,
  type,
}: IFloorTransitionModalProps) => {
  const isCheckScreenModal = type === FloorTransitionModalType.checkScreen;
  const isSendMessageModal = type === FloorTransitionModalType.sendMessage;

  return (
    <FullScreenModal
      open={open}
      onClose={onClose}
      sx={{
        width: isCheckScreenModal ? 'fit-content' : 500,
        paddingBottom: isCheckScreenModal ? '10px' : undefined,
      }}
    >
      {isSendMessageModal &&
        <SendMessageModalContent onClose={onClose}/>
      }
      {isCheckScreenModal &&
        <CheckScreenModalContent onClose={onClose}/>
      }
    </FullScreenModal>
  )
}

interface SendMessageModalContentProps {
  onClose: () => void;
}

const SendMessageModalContent = ({
  onClose,
}: SendMessageModalContentProps) => {
  const {
    activeTeleop
  } = useMissionManagementContext();

  const {
    floors,
    selectedFloor,
    robot,
    project,
  } = activeTeleop || {};

  const [selectedProjectWorkers, setSelectedProjectWorkers] = useState<ProjectWorker[]>([]);
  const [fromFloor, setFromFloor] = useState<ProjectFloor | null | undefined>(selectedFloor);
  const [toFloor, setToFloor] = useState<ProjectFloor | null>(null);
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false);
  const [displayRequestOnRobot, setDisplayRequestOnRobot] = useState<boolean>(true);
  const [emailUsersAlert, setEmailUsersAlert] = useState<IAlert | undefined>();
  const [robotDisplayAlert, setRobotDisplayAlert] = useState<IAlert | undefined>();

  const validForSubmit = !!fromFloor && !!toFloor && !!project;
  const robotSSHPort = robot?.device.ssh_port;

  const resetFloorTransitionModal = () => {
    setSelectedProjectWorkers([]);
    setFromFloor(null);
    setToFloor(null);
  }

  const sendEmailUsersRequest = () => {
    if (validForSubmit && selectedProjectWorkers.length > 0) {
      const floorTransitionData = {
        from_floor: fromFloor.id,
        to_floor: toFloor.id,
        send_to: selectedProjectWorkers.map(worker => worker.user.public_id),
      }

      return sendFloorTransitionEmail(project.public_id, floorTransitionData)
              .then(() => {
                setEmailUsersAlert({severity: 'success', message: 'Users Emailed Successfully'});
              })
              .catch(() => {
                setEmailUsersAlert({severity: 'error', message: 'Error emailing users'});
              });
    }
  }

  const sendDisplayFloorTransitionRequest = () => {
    if (validForSubmit && displayRequestOnRobot && robotSSHPort) {
      const theme = project?.company.name.toLowerCase();

      return activateFloorTransitionScreen(robotSSHPort, toFloor.floor_code, theme)
              .then(() => {
                setRobotDisplayAlert({severity: 'success', message: 'Message displaying on robot'});
              })
              .catch(() => {
                setRobotDisplayAlert({severity: 'error', message: 'Error displaying message on robot'});
              });
    }
  }

  const onSendFloorTransitionRequest = async () => {
    setRequestInProgress(true);
    setEmailUsersAlert(undefined);
    setRobotDisplayAlert(undefined);

    if (validForSubmit) {
      const emailUsersPromise = sendEmailUsersRequest();
      const sendDisplayFloorTransitionRequestPromise = sendDisplayFloorTransitionRequest();

      Promise.allSettled([emailUsersPromise, sendDisplayFloorTransitionRequestPromise])
        .then(settledPromises => {
          const allSucceeded = settledPromises.every(settledPromise => settledPromise.status === 'fulfilled');

          if (allSucceeded) {
            setTimeout(() => {
              resetFloorTransitionModal();
              onClose();
            }, 2000);
          }
        })
        .finally(() => {
          setRequestInProgress(false);
        });
      }
  }

  if (!project) {
    return <LoadingIndicator/>
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <ModalTitle>Floor Transition</ModalTitle>
      <FloorTransitionUsersDropdown
        selectedProjectWorkers={selectedProjectWorkers}
        onChangeSelectedProjectWorkers={setSelectedProjectWorkers}
        projectId={project.public_id}
      />
      <Autocomplete
        options={floors ?? []}
        value={fromFloor}
        loading={!floors}
        getOptionLabel={option => option.name}
        onChange={(e: React.SyntheticEvent, value: ProjectFloor | null) => setFromFloor(value)}
        renderInput={(params) => {
          return (
            <TextField {...params} label="From Floor" required />
          )
        }}
      />
      <Autocomplete
        options={floors ?? []}
        value={toFloor}
        loading={!floors}
        getOptionLabel={option => option.name}
        onChange={(e: React.SyntheticEvent, value: ProjectFloor | null) => setToFloor(value)}
        renderInput={(params) => {
          return (
            <TextField {...params} label="To Floor" required />
          )
        }}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={displayRequestOnRobot}
              onChange={(e, checked) => setDisplayRequestOnRobot(checked)}
            />
          }
          label="Display Request on Robot"
        />
      </FormGroup>
      {emailUsersAlert &&
        <Alert severity={emailUsersAlert.severity}  variant="filled" onClose={() => setEmailUsersAlert(undefined)}>
          {emailUsersAlert.message}
        </Alert>
      }
      {robotDisplayAlert &&
        <Alert severity={robotDisplayAlert.severity}  variant="filled" onClose={() => setRobotDisplayAlert(undefined)}>
          {robotDisplayAlert.message}
        </Alert>
      }
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap="10px"
      >
        <Button
          onClick={onClose}
        >
          Close
        </Button>
        {!requestInProgress &&
          <Button
            disabled={!validForSubmit}
            variant='contained'
            onClick={onSendFloorTransitionRequest}
          >
            Send Floor Transition Request
          </Button>
        }
        {requestInProgress &&
          <Box
            width="273px"
            display="flex"
            justifyContent="center"
          >
            <CircularProgress size={20}/>
          </Box>
        }
      </Box>
    </Box>
  )
}

interface CheckScreenModalContentProps {
  onClose: () => void;
}

const CheckScreenModalContent = ({
  onClose,
}: CheckScreenModalContentProps) => {
  const {
    activeTeleop
  } = useMissionManagementContext();

  const [screenImageUrl, setScreenImageUrl] = useState<string>('');
  const [screenImageLoaded, setScreenImageLoaded] = useState<boolean>(false);
  const [screenImageError, setScreenImageError] = useState<boolean>(false);

  const displayLoadingIndicator = !screenImageLoaded && !screenImageError;
  const displayScreenImage = screenImageLoaded && !screenImageError;

  const robotSSHPort = activeTeleop?.robot?.device.ssh_port;

  const imageHeight = 500;
  const imageWidth = imageHeight * 4 / 3;

  const fetchScreenImage = useCallback(() => {
    if (robotSSHPort) {
      setScreenImageLoaded(false);
      setScreenImageError(false);

      viewRobotScreen(robotSSHPort).then(screenImage => {
        const imageUrl = URL.createObjectURL(screenImage);
  
        setScreenImageUrl(imageUrl);
      }).catch(err => {
        setScreenImageUrl('');
        setScreenImageError(true);
      }).finally(() => {
        setScreenImageLoaded(true);
      });
    }
  }, [robotSSHPort]);

  useEffect(() => {
    fetchScreenImage();
  }, [fetchScreenImage]);
  
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="10px"
      minWidth={`${imageWidth}px`}
    >
      {(!screenImageLoaded || screenImageError) &&
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={`${imageHeight}px`}
        >
          {displayLoadingIndicator &&
            <LoadingIndicator/>
          }
          {screenImageError &&
            <Typography>Error Loading Robot Screen Image</Typography>
          }
        </Box>
      }
      {displayScreenImage &&
        <img
          src={screenImageUrl}
          alt="Robot Screen"
          style={{
            height: `${imageHeight}px`,
            width: 'auto',
          }}
        />
      }
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap="10px"
      >
        <Button
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          disabled={displayLoadingIndicator}
          variant="contained"
          onClick={fetchScreenImage}
        >
          Refresh
        </Button>
      </Box>
    </Box>
  )
}