import { Grid } from "@mui/material"
import React from "react";
import { Link } from "react-router-dom";

export interface GridItem {
  icon: JSX.Element;
  label: string;
  to?: string;
  info: string;
}

interface IStandardGridProps {
  items: GridItem[];
}

export const StandardGrid = ({
  items
}: IStandardGridProps) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: '40px',
        px: '20px'
      }}
    >
      { items.map((item: GridItem) => (
        <React.Fragment key={item.label}>
          <Grid item xs={1.5}>
            {item.icon}
          </Grid>
          <Grid item xs={4}>
            { item.to &&
              <Link
                to={item.to}
                style={{ color: 'white'}}
              >
                {item.label}
              </Link>
            }
            { !item.to &&
              <span>{item.label}</span>
            }
          </Grid>
          <Grid item xs={6}>
            <span>{item.info}</span>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  )
}