import { LoadingIndicator } from "../../../../common/LoadingIndicator";
import * as d3 from "d3";
import { createRef, useCallback, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";

const graphContainerId = 'graph-container';
const graphMargins = {top: 20, right: 80, bottom: 20, left: 80}

interface SpeedEntry {
  registered_on: Date;
  speed_in_mbps: number;
}

interface ISpeedHistoryProps {
  data: SpeedEntry[] | undefined;
  dataLoading: boolean;
}

export const SpeedHistory = ({
  data,
  dataLoading,
}: ISpeedHistoryProps) => {
  const containerRef = createRef<HTMLDivElement>();

  const dataLoaded = !!data && !dataLoading;

  const filteredData: SpeedEntry[] = useMemo(() => {
    if (dataLoaded) {
      const yesterday = new Date();
      yesterday.setDate(new Date().getDate() - 1);
  
      return data.filter(entry => entry.registered_on.getTime() >= yesterday.getTime());
    }

    return [];
  }, [data, dataLoaded]);

  const drawGraph = useCallback(() => {
    d3.selectAll("#graph-svg").remove();

    if (dataLoaded && filteredData.length > 0 && containerRef.current) {
      const width = containerRef.current.clientWidth - graphMargins.left - graphMargins.right;
      const height = 400 - graphMargins.top - graphMargins.bottom;
      const minSpeed = d3.min(filteredData, d => d.speed_in_mbps) ?? 0;
      const maxSpeed = d3.max(filteredData, d => d.speed_in_mbps) ?? 0;
      const minDate = d3.min(filteredData, d => d.registered_on) ?? new Date();
      const maxDate = d3.max(filteredData, d => d.registered_on) ?? new Date();

      const svg = d3.select(`#${graphContainerId}`)
        .append("svg")
          .attr("id", "graph-svg")
          .attr("width", width + graphMargins.left + graphMargins.right)
          .attr("height", height + graphMargins.top + graphMargins.bottom)
        .append("g")
          .attr("transform", `translate(${graphMargins.left},${graphMargins.top})`);

      const x = d3.scaleTime()
        .domain([minDate, maxDate])
        .range([ 0, width ]);

      svg.append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x));

      const y = d3.scaleLinear()
        .domain([minSpeed, maxSpeed])
        .range([height, 0]);

      svg.append("g")
        .call(d3.axisLeft(y));

      const bisect = d3.bisector((d: SpeedEntry) => d.registered_on).left;

      const focus = svg
        .append('g')
        .append('circle')
          .style("fill", "none")
          .attr("stroke", "white")
          .attr("stroke-width", "3")
          .attr('r', 8.5)
          .style("opacity", 0)

      const focusText = svg
        .append('g')
        .append('text')
          .style("opacity", 0)
          .style("fill", "white")
          .attr("text-anchor", "left")
          .attr("alignment-baseline", "middle")

      svg.append("path")
        .datum(filteredData)
        .attr("fill", "none")
        .attr("stroke", "#A7F3D0")
        .attr("stroke-width", 1)
        .attr("d", d3.line<any>()
          .x(d => x(d.registered_on))
          .y(d => y(d.speed_in_mbps))
        )

      svg.selectAll(".data-point")
        .data(filteredData)
        .join("circle")
          .attr("class", "data-point")
          .attr("r", "5")
          .attr("fill", "white")
          .attr("stroke", "#A7F3D0")
          .attr("cx", d => x(d.registered_on))
          .attr("cy", d => y(d.speed_in_mbps))

      const mouseover = () => {
        focus.style("opacity", 1)
        focusText.style("opacity",1)
      }

      const mousemove = (e: MouseEvent) => {
        const x0 = x.invert(d3.pointer(e)[0]);
        const i = bisect(filteredData, x0);
        const selectedData = filteredData[i];

        if (selectedData) {
          focus
            .attr("cx", x(selectedData.registered_on))
            .attr("cy", y(selectedData.speed_in_mbps))

          focusText
            .html(`
              <tspan
                x="${width - 250}"
                y="0"
              >
                Registered On: ${selectedData.registered_on.toLocaleString()}
              </tspan>
              <tspan
                x="${width - 250}"
                y="25"
              >
                Speed: ${selectedData.speed_in_mbps.toFixed(2)} mbps
              </tspan>
            `)
            .attr("x", width - 250)
            .attr("y", 0)
        }
      }

      const mouseout = () => {
        focus.style("opacity", 0)
        focusText.style("opacity", 0)
      }

      svg
        .append('rect')
        .style("fill", "none")
        .style("pointer-events", "all")
        .attr('width', width)
        .attr('height', height)
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseout', mouseout)
    }
  }, [dataLoaded, filteredData, containerRef]);

  useEffect(() => {
    drawGraph();
  }, [drawGraph]);

  if (!dataLoaded) {
    return <LoadingIndicator/>
  }

  return (
    <div ref={containerRef}>
      <div id={graphContainerId}/>

      {(dataLoaded && filteredData.length === 0) &&
        <Typography>No speed history for this device</Typography>
      }
    </div>
  )
}
