import { useState, useMemo, useCallback } from "react";
import { Box, Button, TableCell, TextField } from "@mui/material";
import { HeadCell, Table, TableOrder } from "../../../common/Table";
import { TableRow } from "../../../common/TableRow";
import { Version } from "../../../../api/versions";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { useAlerts } from "../../../../hooks/useAlerts";

enum EditingMode {
  none,
  update,
  create,
}

interface VersionsTableProps<T extends Version> {
  data: T[];
  onEditItem: (id: number, editedItem: Partial<T>) => Promise<T>;
  onCreateItem: (createdItem: T) => Promise<T>;
}

export const VersionsTable = <T extends Version>({
  onEditItem,
  onCreateItem,
  data,
}: VersionsTableProps<T>) => {
  const [originalData, setOriginalData] = useState<T[]>([...data]);
  const [tableData, setTableData] = useState<T[]>([...data]);
  const [editingMode, setEditingMode] = useState<EditingMode>(EditingMode.none);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const {addSuccessAlert, addErrorAlert} = useAlerts();

  const inCreateMode = editingMode === EditingMode.create;
  const inUpdateMode = editingMode === EditingMode.update;
  const readOnly = editingMode === EditingMode.none;

  const newVersionIsUnique = useMemo(() => {
    const savedVersions = tableData.filter(version => version.id > 0);
    const savedVersionSet = new Set(savedVersions.map(version => version.version));
    const newVersion = tableData.find(version => version.id === -1);

    let newVersionIsUnique = true;

    if (newVersion) {
      newVersionIsUnique = !savedVersionSet.has(`${newVersion.major_version}.${newVersion.minor_version}.${newVersion.patch_version}`)
    }

    return newVersionIsUnique;
  }, [tableData]);

  const validForSubmit = useMemo(() => {
    if (editingMode === EditingMode.none || editingMode === EditingMode.update) {
      return true;
    }

    const allVersionsValid = tableData.every(version => {
      return !isNaN(version.major_version) && !isNaN(version.minor_version) && !isNaN(version.patch_version)
    });

    return allVersionsValid && newVersionIsUnique;
  }, [editingMode, newVersionIsUnique, tableData]);

  const originalDataMap = useMemo(() => {
    const versionMap = new Map<number, T>();

    originalData.forEach(version => {
      versionMap.set(version.id, version);
    })

    return versionMap;
  }, [originalData]);

  const saveCreatedItem = async (itemToSave: T) => {
    return await onCreateItem(itemToSave);
  }

  const onClickCreateVersionButton = async () => {
    if (!inCreateMode) {
      setEditingMode(EditingMode.create);
      setTableData(prevTableData => {
        return [
          {id: -1, major_version: 0, minor_version: 0, patch_version: 0, version: '0.0.0', description: ''} as T,
          ...prevTableData,
        ]
      })
    } else {
      const itemToSave = tableData.find(version => version.id === -1);

      if (itemToSave) {
        try {
          setSaveInProgress(true);

          const createdItem = await saveCreatedItem(itemToSave);

          const updatedTableData = tableData.map(version => {
            if (version.id === -1) {
              return createdItem;
            } else {
              return version;
            }
          });
    
          setTableData(updatedTableData);
          setOriginalData(updatedTableData);
          addSuccessAlert(`Created version ${createdItem.version}`);
          setEditingMode(EditingMode.none);
        } catch {
          addErrorAlert('Error creating new version');
        } finally {
          setSaveInProgress(false);
        }
      }
    }
  }

  const saveEditedItems = async () => {
    const promiseArr: Promise<T>[] = [];

    tableData.forEach(version => {
      const originalVersion = originalDataMap.get(version.id);

      if (!!originalVersion && !deepObjectCompare(version, originalVersion)) {
        const editItemPromise = onEditItem(version.id, {description: version.description} as Partial<T>);

        promiseArr.push(editItemPromise);
      }
    });

    return Promise.allSettled(promiseArr)
      .then(resolvedPromises => {
        const successes: T[] = [];
        
        resolvedPromises.forEach(versionPromise => {
          if (versionPromise.status === 'fulfilled') {
            successes.push(versionPromise.value);
          }
        })

        return successes;
      });
  }

  const onClickEditVersionsButton = async () => {
    if (!inUpdateMode) {
      setEditingMode(EditingMode.update);
    } else {
      try {
        setSaveInProgress(true);

        const savedItems = await saveEditedItems();
        const savedItemsMap = new Map<number, T>();

        savedItems.forEach(version => {
          savedItemsMap.set(version.id, version);
        })

        const updatedTableData = tableData.map(version => {
          const updatedVersion = savedItemsMap.get(version.id);

          if (!!updatedVersion) {
            return {...updatedVersion}
          } else {
            return version;
          }
        });

        setTableData(updatedTableData);
        setOriginalData(updatedTableData);
        setEditingMode(EditingMode.none);

        addSuccessAlert(`Version${savedItems.length !== 1 ? 's' : ''} updated successfully`)
      } catch {
        addErrorAlert('Error updating versions');
      } finally {
        setSaveInProgress(false);
      }
    }
  }

  const onClickCancelButton = () => {
    setTableData([...originalData]);
    setEditingMode(EditingMode.none);
  }

  const getActiveFields = (id: number) => {
    const activeFields = {
      major_version: false,
      minor_version: false,
      patch_version: false,
      description: false,
    }

    if (id === -1 && inCreateMode) {
      activeFields.major_version = true;
      activeFields.minor_version = true;
      activeFields.patch_version = true;
      activeFields.description = true;
    } else if (id > -1 && inUpdateMode)  {
      activeFields.description = true;
    }

    return activeFields;
  }

  const onChangeTableData = (id: number, updatedState: Partial<T>) => {
    setTableData(prevData => {
      return prevData.map(version => {
        if (version.id === id) {
          return {...version, ...updatedState}
        } else {
          return version;
        }
      })
    })
  }

  const columns: readonly HeadCell<Version>[] = [
    { id: 'version', label: 'Version' },
    ...!readOnly ? [{ id: 'major_version' as HeadCell<Version>['id'], label: 'Major Version' }] : [],
    ...!readOnly ? [{ id: 'minor_version' as HeadCell<Version>['id'], label: 'Minor Version' }] : [],
    ...!readOnly ? [{ id: 'patch_version' as HeadCell<Version>['id'], label: 'Patch Version' }] : [],
    { id: 'description', label: 'Description' }
  ];

  const renderVisibleRows = (row: Version, index: number) => {
    const activeFields = getActiveFields(row.id);

    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={index}
      >
        <TableCell align="left">
          { (!readOnly && row.id === -1) ? 'New' : row.version }
        </TableCell>
        {!readOnly &&
          <TableCell
            align="left"
          >
            {activeFields.major_version &&
              <TextField
                type="number"
                value={row.major_version}
                onChange={e => onChangeTableData(row.id, {major_version: parseInt(e.target.value)} as Partial<T>)}
                inputProps={{
                  min: 0,
                }}
              />
            }
            {!activeFields.major_version &&
              <>{row.major_version}</>
            }
          </TableCell>
        }
        {!readOnly &&
          <TableCell
            align="left"
          >
            {activeFields.minor_version &&
              <TextField
                type="number"
                value={row.minor_version}
                onChange={e => onChangeTableData(row.id, {minor_version: parseInt(e.target.value)} as Partial<T>)}
                inputProps={{
                  min: 0,
                }}
              />
            }
            {!activeFields.minor_version &&
              <>{row.minor_version}</>
            }
          </TableCell>
        }
        {!readOnly &&
          <TableCell
            align="left"
          >
            {activeFields.patch_version &&
              <TextField
                type="number"
                value={row.patch_version}
                onChange={e => onChangeTableData(row.id, {patch_version: parseInt(e.target.value)} as Partial<T>)}
                inputProps={{
                  min: 0,
                }}
              />
            }
            {!activeFields.patch_version &&
              <>{row.patch_version}</>
            }
          </TableCell>
        }
        <TableCell align="left">
          {!activeFields.description &&
            <>{row.description}</>
          }
          {activeFields.description &&
            <TextField
              value={row.description}
              onChange={e => onChangeTableData(row.id, {description: e.target.value} as Partial<T>)}
            />
          }
        </TableCell>
      </TableRow>
    )
  }

  const compareVersions = useCallback((order: TableOrder, a: Version, b: Version) => {
    const {major_version: aMajor, minor_version: aMinor, patch_version: aPatch} = a;
    const {major_version: bMajor, minor_version: bMinor, patch_version: bPatch} = b;

    if (a.id === -1) {
      return -1;
    } else if (b.id === -1) {
      return 1;
    } else if (aMajor !== bMajor) {
      return order === 'asc' ? aMajor - bMajor : bMajor - aMajor;
    } else if (aMinor !== bMinor) {
      return order === 'asc' ? aMinor - bMinor : bMinor - aMinor;
    } else {
      return order === 'asc' ? aPatch - bPatch : bPatch - aPatch;
    }
  }, []);

  const versionComparator = useCallback((order: TableOrder, orderBy: string | number | symbol) => {
    if (orderBy === 'version') {
      return (a: Version, b: Version) => compareVersions(order, a, b);
    }

    return undefined;
  }, [compareVersions]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
        gap={2}
      >
        {saveInProgress &&
          <LoadingIndicator
            containerStyle={{
              width: '50px'
            }}
          />
        }
        {!readOnly &&
          <Button
            onClick={onClickCancelButton}
          >
            Cancel
          </Button>
        }
        {!inUpdateMode &&
          <Button
            disabled={!validForSubmit}
            variant="contained"
            onClick={onClickCreateVersionButton}
          >
            {inCreateMode ? 'Save Version' : 'Create Version'}
          </Button>
        }
        {(!inCreateMode && tableData.length > 0) &&
          <Button
            disabled={!validForSubmit}
            variant="contained"
            onClick={onClickEditVersionsButton}
          >
            {inUpdateMode ? 'Save Versions' : 'Edit Versions'}
          </Button>
        }
      </Box>
      <Table
        columns={columns}
        rows={tableData}
        initialOrderedColumnName='version'
        initialOrder="desc"
        renderVisibleRows={renderVisibleRows}
        customComparator={versionComparator}
      />
    </Box>
  );
}

function deepObjectCompare(obj1: any, obj2: any): boolean {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }

    for (let i = 0; i < obj1.length; i++) {
      if (!deepObjectCompare(obj1[i], obj2[i])) {
        return false;
      }
    }

    return true;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      if (!deepObjectCompare(obj1[key], obj2[key])) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
}