import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";

export type SpeedMetric = 'upload' | 'download' | 'ping';
const speedMetrics: SpeedMetric[] = ['upload', 'download', 'ping'];

interface SpeedMetricRadioButtonProps {
  selectedSpeedMetric: SpeedMetric;
  onChangeSelectedSpeedMetric: (newMetric: SpeedMetric) => void;
}

export const SpeedMetricRadioButtons = ({
  selectedSpeedMetric,
  onChangeSelectedSpeedMetric,
}: SpeedMetricRadioButtonProps) => {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={selectedSpeedMetric}
        onChange={e => onChangeSelectedSpeedMetric(e.target.value as SpeedMetric)}
      >
        {speedMetrics.map(metric => {
          return (
            <FormControlLabel
              key={metric}
              value={metric}
              control={<Radio />}
              label={metric}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}
