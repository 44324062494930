import { Autocomplete, AutocompleteRenderOptionState, Box, Checkbox, TextField } from "@mui/material"
import React, { useMemo, useState, useRef } from "react";
import { UseQueryResult } from "react-query";
import { DeviceFields, Modem, Radio, RaspberryPi, SimCard } from "../../api/device";
import { Project, ProjectWorker } from "../../api/projects";
import { Robot } from "../../api/robots";
import { StairClimberScript } from "../../api/stairs";
import { useFetchFloorTransitionUsersQuery, useFetchHardwareVersionsQuery, useFetchSoftwareVersionsQuery, useFetchStairClimberScripts, useModemsQuery, useProjectsQuery, useRadiosQuery, useRaspberryPisQuery, useRobotsQuery, useSimCardsQuery } from "../../hooks/queries";
import { HardwareVersion, SoftwareVersion, Version } from "../../api/versions";

interface IStandardDeviceAutocompleteProps<T extends DeviceFields> {
  disabled?: boolean;
  error?: boolean;
  helperText?: string | JSX.Element;
  disableClearable?: boolean;
  query: (onSuccess?: ((data: T[]) => void) | undefined) => UseQueryResult<T[], unknown>
  value: T | null | undefined;
  onChange: (value: T | null) => void;
  getOptionLabel?: (option: T) => string;
  width?: number;
  textFieldLabel: string;
  refetchOnOpen?: boolean;
}

const StandardDeviceAutocomplete = <T extends DeviceFields>({
  disabled,
  error,
  helperText,
  disableClearable,
  query,
  value,
  onChange,
  getOptionLabel,
  width,
  textFieldLabel,
  refetchOnOpen,
}: IStandardDeviceAutocompleteProps<T>) => {
  const initialOpenComplete = useRef<boolean>(false);

  const [options, setOptions] = useState<T[]>([]);

  const {
    isLoading: optionsLoading,
    refetch: refetchOptions,
    isRefetching: isRefetchingOptions
  } = query(setOptions);

  const optionsLoaded = !!options && !optionsLoading;

  const onRefetch = () => {
    if (refetchOnOpen && initialOpenComplete.current) {
      refetchOptions();
    }

    initialOpenComplete.current = true;
  }

  return (
    <Autocomplete
      disabled={disabled || !optionsLoaded}
      disableClearable={disableClearable}
      value={value}
      onChange={(e, value) => onChange(value)}
      onOpen={onRefetch}
      options={options}
      loading={isRefetchingOptions}
      getOptionLabel={option => {
        if (getOptionLabel) {
          return getOptionLabel(option);
        } else {
          return option.device.name;
        }
      }}
      sx={{ width: width ?? 400 }}
      renderInput={(params) => {
        return (
          <TextField {...params} label={textFieldLabel} error={error} helperText={helperText} />
        )
      }}
    />
  )
}

interface IBaseProjectProperties {
  name: string;
  public_id: string;
}

interface IBaseProjectAutocompleteProps<T extends IBaseProjectProperties> {
  selectedProjectId?: string | null;
  setSelectedProjectId?: (newValue: string | null) => void;
  selectedProject?: T | null;
  setSelectedProject?: (newValue: T | null) => void;

  label?: string;
  width?: number;
  minWidth?: number;
  includeAllOption?: boolean;
  includeUnassignedOption?: boolean;
}

interface IProjectAutocompletePropsWithPublicId<T extends IBaseProjectProperties> extends IBaseProjectAutocompleteProps<T> {
  selectedProjectId: string | null;
  setSelectedProjectId: (newValue: string | null) => void;
}

interface IProjectAutocompletePropsWithFullObject<T extends IBaseProjectProperties> extends IBaseProjectAutocompleteProps<T> {
  selectedProject: T | null;
  setSelectedProject: (newValue: T | null) => void;
}

type IProjectAutocompleteProps<T extends IBaseProjectProperties> = IProjectAutocompletePropsWithPublicId<T> | IProjectAutocompletePropsWithFullObject<T>;

export const projectDropdownAllKey = 'All';
export const projectDropdownUnassignedKey = 'Unassigned'

export const ProjectsAutocomplete = <T extends IBaseProjectProperties>({
  selectedProject,
  setSelectedProject,
  selectedProjectId,
  setSelectedProjectId,
  label,
  width,
  minWidth,
  includeAllOption,
  includeUnassignedOption,
}: IProjectAutocompleteProps<T>) => {
  const {data: projects, isLoading: projectsLoading} = useProjectsQuery();
  const projectsLoaded = !!projects && !projectsLoading;

  const projectOptions = useMemo(() => {
    if (projectsLoaded) {
      return [
        ...includeAllOption ? [{name: projectDropdownAllKey, public_id: projectDropdownAllKey}] : [],
        ...includeUnassignedOption ? [{name: projectDropdownUnassignedKey, public_id: projectDropdownUnassignedKey}] : [],
        ...projects
      ]
    }

    return [];
  }, [projectsLoaded, includeAllOption, includeUnassignedOption, projects]);

  const projectForDropdown = useMemo(() => {
    if (selectedProjectId !== undefined) {
      if (projects) {
        return projects.filter(project => project.public_id === selectedProjectId)[0];
      } 
      
      return null;
    } else if (selectedProject !== undefined) {
      return selectedProject;
    }

    return null;
  }, [projects, selectedProject, selectedProjectId]);

  const onChangeSelectedProject = (e: React.SyntheticEvent, value: Project | IBaseProjectProperties | null) => {
    if (setSelectedProjectId) {
      setSelectedProjectId(value ? value.public_id : null);
    } else if (setSelectedProject) {
      setSelectedProject(value as T);
    }
  }

  return (
    <Autocomplete
      disabled={!projectsLoaded}
      value={projectForDropdown}
      onChange={onChangeSelectedProject}
      options={projectOptions ?? []}
      getOptionLabel={option => option.name}
      sx={{ width: width, minWidth: minWidth }}
      renderInput={(params) => <TextField {...params} label={label ?? "Projects"} />}
    />
  )
}

interface IRobotsDropdownProps {
  selectedRobot: Robot | null;
  setSelectedRobot: React.Dispatch<React.SetStateAction<Robot | null>>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>, open: boolean) => void;
}

export const RobotsDropdown = ({
  selectedRobot,
  setSelectedRobot,
  onKeyDown,
}: IRobotsDropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { data: robots, isLoading: robotsLoading } = useRobotsQuery();

  const autoCompleteOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (onKeyDown) {
      onKeyDown(e, open);
    }
  }

  return (
    <Autocomplete
      onKeyDown={autoCompleteOnKeyDown}
      onOpen={() => {setOpen(true)}}
      onClose={() => {setOpen(false)}}
      options={robots ?? []}
      getOptionLabel={option => option.name}
      loading={robotsLoading}
      loadingText="Robots Loading"
      value={selectedRobot}
      onChange={(e: React.SyntheticEvent, value: Robot | null) => setSelectedRobot(value)}
      sx={{ width: 250 }}
      renderInput={(params) => <TextField {...params} label="Robot" />}
    />
  );
}

interface ISharedDeviceAutocompleteProps {
  disableClearable?: boolean;
  error?: boolean;
  helperText?: string | JSX.Element;
}

interface ISimCardsDropdownProps extends ISharedDeviceAutocompleteProps {
  selectedSimCard: SimCard | undefined;
  setSelectedSimCard: (newValue: SimCard | undefined) => void;
};

export const SimCardsDropdown = ({
  selectedSimCard,
  setSelectedSimCard,
  disableClearable,
}: ISimCardsDropdownProps) => {
  return (
    <StandardDeviceAutocomplete
      disableClearable={disableClearable}
      value={selectedSimCard}
      onChange={(value) => setSelectedSimCard(value ? value : undefined)}
      query={useSimCardsQuery}
      textFieldLabel="Sim Card"
      refetchOnOpen
    />
  )
}

interface IModemsDropdownProps extends ISharedDeviceAutocompleteProps {
  selectedModem: Modem | undefined;
  setSelectedModem: (newValue: Modem | undefined) => void;
};

export const ModemsDropdown = ({
  selectedModem,
  setSelectedModem,
  disableClearable,
  error,
  helperText,
}: IModemsDropdownProps) => {
  return (
    <StandardDeviceAutocomplete
      error={error}
      helperText={helperText}
      disableClearable={disableClearable}
      value={selectedModem}
      onChange={(value) => setSelectedModem(value ? value : undefined)}
      query={useModemsQuery}
      textFieldLabel="Modem"
      refetchOnOpen
    />
  )
}

interface IRadiosDropdownProps extends ISharedDeviceAutocompleteProps {
  selectedRadio: Radio | undefined;
  setSelectedRadio: (newValue: Radio | undefined) => void;
};

export const RadiosDropdown = ({
  selectedRadio,
  setSelectedRadio,
  disableClearable,
  error,
  helperText,
}: IRadiosDropdownProps) => {
  return (
    <StandardDeviceAutocomplete
      error={error}
      helperText={helperText}
      disableClearable={disableClearable}
      value={selectedRadio}
      onChange={(value) => setSelectedRadio(value ? value : undefined)}
      textFieldLabel="Radio"
      query={useRadiosQuery}
      refetchOnOpen
    />
  )
}

interface IRaspberryPisDropdownProps extends ISharedDeviceAutocompleteProps {
  selectedRaspberryPi: RaspberryPi | undefined;
  setSelectedRaspberryPi: (newValue: RaspberryPi | undefined) => void;
};

export const RaspberryPisDropdown = ({
  selectedRaspberryPi,
  setSelectedRaspberryPi,
  disableClearable,
  error,
  helperText,
}: IRaspberryPisDropdownProps) => {
  return (
    <StandardDeviceAutocomplete
      error={error}
      helperText={helperText}
      disableClearable={disableClearable}
      value={selectedRaspberryPi}
      onChange={(value) => setSelectedRaspberryPi(value ? value : undefined)}
      textFieldLabel="Raspberry Pi"
      query={useRaspberryPisQuery}
      refetchOnOpen
    />
  )
}

interface IStairClimbingScriptsDropdownProps {
  projectId: string;
  selectedScript: StairClimberScript | null | undefined;
  onChangeSelectedScript: (script: StairClimberScript | null) => void;
}

export const StairClimbingScriptsDropdown = ({
  projectId,
  selectedScript,
  onChangeSelectedScript
}: IStairClimbingScriptsDropdownProps) => {
  const {data: scripts, isLoading: scriptsLoading} = useFetchStairClimberScripts(projectId);

  return (
    <Autocomplete
      options={scripts ?? []}
      value={selectedScript}
      loading={scriptsLoading}
      getOptionLabel={option => `${option.name} - ${option.staircase?.name}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(e: React.SyntheticEvent, value: StairClimberScript | null) => onChangeSelectedScript(value)}
      renderInput={(params) => {
        return (
          <TextField {...params} label="Script Name" />
        )
      }}
    />
  )
}

interface IFloorTransitionUsersDropdownProps {
  projectId: string;
  selectedProjectWorkers: ProjectWorker[];
  onChangeSelectedProjectWorkers: (users: ProjectWorker[]) => void;
  required?: boolean;
}

export const FloorTransitionUsersDropdown = ({
  projectId,
  selectedProjectWorkers,
  onChangeSelectedProjectWorkers,
  required
}: IFloorTransitionUsersDropdownProps) => {
  const {data: scripts, isLoading: scriptsLoading, refetch} = useFetchFloorTransitionUsersQuery(projectId);

  return (
    <Autocomplete
      multiple
      options={scripts ?? []}
      value={selectedProjectWorkers}
      loading={scriptsLoading}
      getOptionLabel={option => `${option.user.first_name} ${option.user.last_name}`}
      onOpen={() => refetch()}
      onChange={(e: React.SyntheticEvent, value: ProjectWorker[]) => onChangeSelectedProjectWorkers(value)}
      renderInput={(params) => {
        return (
          <TextField {...params} label="Email Users" required={required} />
        )
      }}
    />
  )
}

interface VersionDropdownProps <T extends Version> {
  options: T[];
  optionsLoading?: boolean;
  selectedOption: T | null;
  onSelectOption: (option: T | null) => void;
  required?: boolean;
  label?: string;
  onRefetch?: () => void;
}

const VersionDropdown = <T extends Version>({
  options,
  optionsLoading,
  selectedOption,
  onSelectOption,
  required,
  label = "Version",
  onRefetch,
}: VersionDropdownProps<T>) => {
  const initialOpenComplete = useRef<boolean>(false);

  const versionOptions = useMemo(() => {
    const sortedOptions = [...options]
    sortedOptions.sort((a,b) => {
      const {major_version: aMajor, minor_version: aMinor, patch_version: aPatch} = a;
      const {major_version: bMajor, minor_version: bMinor, patch_version: bPatch} = b;
  
      if (aMajor !== bMajor) {
        return bMajor - aMajor;
      } else if (aMinor !== bMinor) {
        return bMinor - aMinor;
      } else {
        return bPatch - aPatch;
      }
    });

    return sortedOptions;
  }, [options]);

  const onOpen = () => {
    if (initialOpenComplete.current) {
      if (onRefetch) {
        onRefetch();
      }
    }

    initialOpenComplete.current = true;
  }

  return (
    <Autocomplete
      options={versionOptions}
      value={selectedOption}
      loading={optionsLoading}
      getOptionLabel={option => option.version}
      onOpen={onOpen}
      onChange={(e: React.SyntheticEvent, value: T | null) => onSelectOption(value)}
      renderInput={(params) => {
        return (
          <TextField {...params} label={label} required={required} />
        )
      }}
    />
  )
}

interface SoftwareVersionDropdownProps {
  selectedSoftwareVersion: SoftwareVersion | null;
  onSelectSoftwareVersion: (option: SoftwareVersion | null) => void;
  label?: string;
}

export const SoftwareVersionDropdown = ({
  selectedSoftwareVersion,
  onSelectSoftwareVersion,
  label = "Software Version"
}: SoftwareVersionDropdownProps) => {
  const {
    data: softwareVersions,
    isLoading: softwareVersionsLoading,
    refetch: refetchSoftwareVersions
  } = useFetchSoftwareVersionsQuery();

  return (
    <VersionDropdown
      options={softwareVersions ?? []}
      optionsLoading={softwareVersionsLoading}
      onRefetch={refetchSoftwareVersions}
      selectedOption={selectedSoftwareVersion}
      onSelectOption={onSelectSoftwareVersion}
      label={label}
    />
  )
}

interface HardwareVersionDropdownProps {
  selectedHardwareVersion: HardwareVersion | null;
  onSelectHardwareVersion: (option: HardwareVersion | null) => void;
  label?: string;
}

export const HardwareVersionDropdown = ({
  selectedHardwareVersion,
  onSelectHardwareVersion,
  label = "Hardware Version"
}: HardwareVersionDropdownProps) => {
  const {
    data: hardwareVersions,
    isLoading: hardwareVersionsLoading,
    refetch: refetchHardwareVersions
  } = useFetchHardwareVersionsQuery();

  return (
    <VersionDropdown
      options={hardwareVersions ?? []}
      optionsLoading={hardwareVersionsLoading}
      onRefetch={refetchHardwareVersions}
      selectedOption={selectedHardwareVersion}
      onSelectOption={onSelectHardwareVersion}
      label={label}
    />
  )
}

export const multiSelectRenderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  optionName: string,
  { selected }: AutocompleteRenderOptionState
) => {
  return (
    <li {...props}>
      <Box
        display="flex"
        alignItems="center"
      >
        <Checkbox checked={selected}/>
        {optionName}
      </Box>
    </li>
  );
}
