import { useRoverPose } from "../../../../../hooks/teleopHooks/useRoverPose"

export const RobotMarker = () => {
  const {
    roverX,
    roverY,
    roverAngle
  } = useRoverPose();

  return (
    <div
      style={{
        position: 'absolute',
        top: `${roverY}px`,
        left: `${roverX}px`,
        transform: `rotate(${roverAngle}deg)`,
        transformOrigin: '24px 24px',
        pointerEvents: 'none'
      }} 
    >
      <img
        src='/images/robot_avatar_nextera.png'
        alt='robot marker'
        style={{
          height: '48px',
          width: '48px',
          objectFit: 'contain',
          objectPosition: 'center'
        }}
      />
    </div>
  )
}