import { Box, Paper, TablePagination, TableBody, TableHead, TableRow, TableCell, Checkbox, TableContainer, TableSortLabel, TableCellProps } from '@mui/material';
import MuiTable from '@mui/material/Table';
import { visuallyHidden } from '@mui/utils';
import { useState } from 'react';

export type TableOrder = 'asc' | 'desc';

interface TableHeadProps<T> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T, nestedProperty?: string) => void;
  order: TableOrder;
  orderBy: string;
  rowCount: number;
  headCells: readonly HeadCell<T>[];
  numSelected?: number;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, nestedOrderBy?: string) {
  let aValue = !!nestedOrderBy ? (a[orderBy] as any)[nestedOrderBy] : a[orderBy];
  let bValue = !!nestedOrderBy ? (b[orderBy] as any)[nestedOrderBy] : b[orderBy];

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: TableOrder,
  orderBy: Key,
  nestedOrderBy?: string,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, nestedOrderBy)
    : (a, b) => -descendingComparator(a, b, orderBy, nestedOrderBy);
}

export interface HeadCell<T> {
  disablePadding?: boolean;
  id: keyof T;
  label: string;
  numeric?: boolean;
  align?: TableCellProps['align'];
  sortProperty?: keyof T;
  nestedSortProperty?: string;
}

const EnhancedTableHead = <T extends {}>(props: TableHeadProps<T>) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells
  } = props;

  const createSortHandler = (property: keyof T, nestedProperty?: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property, nestedProperty);
  };

  return (
    <TableHead>
      <TableRow>
        { (onSelectAllClick !== undefined && numSelected !== undefined) &&
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        }
        {headCells.map((headCell) => {
          const headCellSortProperty = headCell.sortProperty ?? headCell.id;
          const headCellCurrentlyOrdered = orderBy === headCell.id || orderBy === headCell.sortProperty;

          return (
            <TableCell
              key={headCell.id.toString()}
              align={!!headCell.align ? headCell.align : headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={headCellCurrentlyOrdered ? order : false}
            >
              <TableSortLabel
                active={headCellCurrentlyOrdered}
                direction={headCellCurrentlyOrdered ? order : 'asc'}
                onClick={createSortHandler(headCellSortProperty, headCell.nestedSortProperty)}
              >
                {headCell.label}
                {headCellCurrentlyOrdered ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )})}
      </TableRow>
    </TableHead>
  );
}

interface ITableProps<T> {
  columns: readonly HeadCell<T>[];
  rows: T[];
  renderVisibleRows: (row: T, index: number) => JSX.Element;
  selected?: readonly string[];
  initialOrder?: TableOrder;
  initialOrderedColumnName: keyof T;
  handleSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => any;
  initialRowsPerPage?: number;
  customComparator?: (order: TableOrder, orderBy: keyof T) => ((a: T, b: T) => number) | undefined;
}

export const Table = <T extends {}>({
  columns,
  rows,
  renderVisibleRows,
  selected,
  initialOrder,
  initialOrderedColumnName,
  handleSelectAllClick,
  initialRowsPerPage,
  customComparator,
}: ITableProps<T>) => {
  const [order, setOrder] = useState<TableOrder>(initialOrder ?? 'asc');
  const [orderBy, setOrderBy] = useState<keyof T>(initialOrderedColumnName);
  const [nestedOrderBy, setNestedOrderBy] = useState<string | undefined>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage ?? 10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T,
    nestedProperty?: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setNestedOrderBy(nestedProperty);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  let comparator: ((a: any, b: any) => number) | undefined;

  if (customComparator && customComparator(order, orderBy)) {
    comparator = customComparator(order, orderBy);
  } else {
    comparator = getComparator(order, orderBy, nestedOrderBy);
  }

  return (
    <Paper sx={{ width: '100%', mb: 2, boxShadow: 'none' }}>
      <TableContainer>
        <MuiTable
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          <EnhancedTableHead
            headCells={columns}
            numSelected={selected?.length}
            order={order}
            orderBy={orderBy.toString()}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            { rows.slice().sort(comparator)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(renderVisibleRows)
            }
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}