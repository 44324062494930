import { Card, Typography, Box } from "@mui/material";
import { FlipCameraIosOutlined, SettingsOutlined, Refresh, Cancel } from "@mui/icons-material";
import React, { useState, createRef, useEffect, useMemo } from "react";
import { CameraTopic, defaultCameraGain, defaultCameraGamma, useVideoContext } from "../../../../../contexts/videoContext";
import { LoadingIndicator } from "../../../../common/LoadingIndicator";
import { useTeleopContext } from "../../../../../contexts/teleopContext";
import { useMissionManagementContext } from "../../../../../contexts/missionManagementContext";
import { VideoDrawer } from "./VideoDrawer";
import { RoverStatusDisplay } from "./RoverStatusDisplay";
import { lightBlue } from "../../../../../App";
import { CopilotOverlay } from "./CopilotOverlay";
import { copilotPredictionStreamHeight, copilotPredictionStreamWidth } from "../../../../../contexts/copilotContext";

export type DrivingIndicator = 'danger' | 'paused';

interface IRoverVideoCardProps {
  identifier: string;
}

export const RoverVideoCard = ({
  identifier,
}: IRoverVideoCardProps) => {
  const {
    state: videoState,
  } = useVideoContext();

  const {
    robot,
    robotLoading,
  } = useTeleopContext();

  const {
    missions,
    activeTeleopIdentifier,
    setActiveTeleopIdentifier,
  } = useMissionManagementContext();

  const {
    cameraQuality,
    selectedCameraSize,
    selectedCameraTopic,
    selectedCameraTransport,
    feedKey,
  } = videoState;

  const dataLoaded = !robotLoading && !!robot;

  const [flickerBool, setFlickerBool] = useState<boolean>(false);

  const roverTeleop = missions[identifier];

  const {
    isPaused,
    requestSkipPoint,
    copilotOutput,
  } = roverTeleop || {};

  const copilotGivingWarning = ['EMERGENCY STOP', 'STOP', 'EXTREME CAUTION'].includes(copilotOutput?.warning ?? '')

  useEffect( () => {
    let timeout: NodeJS.Timeout | null = null;

    if(isPaused || requestSkipPoint || copilotGivingWarning) {
      timeout = setTimeout(() => {
        setFlickerBool(!flickerBool);
      }, 500)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [isPaused, requestSkipPoint, flickerBool, copilotGivingWarning]);

  const videoSource = useMemo(() => {
    if (dataLoaded) {
      const cameraDimensions = selectedCameraSize.split('x');
      const image_width = cameraDimensions[0];
      const image_height = cameraDimensions[1];

      const videoPort = robot.robot_details.video_port;

      let videoHost = videoPort >= 8018 ?
        `https://stream.nexnet.global/${videoPort}` :
        `http://nexterarobotsvpn.ddns.net:${videoPort}`;

      videoHost += `/stream?topic=${selectedCameraTopic}&default_transport=${selectedCameraTransport}&invert&height=${image_height}&width=${image_width}&quality=${cameraQuality}&feedKey=${feedKey.getTime()}`;

      return videoHost;
    } 
  }, [cameraQuality, dataLoaded, feedKey, robot?.robot_details.video_port, selectedCameraSize, selectedCameraTopic, selectedCameraTransport]);

  const drivingIndicator = useMemo(() => {
    let indicator: DrivingIndicator | undefined;

    const showPauseBorder = isPaused || requestSkipPoint;
    const showDangerBorder = copilotGivingWarning;

    if (showDangerBorder) {
      indicator = 'danger';
    } else if (showPauseBorder) {
      indicator = 'paused';
    }

    return indicator;
  }, [copilotGivingWarning, isPaused, requestSkipPoint]);

  const drivingIndicatorBorderColors: Record<DrivingIndicator, string> = useMemo(() => {
    return {
      paused: lightBlue,
      danger: '#FF5252',
    }
  }, []);

  const borderColor = useMemo(() => {
    let color: React.CSSProperties['borderColor'] = 'transparent';
    const showBorder = Object.getOwnPropertyNames(missions).length > 1;
    const isActive = identifier === activeTeleopIdentifier;

    if (showBorder) {
      if (!!drivingIndicator && flickerBool) {
        color = drivingIndicatorBorderColors[drivingIndicator as DrivingIndicator];
      } else if (isActive) {
        color = "#6366F1";
      }
    }

    return color;
  }, [activeTeleopIdentifier, drivingIndicator, drivingIndicatorBorderColors, flickerBool, identifier, missions]);

  return (
    <Card
      sx={{
        height: '100%',
        padding: 0.5,
        border: `3px solid ${borderColor}`
      }}
      onClick={() => setActiveTeleopIdentifier(identifier)}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        gap={0.5}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          { !dataLoaded &&
            <LoadingIndicator/>
          }
          { dataLoaded &&
            <>
              <Box>
                <Typography variant='h5'>
                  {robot.device.name}
                </Typography>
              </Box>
              <Box>
                <RoverStatusDisplay
                  drivingIndicator={drivingIndicator}
                  identifier={identifier}
                />
              </Box>
              <Box>
                <VideoControls
                  identifier={identifier}
                />
              </Box>
            </>
          }
        </Box>
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
        >
          <RoverVideo
            identifier={identifier}
            src={videoSource}
            alt={`${robot ? robot.device.name : ''} ${selectedCameraTopic}`}
            style={{
              height: "100%",
              width: "auto",
            }}
          />
        </Box>
      </Box>
      <VideoDrawer/>
    </Card>
  )
}

interface IVideoControlsProps {
  identifier: string;
}

const VideoControls = ({
  identifier,
}: IVideoControlsProps) => {
  const {
    state: videoState,
    dispatch: dispatchVideoState,
  } = useVideoContext();

  const {
    removeMission
  } = useMissionManagementContext();

  const onClickRefresh = () => {
    dispatchVideoState({
      feedKey: new Date(),
    })
  }

  const onClickFlipCamera = () => {
    dispatchVideoState({
      selectedCameraTopic: videoState.selectedCameraTopic === CameraTopic.ONE ? CameraTopic.TWO : CameraTopic.ONE,
      cameraGain: defaultCameraGain,
      cameraGamma: defaultCameraGamma,
    });
  }

  const onClickSettings = () => {
    dispatchVideoState({
      drawerOpen: true,
    })
  }

  const onClickRemoveMission = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    removeMission(identifier);
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="5px"
    >
      <Refresh
        onClick={onClickRefresh}
        style={{cursor: 'pointer'}}
      />
      <FlipCameraIosOutlined
        onClick={onClickFlipCamera}
        style={{cursor: 'pointer'}}
      />
      <SettingsOutlined
        onClick={onClickSettings}
        style={{cursor: 'pointer'}}
      />
      <Cancel
        style={{ cursor: 'pointer' }}
        onClick={onClickRemoveMission}
      />
    </Box>
  )
}

interface RoverVideoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  identifier: string;
}

const RoverVideo = ({
  alt,
  identifier,
  ...props
}: RoverVideoProps) => {
  const {
    state: videoState,
  } = useVideoContext();

  const {
    missions,
  } = useMissionManagementContext();

  const imgRef = createRef<HTMLImageElement>();
  const [copilotScaleX, setCopilotScaleX] = useState<number>(1);
  const [copilotScaleY, setCopilotScaleY] = useState<number>(1);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const missionState = missions[identifier];
  const {
    copilotActive,
  } = missionState || {};

  const isFrontFacing = videoState.selectedCameraTopic === CameraTopic.ONE;
  const displayCopilot = imageLoaded && copilotActive && isFrontFacing;

  useEffect(() => {
    const image = imgRef.current;

    return () => {
      if (image) {
        image.src = '';
      }
    }
  }, []);

  useEffect(() => {
    const image = imgRef.current;
    
    if (image) {
      const newScaleX = image.width / copilotPredictionStreamWidth;
      const newScaleY = image.height / copilotPredictionStreamHeight;

      setCopilotScaleX(newScaleX);
      setCopilotScaleY(newScaleY);
    }
  }, [imgRef]);

  return (
    <div
      style={{
        position: 'relative'
      }}
    >
      <img
        {...props}
        ref={imgRef}
        alt={alt}
        onLoad={() => setImageLoaded(true)}
      />
      {displayCopilot &&
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
          }}
        >        
          <CopilotOverlay
            scaleX={copilotScaleX}
            scaleY={copilotScaleY}
            identifier={identifier}
          />
        </div>
      }
    </div>
  )
}