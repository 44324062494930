import React, { useCallback, useMemo } from "react";
import { useRoverSequencePoints } from "../../../../../hooks/teleopHooks/useRoverSequencePoints";
import { useMissionManagementContext } from "../../../../../contexts/missionManagementContext";
import { formatLine } from "../../../../../api/helperFunctions";

export const SequenceLine = () => {
  const {
    activeTeleop,
  } = useMissionManagementContext();

  const sequence = !!activeTeleop?.sequence;
  const regionBased = !!activeTeleop?.regionBased;
  const selectedFloorCode = activeTeleop?.selectedFloor?.floor_code;
  const sequencePoints = useRoverSequencePoints();

  const onMouseMove = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const pointIdMap = useMemo(() => {
    const schedulePoints = activeTeleop?.schedulePoints ?? [];
    const map = new Map();

    schedulePoints.forEach((point) => map.set(point.point_id, point));

    return map;
  }, [activeTeleop?.schedulePoints]);

  const renderedSequenceLine = useMemo(() => {
    if (!sequence || regionBased) {
      return <></>
    }
  
    return sequencePoints.map((pointId: number, i: number, arr: number[]) => {
      if (i < arr.length - 1) {
        const startPoint = pointIdMap.get(pointId);
        const endPoint = pointIdMap.get(arr[i+1]);
  
        if (startPoint && endPoint && startPoint.floor.floor_code === selectedFloorCode && endPoint.floor.floor_code) {
          const {
            top,
            left,
            height,
            rotation
          } = formatLine(
            parseFloat(startPoint.x.toString()),
            parseFloat(startPoint.y.toString()),
            parseFloat(endPoint.x.toString()),
            parseFloat(endPoint.y.toString())
          );
  
          return (
            <div
              key={pointId}
              onMouseMove={onMouseMove}
              style={{
                pointerEvents: 'none',
                position: 'absolute',
                width: '2px',
                marginTop: '-1px',
                backgroundColor: 'red',
                top: top,
                left: left,
                height: height,
                transform: `rotate(${rotation}deg)`
              }}>
            </div>
          );
        } 
      }

      return <React.Fragment key={pointId}></React.Fragment>
    });
  }, [sequence, regionBased, sequencePoints, pointIdMap, selectedFloorCode, onMouseMove]);

  return (
    <>{renderedSequenceLine}</>
  )
}