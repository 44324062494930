import { useMemo } from "react";
import { ManagedMapPoint } from "./ManagedMapPoint";
import { useMissionManagementContext } from "../../../../../contexts/missionManagementContext";

interface IFloorPointsProps {
  activateArrow: (x: string | number, y: string | number) => void;
  pointerEvents?: React.CSSProperties["pointerEvents"];
}

export const FloorPoints = ({
  activateArrow,
  pointerEvents,
}: IFloorPointsProps) => {
  const {
    activeTeleop
  } = useMissionManagementContext();

  const floorPoints = useMemo(() => {
    if (!activeTeleop || activeTeleop?.regionBased) {
      return <></>;
    }

    const floorSchedulePoints = activeTeleop.schedulePoints.filter(point => point.floor.floor_code === activeTeleop.selectedFloor?.floor_code);

    return floorSchedulePoints.map(point => {
      return (
        <ManagedMapPoint
          key={point.point_id}
          point={point}
          activateArrow={activateArrow}
          pointerEvents={pointerEvents}
        />
      )
    });
  }, [activeTeleop, activateArrow, pointerEvents]);

  return (
    <>{floorPoints}</>
  )
}