import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Chip, ChipProps, TableCell, TextField, useTheme, useMediaQuery } from "@mui/material";
import { HeadCell, Table } from "../../common/Table";
import { TableRow } from "../../common/TableRow";
import { Device, DeviceCategory, DeviceStatus } from "../../../api/device";
import { useGeneratedPaths } from "../../../hooks/useGeneratedPaths";
import { DevicePrimaryLocation, DevicePrimaryLocationAutocomplete } from "./NewDevice/DeviceFormLocationDropdowns";
import { useDevicesQuery } from "../../../hooks/queries";

type DeviceTypeColors = Record<DeviceCategory, ChipProps['color']>;

const deviceTypeColors: DeviceTypeColors = {
  robot: 'success',
  gateway: 'violet',
  raspberrypi: 'error',
  "sim-card": 'offline',
  '': 'offline',
  modem: 'info',
  radio: 'secondary',
}

const filterableDeviceTypes: DeviceCategory[] = ['gateway', 'modem', 'radio', 'raspberrypi', 'sim-card']

type DeviceStatusColors = Record<DeviceStatus, ChipProps['color']>;

export const deviceStatusColors: DeviceStatusColors = {
  Online: 'success',
  Offline: 'offline',
  Charging: 'success'
}

interface DevicesTabProps {
  sessionStoragePrefix: string;
  projectId?: string;
}

export const DevicesTable = ({
  projectId,
  sessionStoragePrefix,
}: DevicesTabProps) => {
  const {
    generateDeviceDetailsPath,
    generateRoverDetailsPath,
  } = useGeneratedPaths();

  const sessionStorageFilterKey = `${sessionStoragePrefix}-device-filters`;
  const sessionStorageSearchKey = `${sessionStoragePrefix}-search-text`;
  const sessionStorageLocation = `${sessionStoragePrefix}-location`;

  const stringifiedFilters = sessionStorage.getItem(sessionStorageFilterKey);
  const initialFilters: DeviceCategory[] = !!stringifiedFilters ? JSON.parse(stringifiedFilters || '[]') : !!projectId ? [...filterableDeviceTypes] : ['gateway'];

  const storedSearchText = sessionStorage.getItem(sessionStorageSearchKey) ?? '';

  const storedStringifiedLocations = sessionStorage.getItem(sessionStorageLocation) ?? '[]';
  const parsedStoredLocations = JSON.parse(storedStringifiedLocations)
  const storedLocations = Array.isArray(parsedStoredLocations) ? parsedStoredLocations : [parsedStoredLocations];

  const [searchText, setSearchText] = useState<string>(storedSearchText);
  const [deviceTypeFilters, setDeviceTypeFilters] = useState<DeviceCategory[]>(initialFilters);
  const [selectedLocations, setSelectedLocations] = useState<DevicePrimaryLocation[]>(storedLocations);

  useEffect(() => {
    sessionStorage.setItem(sessionStorageFilterKey, JSON.stringify(deviceTypeFilters));
  }, [deviceTypeFilters, sessionStorageFilterKey]);

  useEffect(() => {
    sessionStorage.setItem(sessionStorageSearchKey, searchText);
  }, [searchText, sessionStorageSearchKey]);

  useEffect(() => {
    sessionStorage.setItem(sessionStorageLocation, JSON.stringify(selectedLocations));
  }, [selectedLocations, sessionStorageLocation]);

  const onlySimSelected = deviceTypeFilters.length === 1 && deviceTypeFilters[0] === 'sim-card';

  const { data: devices, isLoading: devicesLoading } = useDevicesQuery(projectId);
  const dataLoaded = !devicesLoading && !!devices;
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));

  const getSearchedDevices = useCallback((loadedDevices: Device[]) => {
    if (!searchText) {
      return [...loadedDevices]
    } else {
      return loadedDevices.filter(device => {
        const searchTextLower = searchText.toLowerCase();

        const nameMatch = device.name.toLowerCase().includes(searchTextLower);
        const statusMatch = device.status.toLowerCase().includes(searchTextLower);
        const typeMatch = device.type.toLowerCase().includes(searchTextLower);
        const locationMatch = device.location?.toLowerCase().includes(searchTextLower);
        const iperfMatch = device.iperf_port?.toString()?.toLowerCase().includes(searchTextLower);
        
        return nameMatch || statusMatch || typeMatch || locationMatch || iperfMatch;
      });
    }
  }, [searchText]);

  const filteredDevices = useMemo(() => {
    if (dataLoaded) {
      let searchedDevices = getSearchedDevices(devices);

      if (deviceTypeFilters.length > 0) {
        searchedDevices = searchedDevices.filter(device => deviceTypeFilters.includes(device.type));
      }

      if (selectedLocations.length > 0) {
        searchedDevices = searchedDevices.filter(device => {
          return selectedLocations.some(location => {
            const matchesActiveProject = location.type === 'Active Projects' && device.project_public_id === location.id;
            const matchesAuxiliaryLocation = location.type === 'Auxiliary Locations' && device.auxiliary_location === location.id;
            
            return matchesActiveProject || matchesAuxiliaryLocation;
          });
        });        
      }
      
      return searchedDevices;
    }

    return [];
  }, [dataLoaded, devices, getSearchedDevices, deviceTypeFilters, selectedLocations]);

  const columns: readonly HeadCell<any>[] = useMemo(() => [
    { id: 'DeviceID', label: 'ID' },
    { id: 'type', label: 'Category' },
    { id: 'name', label: 'Name' },
    { id: 'location', label: 'Location' },
    ...!onlySimSelected ? [{ id: 'ip', label: 'IP Address' }] : [],
    ...!onlySimSelected ? [{ id: 'iperf_port', label: 'iPerf Port' }] : [],
    { id: 'status', label: 'Status' },
  ], [onlySimSelected]);

  const renderVisibleRows = useCallback((row: Device, index: number) => {
    const redirectTo = row.type === 'robot' ? generateRoverDetailsPath(row.DeviceID) : generateDeviceDetailsPath(row.DeviceID);

    return (
      <TableRow
        hover
        redirectTo={redirectTo}
        role="checkbox"
        tabIndex={-1}
        key={index}
      >
        <TableCell align="left">{row.DeviceID}</TableCell>
        <TableCell align="left"><Chip label={row.type} color={deviceTypeColors[row.type]} sx={{ color: 'white' }}/></TableCell>
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left">{row.location}</TableCell>
        {!onlySimSelected && <TableCell align="left">{row.ip}</TableCell>}
        {!onlySimSelected && <TableCell align="left">{row.iperf_port}</TableCell>}
        <TableCell align="left"><Chip label={row.status} color={deviceStatusColors[row.status]} sx={{ color: 'white' }}/></TableCell>
      </TableRow>
    )
  }, [generateDeviceDetailsPath, generateRoverDetailsPath, onlySimSelected]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={compact ? 'column' : 'row'}
        alignItems={compact ? 'flex-start' : 'center'}
        flexWrap="wrap"
        marginBottom="-10px"
      >
        <Box
          marginBottom="10px"
        >
          {filterableDeviceTypes.map(deviceType => {
            const selected = deviceTypeFilters.includes(deviceType);

            return (
              <Chip
                key={deviceType}
                variant={selected ? 'filled' : 'outlined'}
                label={deviceType}
                color={deviceTypeColors[deviceType]}
                sx={{ color: 'white', mr: 1 }}
                onClick={() => {
                  if (selected) {
                    setDeviceTypeFilters(prevFilters => prevFilters.filter(filter => filter !== deviceType));
                  } else {
                    setDeviceTypeFilters(prevFilters => [...prevFilters, deviceType]);
                  }
                }}
              />
            );
          })}
        </Box>
        {!projectId &&
          <Box
            marginBottom="10px"
          >
            <DevicePrimaryLocationAutocomplete
              label="Site"
              minWidth={450}
              selectedLocation={selectedLocations}
              onChangeLocation={setSelectedLocations}
              multiple
            />
          </Box>
        }
        <Box
          marginBottom="10px"
        >
          <TextField
              label="Search Devices"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{width: '250px'}}
            />
        </Box>
      </Box>
      <Box>
        <Table
            columns={columns}
            rows={filteredDevices}
            initialOrderedColumnName='type'
            renderVisibleRows={renderVisibleRows}
            initialRowsPerPage={25}
          />
      </Box>
    </Box>
  );
}