import axios from "axios";
import { API_BASE } from "./constants";
import { Robot } from "./robots";
import { ScanTask } from "../components/views/Missions/MissionTeleop/TeleopDrawer/StartMission/MissionScriptGenerationControls";

export type MissionStatus = 'Complete' | 'Error' | 'In Progress';

interface Supervisor {
  first_name: string;
  last_name: string;
}

export interface Takeover {
  action: string;
  end_frame: number;
  mission: string;
  project: string;
  public_id: string;
  reason: string | null;
  robot: string;
  start_frame: number;
  user: string;
  video_url: string | null;
}

export interface Mission {
  id: number;
  public_id: string;
  start_time: string;
  start_time_formatted: string;
  end_time: string | null;
  end_time_formatted: string | null;
  robot: Robot;
  supervisor: Supervisor;
  supervisor_initials: string | null;
  status: MissionStatus;
  schedule_name: string;
  schedule_id: number;
  project_id: string;
  project_name: string;
  takeovers: Takeover[];
  manualDriveDuration?: number;
  is_test: boolean;
}

export interface ScheduleMission {
  id: number;
  mission_id: number;
  start_time: string;
  num_photos: number;
  status: MissionStatus;
}

const formatTime = (date: Date) => {
  const timeString = date.toLocaleTimeString();

  return timeString.replace(/:\d\d\s/, ' ');
}

const formatMission = (missions: Mission[]) => {
  missions.forEach((mission: Mission, i) => {
    const startTimeConverted = new Date(mission.start_time);

    mission.start_time_formatted = `${startTimeConverted.toLocaleDateString()} ${formatTime(startTimeConverted)}`;

    if (mission.end_time) {
      const endTimeConverted = new Date(mission.end_time);
      mission.end_time_formatted = `${endTimeConverted.toLocaleDateString()} ${formatTime(endTimeConverted)}`;
    }


    let supervisorInitials = null;

    if (mission.supervisor) {
      supervisorInitials = `${mission.supervisor.first_name[0]}${mission.supervisor.last_name[0]}`
    }

    mission.supervisor_initials = supervisorInitials;

    if (mission.takeovers.length > 0) {
      const driveTakeovers = mission.takeovers.filter(takeover => takeover.action === 'drive');
      const manualDriveDuration = driveTakeovers.reduce((accumulator, currentValue) => accumulator + (currentValue.end_frame - currentValue.start_frame), 0);

      mission.manualDriveDuration = Math.round(manualDriveDuration);
    }
  });
}

export const fetchRecentMissions = async () => {
  const res = await axios.get(
    `${API_BASE}/missions-manager/recent`
  );
  const response = await res.data;

  formatMission(response.data);

  return response.data;
}

export const fetchMyMissions = async () => {
  const res = await axios.get(
    `${API_BASE}/missions-manager/my_missions`
  );
  const response = await res.data;

  formatMission(response.data);

  return response.data;
}

export interface FetchAllMissionsParams {
  status?: MissionStatus;
  robotPublicId?: string;
  startDateLTE?: Date;
  startDateGTE?: Date;
  project?: string;
}

export const fetchAllMissions = async (params: FetchAllMissionsParams = {}): Promise<Mission[]> => {
  const res = await axios.get(
    `${API_BASE}/missions-manager`,
    {
      params: {
        status: params.status,
        robot: params.robotPublicId,
        start_date_gte: params.startDateGTE?.toISOString(),
        start_date_lte: params.startDateLTE?.toISOString(),
        project: params.project,
      }
    }
  );
  const response = await res.data;

  formatMission(response.data);

  return response.data;
}

export const fetchMission = async (missionId: string | number): Promise<Mission> => {
  const res = await axios.get(
    `${API_BASE}/mission-manager/${missionId}`
  );
  const response = await res.data;

  formatMission([response.data]);

  return response.data;
}

export const updateMission = async (missionId: string | number, updateData: Partial<Mission>) => {
  const res = await axios.patch(
    `${API_BASE}/mission-manager/${missionId}`,
    updateData
  );
  const response = await res.data;

  formatMission([response.data]);

  return response.data;
}

export const fetchNumScheduledMissions = async (): Promise<number> => {
  const res = await axios.get(
    `${API_BASE}/missions-manager/scheduled_missions`
  );
  const response = await res.data;
  return response.data;
}

export const fetchNumCompletedMissions = async (): Promise<number> => {
  const res = await axios.get(
    `${API_BASE}/missions-manager/completed_missions`
  );
  const response = await res.data;
  return response.data;
}

export const fetchScheduleMissions = async (scheduleId: number | string): Promise<ScheduleMission[]> => {
  const res = await axios.get(
    `${API_BASE}/schedule/${scheduleId}/get_missions`
  );
  const response = await res.data;

  response.data.forEach((mission: ScheduleMission) => {
    if (mission.start_time) {
      const startTimeConverted = new Date(mission.start_time);
      const day = startTimeConverted.getDate();
      const month = startTimeConverted.getMonth() + 1;
      const year = startTimeConverted.getFullYear();

      mission.start_time = `${month}-${day}-${year}`;
    }
  });

  return response.data;
}

interface CreateMissionInformation {
  supervisor_public_id: string;
  robot_public_id: string;
  schedule_id: string | number;
  project_id: string;
  is_test: boolean;
}

export const createMission = async (createMissionInformation: CreateMissionInformation): Promise<Mission> => {
  const res = await axios.post(
    `${API_BASE}/mission-utils/create-mission`,
    createMissionInformation
  );
  const response = await res.data;
  return response.data;
}

export const endMission = async (missionId: number): Promise<{}> => {
  const res = await axios.post(
    `${API_BASE}/mission-utils/${missionId}/end-mission`,
    {
      uploading_images: false,
      uploading_bag: false,
      rosbag_upload_num_parts: null,
      rosbag_file_name: null,
    }
  );
  const response = await res.data;
  return response.data;
}

export interface CreateScriptOptions {
  lidar_record: boolean;
  starting_goal: number;
  final_goal: number;
  image_upload: true;
  scan_task: ScanTask;
  network_record: boolean;
}

export const simpleCreateScript = async (scheduleId: number | string, robotPublicId: string, options: CreateScriptOptions) => {
  const res = await axios.post(
    `${API_BASE}/mission-utils/simple-create`,
    {
      schedule: scheduleId,
      robot_public_id: robotPublicId,
      options: options,
    }
  );

  const response = res.data;
  return response.data;
}

export const simpleGenerateStairClimberScript = async (scriptId: number) => {
  const res = await axios.post(
    `${API_BASE}/mission-utils/stair-climber-script`,
    {
      script_id: scriptId
    }
  );

  const response = res.data;
  return response.data;
}

export const fetchRobotMissions = async (robotPublicId: string, status?: MissionStatus): Promise<Mission[]> => {
  const queryParams: Record<string, string> = {};

  if (status) {
    queryParams.status = status;
  }

  const res = await axios.get(
    `${API_BASE}/robot/${robotPublicId}/missions`,
    {
      params: queryParams,
    },
  );
  const response = await res.data;

  formatMission(response.data);

  return response.data;
}

export interface MissionSpeedTest {
  download_speed: number | null;
  id: number;
  mission_id: number;
  ping: number | null;
  project_id: number;
  sub_viewpoint_id: number;
  taken_on: string;
  upload_speed: number | null;
}

export const fetchAllSpeedTests = async (projectPublicId?: string): Promise<MissionSpeedTest[]> => {
  const res = await axios.get(
    `${API_BASE}/mission/speed-tests`,
    {
      params: {
        project: projectPublicId,
      }
    }
  );

  const response = await res.data;
  return response.data;
}
