import axios from "axios";
import { API_BASE } from "./constants";

interface Staircase {
  id: number;
  name: string;
  project: {
    id: number;
    name: string;
    public_id: string;
  }
}

export interface StairClimberScriptFloor {
  project_floor: {
    id: number;
    floor_code: string;
  }
  approach_pose_x: number;
  approach_pose_y: number;
}

export interface StairClimberScript {
  floors: StairClimberScriptFloor[];
  id: number;
  name: string;
  script: string;
  staircase: Staircase | null;
}

export const initialStairClimberScript: StairClimberScript = {
  floors: [],
  id: 0,
  name: '',
  script: '',
  staircase: null,
}

export const fetchStairClimberScripts = async (projectId?: string): Promise<StairClimberScript[]> => {
  const res = await axios.get(
    `${API_BASE}/stair-climber-scripts`,
    {
      params: {
        project_id: projectId,
      }
    }
  );
  const response = await res.data;
  
  return response.data;
}
