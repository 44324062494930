import { Box, Grid } from "@mui/material"
import { StairClimberScript } from "../../../../../../api/stairs";
import { StairClimbingScriptsDropdown } from "../../../../../common/Dropdowns";
import { simpleGenerateStairClimberScript } from "../../../../../../api/missions";
import { LoadingIndicator } from "../../../../../common/LoadingIndicator";
import { useState } from "react";
import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";
import { useAlerts } from "../../../../../../hooks/useAlerts";

interface IStairClimbingScriptControlsProps {
  onChangeRobotScript: (newScript: Record<string, any> | null) => void;
  selectedStairClimberScript: StairClimberScript | null | undefined;
  setSelectedStairClimberScript: (newSelectedScript: StairClimberScript | null) => void;
}

export const StairClimbingScriptControls = ({
  onChangeRobotScript,
  selectedStairClimberScript,
  setSelectedStairClimberScript,
}: IStairClimbingScriptControlsProps) => {
  const {
    activeTeleop,
  } = useMissionManagementContext();

  const {addErrorAlert} = useAlerts();

  const project = activeTeleop?.project;

  const [scriptGenerationInProgress, setScriptGenerationInProgress] = useState<boolean>(false);

  const onChangeSelectedScript = async (newSelectedScript: StairClimberScript | null) => {
    setSelectedStairClimberScript(newSelectedScript);

    if (newSelectedScript) {
      try {
        setScriptGenerationInProgress(true);

        const parsedRobotScript = await simpleGenerateStairClimberScript(newSelectedScript.id); 
        
        onChangeRobotScript(parsedRobotScript);
      } catch {
        addErrorAlert("Error generating stair climbing script");
      } finally {
        setScriptGenerationInProgress(false);
      }
    } else {
      onChangeRobotScript(null);
    }
  };

  if (!project) {
    return <LoadingIndicator/>
  }

  return (
    <Grid item>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
      >
        <Box flex={1}>
          <StairClimbingScriptsDropdown
            projectId={project.public_id}
            selectedScript={selectedStairClimberScript}
            onChangeSelectedScript={onChangeSelectedScript}
          />
        </Box>
        {scriptGenerationInProgress &&
          <Box>
            <LoadingIndicator/>
          </Box>
        }
      </Box>
    </Grid>
  )
}