import { Button, Chip, /*ChipProps,*/ TableCell } from "@mui/material";
import { useGeneratedPaths } from "../../../../hooks/useGeneratedPaths";
import { HeadCell, Table } from "../../../common/Table"
import { TableRow } from "../../../common/TableRow";
import { Robot } from "../../../../api/robots";

interface RoversTableProps {
  robots: Robot[];
  onClickSpeedTestButton?: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: Robot) => void;
  onClickMissionButton?: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: Robot) => void;
}

export const RoversTable = ({
  robots,
  onClickSpeedTestButton,
  onClickMissionButton,
}: RoversTableProps) => {
  const {
    generateRoverDetailsPath,
  } = useGeneratedPaths();

  const columns: readonly HeadCell<any>[] = [
    { id: 'public_id', label: 'ID' },
    { id: 'name', label: 'Name' },
    { id: 'current_project', label: 'Current Project' },
    { id: 'vpn_ip_address', label: 'IP' },
    { id: 'is_charging', label: 'Is Charging?' },
    { id: 'is_online', label: 'Is Online?' },
    ...onClickSpeedTestButton ? [{ id: 'speedtest', label: 'Speed Test', align: 'center' as HeadCell<any>['align']}] : [],
    ...onClickMissionButton ? [{ id: 'mission', label: 'Mission', align: 'center' as HeadCell<any>['align']}] : [],
  ];

  const renderVisibleRows = (row: Robot, index: number) => {
    const redirectTo = generateRoverDetailsPath(row.device_id);

    return (
      <TableRow
        hover
        redirectTo={redirectTo}
        role="checkbox"
        tabIndex={-1}
        key={index}
      >
        <TableCell align="left">{row.public_id}</TableCell>
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left">{row.current_project}</TableCell>
        <TableCell align="left">{row.vpn_ip_address}</TableCell>
        <TableCell align="left">
          <Chip
            label={row.is_charging ? 'Charging' : 'Not Charging'}
            color={row.is_charging ? 'success' : 'offline'}
            sx={{ color: 'white' }}
          />
        </TableCell>
        <TableCell align="left">
          <Chip
            label={row.is_online ? 'Online' : 'Offline'}
            color={row.is_online ? 'success' : 'offline'}
            sx={{ color: 'white' }}
          />
        </TableCell>
        {!!onClickSpeedTestButton &&
          <TableCell align="left" onClick={e => onClickSpeedTestButton(e, row)}>
            {!!row.ssh_port &&
              <Button
                sx={{width: '100%', py: 1}}
              >
                Speed Test
              </Button>
            }
          </TableCell>
        }
        {!!onClickMissionButton &&
          <TableCell align="left" onClick={e => onClickMissionButton(e, row)}>
            <Button
              sx={{width: '100%', py: 1}}
            >
              Mission
            </Button>
          </TableCell>
        }
      </TableRow>
    )
  }

  return (
    <Table
      columns={columns}
      rows={robots}
      initialOrderedColumnName='name'
      renderVisibleRows={renderVisibleRows}
    />
  );
}