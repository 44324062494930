import { Box, Typography } from "@mui/material";
import { MissionMapImage } from "./MissionMapImage";
import { LoadingIndicator } from "../../../../../common/LoadingIndicator";
import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";

export const MissionInProgressTab = () => {
  const {
    activeTeleop,
  } = useMissionManagementContext();

  const {
    project,
    floors,
    schedule,
  } = activeTeleop || {};

  const dataLoaded = !!project && !!floors && !!schedule;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      height="100%"
    >
      <Box>
        { !dataLoaded &&
          <LoadingIndicator/>
        }
        { dataLoaded &&
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
              <Typography
                variant='h5'
              >
                {project.name}
              </Typography>
              <Typography
                variant='body2'
              >
                {schedule.name}, {schedule.frequency}
              </Typography>
          </Box>
        }
      </Box>
      <Box
        flex={1}
        overflow="hidden"
      >
        <MissionMapImage/>
      </Box>
    </Box>
  );
}