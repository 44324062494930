import { Box, CircularProgress } from "@mui/material"

interface LoadingIndicatorProps {
  size?: number;
  containerStyle?: React.CSSProperties;
  spinnerStyle?: React.CSSProperties;
}

export const LoadingIndicator = ({
  size,
  containerStyle,
  spinnerStyle,
}: LoadingIndicatorProps) => {
  return (
    <Box
      width="100%"
      display='flex'
      alignItems='center'
      justifyContent='center'
      style={containerStyle}
    >
      <CircularProgress
        size={size ?? 25}
        style={spinnerStyle}
      />
    </Box>
  )
}

export const PageLoadingIndicator = ({
  size,
}: LoadingIndicatorProps) => {
  return (
    <Box
      height="100vh"
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <CircularProgress size={size ?? 25}/>
    </Box>
  )
}