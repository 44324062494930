import { Avatar, Box, Chip, ChipProps, TableCell, TableRow } from "@mui/material";
import { Mission, MissionStatus } from "../../../api/missions";
import { useNavigation } from "../../../hooks/useNavigation";
import { HeadCell, Table } from "../../common/Table"

type MissionStatusColors = Record<MissionStatus, ChipProps['color']>;

export const missionStatusColors: MissionStatusColors = {
  Complete: 'success',
  'In Progress': 'violet',
  Error: 'error'
}

const columns: readonly HeadCell<Mission>[] = [
  { id: 'schedule_name', label: 'Schedule' },
  { id: 'start_time_formatted', label: 'Date', sortProperty: 'start_time' },
  { id: 'project_name', label: 'Location' },
  { id: 'robot', label: 'Rover', nestedSortProperty: 'name' },
  { id: 'supervisor_initials', label: 'Supervisor', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
];

interface IMissionsTableProps {
  rows: Mission[];
}

export const MissionsTable = ({
  rows
}: IMissionsTableProps) => {
  const {
    navigateToMissionControl,
  } = useNavigation();

  const handleClick = (event: React.MouseEvent<unknown>, row: Mission) => {
    navigateToMissionControl([row.id], []);
  };

  const renderVisibleRows = (row: Mission, index: number) => {
    return (
      <TableRow
        hover
        onClick={(event) => handleClick(event, row)}
        role="checkbox"
        tabIndex={-1}
        key={row.public_id}
      >
        <TableCell align="left">{row.schedule_name}</TableCell>
        <TableCell align="left">{row.start_time_formatted}</TableCell>
        <TableCell align="left">{row.project_name}</TableCell>
        <TableCell align="left">{row.robot.name}</TableCell>
        <TableCell>
          { row.supervisor_initials &&
            <Avatar sx={{ margin: 'auto' }}>{row.supervisor_initials}</Avatar>
          }
          { !row.supervisor_initials &&
            <Box textAlign='center'>Unassigned</Box>
          }
        </TableCell>
        <TableCell align="left">
          { !!row.status &&
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Chip label={row.status} color={missionStatusColors[row.status]} sx={{ color: 'white' }}/>
            </Box>
          }
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Table
      columns={columns}
      renderVisibleRows={renderVisibleRows}
      rows={rows}
      initialOrder='desc'
      initialOrderedColumnName='start_time'
      initialRowsPerPage={5}
    />
  )
}