import { Box } from "@mui/material";
import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";
import styled from "styled-components";
import { useMemo, useState } from "react";

type LogEvent = 'NODE_START' | 'NODE_STARING' | 'NODE_STARTED' | 'NODE_FUNCTIONING' | 'NODE_STOP' | 'NODE_STOPPING' | 'NODE_STOPPED' |
'ERROR_DETECTED' | 'FIX_APPLIED' | 'ERROR_RESOLVING' | 'START_MISSION' | 'PAUSE_MISSION' | 'RESUME_MISSION' | 'STOP_MISSION' | 
'SKIP_TASK' | 'SKIP_CHECKPOINT' | 'TASK_INITIALIZED' | 'TASK_PAUSED' | 'HELP' | 'TASK_RESUMED' | 'REQUESTING_SKIP_POINT';

export const isErrorEvent = (event: LogEvent) => {
  return event === 'ERROR_DETECTED';
}

type LogOperation = 'camera_360';

export type LogMessage = {
  event?: LogEvent;
  operation?: LogOperation;
  source: string;
  details?: any;
}

export const LogsTab = () => {
  const {
    activeTeleop
  } = useMissionManagementContext();

  const [selectedLogFilter, setSelectedLogFilter] = useState<string | null>(null);

  const parsedMessages = useMemo(() => {
    const messages = activeTeleop?.messages ?? [];

    return messages.map(message => JSON.parse(message) as LogMessage);
  }, [activeTeleop?.messages]);

  const messageFilteringOptions = useMemo(() => {
    const eventSet = new Set<string>();

    parsedMessages.forEach(message => {
      if (message.event) {
        eventSet.add(message.event);
      }
    });

    const eventArray = Array.from(eventSet);
    eventArray.sort();

    return eventArray;
  }, [parsedMessages]);

  const LogAlerts = useMemo(() => {
    let filteredMessages = parsedMessages.filter(message => {
      if (selectedLogFilter) {
        return message.event === selectedLogFilter
      }

      return true;
    });

    return filteredMessages.map((parsedMessage: LogMessage, i: number) => {
      const isErrorMessage = !!parsedMessage.event && isErrorEvent(parsedMessage.event);
      const color = isErrorMessage ? '#F24236' : '#34D399';

      let messageDetails = "";

      if (parsedMessage.details) {
        const stringifiedMessages = Object.getOwnPropertyNames(parsedMessage.details).map((propName: string) => {
          return `${propName}: ${parsedMessage.details[propName]}`;
        });

        messageDetails = stringifiedMessages.join(', ');
      }

      return (
        <Box
          color={color}
          paddingX="25px"
        >
          {parsedMessage.event &&
            <div><b>Event: </b>{parsedMessage.event}</div>
          }
          {parsedMessage.source &&
            <div><b>Source: </b>{parsedMessage.source}</div>
          }
          <div>{messageDetails}</div>
        </Box>
      );
    })
  }, [parsedMessages, selectedLogFilter]);

  return (
    <LogsContainer>
      <div style={{position: 'absolute', width: '100%'}}>
        {/* {messageFilteringOptions.length > 0 &&
          <Box
            display="flex"
            justifyContent="flex-end"
            width="100%"
            margin="5px 0px 10px 0px"
          >
            <Autocomplete
              options={messageFilteringOptions}
              value={selectedLogFilter}
              onChange={(e: React.SyntheticEvent, value: string | null) => setSelectedLogFilter(value)}
              renderInput={(params) => {
                return (
                  <TextField {...params} label="Log Type" />
                );
              }}
              sx={{
                width: '250px'
              }}
            />
          </Box>
        } */}
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
        >
          {LogAlerts}
        </Box>
      </div>
    </LogsContainer>
  );
}

const LogsContainer = styled.div`
  height: 100%;
  position: relative;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`