import {Button, ChipProps, Grid, Typography} from "@mui/material";
import { useNavigation } from "../../../hooks/useNavigation";
import { Breadcrumb } from "../../common/Breadcrumb"
import { DeviceStatus } from "../../../api/device";
import { PermissionWrappedComponent } from "../../common/PermissionWrapper";
import { DevicesTable } from "./DevicesTable";



type DeviceStatusColors = Record<DeviceStatus, ChipProps['color']>;

export const deviceStatusColors: DeviceStatusColors = {
  Online: 'success',
  Offline: 'offline',
  Charging: 'success'
}

export const Network = () => {
  const {
    navigateToCreateNewDevice,
  } = useNavigation();

  return (
    <Grid container spacing={2} direction='column'>
      <Grid item>
        <Grid container alignItems='center' justifyContent="space-between">
          <Grid item>
            <Typography variant='h5'>
              Devices
            </Typography>
            <Breadcrumb
              items={[{text: 'Devices'}]}
            />
          </Grid>
          <PermissionWrappedComponent permission="device_tables.create_device">
            <Grid item>
              <Button
                variant='contained'
                onClick={navigateToCreateNewDevice}
              >
                Add New Device
              </Button>
            </Grid>
          </PermissionWrappedComponent>
        </Grid>
      </Grid>

      <Grid item>
        <DevicesTable
          sessionStoragePrefix="all-devices"
        />
      </Grid>
    </Grid>
  )
}