import React, { useMemo } from 'react';
import { useRoverPathPoints } from '../../../../../hooks/teleopHooks/useRoverPathPoints';

export const PathPoints = () => {
  const pathPoints = useRoverPathPoints();

  const renderedPathPoints = useMemo(() => {
    if (pathPoints) {
      return pathPoints.map((point, i) => (
        <div
          key={i}
          style={{
            position: "absolute",
            pointerEvents: "none",
            border: "solid 1px",
            height: "10px",
            width: "10px",
            borderRadius: "30px",
            backgroundColor: "#00f",
            borderColor: "#00f",
            left: `${point.x - 5}px`,
            top: `${point.y - 5}px`,
          }}
        />
      ));
    }

    return <></>;
  }, [pathPoints]);

  return (
    <>{renderedPathPoints}</>
  )
}