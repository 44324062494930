import { useState, useEffect, useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { CameraAlt, Pause, PlayArrowOutlined, Stop, SkipNext, Settings, Add } from "@mui/icons-material";
import { useRoverTeleopActions } from "../../../../../hooks/teleopHooks/useRoverTeleopActions";
import { TeleopActionButton } from "../TeleopActionButton";
import { useMissionManagementContext } from "../../../../../contexts/missionManagementContext";
import { UNSAFE_OMEGA_FLAG, UNSAFE_PITCH_FLAG, UNSAFE_YAW_FLAG, useRoverStairClimberInformation } from "../../../../../hooks/teleopHooks/useRoverStairClimberInformation";
import { RoverActionConfirmationModal } from "./RoverActionConfirmationModal";
import { DrawerContentType } from "../TeleopDrawer/TeleopDrawer";

export enum RequiresConfirmation {
  none,
  stop_mission,
  skip_task,
  resume_mission,
}

enum HotKeys {
  Camera = '1',
  PauseMission = '2',
  ResumeMission = '3',
  StopMission = '4',
  SkipTask = '5',
  SkipPoint = '6',
  StartMission = '7',
  Maintenance = '8',
  AddMission = '9',
}

export const RoverActionButtons = () => {
  const {
    teleopTakePicture,
    missionTakePicture,
    pauseMission,
    resumeMission,
    stopMission,
    skipTask,
    skipCheckpoint,
  } = useRoverTeleopActions();

  const {
    missions,
    activeTeleop,
    openTeleopDrawer,
    drawerOpen,
    addNewMission,
    canAddMission,
  } = useMissionManagementContext();

  const {
    isPaused,
    requestSkipPoint,
    robot,
    mission,
    regionBased,
    selectedPoint,
    selectedProgressRegion,
  } = activeTeleop || {};

  const buttonsDisabled = !robot;
  const useMissionButtonSet = !!mission;

  const stairClimberStatus = useRoverStairClimberInformation();

  const [flickerBool, setFlickerBool] = useState(false);
  const [pressedKey, setPressedKey] = useState<string | null>(null);
  const [unsafePauseFlagSeen, setUnsafePauseFlagSeen] = useState<boolean>(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState<boolean>(false);
  const [confirmationModalType, setConfirmationModalType] = useState<RequiresConfirmation>(RequiresConfirmation.none);

  const addMissionButtonActive = (Object.getOwnPropertyNames(missions).length >= 1 && canAddMission);
  const startMissionButtonActive = !buttonsDisabled;
  const cameraButtonActive = !mission || (!!regionBased && !!selectedProgressRegion) || (!regionBased && !!selectedPoint);

  const isUnsafeFlag = (flag: string) => {
    return flag === UNSAFE_OMEGA_FLAG || flag === UNSAFE_PITCH_FLAG || flag === UNSAFE_YAW_FLAG;
  }

  useEffect(() => {
    if (stairClimberStatus && stairClimberStatus.pause_flag && isUnsafeFlag(stairClimberStatus.pause_flag)) {
      setUnsafePauseFlagSeen(true);
    }
  }, [stairClimberStatus]);

  useEffect( () => {
    if(isPaused || requestSkipPoint) {
      setTimeout(() => {
        setFlickerBool(!flickerBool)
      }, 500)
    }
  }, [isPaused, requestSkipPoint, flickerBool]);

  const onOpenConfirmationModal = useCallback((type: RequiresConfirmation) => {
    if (type === RequiresConfirmation.stop_mission) {
      pauseMission();
    }
    
    setConfirmationModalType(type);
    setDisplayConfirmationModal(true);
  }, [pauseMission]);

  const onCloseConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setConfirmationModalType(RequiresConfirmation.none);
  }

  const onResumeMission = useCallback(() => {
    setUnsafePauseFlagSeen(false);
    resumeMission();
  }, [resumeMission]);

  const onReceiveConfirmationModalResponse = useCallback((response: boolean) => {
    if (response) {
      if (confirmationModalType === RequiresConfirmation.skip_task) {
        skipTask();
      } else if (confirmationModalType === RequiresConfirmation.stop_mission) {
        stopMission();
      } else if (confirmationModalType === RequiresConfirmation.resume_mission) {
        onResumeMission();
      }
    } 

    onCloseConfirmationModal();
  }, [confirmationModalType, skipTask, stopMission, onResumeMission]);

  const onClickCameraButton = useCallback(() => {
    if (useMissionButtonSet) {
      missionTakePicture();
    } else {
      teleopTakePicture();
    }
  }, [useMissionButtonSet, missionTakePicture, teleopTakePicture]);

  const onClickPauseMissionButton = useCallback(() => {
    pauseMission();
  }, [pauseMission]);

  const onClickResumeMissionButton = useCallback(() => {
    if (unsafePauseFlagSeen) {
      onOpenConfirmationModal(RequiresConfirmation.resume_mission);
    } else {
      onResumeMission();
    }
  }, [onOpenConfirmationModal, onResumeMission, unsafePauseFlagSeen]);
  
  const onClickStopMissionButton = useCallback(() => {
    onOpenConfirmationModal(RequiresConfirmation.stop_mission)
  }, [onOpenConfirmationModal]);

  const onClickSkipTaskButton = useCallback(() => {
    onOpenConfirmationModal(RequiresConfirmation.skip_task);
  }, [onOpenConfirmationModal]);

  const onClickSkipPointButton = useCallback(() => {
    skipCheckpoint();
  }, [skipCheckpoint]);

  const onClickRobotMaintenance = useCallback(() => {
    openTeleopDrawer(DrawerContentType.robotMaintenance);
  }, [openTeleopDrawer]);

  const onClickStartMission = useCallback(() => {
    openTeleopDrawer(DrawerContentType.startMission);
  }, [openTeleopDrawer]);

  const onClickAddNewMission = useCallback(() => {
    addNewMission(undefined)
  }, [addNewMission]);

  const keyBindings: Partial<Record<HotKeys, (() => void) | undefined>> = useMemo(() => {
    if (!buttonsDisabled) {
      return {
        ...(cameraButtonActive && {[HotKeys.Camera]: onClickCameraButton}),
        ...(useMissionButtonSet && {[HotKeys.PauseMission]: onClickPauseMissionButton}),
        ...(useMissionButtonSet && {[HotKeys.ResumeMission]: onClickResumeMissionButton}),
        ...(useMissionButtonSet && {[HotKeys.StopMission]: onClickStopMissionButton}),
        ...(useMissionButtonSet && {[HotKeys.SkipTask]: onClickSkipTaskButton}),
        ...(useMissionButtonSet && {[HotKeys.SkipPoint]: onClickSkipPointButton}),
        [HotKeys.Maintenance]: onClickRobotMaintenance,
        ...(startMissionButtonActive && {[HotKeys.StartMission]: onClickStartMission}),
        ...(addMissionButtonActive && {[HotKeys.AddMission]: onClickAddNewMission}),
      }
    }

    return {};
  }, [
    buttonsDisabled,
    cameraButtonActive,
    useMissionButtonSet,
    onClickCameraButton,
    onClickPauseMissionButton,
    onClickResumeMissionButton,
    onClickStopMissionButton,
    onClickSkipTaskButton,
    onClickSkipPointButton,
    onClickRobotMaintenance,
    startMissionButtonActive,
    onClickStartMission,
    addMissionButtonActive,
    onClickAddNewMission,
  ]);

  const onKeyPress = useCallback((e: KeyboardEvent) => {
    if (!drawerOpen) {
      const key = e.key;
      setPressedKey(key);

      const action = keyBindings[key as HotKeys];

      if (action) {
        action();
      }
    }
  }, [drawerOpen, keyBindings]);

  const onKeyUp = useCallback(() => {
    setPressedKey(null);
  }, []);

  useEffect(() => {
    document.addEventListener('keypress', onKeyPress);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keypress', onKeyPress);
      document.removeEventListener('keyup', onKeyUp);
    }
  }, [onKeyPress, onKeyUp]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={1}
      minWidth="600px"
    >
      <Box
        display="flex"
        alignItems="center"
        gap={1}
      >
        <TeleopActionButton
          iconButton
          disabled={buttonsDisabled || !cameraButtonActive}
          hotkey={HotKeys.Camera}
          pressedKey={pressedKey}
          color='violet'
          onClick={keyBindings[HotKeys.Camera]}
        >
          <CameraAlt/>
        </TeleopActionButton>
        {useMissionButtonSet &&
          <>
            <TeleopActionButton
              iconButton
              disabled={buttonsDisabled}
              hotkey={HotKeys.PauseMission}
              pressedKey={pressedKey}
              color='lightBlue'
              variant={isPaused && flickerBool ? "contained" : "outlined"}
              onClick={keyBindings[HotKeys.PauseMission]}
            >
              <Pause/>
            </TeleopActionButton>
            <TeleopActionButton
              iconButton
              disabled={buttonsDisabled}
              hotkey={HotKeys.ResumeMission}
              pressedKey={pressedKey}
              color='success'
              onClick={keyBindings[HotKeys.ResumeMission]}
            >
              <PlayArrowOutlined/>
            </TeleopActionButton>
            <TeleopActionButton
              iconButton
              disabled={buttonsDisabled}
              hotkey={HotKeys.StopMission}
              pressedKey={pressedKey}
              color='error'
              onClick={keyBindings[HotKeys.StopMission]}
            >
              <Stop/>
            </TeleopActionButton>
            <TeleopActionButton
              iconButton
              disabled={buttonsDisabled}
              hotkey={HotKeys.SkipTask}
              pressedKey={pressedKey}
              onClick={keyBindings[HotKeys.SkipTask]}
            >
              <SkipNext/>
            </TeleopActionButton>
            <TeleopActionButton
              hotkey={HotKeys.SkipPoint}
              disabled={buttonsDisabled}
              pressedKey={pressedKey}
              variant={requestSkipPoint && flickerBool ? "contained" : "outlined"}
              onClick={keyBindings[HotKeys.SkipPoint]}
              sx={{
                height: '50px'
              }}
            >
              Skip point
            </TeleopActionButton>
          </>
        }
        <TeleopActionButton
          disabled={!startMissionButtonActive}
          hotkey={HotKeys.StartMission}
          pressedKey={pressedKey}
          onClick={keyBindings[HotKeys.StartMission]}
          sx={{
            height: '50px'
          }}
        >
          Start Mission
        </TeleopActionButton>
        <TeleopActionButton
          iconButton
          disabled={buttonsDisabled}
          hotkey={HotKeys.Maintenance}
          pressedKey={pressedKey}
          onClick={keyBindings[HotKeys.Maintenance]}
          color="offline"
        >
          <Settings/>
        </TeleopActionButton>
      </Box>      
      <Box>
        <TeleopActionButton
          iconButton
          disabled={!addMissionButtonActive}
          hotkey={HotKeys.AddMission}
          pressedKey={pressedKey}
          onClick={keyBindings[HotKeys.AddMission]}
        >
          <Add/>
        </TeleopActionButton>
      </Box>
      <RoverActionConfirmationModal
        open={displayConfirmationModal}
        onClose={onCloseConfirmationModal}
        type={confirmationModalType}
        onReceiveResponse={onReceiveConfirmationModalResponse}
      />
    </Box>
  )
}