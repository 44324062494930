import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import { isValidModemManufacturer, Modem, ModemFields, ModemManufacturerChoices, SimCard } from "../../../../api/device";
import { useNewDeviceContext } from "../../../../contexts/newDeviceContext";
import { SimCardsDropdown } from "../../../common/Dropdowns";

export const ModemForm = () => {
  const {newDevice, updateModem} = useNewDeviceContext() as {newDevice: Modem, updateModem: (item: Partial<ModemFields>) => void};

  return (
    <>
      <Typography sx={{textDecoration: 'underline'}}>Modem Fields</Typography>
      <SimCardsDropdown
        selectedSimCard={newDevice.sim_card}
        setSelectedSimCard={(newValue?: SimCard) => updateModem({sim_card: newValue})}
      />
      <TextField
        required
        label='Model'
        value={newDevice.model}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateModem({model: e.target.value})}
      />
      <TextField
        required
        label='MAC Address'
        value={newDevice.mac_address}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateModem({mac_address: e.target.value})}
      />
      <TextField
        required
        label='IMEI'
        value={newDevice.imei}
        inputProps={{ maxLength: 15 }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateModem({imei: e.target.value})}
      />
      <TextField
        label='Public IP'
        value={newDevice.public_ip}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateModem({public_ip: e.target.value})}
      />
      <FormControl sx={{ minWidth: 400 }}>
        <InputLabel id="manufacturer" required>Manufacturer</InputLabel>
        <Select
          labelId="manufacturer"
          value={newDevice.type}
          label="Manufacturer"
          onChange={(e: SelectChangeEvent<ModemManufacturerChoices>) => {
            if (isValidModemManufacturer(e.target.value)) {
              updateModem({type: e.target.value});
            }
          }}
        >
          <MenuItem key="netgear" value="netgear">
            Netgear
          </MenuItem>
          <MenuItem key="mofi" value="mofi">
            MoFi
          </MenuItem>
        </Select>
      </FormControl>
    </>
  )
}