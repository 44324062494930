import { Box, Button, Slider } from "@mui/material";
import { useRoverTeleopActions } from "../../../../../hooks/teleopHooks/useRoverTeleopActions";
import { useMissionManagementContext } from "../../../../../contexts/missionManagementContext";
import styled from "styled-components";
import { useAlerts } from "../../../../../hooks/useAlerts";

export const RoverFlipperAngleSlider = () => {
  const {
    activeTeleop,
    updateActiveMission,
  } = useMissionManagementContext();

  const {
    moveFlipper,
    setDefaultFlipperAngle,
  } = useRoverTeleopActions();

  const {
    sliderFlipperAngle
  } = activeTeleop || {}; 

  const {addSuccessAlert, addErrorAlert} = useAlerts();

  const onChangeSlider = (e: Event, value: number | number[]) => {
    updateActiveMission({sliderFlipperAngle: value as number});
  }

  const onChangeCommittedSlider = (e: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
    moveFlipper(value as number);
  }

  const onClickSetDefaultFlipperAngle = () => {
    try {
      setDefaultFlipperAngle();
      addSuccessAlert('Set default flipper angle message sent', {autoHideDuration: 3000});
    } catch {
      addErrorAlert('Error sending set default flipper angle message', {autoHideDuration: 3000});
    }
  }

  return (
    <SliderContainer>
      <Slider
        color='violet'
        valueLabelDisplay="auto"
        value={sliderFlipperAngle}
        onChange={onChangeSlider}
        onChangeCommitted={onChangeCommittedSlider}
        step={1}
        min={-50}
        max={50}
        sx={{
          height: '105px',
          '& input[type="range"]': {
            WebkitAppearance: 'slider-vertical',
          }
        }}
        orientation="vertical"
      />
      <Button
        variant="outlined"
        color='violet'
        sx={{
          fontSize: '12px',
          padding: '2px 6px'
        }}
        onClick={onClickSetDefaultFlipperAngle}
      >
        Set Default
      </Button>
    </SliderContainer>
  );
}

const SliderContainer = styled(Box)`
  margin-top: 10px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;