import { useState, useEffect } from 'react';
import { SoftwareVersion, createSoftwareVersion, editSoftwareVersion, fetchSoftwareVersions } from "../../../../api/versions";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { VersionsTable } from "./VersionsTable";

export const SoftwareVersionsTab = () => {
  const [softwareVersions, setSoftwareVersions] = useState<SoftwareVersion[]>([]);
  const [softwareVersionsLoaded, setSoftwareVersionsLoaded] = useState<boolean>(false);

  const dataLoaded = !!softwareVersions && !!softwareVersionsLoaded;

  useEffect(() => {
    setSoftwareVersionsLoaded(false);

    fetchSoftwareVersions().then(returnedVersions => {
      setSoftwareVersions(returnedVersions);
      setSoftwareVersionsLoaded(true);
    })
  }, []);

  return (
    <>
      { !dataLoaded &&
        <LoadingIndicator/>
      }
      
      { dataLoaded &&
        <VersionsTable
          data={softwareVersions}
          onCreateItem={createSoftwareVersion}
          onEditItem={editSoftwareVersion}
        />
      }
    </>
  );
}