import { useState, useEffect, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useGeneratedPaths } from "../../../hooks/useGeneratedPaths";
import { PageLoadingIndicator } from "../../common/LoadingIndicator";
import { MissionManagementProvider } from "../../../contexts/missionManagementContext";
import { MissionTeleop } from "./MissionTeleop/MissionTeleop";
import { Mission, fetchMission } from "../../../api/missions";

const getParam = (paramName: string) => {
  const queryParams = new URLSearchParams(window.location.search);

  return queryParams.get(paramName) ?? '';
}

export const MissionControl = () => {
  const {
    generateMissionDetailsPath
  } = useGeneratedPaths();

  const navigate = useNavigate();

  const initialDeviceIds = useMemo(() => {
    const robotDeviceIds = getParam('robots');

    return !!robotDeviceIds ? robotDeviceIds.split(',') : undefined;
  }, []);

  const [missions, setMissions] = useState<Mission[]>([]);
  const [missionsLoaded, setMissionsLoaded] = useState<boolean>(false);

  useEffect(() => {
    setMissionsLoaded(false);

    const missionIds = getParam('missions');
    const initialMissionIds = !!missionIds ? missionIds.split(',') : undefined;

    if (initialMissionIds) {
      Promise.all(initialMissionIds.map(missionId => {
        return fetchMission(missionId);
      }))
      .then(fetchedMissions => {
        setMissions(fetchedMissions);
        setMissionsLoaded(true)
      })
      .catch(() => {
        navigate('/');
      })
    } else {
      setMissionsLoaded(true);
    }
  }, [navigate]);

  if (!missionsLoaded) {
    return <PageLoadingIndicator/>
  }

  const singleCompletedMission = !initialDeviceIds?.length && missions.length === 1 && missions[0].status === 'Complete';

  return (
    <>
      {!singleCompletedMission &&
        <MissionManagementProvider
          initialMissions={missions}
          initialDeviceIds={initialDeviceIds}
        >
          <MissionTeleop/>
        </MissionManagementProvider>
      }
      {singleCompletedMission &&
        <Navigate
          to={generateMissionDetailsPath(missions[0].id)}
          state={{mission: missions[0]}}
        />
      }
    </>
  );
}