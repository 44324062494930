import { generatePath } from 'react-router';

export const PATH_STRINGS = {
  redirect: '/redirect',
  login: '/login',
  rovers: '/rovers',
  roverDetails: '/rovers/:deviceId',
  schedules: '/schedules',
  scheduleDetails: '/schedules/:scheduleId',
  network: '/network',
  deviceDetails: '/network/:deviceId',
  manageDevice: '/network/manage/:deviceId',
  myMissions: '/missions/my',
  allMissions: '/missions/all',
  missionDetails: '/missions/mission/:missionId/details',
  missionControl: '/mission-control',
  projects: '/projects',
  projectDetails: '/project/:projectId'
}

export const useGeneratedPaths = () => {
  const generateScheduleDetailsPath = (scheduleId: string | number) =>
    generatePath(PATH_STRINGS.scheduleDetails, {
      scheduleId: scheduleId.toString(),
    });

  const generateRoversPath = (params?: Record<string, string>) => {
    let path = PATH_STRINGS.rovers;

    if (params && Object.getOwnPropertyNames(params).length > 0) {
      path += '?' + new URLSearchParams(params);
    }

    return path;
  }

  const generateRoverDetailsPath = (deviceId: string | number) =>
    generatePath(PATH_STRINGS.roverDetails, {
      deviceId: deviceId.toString(),
    });
  
  const generateDeviceDetailsPath = (deviceId: string | number) =>
    generatePath(PATH_STRINGS.deviceDetails, {
      deviceId: deviceId.toString()
    });
  
  const generateManageDevicePath = (deviceId: string) => 
    generatePath(PATH_STRINGS.manageDevice, {
      deviceId: deviceId
    });

  const generateMissionDetailsPath = (missionId: string | number) =>
    generatePath(PATH_STRINGS.missionDetails, {
      missionId: missionId.toString(),
    });

  const generateMissionControlPath = (
    initialMissionIds: (string | number)[],
    initialDeviceIds: (string | number)[],
    isTest: boolean = false
  ) => {
    const queryDict: Record<string, string> = {};

    if (initialMissionIds.length > 0) {
      queryDict.missions = initialMissionIds.join(',');
    }

    if (initialDeviceIds.length > 0) {
      queryDict.robots = initialDeviceIds.join(',');
    }

    if (isTest) {
      queryDict.test = 'true';
    }

    let path = PATH_STRINGS.missionControl;

    if (Object.getOwnPropertyNames(queryDict).length > 0) {
      path += '?' + new URLSearchParams(queryDict);
    }

    return path;
  }

  const generateProjectDetailsPath = (projectPublicId: string, params?: Record<string, string>) => {
    let path = generatePath(PATH_STRINGS.projectDetails, {
      projectId: projectPublicId,
    });

    if (params && Object.getOwnPropertyNames(params).length > 0) {
      path += '?' + new URLSearchParams(params);
    }

    return path;
  }
  
  return {
    generateScheduleDetailsPath,
    generateRoversPath,
    generateRoverDetailsPath,
    generateDeviceDetailsPath,
    generateManageDevicePath,
    generateMissionDetailsPath,
    generateMissionControlPath,
    generateProjectDetailsPath,
  };
};