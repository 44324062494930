import axios from "axios";
import { API_BASE } from "./constants";
import { getNextScan, getNextScanFormatted } from "./helperFunctions";
import { ProjectFloorSection } from "./projectFloors";
import { projectBase } from "./projects";

export interface ScheduleFloorSection extends ProjectFloorSection {
  floor_code: string;
}

export interface Schedule {
  frequency: string;
  id: number;
  sub_id: number;
  is_active: boolean;
  name: string;
  num_points: number;
  project_id: string;
  project_name: string;
  schedule_floors: string[];
  sections: ScheduleFloorSection[];
  start_date: string;
  till_date: string;
  time: string;
  next_scan?: Date;
  next_scan_formatted?: string;
  next_scan_expired: boolean;
}

export interface ProjectSchedule {
  id: number | 'new';
  name: string;
  frequency: string;
  start_date: Date | string;
  till_date: Date | string;
  time: string;
}

export interface ProgressRegionPoint {
  id: number;
  order: number;
  x: number;
  y: number;
}

export interface ProgressRegion {
  id: number;
  position: ProgressRegionPoint[];
  viewpoint: number;
  viewpoint_sub_id: number;
}

export interface ProjectSchedulePoint {
  point_id: number;
  x: number;
  y: number;
  is_active: boolean;
  has_image: boolean;
  floor: {
    id: number;
    floor_code: string;
    name: string;
  }
  progress_regions: ProgressRegion[];
}

export enum Frequency {
  never = 'Frequency',
  once = 'once',
  weekly = 'weekly',
  monthly = 'monthly'
}

export const fetchSchedule = async (scheduleId: string | number): Promise<Schedule> => {
  const res = await axios.get(
    `${API_BASE}/schedule/${scheduleId}`
  );
  const response = await res.data;
  const schedule = await response.data;

  const { nextScan, isExpired } = getNextScan(schedule.frequency, schedule.start_date, schedule.till_date, schedule.time);
  schedule.next_scan = nextScan;
  schedule.next_scan_formatted = getNextScanFormatted(nextScan);
  schedule.next_scan_expired = isExpired;

  return schedule;
}

export const fetchSchedules = async (): Promise<Schedule[]> => {
  const res = await axios.get(
    `${API_BASE}/schedules`
  );
  const response = await res.data;

  response.data.forEach((schedule: Schedule) => {
    const { nextScan, isExpired } = getNextScan(schedule.frequency, schedule.start_date, schedule.till_date, schedule.time);
    schedule.next_scan = nextScan;
    schedule.next_scan_formatted = getNextScanFormatted(nextScan);
    schedule.next_scan_expired = isExpired;
  });

  return response.data;
}

export const fetchProjectSchedules = async (projectId: string): Promise<ProjectSchedule> => {
  const res = await axios.get(
    `${projectBase}/${projectId}/schedules`
  );
  const response = await res.data;
  return response.data;
}

export const fetchSchedulePoints = async (projectId: string, scheduleId: string | number): Promise<ProjectSchedulePoint[]> => {
  const res = await axios.get(
    `${projectBase}/${projectId}/schedule/${scheduleId}/points?fields=point_id,x,y,is_active,has_image,floor,progress_regions`
  );
  const response = await res.data;

  return response.data;
}

export const fetchSchedulesWithScansToday = async (): Promise<Schedule[]> => {
  const res = await axios.get(
    `${API_BASE}/schedules/today`
  );
  const response = await res.data;
  return response.data;
}