import { Box, Typography } from "@mui/material"
import { FloorTransitionModal, FloorTransitionModalType } from "./FloorTransitionModal";
import { useState } from "react";
import { Bedtime, Celebration, RestartAlt, VideoCameraBack } from "@mui/icons-material";
import { rebootStartupService, rebootStreamers } from "../../../../../../api/robots";
import { useRoverTeleopActions } from "../../../../../../hooks/teleopHooks/useRoverTeleopActions";
import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";
import { useAlerts } from "../../../../../../hooks/useAlerts";
import { RobotMaintenanceButton } from "./RobotMaintenanceButton";
import { RobotMaintenanceButtonEndIcon } from "./RobotMaintenanceButtonEndIcon";
import { RobotMaintenanceFloorTransitionButtons } from "./RobotMaintenanceFloorTransitionButtons";
import { RobotMaintenanceUploadButtons } from "./RobotMaintenanceUploadButtons";
import { RobotMaintenanceSensorControls } from "./RobotMaintenanceSensorControls";

enum PendingAction {
  streamers,
  startupService,
  deactivateFloorTransition,
}

export const RobotMaintenance = () => {
  const {
    activeTeleop,
  } = useMissionManagementContext();

  const {
    toggleRobotMode,
    restartNode,
  } = useRoverTeleopActions();

  const {addSuccessAlert, addErrorAlert} = useAlerts();

  const robot = activeTeleop?.robot;
  const project = activeTeleop?.project;

  const robotSSHPort = robot?.device.ssh_port;

  const [displayFloorTransitionModal, setDisplayFloorTransitionModal] = useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);
  const [floorTransitionModalType, setFloorTransitionModalType] = useState<FloorTransitionModalType | null>(null);

  const rebootStreamersPending = pendingAction === PendingAction.streamers;
  const rebootStartupServicePending = pendingAction === PendingAction.startupService;

  const resetPendingAction = () => {
    setPendingAction(null);
  }

  const onCloseFloorTransitionModal = () => {
    setFloorTransitionModalType(null);
    setDisplayFloorTransitionModal(false);
  }

  const onOpenFloorTransitionModal = (type: FloorTransitionModalType) => {
    setFloorTransitionModalType(type);
    setDisplayFloorTransitionModal(true);
  }

  const onClickRebootStreamers = async () => {
    if (robotSSHPort) {
      setPendingAction(PendingAction.streamers);

      try {
        await rebootStreamers(robotSSHPort);
        addSuccessAlert('Reboot streamers request sent successfully');
      } catch (err) {
        console.error(err);
        addErrorAlert('Error rebooting streamers');
      } finally {
        resetPendingAction();
      }
    }
  }

  const onClickRebootStartupSerice = async () => {
    if (robotSSHPort) {
      setPendingAction(PendingAction.startupService);

      try {
        await rebootStartupService(robotSSHPort);
        addSuccessAlert('Reboot startup service request sent successfully');
      } catch (err) {
        console.error(err);
        addErrorAlert('Error rebooting startup service');
      } finally {
        resetPendingAction();
      }
    }
  }

  // const onClickRecordLidar = () => {
  //   alert("Record Lidar Not Implemented");
  // }

  const onClickRestartVideoServer = () => {
    restartNode('web_server');
  }

  const onClickHibernate = () => {
    toggleRobotMode();
  }

  return (
    <>
      <Box
        sx={{
          width: '400px',
          height: '100%',
          paddingY: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px'
        }}
      >
        {!!robot &&
          <Typography variant='h4' textAlign="center">
            {robot.device.name}
          </Typography>
        }
        {!!project?.has_floor_transitions &&
          <RobotMaintenanceFloorTransitionButtons
            onOpenFloorTransitionModal={onOpenFloorTransitionModal}
          />
        }
        <RobotMaintenanceButton
          disabled={rebootStreamersPending}
          onClick={onClickRebootStreamers}
          endIcon={
            <RobotMaintenanceButtonEndIcon
              icon={<Celebration/>}
              loading={rebootStreamersPending}
            />
          }
        >
          Reboot Streamers
        </RobotMaintenanceButton>
        <RobotMaintenanceButton
          disabled={rebootStartupServicePending}
          onClick={onClickRebootStartupSerice}
          endIcon={
            <RobotMaintenanceButtonEndIcon
              icon={<RestartAlt/>}
              loading={rebootStartupServicePending}
            />
          }
        >
          Reboot Startup Service
        </RobotMaintenanceButton>
        {/* <RobotMaintenanceButton
          disabled
          onClick={onClickRecordLidar}
          endIcon={<Flare/>}
        >
          Record Lidar
        </RobotMaintenanceButton> */}
        <RobotMaintenanceButton
          onClick={onClickRestartVideoServer}
          endIcon={<VideoCameraBack/>}
        >
          Restart Video Server
        </RobotMaintenanceButton>
        <RobotMaintenanceButton
          onClick={onClickHibernate}
          endIcon={<Bedtime/>}
        >
          Hibernate
        </RobotMaintenanceButton>
        <RobotMaintenanceUploadButtons/>
        <RobotMaintenanceSensorControls/>
      </Box>
      <FloorTransitionModal
        open={displayFloorTransitionModal}
        onClose={onCloseFloorTransitionModal}
        type={floorTransitionModalType}
      />
    </>
  )
}