import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material"
import { Wifi, ElectricCar } from '@mui/icons-material';
import { MissionsTable } from "./MissionsTable";
import { StandardGrid } from "../../common/StandardGrid";
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths";
import { useNavigation } from "../../../hooks/useNavigation";
import { useGetFleetStatusQuery, useMissionCountsQuery, useRecentMissionsQuery, useSchedulesWithScansTodayQuery } from "../../../hooks/queries";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { PermissionWrappedComponent } from "../../common/PermissionWrapper";
import { TodaysSchedulesTable } from "./TodaysSchedulesTable";

export const Dashboard = () => {
  const {
    navigateToMyMissions,
    navigateToAllMissions,
  } = useNavigation();

  const { data: recentMissions, isLoading: missionsLoading } = useRecentMissionsQuery();
  const { data: missionCounts } = useMissionCountsQuery();
  const { data: fleetStatus } = useGetFleetStatusQuery();
  const { data: todaysSchedules } = useSchedulesWithScansTodayQuery();

  const dateDisplay = new Date().toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric" });
  const dataLoaded = !missionsLoading && !!recentMissions && !!missionCounts && !!todaysSchedules;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Card
          sx={{
            height: '100%'
          }}
        >
          <CardContent>
            <Typography variant='h5'>
              Today
            </Typography>
            <Typography color='text.secondary'>
              {dateDisplay}
            </Typography>
          </CardContent>
          <CardContent>
            { !dataLoaded && 
              <LoadingIndicator/>
            }
            { dataLoaded &&
              <>
                <Typography variant='h4' color='text.secondary'>
                  {missionCounts.numScheduledMissions} Scheduled Missions
                </Typography>
                <Typography color='text.secondary'>
                  {missionCounts.numCompletedMissions} Completed
                </Typography>
              </>
            }
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => navigateToMyMissions()}
            >
              My Missions
            </Button>
            <PermissionWrappedComponent
              permission='mission_tables.list_missions'
            >
              <Button
                size="small"
                onClick={() => navigateToAllMissions()}
              >
                All Missions
              </Button>
            </PermissionWrappedComponent>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card
          sx={{
            height: '100%'
          }}
        >
          <CardContent>
            <Typography variant='h5'>
              Fleet Status
            </Typography>
            {!fleetStatus &&
              <LoadingIndicator />
            }
            {fleetStatus &&
              <StandardGrid
                items={[
                  { icon: <Wifi/>, label: 'Network', to: PATH_STRINGS.network, info: `${fleetStatus.nodesOnline}/${fleetStatus.totalNodes} Nodes Online` },
                  { icon: <ElectricCar/>, label: 'Rovers', to: PATH_STRINGS.rovers, info: `${fleetStatus.roversOnline}/${fleetStatus.totalRovers} Rovers Online` }
                ]}
              />
            }
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant='h5'>
              Recent Missions
            </Typography>
          
          { !dataLoaded &&
            <LoadingIndicator/>
          }
          { dataLoaded &&
            <MissionsTable
              rows={recentMissions}
            />
          }
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{mb: 2}}>
          <CardContent>
            <Typography variant='h5'>
              Schedules With Scans Today
            </Typography>
          
          { !dataLoaded &&
            <LoadingIndicator/>
          }
          { dataLoaded &&
            <TodaysSchedulesTable
              schedules={todaysSchedules}
            />
          }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}