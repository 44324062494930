import { useCallback, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { BagUploadOperation, ImageUploadOperation, NexcoreStatus, NexcoreStatusOperation, NexcoreStatusOperationCategory } from "../../../../../../hooks/teleopHooks/useNexcoreStatus";
import { StairClimberStatus } from "../../../../../../hooks/teleopHooks/useRoverStairClimberInformation";

interface IEventsTabProps {
  nexcoreStatus: NexcoreStatus | null;
  stairClimberStatus: StairClimberStatus | null;
}

export const EventsTab = ({
  nexcoreStatus,
  stairClimberStatus
}: IEventsTabProps) => {
  const displayNexcoreStatus = !!nexcoreStatus;
  const displayStairClimberStatus = !!stairClimberStatus;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="20px"
    >
      <Box>
        {displayNexcoreStatus &&
          <Box>
            <Typography
              variant="h5"
              marginBottom="10px"
            >
              Nexcore Status:
            </Typography>
            <NexcoreStatusFields
              nexcoreStatus={nexcoreStatus}
              wrap={displayStairClimberStatus}
            />
          </Box>
        }
      </Box>
      <Box>
        {displayStairClimberStatus &&
          <Box>
            <Typography
              variant="h5"
              marginBottom="10px"
            >
              Stair Climber:
            </Typography>
            <StairClimberFields
              stairClimberStatus={stairClimberStatus}
            />
          </Box>
        }
      </Box>
    </Box>
  );
}

interface RobotInformationDisplayProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  label: string;
  value: string | number | null;
}

const RobotInformationDisplay = ({
  label,
  value,
  ...rest
}: RobotInformationDisplayProps) => {
  return (
    <div
      {...rest}
    >
      <b>{label}</b> {value}
    </div>
  )
}

interface NexcoreStatusFieldsProps {
  nexcoreStatus: NexcoreStatus;
  wrap?: boolean;
}

const NexcoreStatusFields = ({
  nexcoreStatus,
  wrap,
}: NexcoreStatusFieldsProps) => {
  const commonStyle = {
    flexBasis: wrap ? '50%' : '100%',
    paddingRight: '10px',
    paddingBottom: wrap ? '0px': '5px'
  };

  const getOperation = useCallback((operationName: NexcoreStatusOperation['name']) => {
    const operation: Record<NexcoreStatusOperationCategory, NexcoreStatusOperation | undefined> = {
      active: undefined,
      completed: undefined,
      failed: undefined,
    }

    if (nexcoreStatus) {
      operation.active = nexcoreStatus.operations.active.find(operation => operation.name === operationName);
      operation.completed = nexcoreStatus.operations.completed.find(operation => operation.name === operationName);
      operation.failed = nexcoreStatus.operations.failed.find(operation => operation.name === operationName);
    }

    return operation;
  }, [nexcoreStatus]);

  const imageUploadStatus = useMemo(() => {
    const imageOperations = getOperation('image_upload') as Record<NexcoreStatusOperationCategory, ImageUploadOperation | undefined>;

    return imageOperations.failed?.progress || imageOperations.completed?.progress || imageOperations.active?.progress;
  }, [getOperation]);

  const bagUploadStatus = useMemo(() => {
    const bagOperations = getOperation('bag_upload') as Record<NexcoreStatusOperationCategory, BagUploadOperation | undefined>;

    return bagOperations.failed?.progress || bagOperations.completed?.progress || bagOperations.active?.progress;
  }, [getOperation]);

  const mapDownloadStatus = useMemo(() => {
    const mapDownloadOperationName = "map_download";
    const {
      active: activeOperations,
      completed: completedOperations,
      failed: failedOperations,
    } = nexcoreStatus.operations;
    
    return {
      active: activeOperations.filter(operation => operation.name === mapDownloadOperationName),
      completed: completedOperations.filter(operation => operation.name === mapDownloadOperationName),
      failed: failedOperations.filter(operation => operation.name === mapDownloadOperationName),
    }
  }, [nexcoreStatus.operations]);

  return (
    <Box
      display="flex"
      flexWrap="wrap"
    >
      <RobotInformationDisplay
        label="Core State:"
        value={nexcoreStatus.core_state}
        style={commonStyle}
      />
      <RobotInformationDisplay
        label="Task State:"
        value={nexcoreStatus.task_state}
        style={commonStyle}
      />
      <RobotInformationDisplay
        label="Task Name:"
        value={nexcoreStatus.task_name}
        style={commonStyle}
      />
      <RobotInformationDisplay
        label="Error Nodes:"
        value={nexcoreStatus.error_nodes.join(', ')}
        style={commonStyle}
      />
      <RobotInformationDisplay
        label="Logs:"
        value={nexcoreStatus.logs}
        style={commonStyle}
      />
      {!!imageUploadStatus &&
        <RobotInformationDisplay
          label="Image Upload:"
          value={imageUploadStatus}
          style={{
            flexBasis: wrap ? '50%' : '100%',
          }}
        />
      }
      {!!bagUploadStatus &&
        <RobotInformationDisplay
          label="Bag Upload:"
          value={bagUploadStatus}
          style={{
            flexBasis: wrap ? '50%' : '100%',
          }}
        />
      }
      {Object.entries(mapDownloadStatus).map(([operationName, operations], i) => {
        return operations.map(operation => (
          <RobotInformationDisplay
            key={i}
            label={`${operationName[0].toUpperCase() + operationName.substring(1).toLowerCase()} Map Download:`}
            value={operation.progress}
            style={commonStyle}
          />
        ))
      })}
    </Box>
  )
}

interface StairClimberFieldsProps {
  stairClimberStatus: StairClimberStatus;
}

const StairClimberFields = ({
  stairClimberStatus,
}: StairClimberFieldsProps) => {
  const {
    x,
    y,
    z,
  } = stairClimberStatus.orientation ?? {x: null, y: null, z: null};

  const truncateStairClimberOrientation = (num: number | null) => {
    if (num !== null) {
      return parseFloat(num.toFixed(3));
    }
    
    return 0;
  }

  return (
    <Box
      display="flex"
      gap="5px"
    >
      <Box
        flex={1}
      >
        <RobotInformationDisplay
          label="Mode:"
          value={stairClimberStatus.mode}
        />
        <RobotInformationDisplay
          label="State:"
          value={stairClimberStatus.state}
        />
        <RobotInformationDisplay
          label="Flag:"
          value={stairClimberStatus.flag}
        />
        <RobotInformationDisplay
          label="Turn Direction:"
          value={stairClimberStatus.turn_direction}
        />
        <RobotInformationDisplay
          label="Remaining Landings:"
          value={stairClimberStatus.remaining_landings}
        />
        <RobotInformationDisplay
          label="Flipper Angle:"
          value={stairClimberStatus.curr_flipper_angle}
        />
      </Box>
      <Box
        flex={1}
      >
        <RobotInformationDisplay
          label="Direction:"
          value={stairClimberStatus.direction}
        />
        <RobotInformationDisplay
          label="Substate:"
          value={stairClimberStatus.substate}
        />
        <RobotInformationDisplay
          label="Pause Flag:"
          value={stairClimberStatus.pause_flag}
        />
        <RobotInformationDisplay
          label="Turn Type:"
          value={stairClimberStatus.turn_type}
        />
        <RobotInformationDisplay
          label="Dist. To Far Edge:"
          value={stairClimberStatus.dist_to_far_edge}
        />
        <RobotInformationDisplay
          label="Orientation:"
          value={stairClimberStatus.orientation && `(${truncateStairClimberOrientation(x)}, ${truncateStairClimberOrientation(y)}, ${truncateStairClimberOrientation(z)})`}
        />
      </Box>
    </Box>
  )
}