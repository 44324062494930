import { LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

interface LinearProgressWithLabelProps {
  label?: string;
  value: number;
  containerStyles?: React.CSSProperties; 
}

export const LinearProgressWithLabel = (props: LinearProgressWithLabelProps & LinearProgressProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ...props.containerStyles }}>
      {!!props.label &&
        <Box>
          <Typography variant="body2" color="text.secondary" width="50px">
            {props.label}
          </Typography>
        </Box>
      }
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(props.value, )}%`}
        </Typography>
      </Box>
    </Box>
  );
}