import { Box, Switch, alpha, useTheme } from "@mui/material";
import { blue, green, grey, red } from "@mui/material/colors";
import { useMemo } from "react";
import styled from "styled-components";

interface ISafetyDetectorControlProps {
  detector: string;
  enabled: boolean;
  triggered: boolean;
  temp: boolean;
  onChangeSwitch: (checked: boolean) => void;
  switchDisabled?: boolean;
}

export const SafetyDetectorControl = ({
  detector,
  enabled,
  triggered,
  temp,
  onChangeSwitch,
  switchDisabled
}: ISafetyDetectorControlProps) => {
  const checked = enabled;
  const theme = useTheme();

  const name = useMemo(() => {
    return detector.split('_').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  }, [detector]);

  const boxColor = useMemo(() => {
    return temp ? 'primary.main' : enabled ? (triggered ? 'error.main' : 'success.main') : '#A4A9AD';
  }, [enabled, temp, triggered]);

  const switchSX = useMemo(() => {
    if (enabled) {
      const color = temp ? blue : triggered ? red : green;
      const index = 900;
      const disabledIndex = 700;

      return {
        '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
          color: grey[disabledIndex],
          '&:hover': {
            backgroundColor: alpha(grey[disabledIndex], theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: color[index],
          '&:hover': {
            backgroundColor: alpha(color[index], theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: grey[disabledIndex],
        },
        '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: color[index],
        },
      }
    }
  }, [enabled, temp, theme.palette.action.hoverOpacity, triggered]);

  return (
    <Box>
      <SafetyDetectorControlContainer
        sx={{
          bgcolor: boxColor,
        }}
        onClick={() => onChangeSwitch(!checked)}
      >
        <Box>
          {name}
        </Box>
        <Switch
          disabled={switchDisabled}
          checked={checked}
          sx={switchSX}
        />
      </SafetyDetectorControlContainer>
    </Box>
  )

  // return (
  //   <Box>
  //     <SafetyDetectorControlContainer>
  //       <Box
  //         sx={{
  //           bgcolor: boxColor,
  //           width: '250px',
  //           padding: '7px 5px',
  //           borderRadius: '10px',
  //         }}
  //       >
  //         {name}
  //       </Box>
  //       <Switch
  //         disabled={switchDisabled}
  //         checked={enabled}
  //         onChange={(e, checked) => onChangeSwitch(checked)}
  //         // sx={switchSX}
  //       />
  //     </SafetyDetectorControlContainer>
  //   </Box>
  // )
}

const SafetyDetectorControlContainer = styled(Box)`
  width: 300px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 5px;
  border-radius: 10px;
  cursor: pointer;
`;