import { useCallback, useState } from "react"
import { RoverSocketOnMessageCallback, useRoverSocketOnMessage } from "./useRoverSocketOnMessage";

export const useRoverLinearVelocity = (teleopIdentifier?: string) => {
  const [speedX, setSpeedX] = useState<number | null>(null);
  const [speedY, setSpeedY] = useState<number | null>(null);

  const onReceiveLinearVelocityMessage: RoverSocketOnMessageCallback = useCallback(({message_type, message_version, message}) => {
    if (message_type === 'linear_velocity') {
      if (message_version === 1) {
        setSpeedX(message.linear.x);
        setSpeedY(message.angular.z);
      } else if (message_version === 2) {
        setSpeedX(message.x);
        setSpeedY(message.y);
      }
    }
  }, []);

  const resetLinearVelocity = useCallback(() => {
    setSpeedX(null);
    setSpeedY(null);
  }, []);

  useRoverSocketOnMessage(onReceiveLinearVelocityMessage, resetLinearVelocity, teleopIdentifier);

  return {
    speedX,
    speedY,
  };
}