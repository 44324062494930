import React, { useMemo, useCallback } from 'react';
import { Check, DoNotDisturbAlt, Delete, EditOutlined } from "@mui/icons-material";
import { Drawer, Typography, Alert, TextField, Button, Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { createDeviceNote, updateDeviceNote, DeviceNote, fetchDeviceNotes } from "../../../../api/device";
import { useUserContext } from "../../../../contexts/userContext";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { FullScreenModal, ModalTitle } from '../../../common/FullScreenModal';
import { useAlerts } from '../../../../hooks/useAlerts';

const enterKey = 'Enter';

interface IDeviceNotesDrawerProps {
  open: boolean;
  onClose: () => void;
  deviceId: string;
}

export const DeviceNotesDrawer = ({
  open,
  onClose,
  deviceId,
}: IDeviceNotesDrawerProps) => {
  const [deviceNotes, setDeviceNotes] = useState<DeviceNote[]>([]);
  const [notesLoaded, setNotesLoaded] = useState<boolean>(false);
  const [newNote, setNewNote] = useState<string>('');
  const [deleteNoteModalOpen, setDeleteNoteModalOpen] = useState<boolean>(false);
  const [notePendingDelete, setNotePendingDelete] = useState<DeviceNote | null>(null);

  const {addSuccessAlert, addErrorAlert} = useAlerts();

  const {
    state: userState
  } = useUserContext();

  useEffect(() => {
    if (open) {
      fetchDeviceNotes(deviceId)
        .then(returnedNotes => {
          setDeviceNotes(returnedNotes);
          setNotesLoaded(true);
        })
    } else {
      setNotesLoaded(false);
    }
  }, [open, deviceId]);

  const onClickAddNote = () => {
    setNotesLoaded(false);

    const noteToCreate = {
      note: newNote,
      user_public_id: userState.public_id
    };

    createDeviceNote(deviceId, noteToCreate)
      .then(createdNote => {
        setDeviceNotes(prevNotes => [createdNote, ...prevNotes]);
        setNewNote('');
        setNotesLoaded(true);
        addSuccessAlert('Note saved successfully');
      })
      .catch(err => {
        addErrorAlert('Error saving note');
      });
  }

  const onCloseModal = () => {
    setDeleteNoteModalOpen(false);
    setNotePendingDelete(null);
  }

  const onInitiateDeleteNote = (deviceNote: DeviceNote) => {
    if (userState.public_id === deviceNote.user.public_id) {
      setNotePendingDelete(deviceNote);
      setDeleteNoteModalOpen(true);
    }
  }

  const onDeleteNote = useCallback(async () => {
    if (notePendingDelete && userState.public_id === notePendingDelete.user.public_id) {
      try {
        const deletedNote = await updateDeviceNote(notePendingDelete.device_id, notePendingDelete.id, {deleted: true});
        
        setDeviceNotes(prevNotes => prevNotes.filter(note => note.id !== deletedNote.id));
        onCloseModal();
        addSuccessAlert('Note deleted successfully');
      } catch (err) {
        addErrorAlert('Error deleting note');
      }
    }
  }, [addErrorAlert, addSuccessAlert, notePendingDelete, userState.public_id]);

  const onUpdateNote = useCallback(async (deviceNote: DeviceNote, newNote: string) => {
    if (userState.public_id === deviceNote.user.public_id) {
      try {
        const updatedNote = await updateDeviceNote(deviceNote.device_id, deviceNote.id, {note: newNote});
      
        setDeviceNotes(prevNotes => {
          return prevNotes.map(note => {
            if (note.id === deviceNote.id) {
              return updatedNote;
            }

            return note;
          });
        });

        addSuccessAlert('Note updated successfully');
      } catch (err) {
        addErrorAlert('Error updating note');
      }
    }
  }, [addErrorAlert, addSuccessAlert, userState.public_id]);

  const handleNewNoteEnterPress = (e: React.KeyboardEvent) => {
    if (e.key === enterKey) {
      e.preventDefault();
      onClickAddNote();
    }
  }

  return (
    <>
      <Drawer
        anchor='right'
        open={open}
        onClose={onClose}
      >
        <Box
          display="flex"
          sx={{
            flexDirection: 'column',
            height: '100%',
            width: '300px',
            padding: '74px 20px 10px 20px',
            gap: '10px',
            '& ::-webkit-scrollbar':{
              width: 0,
              height: 0
            },
            alignItems: 'center'
          }}
        >
          <Typography>Notes</Typography>

          {!notesLoaded &&
            <Box width="250px">
              <LoadingIndicator/>
            </Box>
          }

          <Box flex='1 1 auto' sx={{overflowY: 'auto', minHeight: '0px'}}>
            {deviceNotes.map(deviceNote => {
              const currentUserIsNoteAuthor = userState.public_id === deviceNote.user.public_id;

              return (
                <DeviceNoteAlert
                  deviceNote={deviceNote}
                  onDeleteNote={currentUserIsNoteAuthor ? () => onInitiateDeleteNote(deviceNote) : undefined}
                  onUpdateNote={currentUserIsNoteAuthor ? newNote => onUpdateNote(deviceNote, newNote) : undefined}
                  currentUserIsNoteAuthor={currentUserIsNoteAuthor}
                />
              )
            })}
          </Box>
              
          <TextField
            onKeyDown={handleNewNoteEnterPress}
            multiline
            fullWidth
            label='New Note'
            rows={5}
            value={newNote}
            onChange={e => setNewNote(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={onClickAddNote}
            disabled={!notesLoaded}
          >
            Add Note
          </Button>
        </Box>
      </Drawer>
      <DeleteNoteModal
        open={deleteNoteModalOpen}
        onClose={onCloseModal}
        onConfirmDelete={onDeleteNote}
        noteText={notePendingDelete?.note}
      />
    </>
  )
}

interface IDeviceNoteAlertProps {
  deviceNote: DeviceNote;
  currentUserIsNoteAuthor: boolean;
  onDeleteNote?: () => void;
  onUpdateNote?: (newNote: string) => Promise<void>;
}

const DeviceNoteAlert = ({
  deviceNote,
  currentUserIsNoteAuthor,
  onDeleteNote,
  onUpdateNote,
}: IDeviceNoteAlertProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [noteTextFieldValue, setNoteTextFieldValue] = useState<string>(deviceNote.note);

  const registeredOn = new Date(deviceNote.registered_on);
  const dateString = `${registeredOn.getMonth() + 1}/${registeredOn.getDate()}/${registeredOn.getFullYear()}`;
  const hours = ((registeredOn.getHours() + 11) % 12 + 1);
  const amPm = registeredOn.getHours() < 12 ? 'AM' : 'PM';
  const minutesNumber = registeredOn.getMinutes();
  const minutes = minutesNumber < 10 ? `0${minutesNumber}` : minutesNumber;
  const timeString = `${hours}:${minutes} ${amPm}`;
  
  const registeredOnFormatted = `${dateString} ${timeString}`;

  const onClickDeleteButton = useCallback(() => {
    if (!editMode) {
      if (onDeleteNote) {
        onDeleteNote();
      }
    } else {
      setEditMode(false);
    }
  }, [editMode, onDeleteNote]);

  const onClickEditButton = useCallback(() => {
    if (!editMode) {
      setEditMode(true);
      setNoteTextFieldValue(deviceNote.note);
    } else {
      if (onUpdateNote) {
        onUpdateNote(noteTextFieldValue).then(() => {
          setEditMode(false);
        });
      }
    }
  }, [deviceNote.note, editMode, onUpdateNote, noteTextFieldValue]);

  const alertAction = useMemo(() => {
    if (currentUserIsNoteAuthor) {
      return (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <AlertActionButton
            onClick={onClickDeleteButton}
          >
            {editMode &&
              <DoNotDisturbAlt fontSize="small"/>
            }
            {!editMode &&
              <Delete fontSize="small"/>
            }
          </AlertActionButton>
          <AlertActionButton
            onClick={onClickEditButton}
          >
            {editMode && 
              <Check fontSize="small"/>
            }
            {!editMode &&
              <EditOutlined fontSize="small"/>
            }
          </AlertActionButton>
        </Box>
      )
    } else {
      return undefined;
    }
  }, [currentUserIsNoteAuthor, editMode, onClickDeleteButton, onClickEditButton]);

  const onEditFieldKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === enterKey) {
      e.preventDefault();
      onClickEditButton();
    }
  }

  return (
    <Alert
      key={deviceNote.id}
      severity="info"
      icon={false}
      action={alertAction}
      sx={{
        mt: 1,
        width: '250px',
        display: 'relative',
        '& .MuiAlert-message': {
          width: '100%'
        },
        '& .MuiAlert-action': {
          padding: '4px 0px 0px 4px'
        }
      }}
    >
      {!editMode &&
        <>
          {deviceNote.note}
        </>
      }
      {editMode &&
        <TextField
          onKeyDown={onEditFieldKeyDown}
          multiline
          fullWidth
          rows={4}
          inputProps={{
            style: {
              fontSize: '14px',
            }
          }}
          sx={{
            '& .MuiInputBase-root': {
              padding: '5px'
            }
          }}
          value={noteTextFieldValue}
          onChange={e => setNoteTextFieldValue(e.target.value)}
        />
      }
      
      {!editMode &&
        <>
          <br/>
          <br/>
          {`-${deviceNote.user.first_name} ${deviceNote.user.last_name}`} 
          <br/>
          {registeredOnFormatted}
        </>
      }
    </Alert>
  )
}

interface IAlertActionButtonProps extends React.PropsWithChildren {
  onClick?: () => void;
}

const AlertActionButton = ({
  onClick,
  children
}: IAlertActionButtonProps) => {
  return (
    <IconButton
      size="small"
      onClick={onClick}
    >
      {children}
    </IconButton>
  )
}

interface IDeleteNoteModalProps {
  open: boolean;
  noteText?: string;
  onClose: () => void;
  onConfirmDelete: () => void;
}

const DeleteNoteModal = ({
  open,
  noteText,
  onClose,
  onConfirmDelete,
}: IDeleteNoteModalProps) => {
  return (
    <FullScreenModal
      open={open}
      onClose={onClose}
    >
      <ModalTitle>
        Please Confirm
      </ModalTitle>
      <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: '18px' }}>
        Are you sure you would like to delete note:
      </Typography>
      <Typography>
        "{noteText}"
      </Typography>
      <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
        <Button
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          color="error"
          variant='contained'
          onClick={onConfirmDelete}
        >
          Delete
        </Button>
      </Box>
    </FullScreenModal>
  )
}