import { Breadcrumbs, Link, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";

interface IBreadcrumbItem {
  text: string;
  to?: string;
}

interface IBreadcrumbProps {
  hideHome?: boolean;
  items: IBreadcrumbItem[];
}

export const Breadcrumb = ({
  hideHome,
  items
}: IBreadcrumbProps) => {
  const navigate = useNavigate();

  const onClickLink = (e: React.MouseEvent<HTMLAnchorElement>, to?: string) => {
    e.preventDefault();

    if (to) {
      navigate(to);
    }
  }

  const breadcrumbChildren = items.map((item: IBreadcrumbItem, index: number, arr: IBreadcrumbItem[]) => {
    if (index !== arr.length - 1) {
      return (
        <Link
          key={item.text}
          underline="hover"
          color="inherit"
          href={item.to}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => onClickLink(e, item.to)}
        >
          {item.text}
        </Link>
      )
    } else {
      return (
        <Typography key={item.text} color="text.primary">{item.text}</Typography>
      )
    }
  });

  if (!hideHome) {
    breadcrumbChildren.unshift(
      <Link
        key='home'
        underline="hover"
        color="inherit"
        href='/'
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => onClickLink(e, '/')}
      >
        Home
      </Link>
    )
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbChildren}
    </Breadcrumbs>
  )
}