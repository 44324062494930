import { useMemo } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { ProjectFloor } from "../../../../../../api/projectFloors";
import { Schedule } from "../../../../../../api/schedules";
import { useSchedulesQuery } from "../../../../../../hooks/queries";
import { LoadingIndicator } from "../../../../../common/LoadingIndicator";
import { useMissionManagementContext } from "../../../../../../contexts/missionManagementContext";
import { MissionMapImage } from "./MissionMapImage";

export const CreateMissionTab = () => {
  const {
    activeTeleop,
    updateActiveMission,
  } = useMissionManagementContext();

  const {
    project,
    floors,
    selectedFloor,
    schedule,
  } = activeTeleop || {};

  const {data: schedules} = useSchedulesQuery();

  const scheduleOptions = useMemo(() => {
    if (schedules && selectedFloor) {
      return schedules.filter(schedule => {
        const matchesProject = schedule.project_id === project?.public_id;
        const matchesFloorCode = schedule.schedule_floors.includes(selectedFloor?.floor_code); 

        return matchesProject && matchesFloorCode;
      });
    }

    return schedules ?? [];
  }, [schedules, selectedFloor, project]);

  const onChangeProjectFloor = (e: React.SyntheticEvent, value: ProjectFloor | null) => {
    updateActiveMission({
      selectedFloor: value,
      schedule: null,
    });
  }

  const onChangeSchedule = (e: React.SyntheticEvent, value: Schedule | null) => {
    updateActiveMission({
      schedule: value,
    });
  }

  if (!project) {
    return <LoadingIndicator/>
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1.5}
      height="100%"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        <Typography
          variant='h5'
        >
          {project.name}
        </Typography>
        <Box
          display="flex"
          gap={1}
          alignItems="center"
        >
          <Autocomplete
            options={floors ?? []}
            value={selectedFloor}
            getOptionLabel={option => option.floor_code}
            onChange={onChangeProjectFloor}
            renderInput={(params) => {
              return (
                <TextField {...params} label="Floor" />
              )
            }}
          />
          <Autocomplete
            options={scheduleOptions}
            value={schedule}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={onChangeSchedule}
            sx={{width: '250px'}}
            renderInput={(params) => {
              return (
                <TextField {...params} label="Schedule" />
              )
            }}
          />
        </Box>
      </Box>
      <Box
        flex={1}
        overflow="hidden"
      >
        <MissionMapImage/>
      </Box>
    </Box>
  );
}