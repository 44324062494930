import React, {useState} from 'react';
import { Tooltip } from '@mui/material';
import styled from 'styled-components';

const PointSize = 30;

interface IMapPointMainCircleProps {
  pointSize?: number;
  color?: string;
  empty?: boolean;
  shadow?: boolean;
}

export const MapPointMainCircle = styled.div<IMapPointMainCircleProps>`
  position: absolute;
  width: ${props => props.pointSize ?? PointSize}px;
  height: ${props => props.pointSize ?? PointSize}px;
  transform: translate(-50%, -50%);
  background-color: ${props => props.empty ? 'white' : props.color ?? 'white'};
  border-color: ${props => props.color ?? '#dbdbdb'};
  border-radius: 50%;
  border-width: ${props => (props.pointSize ?? PointSize) / 6}px;
  border-style: solid;
  box-sizing: content-box;
  box-shadow: ${props => props.shadow ? '0 5px 10px rgba(0, 0, 0, 0.15)' : 'none'};
`;

interface IMapPointContainerProps {
  x: string | number;
  y: string | number;
  isActive: boolean;
}

export const MapPointContainer = styled.div<IMapPointContainerProps>`
  position: absolute;
  left: ${props => props.x}px;
  top: ${props => props.y}px;
  cursor: ${props => props.isActive ? 'pointer' : 'default'};
`;

interface IMapPointProps {
  id: number;
  x: string | number;
  y: string | number;
  empty?: boolean;
  color?: string;
  tooltipTitle?: string | number | JSX.Element;
  onMouseMove?: (e: React.MouseEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
  onDoubleClick?: (e: React.MouseEvent) => void;
  pointerEvents?: React.CSSProperties["pointerEvents"];
  onContextMenu?: (e: React.MouseEvent) => void;
}

export const MapPoint = ({
  id,
  x,
  y,
  empty,
  color,
  tooltipTitle,
  onMouseMove,
  onClick,
  onDoubleClick,
  pointerEvents,
  onContextMenu,
}: IMapPointProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <MapPointContainer
      id={`point-${id}`}
      className={`map-point mp-${id}`}
      x={x}
      y={y}
      isActive
      onMouseMove={onMouseMove}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onContextMenu={onContextMenu}
      style={{
        pointerEvents: pointerEvents
      }}
    >
      <Tooltip
        arrow
        open={showTooltip}
        title={tooltipTitle ?? id}
        placement="top"
      >
        <MapPointMainCircle
          color={color}
          empty={empty}
        />
      </Tooltip>
    </MapPointContainer>
  );
};