import { Box, Button, Card } from "@mui/material";
import { RobotsDropdown } from "../../../common/Dropdowns";
import { IMissionManagementState, useMissionManagementContext } from "../../../../contexts/missionManagementContext";
import { useState } from "react";
import { Robot } from "../../../../api/robots";
import { Cancel } from "@mui/icons-material";
import { Schedule } from "../../../../api/schedules";
import { useActiveRobotMissions } from "../../../../hooks/useActiveRobotMissions";

interface AddNewRobotCardProps {
  identifier?: string;
}

export const AddNewRobotCard = ({
  identifier,
}: AddNewRobotCardProps) => {
  const {
    addNewMission,
    updateMission,
    removeMission,
  } = useMissionManagementContext();

  const showRemoveMissionButton = !!identifier;

  const {roverPublicIdMissionMap} = useActiveRobotMissions();

  const [selectedRobot, setSelectedRobot] = useState<Robot | null>(null);

  const onClickOpenVideo = () => {
    if (selectedRobot) {
      const activeRobotMission = roverPublicIdMissionMap?.get(selectedRobot.public_id);
      const initialState: Partial<IMissionManagementState> = {
        robotDeviceId: selectedRobot.device_id,
      };

      if (activeRobotMission) {
        initialState.mission = activeRobotMission;
        initialState.schedule = {id: activeRobotMission.schedule_id} as Schedule;
      }

      if (identifier) {
        updateMission(identifier, initialState, true);
      } else {
        addNewMission(selectedRobot.device_id, initialState);
      }
    }

    setSelectedRobot(null);
  }

  return (
    <Card
      sx={{
        height: '100%',
        padding: 1,
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="10px"
      >
        <RobotsDropdown
          selectedRobot={selectedRobot}
          setSelectedRobot={setSelectedRobot}
        />
        <Button
          sx={{width: '250px'}}
          disabled={!selectedRobot}
          onClick={onClickOpenVideo}
        >
          Open Video
        </Button>
      </Box>
      {showRemoveMissionButton &&
        <Cancel
          style={{
            position: 'absolute',
            cursor: 'pointer',
            top: '5px',
            right: '5px'
          }}
          onClick={() => removeMission(identifier)}
        />
      }
    </Card>
  );
}